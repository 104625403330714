export const VISUAL_CUE_TYPE_STICKERS = 'stickers';
export const VISUAL_CUE_TYPE_GESTURES = 'gestures';
export const VISUAL_CUE_TYPE_POINTERS = 'pointers';

export const VISUAL_CUE_VALUES = {
  [VISUAL_CUE_TYPE_STICKERS]: {
    NEW: 'new',
    WOW: 'wow',
    GOOD_JOB: 'good-job',
    PERFECT: 'perfect',
    DONE: 'done',
    QUESTION_MARK: 'question-mark',
    EXCLAMATION_MARK: 'exclamation-mark',
    URGENT: 'urgent',
    MODERN_CURSOR: 'modern-cursor',
    TRADITIONAL_CURSOR: 'traditional-cursor',
    STAR: 'star',
    FINGER: 'finger',
    ARROW: 'arrow',
    LIGHTNING: 'lightning',
    HEART: 'heart',
  },
  [VISUAL_CUE_TYPE_GESTURES]: {
    HAND_TAP: 'hand-tap',
    HAND_MOVE: 'hand-move',
    HAND_SCROLL_LEFT: 'hand-scroll-left',
    HAND_SCROLL_RIGHT: 'hand-scroll-right',
    HAND_SCROLL_HORIZONTAL: 'hand-scroll-horizontal',
    HAND_SCROLL_VERTICAL: 'hand-scroll-vertical',
    HAND_SCROLL_UP: 'hand-scroll-up',
    HAND_SCROLL_DOWN: 'hand-scroll-down',
    HAND_SWIPE_LEFT: 'hand-swipe-left',
    HAND_SWIPE_RIGHT: 'hand-swipe-right',
    FINGER_TAP: 'finger-tap',
    FINGER_MOVE: 'finger-move',
    FINGER_SCROLL_LEFT: 'finger-scroll-left',
    FINGER_SCROLL_RIGHT: 'finger-scroll-right',
    FINGER_SCROLL_HORIZONTAL: 'finger-scroll-horizontal',
    FINGER_SCROLL_VERTICAL: 'finger-scroll-vertical',
    FINGER_SCROLL_UP: 'finger-scroll-up',
    FINGER_SCROLL_DOWN: 'finger-scroll-down',
    FINGER_SWIPE_LEFT: 'finger-swipe-left',
    FINGER_SWIPE_RIGHT: 'finger-swipe-right',
  },
  [VISUAL_CUE_TYPE_POINTERS]: {
    MODERN: 'modern',
    LONG: 'long',
    '3D_HAND': '3d-hand',
    FINGER: 'pointer-finger',
    CLASSIC: 'classic',
    CURVE: 'curve',
    SWIRL: 'swirl',
    DRAWING: 'drawing',
    LINE: 'line',
  },
};

export const visualCues = [
  {
    value: VISUAL_CUE_VALUES.stickers.NEW,
    name: 'New',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/stickers/new.svg',
    primaryColor: '#1260EB',
    secondaryColor: '#FFB443',
    borderColor: '#FFFFFF',
    type: VISUAL_CUE_TYPE_STICKERS,
    animation: 'scaleInOut',
  },
  {
    value: VISUAL_CUE_VALUES.stickers.WOW,
    name: 'Wow',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/stickers/wow.svg',
    primaryColor: '#5E21F1',
    secondaryColor: '#FFB443',
    borderColor: '#FFFFFF',
    type: VISUAL_CUE_TYPE_STICKERS,
    animation: 'scaleInOut',
  },
  {
    value: VISUAL_CUE_VALUES.stickers.GOOD_JOB,
    name: 'Good Job',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/stickers/good-job.svg',
    primaryColor: '#FAB70B',
    borderColor: '#FFFFFF',
    type: VISUAL_CUE_TYPE_STICKERS,
    animation: 'scaleInOut',
  },
  {
    value: VISUAL_CUE_VALUES.stickers.PERFECT,
    name: 'Perfect',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/stickers/101.svg',
    primaryColor: '#F93B21',
    borderColor: '#FFFFFF',
    type: VISUAL_CUE_TYPE_STICKERS,
    animation: 'scaleInOut',
  },
  {
    value: VISUAL_CUE_VALUES.stickers.DONE,
    name: 'Done',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/stickers/done.svg',
    primaryColor: '#893EFF',
    borderColor: '#FFFFFF',
    type: VISUAL_CUE_TYPE_STICKERS,
    animation: 'scaleInOut',
  },
  {
    value: VISUAL_CUE_VALUES.stickers.QUESTION_MARK,
    name: 'Question Mark',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/stickers/question-mark.svg',
    primaryColor: '#811EF2',
    borderColor: '#FFFFFF',
    type: VISUAL_CUE_TYPE_STICKERS,
    animation: 'scaleInOut',
  },
  {
    value: VISUAL_CUE_VALUES.stickers.EXCLAMATION_MARK,
    name: 'Exclamation Mark',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/stickers/exclamation-mark.svg',
    primaryColor: '#F28C1E',
    borderColor: '#FFFFFF',
    type: VISUAL_CUE_TYPE_STICKERS,
    animation: 'scaleInOut',
  },
  {
    value: VISUAL_CUE_VALUES.stickers.URGENT,
    name: 'Urgent',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/stickers/urgent.svg',
    primaryColor: '#F24E1E',
    borderColor: '#FFFFFF',
    type: VISUAL_CUE_TYPE_STICKERS,
    animation: 'scaleInOut',
  },
  {
    value: VISUAL_CUE_VALUES.stickers.MODERN_CURSOR,
    name: 'Modern Cursor',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/stickers/cursor.svg',
    primaryColor: '#1570EF',
    borderColor: '#F5FAFF',
    type: VISUAL_CUE_TYPE_STICKERS,
    animation: 'scaleInOut',
  },
  {
    value: VISUAL_CUE_VALUES.stickers.TRADITIONAL_CURSOR,
    name: 'Traditional Cursor',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/stickers/traditional-cursor.svg',
    type: VISUAL_CUE_TYPE_STICKERS,
    animation: 'scaleInOut',
  },
  {
    value: VISUAL_CUE_VALUES.stickers.STAR,
    name: 'Star',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/stickers/star.svg',
    primaryColor: '#FFC319',
    borderColor: '#FFFFFF',
    type: VISUAL_CUE_TYPE_STICKERS,
    animation: 'scaleInOut',
  },
  {
    value: VISUAL_CUE_VALUES.stickers.FINGER,
    name: 'Finger',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/stickers/finger.svg',
    primaryColor: '#FFB443',
    borderColor: '#FFFFFF',
    type: VISUAL_CUE_TYPE_STICKERS,
    animation: 'scaleInOut',
  },
  {
    value: VISUAL_CUE_VALUES.stickers.ARROW,
    name: 'Arrow',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/stickers/arrow.svg',
    primaryColor: '#64D181',
    borderColor: '#FFFFFF',
    type: VISUAL_CUE_TYPE_STICKERS,
    animation: 'scaleInOut',
  },
  {
    value: VISUAL_CUE_VALUES.stickers.LIGHTNING,
    name: 'Lightning',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/stickers/lightning.svg',
    primaryColor: '#FAB70B',
    borderColor: '#FFFFFF',
    type: VISUAL_CUE_TYPE_STICKERS,
    animation: 'scaleInOut',
  },
  {
    value: VISUAL_CUE_VALUES.stickers.HEART,
    name: 'Heart',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/stickers/heart.svg',
    primaryColor: '#FF8577',
    borderColor: '#FFFFFF',
    type: VISUAL_CUE_TYPE_STICKERS,
    animation: 'scaleInOut',
  },
  {
    value: VISUAL_CUE_VALUES.gestures.HAND_TAP,
    name: 'Tap',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/gestures/hand/tap.svg',
    type: VISUAL_CUE_TYPE_GESTURES,
    subtype: 'hand',
    animation: 'tap',
    primaryColor: '#000000',
    secondaryColor: '#105BFB',
  },
  {
    value: VISUAL_CUE_VALUES.gestures.HAND_MOVE,
    name: 'Move',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/gestures/hand/move.svg',
    type: VISUAL_CUE_TYPE_GESTURES,
    subtype: 'hand',
    animation: 'move',
    primaryColor: '#000000',
    secondaryColor: '#105BFB',
  },
  {
    value: VISUAL_CUE_VALUES.gestures.HAND_SCROLL_LEFT,
    name: 'Scroll Left',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/gestures/hand/scroll-left.svg',
    type: VISUAL_CUE_TYPE_GESTURES,
    subtype: 'hand',
    animation: 'scrollLeft',
    primaryColor: '#000000',
    secondaryColor: '#105BFB',
  },
  {
    value: VISUAL_CUE_VALUES.gestures.HAND_SCROLL_RIGHT,
    name: 'Scroll Right',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/gestures/hand/scroll-right.svg',
    type: VISUAL_CUE_TYPE_GESTURES,
    subtype: 'hand',
    animation: 'scrollRight',
    primaryColor: '#000000',
    secondaryColor: '#105BFB',
  },
  {
    value: VISUAL_CUE_VALUES.gestures.HAND_SCROLL_HORIZONTAL,
    name: 'Scroll Horizontal',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/gestures/hand/scroll-horizontal.svg',
    type: VISUAL_CUE_TYPE_GESTURES,
    subtype: 'hand',
    animation: 'scrollHorizontal',
    primaryColor: '#000000',
    secondaryColor: '#105BFB',
  },
  {
    value: VISUAL_CUE_VALUES.gestures.HAND_SCROLL_VERTICAL,
    name: 'Scroll Vertical',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/gestures/hand/scroll-vertical.svg',
    type: VISUAL_CUE_TYPE_GESTURES,
    subtype: 'hand',
    animation: 'scrollVertical',
    primaryColor: '#000000',
    secondaryColor: '#105BFB',
  },
  {
    value: VISUAL_CUE_VALUES.gestures.HAND_SCROLL_UP,
    name: 'Scroll Up',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/gestures/hand/scroll-up.svg',
    type: VISUAL_CUE_TYPE_GESTURES,
    subtype: 'hand',
    animation: 'scrollUp',
    primaryColor: '#000000',
    secondaryColor: '#105BFB',
  },
  {
    value: VISUAL_CUE_VALUES.gestures.HAND_SCROLL_DOWN,
    name: 'Scroll Down',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/gestures/hand/scroll-down.svg',
    type: VISUAL_CUE_TYPE_GESTURES,
    subtype: 'hand',
    animation: 'scrollDown',
    primaryColor: '#000000',
    secondaryColor: '#105BFB',
  },
  {
    value: VISUAL_CUE_VALUES.gestures.HAND_SWIPE_LEFT,
    name: 'Swipe Left',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/gestures/hand/swipe-left.svg',
    type: VISUAL_CUE_TYPE_GESTURES,
    subtype: 'hand',
    animation: 'swipeLeft',
    primaryColor: '#000000',
    secondaryColor: '#105BFB',
  },
  {
    value: VISUAL_CUE_VALUES.gestures.HAND_SWIPE_RIGHT,
    name: 'Swipe Right',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/gestures/hand/swipe-right.svg',
    type: VISUAL_CUE_TYPE_GESTURES,
    subtype: 'hand',
    animation: 'swipeRight',
    primaryColor: '#000000',
    secondaryColor: '#105BFB',
  },
  {
    value: VISUAL_CUE_VALUES.gestures.FINGER_TAP,
    name: 'Finger Tap',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/gestures/finger/tap.svg',
    type: VISUAL_CUE_TYPE_GESTURES,
    subtype: 'finger',
    animation: 'tap',
    primaryColor: '#000000',
    secondaryColor: '#105BFB',
  },
  {
    value: VISUAL_CUE_VALUES.gestures.FINGER_MOVE,
    name: 'Finger Move',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/gestures/finger/move.svg',
    type: VISUAL_CUE_TYPE_GESTURES,
    subtype: 'finger',
    animation: 'move',
    primaryColor: '#000000',
    secondaryColor: '#105BFB',
  },
  {
    value: VISUAL_CUE_VALUES.gestures.FINGER_SCROLL_LEFT,
    name: 'Finger Scroll Left',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/gestures/finger/scroll-left.svg',
    type: VISUAL_CUE_TYPE_GESTURES,
    subtype: 'finger',
    animation: 'scrollLeft',
    primaryColor: '#000000',
    secondaryColor: '#105BFB',
  },
  {
    value: VISUAL_CUE_VALUES.gestures.FINGER_SCROLL_RIGHT,
    name: 'Finger Scroll Right',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/gestures/finger/scroll-right.svg',
    type: VISUAL_CUE_TYPE_GESTURES,
    subtype: 'finger',
    animation: 'scrollRight',
    primaryColor: '#000000',
    secondaryColor: '#105BFB',
  },
  {
    value: VISUAL_CUE_VALUES.gestures.FINGER_SCROLL_HORIZONTAL,
    name: 'Finger Scroll Horizontal',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/gestures/finger/scroll-horizontal.svg',
    type: VISUAL_CUE_TYPE_GESTURES,
    subtype: 'finger',
    animation: 'scrollHorizontal',
    primaryColor: '#000000',
    secondaryColor: '#105BFB',
  },
  {
    value: VISUAL_CUE_VALUES.gestures.FINGER_SCROLL_VERTICAL,
    name: 'Finger Scroll Vertical',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/gestures/finger/scroll-vertical.svg',
    type: VISUAL_CUE_TYPE_GESTURES,
    subtype: 'finger',
    animation: 'scrollVertical',
    primaryColor: '#000000',
    secondaryColor: '#105BFB',
  },
  {
    value: VISUAL_CUE_VALUES.gestures.FINGER_SCROLL_UP,
    name: 'Finger Scroll Up',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/gestures/finger/scroll-up.svg',
    type: VISUAL_CUE_TYPE_GESTURES,
    subtype: 'finger',
    animation: 'scrollUp',
    primaryColor: '#000000',
    secondaryColor: '#105BFB',
  },
  {
    value: VISUAL_CUE_VALUES.gestures.FINGER_SCROLL_DOWN,
    name: 'Finger Scroll Down',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/gestures/finger/scroll-down.svg',
    type: VISUAL_CUE_TYPE_GESTURES,
    subtype: 'finger',
    animation: 'scrollDown',
    primaryColor: '#000000',
    secondaryColor: '#105BFB',
  },
  {
    value: VISUAL_CUE_VALUES.gestures.FINGER_SWIPE_LEFT,
    name: 'Finger Swipe Left',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/gestures/finger/swipe-left.svg',
    type: VISUAL_CUE_TYPE_GESTURES,
    subtype: 'finger',
    animation: 'swipeLeft',
    primaryColor: '#000000',
    secondaryColor: '#105BFB',
  },
  {
    value: VISUAL_CUE_VALUES.gestures.FINGER_SWIPE_RIGHT,
    name: 'Finger Swipe Right',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/gestures/finger/swipe-right.svg',
    type: VISUAL_CUE_TYPE_GESTURES,
    subtype: 'finger',
    animation: 'swipeRight',
    primaryColor: '#000000',
    secondaryColor: '#105BFB',
  },
  {
    value: VISUAL_CUE_VALUES.pointers.MODERN,
    name: 'Modern',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/pointers/cursor.svg',
    primaryColor: '#1570EF',
    borderColor: '#F5FAFF',
    type: VISUAL_CUE_TYPE_POINTERS,
    animation: 'moveArrow',
  },
  {
    value: VISUAL_CUE_VALUES.pointers.LONG,
    name: 'Long',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/pointers/arrow.svg',
    primaryColor: '#1570EF',
    borderColor: '#F5FAFF',
    type: VISUAL_CUE_TYPE_POINTERS,
    animation: 'moveArrow',
  },
  {
    value: VISUAL_CUE_VALUES.pointers['3D_HAND'],
    name: '3D Hand',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/pointers/3d-hand.svg',
    type: VISUAL_CUE_TYPE_POINTERS,
    animation: 'moveArrow',
  },
  {
    value: VISUAL_CUE_VALUES.pointers.FINGER,
    name: 'Finger',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/pointers/finger.svg',
    primaryColor: '#1570EF',
    borderColor: '#FFFFFF',
    type: VISUAL_CUE_TYPE_POINTERS,
    animation: 'moveArrow',
  },
  {
    value: VISUAL_CUE_VALUES.pointers.CLASSIC,
    name: 'Classic',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/pointers/classic.svg',
    primaryColor: '#1570EF',
    borderColor: '#F5FAFF',
    type: VISUAL_CUE_TYPE_POINTERS,
    animation: 'moveArrow',
  },
  {
    value: VISUAL_CUE_VALUES.pointers.CURVE,
    name: 'Curve',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/pointers/curve.svg',
    primaryColor: '#1570EF',
    borderColor: '#F5FAFF',
    type: VISUAL_CUE_TYPE_POINTERS,
    animation: 'moveArrow',
  },
  {
    value: VISUAL_CUE_VALUES.pointers.SWIRL,
    name: 'Swirl',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/pointers/swirl.svg',
    primaryColor: '#1570EF',
    borderColor: '#F5FAFF',
    type: VISUAL_CUE_TYPE_POINTERS,
    animation: 'moveArrow',
  },
  {
    value: VISUAL_CUE_VALUES.pointers.DRAWING,
    name: 'Drawing',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/pointers/drawing.svg',
    primaryColor: '#1260EB',
    type: VISUAL_CUE_TYPE_POINTERS,
    animation: 'moveArrow',
  },
  {
    value: VISUAL_CUE_VALUES.pointers.LINE,
    name: 'Line',
    url: 'https://s3.eu-west-3.amazonaws.com/jimo.asset-files/visual-cues/pointers/line.svg',
    primaryColor: '#1260EB',
    type: VISUAL_CUE_TYPE_POINTERS,
    animation: 'moveArrow',
  },
];
