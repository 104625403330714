import classNames from 'classnames';
import React from 'react';
import './_Styles.scss';

const positions = [
  ['top-left', 'top-center', 'top-right'],
  ['center-left', 'center', 'center-right'],
  ['bottom-left', 'bottom-center', 'bottom-right'],
];

const PositionInElementPicker = ({value, onChange}) => {
  return (
    <div className="position-in-element-picker">
      {positions.map((row, index) => (
        <div className="position-in-element-row">
          {row.map((position) => (
            <div
              className={classNames('position-in-element-item', {
                selected: value === position,
              })}
              onClick={() => {
                onChange(position);
              }}>
              <div className="position-in-element-item-dot" />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default PositionInElementPicker;
