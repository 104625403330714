import classNames from 'classnames';
import React, {useEffect, useState} from 'react';
import {visualCues} from '..';
import './_Styles.scss';
import {getVisualCueAnimation} from './utils';

const BlockVisualCue = ({block, disableAnimation = false}) => {
  const [svgContent, setSvgContent] = useState(null);

  const {value, style} = block || {};

  const selectedVisualCue = visualCues.find((s) => s.value === value);

  useEffect(() => {
    if (selectedVisualCue == null) {
      return;
    }

    fetch(selectedVisualCue.url)
      .then((response) => response.text())
      .then((data) => setSvgContent(data))
      .catch((error) => console.error('Error loading SVG:', error));
  }, [selectedVisualCue]);

  if (block == null || svgContent == null) {
    return null;
  }

  const {
    primaryColor = selectedVisualCue?.primaryColor,
    secondaryColor = selectedVisualCue?.secondaryColor,
    borderColor = selectedVisualCue?.borderColor,
  } = style;

  // Customize colors using a wrapper function
  const customizeSvgColors = (svg) =>
    svg
      .replace(/fill={primaryColor}/g, `fill="${primaryColor}"`)
      .replace(/fill={secondaryColor}/g, `fill="${secondaryColor}"`)
      .replace(/fill={borderColor}/g, `fill="${borderColor}"`)
      .replace(/stroke={primaryColor}/g, `stroke="${primaryColor}"`)
      .replace(/stroke={secondaryColor}/g, `stroke="${secondaryColor}"`)
      .replace(/stroke={borderColor}/g, `stroke="${borderColor}"`);

  const svg = customizeSvgColors(svgContent);

  return (
    <div
      className={classNames('block-visual-cue', {})}
      style={{
        animation:
          disableAnimation === true
            ? undefined
            : getVisualCueAnimation(style, selectedVisualCue?.animation),
      }}
      dangerouslySetInnerHTML={{__html: svg}}
    />
  );
};

export default BlockVisualCue;
