import classNames from 'classnames';
import React from 'react';
import './_Styles.scss';

const Section = ({
  title,
  titleExtra,
  description,
  children,
  className = '',
}) => {
  return (
    <div className={classNames('section', className)}>
      {(title || titleExtra || description) && (
        <div className="section-title-wrapper">
          <div className="section-title subtitle-4">
            {title}
            {titleExtra && (
              <div className="section-title-extra">{titleExtra}</div>
            )}
          </div>
          {description && (
            <div className="section-description body-4 n-700">
              {description}
            </div>
          )}
        </div>
      )}

      {children && <div className="section-content">{children}</div>}
    </div>
  );
};

export default Section;
