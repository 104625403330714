import {Elements} from '@stripe/react-stripe-js';
import {dataActions} from 'actions';
import classnames from 'classnames';
import commaNumber from 'comma-number';
import Button from 'components/Button';
import DefaultLoader from 'components/Loader';
import {LineProgress} from 'components/Progress';
import {toastDanger} from 'components/Toaster';
import {stripePromised} from 'conf/stripe';
import dayjs from 'dayjs';
import {crispHelpers, errorHelpers} from 'helpers';
import {hasFlag} from 'helpers/bitwise';
import {useAddonCancel} from 'hooks/useAddonCancel';
import {useAddonCheckout} from 'hooks/useAddonCheckout';
import {useCancelPlan} from 'hooks/useCancelPlan';
import {useInvoiceUpcoming} from 'hooks/useInvoiceUpcoming';
import {usePlanUsage} from 'hooks/usePlanUsage';
import {useTrialRemaining} from 'hooks/useTrialRemaining';
import {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom/cjs/react-router-dom';
import {ROUTE_SETTINGS_PLAN} from 'router/routes.const';
import {SettingsBody} from 'scenes/Settings/components/Body';
import {dataSelector, generalSelector} from 'selectors';
import {subscriptionService} from 'services';
import {getDayToReset, isPaying, isTrying} from 'services/project';
import {
  ADDON_ADDITIONAL_ENVIRONMENT,
  ADDON_ADDITIONAL_SEAT,
  ADDON_ADDITIONAL_TRACKER,
  ADDON_EXTRA_MAU_ID,
  ADDON_MAU,
  ADDON_WHITE_LABEL_ID,
  F_PRICING_V0,
  PLAN_GROWTH_ID,
  PLAN_STARTUP_ID,
  displayPrice,
  getAddonMonthlyPrice,
  getAddonYearlyPrice,
  hasAddon,
  hasAnnualBilling,
  hasSaasMantraPlan,
} from 'services/subscription';
import {Swaler} from 'swaler';
import './_styles.scss';
import {BillingCardTrial} from './components/CardTrial';

const logger = new Swaler('SettingsBilling');

function SettingsBilling() {
  const history = useHistory();
  const dispatch = useDispatch();

  const {mau} = usePlanUsage();
  const {cancel, isCanceling} = useCancelPlan();
  const {
    fetch: fetchUpcoming,
    invoice,
    isFetching: isFetchingInvoice,
  } = useInvoiceUpcoming();
  const {cancel: cancelAddon} = useAddonCancel();
  const {start} = useAddonCheckout();
  const {daysTotal} = useTrialRemaining();

  const setSubscriptionPlans = (plans) =>
    dispatch(dataActions.setSubscriptionPlans(plans));
  const setSubscriptionAddons = (addons) =>
    dispatch(dataActions.setSubscriptionAddons(addons));

  const project = useSelector((state) => generalSelector.getProject(state));
  const subscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );
  const plans = useSelector((state) =>
    dataSelector.getSubscriptionsPlans(state)
  );
  const addons = useSelector((state) =>
    dataSelector.getSubscriptionsAddons(state)
  );

  const [creatingPortalUrl, setCreatingPortalUrl] = useState(false);

  const handleManageSubscription = async () => {
    setCreatingPortalUrl(true);

    const portalUrl = await subscriptionService.createStripePortalSession();

    if (portalUrl == null) {
      return;
    }
    window.location = portalUrl;
  };
  const fetchPlansAndAddons = async () => {
    const plans = await subscriptionService.getSubscriptionPlans();
    const addons = await subscriptionService.getSubscriptionAddons();

    setSubscriptionPlans(plans);
    setSubscriptionAddons(addons);
  };

  const {data: invoices = [], isLoading: isLoadingInvoices} = useQuery({
    queryKey: 'invoices',
    queryFn: () => subscriptionService.getInvoices(),
    refetchOnWindowFocus: false,
    onError: (err) => {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Fetching invoices failed with error ', code);
      toastDanger([title, message], {actions});
    },
  });

  useEffect(() => {
    if (isPaying()) {
      fetchUpcoming();
    }
  }, [hasAddon(ADDON_WHITE_LABEL_ID)]);
  useEffect(() => {
    if (plans.length === 0 || addons.length === 0) {
      fetchPlansAndAddons();
    }
  }, []);

  const dayToReset = getDayToReset();

  if (plans.length === 0 || addons.length === 0) {
    return (
      <SettingsBody className="s-settings-billing">
        <div className="title-3">Billing</div>
        <DefaultLoader width="12px" />
      </SettingsBody>
    );
  }

  const addonWhiteLabel = addons.find((a) => a.uid === ADDON_WHITE_LABEL_ID);
  const addonAdditionalSeat = addons.find(
    (a) => a.uid === ADDON_ADDITIONAL_SEAT
  );
  const addonAdditionalEnvironment = addons.find(
    (a) => a.uid === ADDON_ADDITIONAL_ENVIRONMENT
  );
  const addonAdditionalTracker = addons.find(
    (a) => a.uid === ADDON_ADDITIONAL_TRACKER
  );

  const plan = plans.find((p) => p.uid === subscription.plan);
  const extraEnvironments =
    plan != null ? project.thresholdEnvironments - plan.environments : 0;
  const extraSeats = plan != null ? project.thresholdSeats - plan.seats : 0;
  const extraTrackers =
    plan != null ? project.thresholdTrackers - plan.trackers : 0;

  return (
    <SettingsBody className="s-settings-billing">
      <div className="title-3">Billing</div>
      {isTrying() === true && <BillingCardTrial />}
      <div className="settings-card card-current-plan">
        <div className="card-header">
          <div className="subtitle-3">Plan</div>
        </div>
        <div className="card-body">
          {isPaying() === false &&
            isTrying() === false &&
            hasSaasMantraPlan(project) === false && (
              <div className="billing-plan-details">
                <div className="left-side">
                  <div className="title-4">Free</div>
                  <div className="body-2 n-700">{displayPrice(0)} /month</div>
                </div>
                <div className="right-side">
                  <Button
                    primary
                    onClick={() => history.push(ROUTE_SETTINGS_PLAN)}>
                    Explore plans
                  </Button>
                </div>
              </div>
            )}
          {(isTrying() === true ||
            isPaying() === true ||
            hasSaasMantraPlan(project)) && (
            <div
              className={classnames(
                'billing-plan-details',
                `plan-${subscription.plan.toLowerCase()}`
              )}>
              <div className="main-wrapper">
                <div className="left-side">
                  <div className="plan-logo-title-wrapper">
                    <div className="plan-title title-4">
                      {subscription.plan.toLowerCase()}
                    </div>
                  </div>
                  <div className="body-2 n-700">
                    {isTrying() === true
                      ? `Free for ${daysTotal} days`
                      : hasSaasMantraPlan(project) === true
                      ? 'Saas mantra applied'
                      : ''}
                  </div>
                </div>
                <div className="right-side">
                  <Button thin danger onClick={cancel} loading={isCanceling}>
                    Cancel {isTrying() === true ? 'trial' : 'plan'}
                  </Button>
                </div>
              </div>
              {isPaying() === true && (
                <>
                  <div className="addon-mau-wrapper">
                    <div className="left-side">
                      <div className="subtitle-3">
                        {commaNumber(subscription.extraJimers)} MAU
                      </div>
                    </div>
                    <div className="right-side">
                      {hasFlag(F_PRICING_V0, subscription.extraFlags) ? (
                        <Button
                          thin
                          onClick={() => {
                            crispHelpers.startCrispThread(
                              `Hi! I'd like to change my MAU limit but I'm on a old version of your pricing. Can you help me?`
                            );
                          }}>
                          Contact us to change your MAU limit
                        </Button>
                      ) : (
                        <Button
                          thin
                          onClick={() => {
                            start(ADDON_MAU);
                          }}>
                          Change MAU limit
                        </Button>
                      )}
                    </div>
                  </div>
                  <div className="addon-seats-wrapper">
                    <div className="left-side">
                      <div className="subtitle-3">{plan.seats} seats</div>
                      <div className="body-3 n-700">
                        {project.thresholdSeats === plan.seats
                          ? 'Included'
                          : project.seats === -2
                          ? 'Custom'
                          : hasAnnualBilling()
                          ? `+${extraSeats} additional seats - ${displayPrice(
                              getAddonYearlyPrice(addonAdditionalSeat) *
                                12 *
                                extraSeats
                            )} / year`
                          : `+${extraSeats} additional seats - ${displayPrice(
                              getAddonMonthlyPrice(addonAdditionalSeat) *
                                extraSeats
                            )} / month`}
                      </div>
                    </div>
                  </div>
                  <div className="addon-environments-wrapper">
                    <div className="left-side">
                      <div className="subtitle-3">
                        {plan.environments} environments
                      </div>
                      <div className="body-3 n-700">
                        {project.thresholdEnvironments === plan.environments
                          ? 'Included'
                          : project.thresholdEnvironments === -2
                          ? 'Custom'
                          : hasAnnualBilling()
                          ? `+${extraEnvironments} additional environments - ${displayPrice(
                              getAddonYearlyPrice(addonAdditionalEnvironment) *
                                12 *
                                extraEnvironments
                            )} / year`
                          : `+${extraEnvironments} additional environments - ${displayPrice(
                              getAddonMonthlyPrice(addonAdditionalEnvironment) *
                                extraEnvironments
                            )} / month`}
                      </div>
                    </div>
                  </div>
                  <div className="addon-trackers-wrapper">
                    <div className="left-side">
                      <div className="subtitle-3">{plan.trackers} trackers</div>
                      <div className="body-3 n-700">
                        {project.thresholdTrackers === plan.trackers
                          ? 'Included'
                          : project.thresholdTrackers === -1
                          ? 'Unlimited'
                          : hasAnnualBilling()
                          ? `+${extraTrackers} additional trackers - ${displayPrice(
                              getAddonYearlyPrice(addonAdditionalTracker) *
                                12 *
                                extraTrackers
                            )} / year`
                          : `+${extraTrackers} additional trackers - ${displayPrice(
                              getAddonMonthlyPrice(addonAdditionalTracker) *
                                extraTrackers
                            )} / month`}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {hasAddon(ADDON_WHITE_LABEL_ID) === true && (
                <div className="addon-white-label-wrapper">
                  <div className="left-side">
                    <div className="subtitle-3">+ Hide Jimo Label</div>
                    <div className="body-3 n-700">
                      {subscription.plan === PLAN_GROWTH_ID
                        ? 'Included'
                        : hasAnnualBilling()
                        ? `${displayPrice(
                            getAddonYearlyPrice(addonWhiteLabel) * 12
                          )} / year`
                        : `${displayPrice(
                            getAddonMonthlyPrice(addonWhiteLabel)
                          )} / month`}
                    </div>
                  </div>
                  <div className="right-side">
                    {subscription.plan === PLAN_STARTUP_ID && (
                      <>
                        {hasFlag(F_PRICING_V0, subscription.extraFlags) ? (
                          <Button
                            thin
                            onClick={() => {
                              crispHelpers.startCrispThread(
                                `Hi! I'd like to change my WhiteLabel addon but I'm on a old version of your pricing. Can you help me?`
                              );
                            }}>
                            Contact us to cancel your addon
                          </Button>
                        ) : (
                          <Button
                            danger
                            thin
                            onClick={() => cancelAddon(ADDON_WHITE_LABEL_ID)}>
                            Cancel add-on
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
          {isPaying() === true && (
            <div className="plan-details">
              <div className="item-detail">
                <div className="body-3 n-700">Billing period</div>
                <div className="subtitle-3">
                  {hasAnnualBilling() ? 'Yearly' : 'Monthly'}
                </div>
              </div>
              <div className="item-detail">
                <div className="body-3 n-700">Next payment</div>
                <div className="subtitle-3">
                  {isFetchingInvoice === true && <DefaultLoader width="10px" />}
                  {invoice != null
                    ? dayjs(subscription.stripeCurrentPeriodEnd * 1000).format(
                        'MMMM DD, YYYY'
                      )
                    : '-'}
                </div>
              </div>
              <div className="item-detail">
                <div className="body-3 n-700">Upcoming amount</div>
                <div className="subtitle-3">
                  {isFetchingInvoice === true && <DefaultLoader width="10px" />}
                  {invoice != null
                    ? `${displayPrice(Math.round(invoice.billingTotal / 100))}`
                    : '-'}
                  {invoice != null && invoice.billingTax > 0 && (
                    <div className="body-3 n-500">
                      {displayPrice(Math.round(invoice.billingSubtotal / 100))}{' '}
                      +{displayPrice(Math.round(invoice.billingTax / 100))} of
                      taxes
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {(isTrying() || isPaying()) && (
        <div
          className={classnames('settings-card card-mau-usage', {
            exceeded: mau.exceeded,
            percent90: mau.percentage >= 90 && mau.percentage < 100,
          })}>
          <div className="card-header">
            <div className="left-side">
              <div className="card-title subtitle-3">
                {mau.exceeded === true ? (
                  <>
                    <i className="isax isax-danger5"></i>
                    You have reached your MAU limit
                  </>
                ) : mau.percentage >= 90 ? (
                  <>
                    <i className="isax isax-danger5"></i>
                    You have almost reached your MAU limit
                  </>
                ) : (
                  'MAU for the current month'
                )}
              </div>
              <div className="body-3 n-700">
                If you reach the limit, new active users will not view your
                experiences. Users active this month can still view them.
              </div>
            </div>
          </div>
          <div className="card-body">
            <LineProgress lineHeight={17} value={mau.percentage} />
            <div className="additional-wrapper">
              <div className="body-3 n-700">
                {commaNumber(mau.current > mau.max ? mau.max : mau.current)} /{' '}
                {commaNumber(mau.max)} MAU
                {hasAddon(ADDON_EXTRA_MAU_ID) && (
                  <span className="n-600">
                    {' '}
                    (+{subscription.extraJimers} MAU until{' '}
                    {dayjs().add(dayToReset, 'day').format('MMM DD, YYYY')})
                  </span>
                )}
              </div>

              <div className="body-3 n-700">
                {dayToReset === 0
                  ? 'Today'
                  : `Reset in ${dayToReset}
                ${dayToReset > 1 ? ' days' : ' day'}`}
              </div>
            </div>
          </div>
        </div>
      )}
      {subscription.paymentMethodBrand != null && (
        <div className="settings-card card-saved-payment-method">
          <div className="card-header">
            <div className="left-side">
              <div className="subtitle-3">Payment method</div>
              <div className="body-3 n-700">
                <i className="isax isax-card"></i>{' '}
                <span className="payment-method-brand">
                  {subscription.paymentMethodBrand}
                </span>
                ending with {subscription.paymentMethodLast4}
              </div>
            </div>
            <div className="right-side">
              <Button
                thin
                iconLeft="isax isax-edit-2"
                loading={creatingPortalUrl}
                onClick={handleManageSubscription}>
                Update
              </Button>
            </div>
          </div>
        </div>
      )}
      {hasSaasMantraPlan(project) === false && (
        <div className="settings-card card-invoices">
          <div className="card-header">
            <div className="subtitle-3">Invoices</div>
          </div>
          <div className="card-body">
            {isLoadingInvoices === true && <DefaultLoader width="20px" />}
            {isLoadingInvoices === false && invoices.length === 0 && (
              <div className="invoices-empty body-3 n-700">No history yet</div>
            )}
            {isLoadingInvoices === false && invoices.length > 0 && (
              <div className="invoice-list">
                <div className="list-header">
                  <div className="body-3 n-500">Bill date</div>
                  <div className="body-3 n-500">Amount</div>
                  <div className="body-3 n-500">Plan</div>
                  <div className="body-3 n-500">Invoices</div>
                </div>
                {invoices.map((i) => (
                  <div className="item-invoice">
                    <div className="invoice-date body-2">
                      {dayjs.unix(i.created).format('MMMM DD, YYYY')}
                    </div>
                    <div className="invoice-price body-2">
                      {displayPrice(Math.round(i.price / 100))}
                    </div>
                    <div>{i.products.join(', ')}</div>
                    <div className="invoice-link body-2">
                      <a
                        href={i.link}
                        target="_blank"
                        rel="noopener noreferrer">
                        <Button thin>View Invoice</Button>
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </SettingsBody>
  );
}

export default function SettingsBillingWithStripe(props) {
  return (
    <Elements stripe={stripePromised}>
      <SettingsBilling {...props} />
    </Elements>
  );
}
