import classNames from 'classnames';
import React, {useContext} from 'react';
import ClickableBlockOverlay from '../../../Poke/components/ClickableBlockOverlay';
import {
  BLOCK_CHECKLIST_HEADER,
  BLOCK_CHECKLIST_TRIGGER,
} from '../../../Poke/constants/blocks';
import {PokeStateContext} from '../../../Poke/context';
import {CHECKLIST_TRIGGER_TYPE_FLOAT} from '../../constants';
import ChecklistFloatTrigger from '../ChecklistFloatTrigger';
import ChecklistParagraph from '../ChecklistParagraph';
import ChecklistProgress from '../ChecklistProgress';
import ChecklistTitle from '../ChecklistTitle';
import './_Styles.scss';

const ChecklistHeader = ({setHeaderHeight}) => {
  const {
    blocks,
    isMinimized,
    inBuilder,
    selectedBlock,
    onBlockSelected,
    onTaskSelected,
    currentStep,
    setIsMinimized,
  } = useContext(PokeStateContext);

  const {style: stepStyle} = currentStep ?? {};
  const {borderRadius} = stepStyle ?? {};

  const block = blocks.find((b) => b.type === BLOCK_CHECKLIST_HEADER);
  const triggerBlock = blocks.find((b) => b.type === BLOCK_CHECKLIST_TRIGGER);

  const {style} = block;

  return (
    <div
      className={classNames('checklist-header', {
        'is-minimized': isMinimized,
        'poke-block-clickable': inBuilder === true,
        selected: selectedBlock === BLOCK_CHECKLIST_HEADER,
        'is-float': triggerBlock?.style?.type === CHECKLIST_TRIGGER_TYPE_FLOAT,
      })}
      style={{
        ...style,
        borderRadius:
          isMinimized === true
            ? triggerBlock?.style?.type === CHECKLIST_TRIGGER_TYPE_FLOAT
              ? `${triggerBlock?.style?.borderRadius}px`
              : `${borderRadius}px`
            : `${borderRadius}px ${borderRadius}px 0 0`,
      }}
      onClick={(e) => {
        e.stopPropagation();

        if (inBuilder === true) {
          onBlockSelected(BLOCK_CHECKLIST_HEADER);
          onTaskSelected(null);
          return;
        }

        setIsMinimized(!isMinimized);
      }}
      ref={(ref) => {
        if (ref != null) {
          setHeaderHeight(ref.clientHeight);
        }
      }}>
      {isMinimized === true &&
      triggerBlock?.style?.type === CHECKLIST_TRIGGER_TYPE_FLOAT ? (
        <ChecklistFloatTrigger />
      ) : (
        <>
          <ChecklistTitle />
          <ChecklistParagraph />
          <ChecklistProgress />
        </>
      )}

      <ClickableBlockOverlay />
    </div>
  );
};

export default ChecklistHeader;
