import {generalActions} from 'actions';
import Button from 'components/Button';
import Input from 'components/Input';
import {toastDanger, toastSuccess} from 'components/Toaster';
import Toggle from 'components/Toggle';
import copy from 'copy-to-clipboard';
import hmacSHA512 from 'crypto-js/hmac-sha256';
import {errorHelpers} from 'helpers';
import {hasFlag, reverseFlag} from 'helpers/bitwise';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ReactSyntaxHighlighter from 'react-syntax-highlighter';
import {tomorrowNightEighties} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {SettingsBody} from 'scenes/Settings/components/Body';
import {generalSelector} from 'selectors';
import {projectService} from 'services';
import {F_EXTRA_IDENTIFY_VERIFICATION_ENABLED} from 'services/project';
import {Swaler} from 'swaler';
import {SettingsInstallationHeader} from '../../components/SettingsInstallationHeader';
import './_Styles.scss';

const logger = new Swaler('Settings/Identification');

const Identification = () => {
  const dispatch = useDispatch();

  const project = useSelector((state) => generalSelector.getProject(state));

  const uptProject = (data) => dispatch(generalActions.uptProject(data));

  const [isRefetchingKey, setIsRefetchingKey] = useState(false);
  const [isToggleLoading, setIsToggleLoading] = useState(false);

  const fetchIdentifySecret = async (isEnabled, shouldRegenerateKey) =>
    await projectService.updateProjectIdentifyVerification(project.uid, {
      isEnabled,
      shouldRegenerateKey,
    });

  const handleRefetchButtonClick = async () => {
    setIsRefetchingKey(true);
    try {
      const identifySecret = await fetchIdentifySecret(
        hasFlag(F_EXTRA_IDENTIFY_VERIFICATION_ENABLED, project.extraFlags),
        true
      );

      uptProject({
        identifySecret,
      });
      setIsRefetchingKey(false);
      toastSuccess('Secret Key regenerated!', {
        toastId: 'identify-secret-regenerated',
      });
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      setIsRefetchingKey(false);
      logger.error('Identity verification refresh key failed', code);
      toastDanger([title, message], {
        actions,
      });
      throw new Error('IDENTIFY_VERIFICATION_REFRESH_KEY_FAILED');
    } finally {
      setIsRefetchingKey(false);
    }
  };

  const handleToggleClick = async () => {
    setIsToggleLoading(true);
    try {
      const flagsUpdated = reverseFlag(
        F_EXTRA_IDENTIFY_VERIFICATION_ENABLED,
        project.extraFlags
      );

      const isEnabled = hasFlag(
        F_EXTRA_IDENTIFY_VERIFICATION_ENABLED,
        flagsUpdated
      );

      const identifySecret =
        await projectService.updateProjectIdentifyVerification(project.uid, {
          isEnabled,
        });

      uptProject({
        extraFlags: flagsUpdated,
        identifySecret,
      });
      if (isEnabled) {
        toastSuccess([
          'Identity Verification enabled',
          'Use the secret key to activate Identity Verification in your project',
        ]);
      }
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      setIsToggleLoading(false);
      logger.error('Identity verification toggle failed', code);
      toastDanger([title, message], {
        actions,
      });
      throw new Error('IDENTIFY_VERIFICATION_TOGGLE_FAILED');
    } finally {
      setIsToggleLoading(false);
    }
  };

  const hasIdentifyVerificationEnabled = hasFlag(
    F_EXTRA_IDENTIFY_VERIFICATION_ENABLED,
    project.extraFlags
  );

  return (
    <SettingsBody className="s-settings-installation-identification">
      <SettingsInstallationHeader />
      <main>
        <div className="settings-card card-identify-verification">
          <div className="card-header">
            <div className="left-wrapper">
              <span className="subtitle-3">Identify Verification</span>
              <div className="body-3 n-700">
                Verify reached users' identities by verifying their email using
                cryptographic key.{' '}
                <a
                  href="https://help.usejimo.com/help-center/v/for-developers/for-developers/sdk-guides/setup-identify-verification"
                  target="_blank"
                  rel="noreferrer">
                  How to set up <i className="isax isax-export-3" />
                </a>
              </div>
            </div>
            <Toggle
              checked={hasIdentifyVerificationEnabled}
              onChange={handleToggleClick}
              disabled={isToggleLoading}
            />
          </div>
          {hasIdentifyVerificationEnabled && (
            <div className="card-body">
              <div className="left-wrapper">
                <Input
                  value={project.identifySecret}
                  iconRight="isax isax-copy"
                  iconRightProps={{
                    onClick: () => {
                      copy(project.identifySecret);
                      toastSuccess('Secret key copied!', {
                        toastId: 'identify-verification-secret-copied',
                      });
                    },
                  }}
                />
                <div className="body-4 n-700">
                  Copy this secret key to your backend. Do not share this to
                  anyone.
                </div>
              </div>
              <div className="right-wrapper">
                <Button
                  loading={isRefetchingKey}
                  iconLeft="isax isax-refresh" // should be isax-refresh-2
                  onClick={handleRefetchButtonClick}>
                  Regenerate Key
                </Button>
              </div>
            </div>
          )}
        </div>

        <div className="settings-card card-command-identify">
          <div className="card-header">
            <div className="left-wrapper">
              <span className="subtitle-3">
                Identify users with Jimo Javascript SDK
              </span>
              <div className="body-3 n-700">
                After installing Jimo, you can use our SDK commands to identify
                users.{' '}
                <a href="https://help.usejimo.com/help-center/v/for-developers/for-developers/sdk-guides/identify-users">
                  Read complete documentation{' '}
                  <i className="isax isax-export-3"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="card-body">
            <ReactSyntaxHighlighter
              language="javascript"
              style={tomorrowNightEighties}>
              {hasIdentifyVerificationEnabled === true
                ? `
window.jimo.push(['do', 'identify', [identifier, null, identifierSigned]]);

// Example with identify verification enabled (with uuid and signed identifier)
window.jimo.push(['do', 'identify', ['a827280b-d6dd-4808-9986-58070bb6b1ab', null, '${hmacSHA512(
                    'a827280b-d6dd-4808-9986-58070bb6b1ab',
                    project.identifySecret
                  )}']]);
              `
                : `
window.jimo.push(['do', 'identify', [identifier]]);

// Example (uuid)
window.jimo.push(['do', 'identify', ['a827280b-d6dd-4808-9986-58070bb6b1ab']]);
              `}
            </ReactSyntaxHighlighter>
          </div>
        </div>

        <div className="settings-card card-command-attributes">
          <div className="card-header">
            <div className="left-wrapper">
              <span className="subtitle-3">
                Set user attributes with Jimo Javascript SDK
              </span>
              <div className="body-3 n-700">
                After installing Jimo, you can use our SDK commands to segment
                users manually.{' '}
                <a href="https://help.usejimo.com/help-center/v/for-developers/for-developers/sdk-guides/identify-users">
                  Read complete documentation{' '}
                  <i className="isax isax-export-3"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="command-wrapper">
              <div className="subtitle-4">Change user email</div>
              <ReactSyntaxHighlighter
                language="javascript"
                style={tomorrowNightEighties}>
                {`
window.jimo.push(['set', 'user:email', [email]]);

// Example
window.jimo.push(['set', 'user:email', ['john.doe@company.com']]);
              `}
              </ReactSyntaxHighlighter>
            </div>
            <div className="command-wrapper">
              <div className="subtitle-4">Change user name</div>
              <ReactSyntaxHighlighter
                language="javascript"
                style={tomorrowNightEighties}>
                {`
window.jimo.push(['set', 'user:name', [name]]);

// Example
window.jimo.push(['set', 'user:name', ['John']]);
              `}
              </ReactSyntaxHighlighter>
            </div>
            <div className="command-wrapper">
              <div className="subtitle-4">Change user attributes</div>
              <ReactSyntaxHighlighter
                language="javascript"
                style={tomorrowNightEighties}>
                {`
window.jimo.push(['set', 'user:attributes', [attributes]]);

// Example 1 : set the attribute foo and bar
window.jimo.push(['set', 'user:attributes', [{ foo: "hello", bar: "world"}]]);

// Example 2 : set the attribute foo and bar and refetch experiences
window.jimo.push(['set', 'user:attributes', [{ foo: "hello", bar: "world"}, true]]);
              `}
              </ReactSyntaxHighlighter>
            </div>
            <div className="command-wrapper">
              <div className="subtitle-4">Change user language</div>
              <ReactSyntaxHighlighter
                language="javascript"
                style={tomorrowNightEighties}>
                {`
window.jimo.push(['set', 'core:language', [string /* language */]])

// Example 1 : set the langue to english
window.jimo.push([ "set", "core:language", [ "en" ] ])

// Supported languages:
en, fr, es, pt, it, de, jp, zh, se, nl, fi, ru, cs, hu, pl, da, sv, no, ro
              `}
              </ReactSyntaxHighlighter>
            </div>
          </div>
        </div>
      </main>
    </SettingsBody>
  );
};

export default Identification;
