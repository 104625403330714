import {hasFlag} from 'helpers/bitwise';
import {InlineWidget, useCalendlyEventListener} from 'react-calendly';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {ROUTE_TOURS} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {meService} from 'services';
import {
  F_USER_STATUS_ACTIVE,
  F_USER_STATUS_ONBOARDING_BOOKED,
} from 'services/auth';
import imgLogo from './../../assets/logo.svg';
import './_Styles.scss';

const isEmailBanned = (email) => {
  const bannedWords = 'gmail outlook hotmail';

  if (bannedWords.split(' ').some((w) => email.includes(w))) {
    return true;
  }
};

export const OnboardingWaiting = () => {
  const history = useHistory();

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      meService.onboardingScheduled();
    },
  });

  const user = useSelector((state) => generalSelector.getUser(state));

  if (hasFlag(F_USER_STATUS_ACTIVE, user.flags) === true) {
    history.push(ROUTE_TOURS);
    return <></>;
  }

  if (isEmailBanned(user.email)) {
    return (
      <div className="s-onboarding-waiting">
        <div className="cover-peoples people-left"></div>
        <div className="cover-peoples people-right"></div>
        <div className="s-onboarding-waiting fade-in-right">
          <div className="main-wrapper">
            <img width="64px" src={imgLogo} alt="Logo of Jimo" />
            <div className="title">Your access has been requested!</div>
            <div className="subtitle">
              We receive a lot of requests at the moment, we are doing our best
              to answer them as quick as possible! <br />
              <br />
              Thank you for your patience 🙏
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (hasFlag(F_USER_STATUS_ONBOARDING_BOOKED, user.flags) !== true) {
    return (
      <div className="s-onboarding-calendly">
        <div className="onboarding-calendly">
          <div className="content">
            <div className="title">Let's schedule your onboarding</div>
            <div className="subtitle">
              We want you to make the best out of Jimo and that is why we would
              love to take a few moments to show you all the specificities so
              you're ready to go !
            </div>
          </div>

          <InlineWidget url="https://calendly.com/thomas-747/cettime?month=2022-10" />
        </div>
      </div>
    );
  }

  return (
    <div className="s-onboarding-waiting">
      <div className="cover-peoples people-left"></div>
      <div className="cover-peoples people-right"></div>
      <div className="s-onboarding-waiting fade-in-right">
        <div className="main-wrapper">
          <img width="64px" src={imgLogo} alt="Logo of Jimo" />
          <div className="title">Your onboarding is booked!</div>
          <div className="subtitle">
            We can't wait for you to try Jimo, we're looking forward to showing
            you the endless possibilities it brings you !
            <br />
            <br />
            Thank you for your patience 🙏
          </div>
        </div>
      </div>
    </div>
  );
};
