import React, {useContext, useEffect} from 'react';
import {
  BLOCK_CHECKLIST_TASK_ITEM,
  BLOCK_CHECKLIST_TRIGGER,
} from '../../../Poke/constants/blocks';
import {PokeStateContext} from '../../../Poke/context';
import {
  CHECKLIST_TRIGGER_PROGRESS_TYPE_CIRCULAR,
  CHECKLIST_TRIGGER_PROGRESS_TYPE_COUNT,
} from '../../constants';
import './_Styles.scss';
import CircularProgress from './CircularProgress';

const ChecklistFloatTrigger = () => {
  const {blocks, completedTasks, inBuilder, addFontFamily} =
    useContext(PokeStateContext);

  const triggerBlock = blocks.find((b) => b.type === BLOCK_CHECKLIST_TRIGGER);
  const {value, style} = triggerBlock;
  // eslint-disable-next-line no-unused-vars
  const {progressType, fontFamily, fontColor, width, ...restStyle} = style;

  useEffect(() => {
    addFontFamily(fontFamily);
  }, [fontFamily]);

  const isCircular = progressType === CHECKLIST_TRIGGER_PROGRESS_TYPE_CIRCULAR;
  const isCount = progressType === CHECKLIST_TRIGGER_PROGRESS_TYPE_COUNT;

  const taskItemBlocks = blocks.filter(
    (b) => b.type === BLOCK_CHECKLIST_TASK_ITEM
  );
  const completedSteps =
    inBuilder === true
      ? 1
      : taskItemBlocks.filter((b) => completedTasks.includes(b.uid)).length;
  const taskCount = blocks.filter(
    (b) => b.type === BLOCK_CHECKLIST_TASK_ITEM
  ).length;
  const tasksLeft = taskCount - completedSteps;

  return (
    <div
      className="checklist-float-trigger"
      style={{
        ...restStyle,
      }}>
      {isCircular === true && (
        <CircularProgress progress={(completedSteps / taskCount) * 100} />
      )}
      {isCount === true && <div className="count-progress">{tasksLeft}</div>}
      {value && (
        <div
          className="label"
          style={{
            fontFamily,
            color: fontColor,
          }}>
          {value}
        </div>
      )}
    </div>
  );
};

export default ChecklistFloatTrigger;
