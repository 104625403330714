import SelectGroup from 'components/Select';
import React from 'react';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import './_Styles.scss';

export const bannerAlignmentOptions = [
  {
    label: (
      <>
        <i className="isax isax-align-vertically" />
        <span>Center all elements</span>
      </>
    ),
    value: 'center',
  },
  {
    label: (
      <>
        <i className="isax isax-align-left" />
        <span>Left align all elements</span>
      </>
    ),
    value: 'left',
  },
  {
    label: (
      <>
        <i
          className="isax isax-pharagraphspacing"
          style={{transform: 'rotate(90deg)'}}
        />
        <span>Space between elements & button</span>
      </>
    ),
    value: 'space-between',
  },
];

const BannerAlignmentItem = ({value, onChange}) => {
  return (
    <SectionItem title="Alignment" sectionItemClassName="banner-alignment-item">
      <SelectGroup
        isSearchable={false}
        classNamePrefix="banner-alignment-select"
        options={bannerAlignmentOptions}
        value={bannerAlignmentOptions.find((o) => o.value === value)}
        onChange={(option) => {
          onChange(option.value);
        }}
      />
    </SectionItem>
  );
};

export default BannerAlignmentItem;
