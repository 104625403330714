import Axios from 'axios';
import {generalSelector} from 'selectors';

export const F_MIXPANEL_DATA_JIMO_2_MIXPANEL = 1;
export const F_MIXPANEL_DATA_MIXPANEL_2_JIMO = 2;

// Endpoint
const EP_MIXPANEL_SETUP = '/mixpanel/setup';
const EP_MIXPANEL_SETUP_UPDATE = '/mixpanel/setup';
const EP_MIXPANEL_REMOVE = '/mixpanel/setup';
const EP_MIXPANEL_EVENTS = '/mixpanel/events';

// Method
export const setup = (projectId) => {
  return Axios.post(
    EP_MIXPANEL_SETUP,
    {},
    {
      params: {projectId},
    }
  ).then((response) => response.data);
};

export const remove = (projectId) => {
  return Axios.delete(EP_MIXPANEL_REMOVE, {
    params: {projectId},
  }).then((response) => response.data);
};

export const getMixpanelEvents = (
  projectId = generalSelector.getProject().uid
) => {
  return Axios.get(EP_MIXPANEL_EVENTS, {params: {projectId}}).then(
    (response) => response.data
  );
};

export const update = (data, projectId = generalSelector.getProject().uid) => {
  const {flags} = data;

  return Axios.put(
    EP_MIXPANEL_SETUP_UPDATE,
    {flags},
    {params: {projectId}}
  ).then((response) => response.data);
};
