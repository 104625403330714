import Divider from 'components/Divider';
import BasicEditor from 'components/MarkdownEditor/BasicEditor';
import {BuilderContext} from 'contextes/builder';
import React, {useContext, useEffect, useState} from 'react';
import Section from 'scenes/PokeBuilder/components/Section';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {BLOCK_TYPE_CHECKLIST_HEADER, STEP_TYPE_CHECKLIST} from 'services/steps';
import RadioGroup from '../../../../../../components/RadioGroup';
import ColorItem from '../../components/sectionItems/ColorItem';
import FontFamilyItem from '../../components/sectionItems/FontFamilyItem';
import FontSizeItem from '../../components/sectionItems/FontSizeItem';
import FontWeightItem from '../../components/sectionItems/FontWeightItem';
import './_Styles.scss';

const alignOptions = [
  {label: <i className="icon-text-align-l" />, value: 'left'},
  {label: <i className="icon-text-align-c" />, value: 'center'},
  {label: <i className="icon-text-align-r" />, value: 'right'},
];

const strikethroughOptions = [
  {label: 'Yes', value: true},
  {label: 'No', value: false},
];

const Title = () => {
  const {
    selectedStep: step,
    updateBlock: uptBlock,
    selectedChecklistItemId,
    selectedBlockType,
  } = useContext(BuilderContext);

  const [isEditing, setIsEditing] = useState(false);
  const [markdownEditorKey, setMarkdownEditorKey] = useState(null);

  const isChecklistStep = step?.type === STEP_TYPE_CHECKLIST;

  const checklistHeaderBlock = step?.blocks.find(
    (b) => b.type === BLOCK_TYPE_CHECKLIST_HEADER
  );

  const block = step?.blocks.find(
    (b) =>
      b.type === selectedBlockType &&
      ((isChecklistStep !== true && b.parentBlockId == null) ||
        (selectedChecklistItemId == null &&
          [null, checklistHeaderBlock?.uid].includes(b.parentBlockId)) ||
        selectedChecklistItemId === b.parentBlockId)
  );

  const {value, rawValue, style} = block || {};

  const updateBlock = (updateObj, all = false) => {
    if (all !== true && block?.parentBlockId != null) {
      uptBlock(selectedBlockType, updateObj, {
        parentBlockId: block.parentBlockId,
      });
    } else {
      uptBlock(selectedBlockType, updateObj);
    }
  };

  useEffect(() => {
    if (isEditing === false) {
      setMarkdownEditorKey(JSON.stringify(rawValue));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawValue]);

  if (step == null || block == null) {
    return <></>;
  }

  const [text, action, urlOrPostId] = value.split('|-|');

  return (
    <div className="block-settings title">
      <Section title="Content">
        <BasicEditor
          key={markdownEditorKey}
          value={text}
          rawValue={rawValue}
          onChange={({value, rawValue}) =>
            updateBlock({
              value: `${value}|-|${action}|-|${urlOrPostId}`,
              rawValue,
            })
          }
          onFocus={() => setIsEditing(true)}
          onBlur={() => setIsEditing(false)}
          isSingleLine
          autoFocus
        />
      </Section>
      <Divider />
      {isChecklistStep && selectedChecklistItemId != null ? (
        <>
          <Section
            title={
              <>
                <i className="isax isax-main-component5 p-500" />
                Style
              </>
            }
            titleExtra={
              <>
                <div className="body-4 n-700">For every</div>
                <div className="block-group subtitle-4 n-800">
                  <div className="icon-wrapper">
                    <i className="icon-text" />
                  </div>
                  Title
                </div>
              </>
            }
          />
          <Divider />
          <Section title="Incomplete">
            <TitleStyleSection
              style={style}
              updateStyle={(value) => updateBlock(value, true)}
              hideAlign={isChecklistStep}
            />
          </Section>

          <Divider />
          <Section title="Completed">
            <ColorItem
              title="Color"
              value={style.completedFontColor ?? style.fontColor}
              onChange={(value) =>
                updateBlock(
                  {
                    style: {...style, completedFontColor: value},
                  },
                  true
                )
              }
            />
            <SectionItem title="Strikethrough">
              <RadioGroup
                value={style.completedStrikethrough ?? false}
                options={strikethroughOptions}
                onSelect={(value) =>
                  updateBlock(
                    {
                      style: {...style, completedStrikethrough: value},
                    },
                    true
                  )
                }
              />
            </SectionItem>
          </Section>
        </>
      ) : (
        <Section title="Style">
          <TitleStyleSection
            style={style}
            updateStyle={(value) => updateBlock(value)}
            hideAlign={isChecklistStep}
          />
        </Section>
      )}
    </div>
  );
};

export const TitleStyleSection = ({style, updateStyle, hideAlign = false}) => {
  return (
    <>
      <ColorItem
        title="Color"
        value={style.fontColor}
        onChange={(value) =>
          updateStyle({
            style: {...style, fontColor: value},
          })
        }
      />
      <FontFamilyItem
        value={style.fontFamily}
        onChange={(value) =>
          updateStyle({
            style: {...style, fontFamily: value},
          })
        }
      />
      <FontSizeItem
        value={style.fontSize}
        onChange={(value) =>
          updateStyle({
            style: {...style, fontSize: value},
          })
        }
      />
      <FontWeightItem
        value={style.fontWeight}
        onChange={(value) =>
          updateStyle({
            style: {...style, fontWeight: value},
          })
        }
      />
      {hideAlign !== true && (
        <SectionItem title="Align">
          <RadioGroup
            value={style.align}
            options={alignOptions}
            onSelect={(value) =>
              updateStyle({
                style: {
                  ...style,
                  align: value,
                },
              })
            }
          />
        </SectionItem>
      )}
    </>
  );
};

export default Title;
