import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import classNames from 'classnames';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import TextArea from 'components/TextArea';
import Tooltip from 'components/Tooltip';
import {PermissionsEvent} from 'constants/permissions';
import {hasPermissions} from 'helpers/permission';
import {useContext} from 'react';
import {EVENT_SOURCE_JIMO} from 'services/event';
import {EventContext} from '../..';
import './_Styles.scss';

const EventDetails = () => {
  const {
    event,
    name,
    setName,
    codeName,
    setCodeName,
    description,
    setDescription,
    icon,
    setIcon,
    setIsCodeNameManuallyChanged,
    isCodeNameAlreadyUsed,
  } = useContext(EventContext);

  const canEditEvent = hasPermissions(PermissionsEvent.CREATE_EDIT);

  return (
    <>
      <div className="event-details">
        <div className="event-details-title">Event details</div>
        <div className="input-section">
          <div className="element">
            <div className="input-label">Name</div>
            <div className="input-group-wrapper">
              <Input
                labelTextLeft={
                  <Dropdown
                    className="emoji-picker-dropdown"
                    position="bottom left"
                    offsetY={8}
                    disabled={!canEditEvent}
                    trigger={
                      <div
                        className="icon-wrapper"
                        style={{background: '#FFFFFF'}}>
                        {icon}
                      </div>
                    }>
                    <Picker
                      data={data}
                      onEmojiSelect={(emoji) => setIcon(emoji.native)}
                    />
                  </Dropdown>
                }
                className="input-name"
                value={name}
                onChange={({target}) => setName(target.value)}
                placeholder="User click on Sign Up"
                disabled={!canEditEvent}
              />
              <div className="body-4 n-500">Can be changed later</div>
            </div>
          </div>
          <div
            className={classNames('element code-name', {
              disabled: event.uid != null && event.codeName != null,
            })}>
            <div className="input-label">
              Code name
              <Tooltip
                dark
                offsetY={8}
                trigger={<i className="icon-question-circle n-700" />}>
                Code-friendly name to be used in integrations and webhooks
              </Tooltip>
              {isCodeNameAlreadyUsed && (
                <div className="error-message subtitle-4 r-500">
                  This code name is already used
                </div>
              )}
            </div>
            <div className="input-group-wrapper">
              <Input
                className="input-name"
                value={codeName}
                onChange={({target}) => {
                  setCodeName(target.value);
                  setIsCodeNameManuallyChanged(true);
                }}
                placeholder="user_click_on_sign_up"
                disabled={
                  !canEditEvent ||
                  (event.uid != null && event.codeName != null) ||
                  event.source !== EVENT_SOURCE_JIMO
                }
              />
              <div className="body-4 n-500">
                Can <strong>NOT</strong> be changed later
              </div>
            </div>
          </div>
        </div>
        <div className="input-section">
          <div className="element">
            <div className="input-label">Description</div>
            <TextArea
              className="input-description"
              value={description}
              onChange={({target}) => setDescription(target.value)}
              placeholder="Description"
              disabled={!canEditEvent}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EventDetails;
