import React from 'react';
import PopupSetting from 'scenes/PokeBuilder/components/BlockEditor/components/PopupSetting';
import ClickableInput from 'scenes/PokeBuilder/components/BlockEditor/components/items/ClickableInput';
import {
  VISUAL_CUE_TYPE_STICKERS,
  visualCues,
} from 'shared/front/components/Poke/components/VisualCues';
import DynamicSvg from '../../../VisualCue/components/DynamicSvg';
import {VisualCueItem} from '../../../VisualCue/components/VisualCuePicker';
import './_Styles.scss';

const stickers = visualCues.filter(
  (item) => item.type === VISUAL_CUE_TYPE_STICKERS
);

const StickerPicker = ({value, onChange}) => {
  const selectedVisualCue = visualCues.find((item) => item.value === value);

  return (
    <>
      <PopupSetting
        className="picker-sticker-popup"
        trigger={
          <ClickableInput
            value={selectedVisualCue?.name || ''}
            leftLabel={
              <span className="preview-icon" style={{}}>
                <DynamicSvg
                  url={selectedVisualCue?.url}
                  {...selectedVisualCue}
                />
              </span>
            }
            inputProps={{
              placeholder: 'Select a sticker',
            }}
          />
        }
        title="Hint stickers"
        content={
          <div className="sticker-picker-content">
            <div className="section-grid">
              {stickers.map((item) => (
                <VisualCueItem
                  name={item.name}
                  url={item.url}
                  onClick={() =>
                    onChange({
                      value: item.value,
                      style: {
                        primaryColor: item.primaryColor || null,
                        secondaryColor: item.secondaryColor || null,
                        borderColor: item.borderColor || null,
                      },
                    })
                  }
                  selected={value === item.value}
                  {...item}
                />
              ))}
            </div>
          </div>
        }
      />
    </>
  );
};

export default StickerPicker;
