import {generalActions} from 'actions';
import Button from 'components/Button';
import Divider from 'components/Divider';
import DefaultLoader from 'components/Loader';
import SelectGroup from 'components/Select';
import {toastDanger, toastSuccess} from 'components/Toaster';
import Tooltip from 'components/Tooltip';
import {errorHelpers} from 'helpers';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom/cjs/react-router-dom';
import {components} from 'react-select';
import {ROUTE_SETTINGS_INTEGRATIONS} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {customerioService} from 'services';
import {Swaler} from 'swaler';
import CioFull from '../../imgs/cio-full.png';
import CioIcon from '../../imgs/cio.png';
import JimoIcon from '../../imgs/jimo.svg';
import './_styles.scss';

export const JimoIconSmall = JimoIcon;

const logger = new Swaler('IntegrationCioSettings');

const cioIdentifiers = [
  {
    label: 'Id',
    value: 'id',
  },
  {
    label: 'Email',
    value: 'email',
  },
];

export const IntegrationCioSettings = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const cio = useSelector((state) => generalSelector.getProject(state).cio);

  const uptProject = (data) => dispatch(generalActions.uptProject(data));

  const [reportingWebhook, setReportingWebhook] = useState(null);
  const [loadingReportingWebhook, setLoadingReportingWebhook] = useState(false);
  const [updatingReportingWebhook, setUpdatingReportingWebhook] =
    useState(false);

  const fetchReportingWebhook = async () => {
    setLoadingReportingWebhook(true);

    try {
      const webhook = await customerioService.getCioReportingWebhook();

      setReportingWebhook(webhook);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(`Fetching CIO reporting webhook failed with error `, code);
      toastDanger([title, message], {actions});
    } finally {
      setLoadingReportingWebhook(false);
    }
  };
  const handleEnableReportingWebhook = async () => {
    setUpdatingReportingWebhook(true);

    try {
      const webhook = await customerioService.createReportingWebhook();

      uptProject({
        cio: {
          ...cio,
          reportingWebhookId: webhook.id,
        },
      });
      toastSuccess(
        [
          'Webhook created',
          'The Customer.io reporting webhook has been created! You can now configure events that Jimo should be aware of.',
        ],
        {
          actions: [
            {
              text: 'Configure webhook',
              props: {
                onClick: () =>
                  window.open(
                    `https://fly.customer.io/workspaces/${cio.workspaceId}/journeys/integrations/reporting-webhooks/${webhook.id}`,
                    '_blank'
                  ),
                iconLeft: 'icon-options',
              },
            },
          ],
        }
      );
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(`Creating CIO reporting webhook failed with error `, code);
      toastDanger([title, message], {actions});
    } finally {
      setUpdatingReportingWebhook(false);
    }
  };
  const handleDeleteReportingWebhook = async () => {
    setUpdatingReportingWebhook(true);

    try {
      await customerioService.deleteReportingWebhook();

      uptProject({
        cio: {
          ...cio,
          reportingWebhookId: null,
        },
      });
      toastSuccess([
        'Webhook delete',
        'The Customer.io reporting webhook has been deleted!',
      ]);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(`Deleting CIO reporting webhook failed with error `, code);
      toastDanger([title, message], {actions});
    } finally {
      setUpdatingReportingWebhook(false);
    }
  };
  const handleUpdateIdentifyField = async (field) => {
    try {
      await customerioService.updateCioIntegration({
        identifyField: field.value,
      });
      uptProject({cio: {...cio, identifyField: field.value}});
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(`Updating Cio identify field failed with error `, code);
      toastDanger([title, message], {actions});
    }
  };

  useEffect(() => {
    if (cio.reportingWebhookId == null) return;
    fetchReportingWebhook();
  }, [cio.reportingWebhookId]);

  const Control = ({children, ...rest}) => {
    const {isCio, isJimo} = rest;

    return (
      <components.Control {...rest}>
        <div className="icon-wrapper">
          {isJimo === true && (
            <img src={JimoIcon} width={40} height={40} alt="jimo-icon" />
          )}
          {isCio === true && (
            <img src={CioIcon} width={40} height={32} alt="jimo-icon" />
          )}
        </div>
        {children}
      </components.Control>
    );
  };

  return (
    <div className="integration-setting-cio">
      <div className="settings-header">
        <Button
          className="back-btn"
          iconOnly
          iconLeft="icon-chevron-left"
          onClick={async () => {
            history.push(ROUTE_SETTINGS_INTEGRATIONS);
          }}
        />
        <div className="title">Customerio</div>
      </div>
      <div className="settings-integration-content">
        <div className="left-col">
          <div className="setting-section section-match-id">
            <div className="section-title">Match user using</div>
            <div className="section-body">
              <SelectGroup
                options={cioIdentifiers}
                placeholder="Select customerio identifier"
                onChange={(option) => handleUpdateIdentifyField(option)}
                value={cioIdentifiers.find(
                  (i) => i.value === cio.identifyField
                )}
                components={{
                  Control: (props) => <Control {...props} isCio />,
                }}
              />
              <i className="icon-arrow-right"></i>
              <p>
                Jimer id (set using SDK).{' '}
                <a
                  href="https://help.usejimo.com/help-center/v/for-developers/for-developers/sdk-guides/identify-users"
                  target="_blank"
                  rel="noopener noreferrer">
                  How to identify users with Jimo SDK?
                </a>
              </p>
            </div>
          </div>
          <div className="setting-section section-reporting-webhook">
            <div className="section-title">
              Jimo <i className="icon-arrow-left"></i> Customer.io
            </div>
            <div className="section-body">
              <div className="section-item item-reporting-webhook">
                <h1>
                  Reporting webhook{' '}
                  {cio.reportingWebhookId != null && (
                    <span>
                      enabled <i className="icon-tick"></i>
                    </span>
                  )}
                </h1>
                {loadingReportingWebhook === false ? (
                  <>
                    {cio.reportingWebhookId == null ? (
                      <>
                        <p>
                          Jimo uses Customer.io reporting webhook to be aware of
                          what's happening in your Customer.io workspace.
                        </p>
                        <Button
                          primary
                          thin
                          loading={updatingReportingWebhook}
                          onClick={handleEnableReportingWebhook}>
                          Enable reporting webhook
                        </Button>
                      </>
                    ) : (
                      <>
                        <p className="webhook-details">
                          Your reporting webhook is currently listening to{' '}
                          <Tooltip
                            className="tooltip-cio-listeners"
                            position="right"
                            trigger={
                              <span>
                                {reportingWebhook?.events?.length} events
                              </span>
                            }>
                            <span>Listening to</span>
                            <pre>{reportingWebhook?.events?.join(', ')}</pre>
                          </Tooltip>
                          .
                          <br />
                          <a
                            href={`https://fly.customer.io/workspaces/${cio.workspaceId}/journeys/integrations/reporting-webhooks/${cio.reportingWebhookId}`}
                            target="_blank"
                            rel="noopener noreferrer">
                            Select events to listen{' '}
                          </a>
                        </p>
                        <Button
                          danger
                          thin
                          loading={updatingReportingWebhook}
                          onClick={handleDeleteReportingWebhook}>
                          Disable reporting webhook
                        </Button>
                      </>
                    )}
                  </>
                ) : (
                  <DefaultLoader width="14px" />
                )}
              </div>
            </div>
          </div>
          <div className="setting-section section-reporting-webhook">
            <div className="section-title">
              Jimo <i className="icon-arrow-right"></i> Customer.io{' '}
              <div className="coming-soon">Coming soon</div>
            </div>
            <div className="section-body">
              <div className="section-item item-reporting-webhook"></div>
            </div>
          </div>
        </div>
        <div className="right-col">
          <div className="cio-help-section">
            <div className="cio-help-section-header">
              <img src={CioFull} alt="Customerio" />
            </div>
            <div className="cio-help-section-content">
              <div className="section cio-workspace">
                <div className="section-title">Customer.io workspace</div>
                <div className="section-content">
                  <div className="parameter-item">
                    Workspace{' '}
                    <span>
                      {cio.workspaceName} (ID: {cio.workspaceId})
                    </span>
                  </div>
                  <div className="parameter-item">
                    Region <span>{cio.region.toUpperCase()}</span>
                  </div>
                </div>
              </div>
              <Divider />
              <div className="section">
                <div className="section-title">Resources</div>
                <div className="section-content">
                  <Button
                    iconLeft="icon-eye"
                    thin
                    onClick={() =>
                      window.open(
                        'https://help.usejimo.com/help-center/v/for-developers',
                        '_blank',
                        'noopener,noreferrer'
                      )
                    }>
                    Documentation
                  </Button>
                  <Button
                    iconLeft="icon-eye"
                    thin
                    onClick={() =>
                      window.open(
                        'https://www.customer.io/',
                        '_blank',
                        'noopener,noreferrer'
                      )
                    }>
                    Customerio website
                  </Button>
                </div>
              </div>
              <Divider />
              <div className="section">
                <div className="section-title">How it works</div>
                <div className="section-content">
                  <div className="how-it-works">
                    <span>
                      Jimo <i className="icon-arrow-left"></i> Customer.io
                    </span>
                    <p>
                      Jimo uses reporting webhook feature provided by Customerio
                      in order to be aware of events happening in your
                      Customer.io workspace. For example, Jimo can be aware if a
                      user have seen or open an email sent by one of your
                      Customer.io campaign.
                    </p>
                    <span>
                      Jimo <i className="icon-arrow-right"></i> Customer.io
                    </span>
                    <p>Coming soon</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
