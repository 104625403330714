import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import classNames from 'classnames';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import InputGroup from 'components/Input';
import {Modal} from 'components/Modal';
import {Creatable} from 'components/Select';
import TextArea from 'components/TextArea';
import {toastDanger, toastSuccess} from 'components/Toaster';
import Tooltip from 'components/Tooltip';
import {errorHelpers} from 'helpers';
import {useState} from 'react';
import {components} from 'react-select';
import {getRandomEmoji} from 'scenes/SuccessTracker/components/Event';
import {environmentService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';

const logger = new Swaler('ModalEditEnvironment');

const colors = [
  '#E6FDE8',
  '#DDEEFF',
  '#FDF2E6',
  '#ECE6FD',
  '#FFD7D7',
  '#FDF8C9',
  '#ECECEC',
];

export const extractDomainOrRegex = (input) => {
  try {
    if (/^\\|\$|\^/.test(input)) {
      return input;
    }

    let processedInput = input;

    if (!input.match(/^https?:\/\//)) {
      processedInput = 'http://' + input;
    }

    const parsedUrl = new URL(processedInput);

    return parsedUrl.host.replace(/^www\./, '');
  } catch (e) {
    return input;
  }
};

const createOption = (label) => ({
  label,
  value: label,
});

export const Control = (props) => {
  return (
    <components.Control {...props}>
      <i className="isax isax-global" />
      <div className="control-wrapper">{props.children}</div>
    </components.Control>
  );
};

export const MultiValueLabel = (props) => {
  return (
    <components.MultiValueLabel {...props}>
      <div className="multi-value-label body-3">{props.data.label}</div>
    </components.MultiValueLabel>
  );
};

export const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <i {...props.innerProps} className="icon-close" />
    </components.MultiValueRemove>
  );
};

export const ClearIndicator = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <i {...props.innerProps} className="isax isax-trash n-700" />
    </components.ClearIndicator>
  );
};

const ModalEditEnvironment = ({isOpen, onRequestClose, environment}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState(environment?.name || '');
  const [icon, setIcon] = useState(environment?.icon || getRandomEmoji());
  const [color, setColor] = useState(environment?.color || '#E6FDE8');
  const [domainsInputValue, setDomainsInputValue] = useState('');
  const [domains, setDomains] = useState(environment?.domains || '');
  const [description, setDescription] = useState(
    environment?.description || ''
  );

  const handleConfirm = async () => {
    setIsSubmitting(false);
    try {
      if (environment?.uid != null) {
        await environmentService.updateEnvironment(environment.uid, {
          name,
          icon,
          color,
          domains,
          description,
        });
        toastSuccess(
          [
            'Environment updated successfully!',
            'You can now manage and publish experiences to this environment.',
          ],
          {
            autoClose: 3000,
            toastId: 'changes-saved',
          }
        );
        onRequestClose();
        return;
      } else {
        await environmentService.createEnvironment({
          name,
          icon,
          color,
          domains,
          description,
        });
        toastSuccess(
          [
            'Environment created successfully!',
            'You can now manage and publish experiences to this environment.',
          ],
          {
            autoClose: 3000,
            toastId: 'changes-saved',
          }
        );
        onRequestClose();
        return;
      }
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Failed to save environment with error', code);
      return toastDanger([title, message], {actions});
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleKeyDown = (event) => {
    if (!domainsInputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
      case ' ':
        const newDomain = extractDomainOrRegex(domainsInputValue);
        setDomains((prev) => prev + ';' + newDomain);
        setDomainsInputValue('');
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  const canSaveEnvironment = name.length > 0 && domains.length > 0;

  const domainsArr = domains
    .split(';')
    .filter((domain) => domain.length > 0)
    .map((domain) => createOption(domain));

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="modal-edit-environment"
      title={
        environment != null ? 'Edit environment' : 'Create new environment'
      }
      footer={
        <>
          <Button thin onClick={() => onRequestClose()} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button
            primary
            thin
            onClick={handleConfirm}
            loading={isSubmitting}
            disabled={canSaveEnvironment !== true}>
            Save environment
          </Button>
        </>
      }>
      <div className="modal-edit-environment-content">
        <div className="color-and-icon-wrapper">
          <Dropdown
            className="emoji-picker-dropdown"
            position="bottom left"
            offsetY={8}
            trigger={
              <div className="icon-wrapper" style={{backgroundColor: color}}>
                {icon}
              </div>
            }
            contentStyle={{zIndex: 1002}}>
            <Picker
              data={data}
              onEmojiSelect={(emoji) => setIcon(emoji.native)}
            />
          </Dropdown>
          <div className="color-picker-wrapper">
            <div className="body-3">Colors</div>
            <div className="color-picker">
              {colors.map((c) => (
                <div
                  key={c}
                  className={classNames('color', {
                    selected: c === color,
                  })}
                  style={{backgroundColor: c}}
                  onClick={() => setColor(c)}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="form-section">
          <div className="label body-3">Name</div>
          <InputGroup
            value={name || ''}
            onChange={(e) => setName(e.target.value)}
            placeholder="A descriptive name (ex: Staging, QA, etc)"
          />
        </div>

        <div className="form-section domains-section">
          <div className="label body-3">
            Domains
            <Tooltip
              className="environment-tooltip-domains"
              trigger={<i className="icon-question-circle n-600" />}
              contentStyle={{zIndex: 1003, width: '500px'}}
              offsetY={8}>
              <div className="filter-domain-info-wrapper">
                <p>
                  <i className="icon-info-circle-o"></i>Tips
                </p>
                <p className="filter-domain-info">
                  - A domain :<pre>foo.com</pre>
                </p>
                <p className="filter-domain-info">
                  - List of domains :<pre>foo.com bar.com</pre> (separate
                  domains with a space or by pressing Enter)
                </p>
                <p className="filter-domain-info">
                  - Regex :<pre>\.bar.com$</pre> (match all subdomains ending
                  with <pre>bar.com</pre>)
                </p>
              </div>
            </Tooltip>
          </div>
          <Creatable
            className="domains-creatable"
            components={{
              DropdownIndicator: null,
              MultiValueRemove,
              MultiValueLabel,
              Control,
              ClearIndicator,
            }}
            inputValue={domainsInputValue}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={(newValue, triggeredAction) => {
              if (triggeredAction.action === 'clear') {
                setDomains('');
                return;
              }
              const newDomains = newValue
                .map((option) => option.value)
                .join(';');
              setDomains(newDomains);
            }}
            onInputChange={(newValue) => setDomainsInputValue(newValue)}
            onKeyDown={handleKeyDown}
            placeholder="Insert domains separated by space"
            value={domainsArr}
          />
        </div>

        <div className="form-section">
          <div className="label body-3">Description (optional)</div>
          <TextArea
            className="input-description"
            value={description || ''}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Environment description"
          />
        </div>
      </div>
    </Modal>
  );
};

export default ModalEditEnvironment;
