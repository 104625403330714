import {
  EVENT_DELETE_EVOLUTION,
  EVENT_DUPLICATE_EVOLUTION,
  EVENT_EDIT_EVOLUTION,
  EVENT_PAUSE_POKE,
  EVENT_PLAY_POKE,
} from 'amplitude';
import amplitude from 'amplitude-js';
import classNames from 'classnames';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import {
  expiredTag,
  scheduledTag,
} from 'components/EvolutionList/components/Evolution';
import {Menu, MenuItem} from 'components/Menu';
import {ModalConfirm} from 'components/Modal';
import {PokeTagSelector} from 'components/PokeTagSelector';
import SharableLinkModal from 'components/SharableLinkModal';
import {toastDanger, toastSuccess} from 'components/Toaster';
import dayjs from 'dayjs';
import {errorHelpers} from 'helpers';
import {hasFlag} from 'helpers/bitwise';
import {errorCodes} from 'helpers/error';
import {useUpdateSubscription} from 'hooks/useUpdateSubscription';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {
  ROUTE_BANNERS,
  ROUTE_BUILDER_CREATE,
  ROUTE_CHECKLISTS,
  ROUTE_HINTS,
  ROUTE_POKE_BUILDER_FROM_TYPE,
  ROUTE_POKE_SETTINGS_FROM_TYPE,
  ROUTE_SURVEYS,
  ROUTE_TOURS,
} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {evolutionService} from 'services';
import {
  EVOLUTION_STATE_LIVE,
  EVOLUTION_STATE_PAUSED,
  EVOLUTION_TYPE_BANNER,
  EVOLUTION_TYPE_CHECKLIST,
  EVOLUTION_TYPE_HINT,
  EVOLUTION_TYPE_SURVEY,
  EVOLUTION_TYPE_TOUR,
  F_OPTION_V2,
} from 'services/evolution';
import {
  F_EXTRA_CAN_PUBLISH,
  PROJECT_ROLE_ADMIN,
  PROJECT_ROLE_EDITOR,
  PROJECT_ROLE_MEMBER,
} from 'services/project';
import {PLAN_GROWTH_ID, PLAN_STARTUP_ID} from 'services/subscription';
import {Swaler} from 'swaler';
import './_Styles.scss';
import EnvironmentSelector from './components/EnvironmentSelector';

const logger = new Swaler('PokeHeader');

const PokeHeader = ({evolution, refetch, scrolled}) => {
  const history = useHistory();
  const {update} = useUpdateSubscription();

  const project = useSelector((state) => generalSelector.getProject(state));
  const projectMember = useSelector((state) =>
    generalSelector.getProjectMember(state)
  );

  const [showModalDeleteEvolution, setShowModalDeleteEvolution] =
    useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isTogglingState, setIsTogglingState] = useState(false);
  const [showShareLinkModal, setShowShareLinkModal] = useState(false);

  const canEditPush = [
    PROJECT_ROLE_ADMIN,
    PROJECT_ROLE_EDITOR,
    PROJECT_ROLE_MEMBER,
  ].includes(projectMember.role);

  const handleShowHideModalDeleteEvolution = (
    value = !showModalDeleteEvolution
  ) => setShowModalDeleteEvolution(value);
  const handleDeleteEvolution = async () => {
    setIsDeleting(true);
    try {
      amplitude.getInstance().logEvent(EVENT_DELETE_EVOLUTION, {
        from: 'Post',
      });
      await evolutionService.deleteEvolution(evolution.uid);
      toastSuccess('Experience deleted 👍', {toastId: 'evolution-deleted'});
      setIsDeleting(false);
      setShowModalDeleteEvolution(false);
      history.push(
        evolution.type === EVOLUTION_TYPE_TOUR
          ? ROUTE_TOURS
          : evolution.type === EVOLUTION_TYPE_SURVEY
          ? ROUTE_SURVEYS
          : evolution.type === EVOLUTION_TYPE_BANNER
          ? ROUTE_BANNERS
          : evolution.type === EVOLUTION_TYPE_HINT
          ? ROUTE_HINTS
          : evolution.type === EVOLUTION_TYPE_CHECKLIST
          ? ROUTE_CHECKLISTS
          : '#'
      );
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Deleting experience failed with error ', code);
      toastDanger([title, message], {actions});
      setIsDeleting(false);
      setShowModalDeleteEvolution(false);
    }
  };

  const handleToggleState = async (live) => {
    if (live === true) {
      if (hasFlag(F_EXTRA_CAN_PUBLISH, project.extraFlags) === false) {
        return update({
          planId: PLAN_STARTUP_ID,
          title: 'Publish your experiences',
          description:
            'Publish your experiences and reach your users with the Startup plan.',
        });
      }
    }

    setIsTogglingState(true);
    try {
      if (live === true) {
        amplitude.getInstance().logEvent(EVENT_PLAY_POKE, {
          from: 'Poke',
        });
      } else {
        amplitude.getInstance().logEvent(EVENT_PAUSE_POKE, {
          from: 'Poke',
        });
      }
      await evolutionService.updateEvolutionState(
        evolution.uid,
        live === true ? EVOLUTION_STATE_LIVE : EVOLUTION_STATE_PAUSED
      );
      toastSuccess('Experience updated 👍', {toastId: 'poke-updated'});
      refetch();
      setIsTogglingState(false);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      setIsTogglingState(false);
      if (code === errorCodes.LIVE_TOURS_LIMIT_REACHED) {
        return update({
          planId: PLAN_GROWTH_ID,
          title: 'Unlimited Tours',
          description: 'Publish unlimited tours with our Growth plan.',
        });
      }
      if (code === errorCodes.LIVE_BANNERS_LIMIT_REACHED) {
        return update({
          planId: PLAN_GROWTH_ID,
          title: 'Unlimited Banners',
          description: 'Publish unlimited banners with our Growth plan.',
        });
      }
      if (code === errorCodes.LIVE_HINTS_LIMIT_REACHED) {
        return update({
          planId: PLAN_GROWTH_ID,
          title: 'Unlimited Hints',
          description: 'Publish unlimited hints with our Growth plan.',
        });
      }
      if (code === errorCodes.LIVE_SURVEYS_LIMIT_REACHED) {
        return update({
          planId: PLAN_GROWTH_ID,
          title: 'Unlimited Surveys',
          description: 'Publish unlimited surveys with the Growth plan.',
        });
      }
      if (code === errorCodes.LIVE_CHECKLISTS_LIMIT_REACHED) {
        return update({
          planId: PLAN_GROWTH_ID,
          title: 'Unlimited Checklists',
          description: 'Publish unlimited checklists with the Growth plan.',
        });
      }
      logger.error('Updating experience failed with error ', code);
      toastDanger([title, message], {actions});
    }
  };

  const handleEdit = () => {
    amplitude.getInstance().logEvent(EVENT_EDIT_EVOLUTION, {
      from: 'Poke',
    });
    history.push(ROUTE_POKE_BUILDER_FROM_TYPE(evolution.uid, evolution.type));
  };

  const handleDuplicate = async () => {
    amplitude.getInstance().logEvent(EVENT_DUPLICATE_EVOLUTION, {
      from: 'PushesTable',
    });
    const createdEvolution = await evolutionService.duplicateEvolution(
      evolution.uid
    );
    if (
      [
        EVOLUTION_TYPE_TOUR,
        EVOLUTION_TYPE_SURVEY,
        EVOLUTION_TYPE_BANNER,
        EVOLUTION_TYPE_HINT,
        EVOLUTION_TYPE_CHECKLIST,
      ].includes(createdEvolution.type)
    ) {
      history.push(
        ROUTE_POKE_BUILDER_FROM_TYPE(
          createdEvolution.uid,
          createdEvolution.type
        )
      );
    } else {
      history.push(ROUTE_BUILDER_CREATE({evolutionId: createdEvolution.uid}));
    }
  };

  let {isDraft, lastStepChangeAt, expiresAt, type} = evolution;
  let extraTag = null;
  const curDate = new Date();

  if (
    isDraft !== true &&
    new Date(lastStepChangeAt).getTime() > curDate.getTime()
  ) {
    const publishDate = new Date(lastStepChangeAt);

    if (publishDate.getTime() > curDate.getTime()) {
      extraTag = scheduledTag(lastStepChangeAt);
    }
  }
  if (isDraft !== true && extraTag == null && expiresAt != null) {
    extraTag = expiredTag(expiresAt);
  }

  isDraft = isDraft === true ? true : false;

  const isLive =
    isDraft !== true &&
    (evolution?.state === EVOLUTION_STATE_LIVE || evolution?.state == null);
  const isPaused =
    isDraft !== true && evolution?.state === EVOLUTION_STATE_PAUSED;
  const isScheduled =
    isDraft !== true && dayjs(lastStepChangeAt).isAfter(dayjs());
  const isExpired =
    isDraft !== true && expiresAt != null && dayjs(expiresAt).isBefore(dayjs());

  const isOldPoke = hasFlag(F_OPTION_V2, evolution?.optionsFlags) !== true;

  const isTour = type === EVOLUTION_TYPE_TOUR;
  const isSurvey = type === EVOLUTION_TYPE_SURVEY;
  const isBanner = type === EVOLUTION_TYPE_BANNER;
  const isHint = type === EVOLUTION_TYPE_HINT;
  const isChecklist = type === EVOLUTION_TYPE_CHECKLIST;

  const isAdoption = isTour || isBanner || isHint;
  const isDiscovery = isSurvey;

  return (
    <div className={classNames('poke-header', {scrolled: scrolled})}>
      <div className="infos">
        <Button
          thin
          className="back-btn"
          iconOnly
          iconLeft="icon-chevron-left"
          onClick={async () => {
            if (type === EVOLUTION_TYPE_TOUR) {
              history.push(ROUTE_TOURS);
            } else if (type === EVOLUTION_TYPE_SURVEY) {
              history.push(ROUTE_SURVEYS);
            } else if (type === EVOLUTION_TYPE_BANNER) {
              history.push(ROUTE_BANNERS);
            } else if (type === EVOLUTION_TYPE_HINT) {
              history.push(ROUTE_HINTS);
            } else if (type === EVOLUTION_TYPE_CHECKLIST) {
              history.push(ROUTE_CHECKLISTS);
            }
          }}
        />
        <div
          className={classNames('icon-wrapper', {
            adoption: isAdoption,
            discovery: isDiscovery,
            'is-checklist': isChecklist,
          })}>
          {isTour && <i className="isax isax-routing-25" />}
          {isDiscovery && <i className="isax isax-note-215" />}
          {isBanner && <i className="icon-slot-top-bar-bold" />}
          {isHint && <i className="icon-slot-dot-bold" />}
          {isChecklist && <i className="isax isax-task-square5" />}
        </div>
        <div className="push-title-wrapper">
          <div className="push-title title-4">{evolution.title}</div>
          <div className="created-at body-3 n-500">
            {isTour
              ? 'Tour'
              : isSurvey
              ? 'Survey'
              : isBanner
              ? 'Banner'
              : isHint
              ? 'Hint'
              : isChecklist
              ? 'Checklist'
              : ''}{' '}
            &bull; Created {dayjs(evolution.createdAt).format('MMM DD, YYYY')}
          </div>
        </div>
        <PokeTagSelector poke={evolution} />
      </div>
      <div className="actions">
        {canEditPush && (
          <>
            {isDraft !== true && (
              <>
                <EnvironmentSelector evolution={evolution} refetch={refetch} />
                <Button
                  thin
                  className={classNames('toggle-state-btn', {
                    resume: isPaused,
                    pause: isLive,
                  })}
                  disabled={isExpired || isScheduled}
                  loading={isTogglingState}
                  iconLeft={isLive ? 'icon-pause-rounded' : 'icon-play-rounded'}
                  onClick={() => handleToggleState(isLive ? false : true)}>
                  {isExpired === true
                    ? 'Expired'
                    : isScheduled === true
                    ? 'Scheduled'
                    : isLive
                    ? 'Pause'
                    : 'Play'}
                </Button>
              </>
            )}
            {isOldPoke !== true && (
              <>
                <Button
                  thin
                  iconLeft="icon-settings"
                  onClick={() => {
                    history.push(
                      ROUTE_POKE_SETTINGS_FROM_TYPE(
                        evolution?.uid,
                        evolution.type
                      )
                    );
                  }}>
                  Settings
                </Button>
              </>
            )}
            <Dropdown
              className="dropdown-more-actions"
              triggerClassName="menu-dropdown-trigger"
              trigger={
                <Button thin className="menu-btn" iconOnly>
                  <i className="icon-menu-vertical" />
                </Button>
              }
              position="bottom right"
              offsetY={8}>
              <Menu>
                <MenuItem onClick={handleEdit}>
                  <i className="icon-edit-outline" />
                  Edit
                </MenuItem>
                <MenuItem onClick={handleDuplicate}>
                  <i className="icon-duplicate" />
                  Duplicate
                </MenuItem>
                {isLive === true && (
                  <MenuItem onClick={() => setShowShareLinkModal(true)}>
                    <i className="isax isax-link-2" />
                    Public URL
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    handleShowHideModalDeleteEvolution(true);
                    setShowModalDeleteEvolution(true);
                  }}>
                  <i className="icon-trash" />
                  Delete
                </MenuItem>
              </Menu>
            </Dropdown>
          </>
        )}
      </div>
      <ModalConfirm
        isOpen={showModalDeleteEvolution}
        isConfirming={isDeleting}
        confirmText="Yes, delete"
        onConfirm={handleDeleteEvolution}
        onCancel={() => handleShowHideModalDeleteEvolution(false)}
      />
      {showShareLinkModal && (
        <SharableLinkModal
          isOpen={showShareLinkModal}
          onRequestClose={() => setShowShareLinkModal(false)}
          evolution={evolution}
        />
      )}
    </div>
  );
};

export default PokeHeader;
