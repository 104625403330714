import {hasFlag} from 'helpers/bitwise';
import {useSelector} from 'react-redux';
import {SettingsBody} from 'scenes/Settings/components/Body';
import {generalSelector} from 'selectors';
import {
  F_EXTRA_CAN_SETUP_SEGMENT_INTEGRATION,
  F_EXTRA_CAN_SETUP_SSE_INTEGRATION,
  F_EXTRA_HAS_CRISP_WIDGET_SETUP,
} from 'services/project';
import './_Styles.scss';
import Crisp from './Crisp';
import Hubspot from './Hubspot';
import Intercom from './Intercom';
import Mixpanel from './Mixpanel';
import Posthog from './Posthog';
import Segment from './Segment';
import Slack from './Slack';
import Sse from './Sse';
import Zapier from './Zapier';

const Integrations = () => {
  const project = useSelector(() => generalSelector.getProject());

  const {segmentio, sse} = project;
  const segmentActive = !!segmentio?.apiKey;
  const sseActive = !!sse?.apiKey;
  return (
    <SettingsBody className="s-settings-integrations">
      <div className="title-3">Integrations</div>
      <div className="integrations-list">
        <Intercom />
        {(segmentActive === true ||
          hasFlag(
            F_EXTRA_CAN_SETUP_SEGMENT_INTEGRATION,
            project.extraFlags
          )) && <Segment />}
        <Zapier />
        {/* <Customerio /> */}
        <Hubspot />
        <Slack />
        {hasFlag(F_EXTRA_HAS_CRISP_WIDGET_SETUP, project.extraFlags) && (
          <Crisp />
        )}
        <Posthog />
        <Mixpanel />
        {(sseActive ||
          hasFlag(F_EXTRA_CAN_SETUP_SSE_INTEGRATION, project.extraFlags)) && (
          <Sse />
        )}
      </div>
    </SettingsBody>
  );
};

export default Integrations;
