import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {errorCodes} from 'helpers/error';
import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {fileService} from 'services';
import {
  PLAN_BASIC_ID,
  PLAN_GROWTH_ID,
  PLAN_STARTUP_ID,
} from 'services/subscription';
import {Swaler} from 'swaler';
import {useUpdateSubscription} from './useUpdateSubscription';

export const useFileUpload = ({logger = new Swaler('useFileUpload')}) => {
  const {update} = useUpdateSubscription();
  const subscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );

  async function upload({file, blockType = undefined}) {
    if (file == null) return logger.warn('No file provided');

    try {
      const uploadedFile = await fileService.uploadPublicFile({
        file,
        blockType,
      });

      return uploadedFile;
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      if (code === errorCodes.FILE_SIZE_LIMIT_EXCEEDED) {
        if ([PLAN_BASIC_ID, PLAN_STARTUP_ID].includes(subscription.plan)) {
          return update({
            planId: PLAN_GROWTH_ID,
            title: 'Upload file up to 3Mb',
            description:
              'Your current plan allows you to upload files up to 1Mb.',
          });
        } else {
          return toastDanger([
            'File size limit exceeded',
            'You are file is over 3Mb. Please try with a smaller file.',
          ]);
        }
      }
      logger.error('Failed to upload file', code);
      toastDanger([title, message], {actions});
      return null;
    }
  }

  return {
    upload,
  };
};
