import classNames from 'classnames';
import DefaultLoader from 'components/Loader';
import {Modal} from 'components/Modal';
import {bool, func} from 'prop-types';
import React from 'react';
import {useQuery} from 'react-query';
import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {eventService} from 'services';
import {
  EVENT_SOURCE_MIXPANEL,
  EVENT_SOURCE_POSTHOG,
  EVENT_SOURCE_SEGMENT,
} from 'services/event';
import './_Styles.scss';
import EventsList from './components/EventsList';

const propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func.isRequired,
  onEventTypeClick: func.isRequired,
};

const defaultProps = {};

const MODE_IMPORT = 'IMPORT';

const MODE_DEFAULT = 'DEFAULT';

const ModalAddStepEvent = ({
  isOpen,
  onRequestClose,
  onCreateOnTheFlyEvent,
  onSelectEvent,
}) => {
  const project = useSelector((state) => generalSelector.getProject(state));

  let content = null;

  let {data: events = [], isLoading: isLoadingEvents} = useQuery({
    queryKey: 'trackedEvents',
    queryFn: () =>
      eventService.getEvents({
        relations: ['conditions'],
      }),
    refetchOnWindowFocus: false,
  });

  const hasEvents = events.length > 0;

  if (isLoadingEvents) {
    content = (
      <div className="loader-wrapper">
        <DefaultLoader />
      </div>
    );
  } else {
    content = (
      <EventsList
        events={events.filter(
          (event) =>
            [
              EVENT_SOURCE_SEGMENT,
              EVENT_SOURCE_POSTHOG,
              EVENT_SOURCE_MIXPANEL,
            ].includes(event.source) === false
        )}
        segmentioEvents={events.filter(
          (event) => event.source === EVENT_SOURCE_SEGMENT
        )}
        posthogEvents={events.filter(
          (event) => event.source === EVENT_SOURCE_POSTHOG
        )}
        mixpanelEvents={events.filter(
          (event) => event.source === EVENT_SOURCE_MIXPANEL
        )}
        onCreateOnTheFlyEvent={onCreateOnTheFlyEvent}
        onSelectEvent={onSelectEvent}
      />
    );
  }

  return (
    <>
      <Modal
        className={classNames('modal-add-step-event', {
          'mode-empty-state': hasEvents === false && isLoadingEvents === false,
        })}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        title={
          <>
            <div className="icon-wrapper">
              <i className="icon-plus-rounded" />
            </div>
            Track a new event
          </>
        }>
        {content}
      </Modal>
    </>
  );
};

ModalAddStepEvent.propTypes = propTypes;
ModalAddStepEvent.defaultProps = defaultProps;

export default ModalAddStepEvent;
