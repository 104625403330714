import Alert from 'components/Alert';
import Divider from 'components/Divider';
import SelectGroup from 'components/Select';
import {BuilderContext} from 'contextes/builder';
import React, {useContext, useEffect} from 'react';
import {EVOLUTION_TYPE_SURVEY} from 'services/evolution';
import {
  STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
  STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
  STEP_TYPE_NPS,
  STEP_TYPE_OPINION_SCALE,
  STEP_TYPE_SLIDER,
  STEP_TYPE_TEXT_LONG,
} from 'services/steps';
import './_Styles.scss';
import Triggers from './components/Triggers';

const propTypes = {};
const defaultProps = {};

const TriggerManager = () => {
  const {
    evolution,
    selectedStep: step,
    updateStep,
    selectingElementTriggerConditionId,
    setSelectedTriggerId,
  } = useContext(BuilderContext);

  useEffect(() => {
    return () => setSelectedTriggerId(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (step == null) {
    return null;
  }

  const {goTo} = step;

  const isSurvey = evolution?.type === EVOLUTION_TYPE_SURVEY;

  const canAddConditions = [
    STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
    STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
    STEP_TYPE_NPS,
    STEP_TYPE_SLIDER,
    STEP_TYPE_OPINION_SCALE,
    STEP_TYPE_TEXT_LONG,
  ].includes(step.type);

  const nestedStepParent = evolution.steps.find((s) =>
    s.prototypes?.[0]?.steps.find((s) => s.uid === step.uid)
  );
  const stepsList =
    nestedStepParent != null
      ? nestedStepParent.prototypes[0].steps.filter((s) => s.removed !== true)
      : evolution.steps.filter((s) => s.removed !== true);

  stepsList.sort((a, b) => a.indexOrder - b.indexOrder);

  const currentStepIndex = stepsList.indexOf(step);

  const stepsOptions = [
    ...stepsList
      .filter((s, index) => index > currentStepIndex + 1)
      .map((s) => ({label: s.name, value: s.uid})),
    ...(nestedStepParent != null
      ? [{label: 'End concept test survey', value: 'end-survey'}]
      : [{label: 'End survey', value: 'end-survey'}]),
  ];

  return (
    <div className="trigger-manager-wrapper">
      <div className="trigger-manager" key={step.uid}>
        <div className="trigger-manager-header">
          <div className="trigger-manager-title">
            {isSurvey ? 'Conditions' : 'Progress triggers'}
          </div>
        </div>
        <Divider />
        <div className="triggers-content">
          {isSurvey && (
            <>
              <div className="default-wrapper">
                <div className="subtitle-4 n-800">
                  {step.triggers?.length > 0
                    ? 'If no conditions are met'
                    : 'Always'}
                </div>
                <div className="section">
                  <div className="body-3 n-700">Go to</div>
                  <SelectGroup
                    isSearchable={false}
                    className="condition-select"
                    menuPortalTarget={document.body}
                    options={[
                      ...(stepsList[currentStepIndex + 1] != null
                        ? [
                            {
                              label: stepsList[currentStepIndex + 1]?.name,
                              value: null,
                            },
                          ]
                        : []),
                      ...stepsOptions,
                    ]}
                    placeholder="Select a step"
                    onChange={(s) => {
                      updateStep(step.uid, {
                        ...step,
                        ...(s.value === 'end-survey'
                          ? {
                              goTo: null,
                              endSurvey: true,
                            }
                          : {
                              goTo: s.value,
                              endSurvey: false,
                            }),
                      });
                    }}
                    value={[
                      ...(stepsList[currentStepIndex + 1] != null
                        ? [
                            {
                              label: stepsList[currentStepIndex + 1]?.name,
                              value: null,
                            },
                          ]
                        : []),
                      ...stepsOptions,
                    ].find((o) => {
                      if (step.endSurvey === true) {
                        return o.value === 'end-survey';
                      }
                      if (step.goTo == null) {
                        return o.value === null;
                      }
                      return o.value === goTo?.uid || o.value === goTo;
                    })}
                    isDisabled={false}
                    menuPlacement="auto"
                  />
                </div>
              </div>
              <Divider />
            </>
          )}
          {isSurvey && canAddConditions !== true ? (
            <div className="no-conditions">
              <Alert
                warning
                icon="icon-info-circle-o"
                title="This step does not support conditions"
              />
            </div>
          ) : (
            <Triggers
              key={selectingElementTriggerConditionId}
              step={step}
              updateStep={(upt) => updateStep(step.uid, upt)}
              isSurvey={isSurvey}
            />
          )}
        </div>
      </div>
    </div>
  );
};

TriggerManager.propTypes = propTypes;
TriggerManager.defaultProps = defaultProps;

export default TriggerManager;
