import {uptProject} from 'actions/general';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import {toastDanger, toastSuccess} from 'components/Toaster';
import dayjs from 'dayjs';
import {errorHelpers} from 'helpers';
import {removeFlag} from 'helpers/bitwise';
import {useState} from 'react';
import {useQuery} from 'react-query';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import {ROUTE_POKE_WITH_ID_AND_TYPE} from 'router/routes.const';
import {SettingsBody} from 'scenes/Settings/components/Body';
import {generalSelector} from 'selectors';
import {evolutionService, projectService} from 'services';
import {
  EVOLUTION_STATE_LIVE,
  EVOLUTION_STATE_PAUSED,
  EVOLUTION_TYPE_BANNER,
  EVOLUTION_TYPE_HINT,
  EVOLUTION_TYPE_SURVEY,
  EVOLUTION_TYPE_TOUR,
  F_OPTION_IGNORE_RATE_LIMITING,
  isNotRateLimited,
} from 'services/evolution';
import {Swaler} from 'swaler';
import Saver from '../../components/Saver';
import './_Styles.scss';
import ModalIgnoreRateLimiting from './components/ModalIgnoreRateLimiting';

const logger = new Swaler('Control');

const RATE_LIMIT_UNIT_DAYS = 'DAYS';
const RATE_LIMIT_UNIT_HOURS = 'HOURS';

export const rateLimitUnitOptions = [
  {
    value: RATE_LIMIT_UNIT_DAYS,
    content: 'Days',
    label: 'Days',
  },
  {
    value: RATE_LIMIT_UNIT_HOURS,
    content: 'Hours',
    label: 'Hours',
  },
];

const Control = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const updateProject = (project) => {
    dispatch(uptProject(project));
  };

  const project = useSelector((state) => generalSelector.getProject(state));

  const [rateLimitPokeAmount, setRateLimitPokeAmount] = useState(
    project.rateLimitPokeAmount
  );
  const [rateLimitValue, setRateLimitValue] = useState(project.rateLimitValue);
  const [rateLimitUnit, setRateLimitUnit] = useState(project.rateLimitUnit);
  const [isSaving, setIsSaving] = useState(false);
  const [showModalIgnore, setShowModalIgnore] = useState(false);
  const [isDeleting, setIsDeleting] = useState(null);

  const {data: evolutions = [], refetch} = useQuery({
    queryKey: 'evolutionsIgnoringRateLimiting',
    queryFn: () =>
      evolutionService.getEvolutions({
        where: [isNotRateLimited],
        onlyPokes: true,
        relations: ['segments'],
      }),
  });

  const handleSubmit = async () => {
    setIsSaving(true);
    try {
      const updatedProject = await projectService.updateProject(project.uid, {
        rateLimitPokeAmount,
        rateLimitValue,
        rateLimitUnit,
      });
      updateProject(updatedProject);
      return toastSuccess(['Done!', `Changes has been saved successfully!`], {
        autoClose: 3000,
        toastId: 'changes-saved',
      });
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(
        'Unexpected error when updating project rate limiting : ',
        code
      );
      return toastDanger([title, message], {actions});
    } finally {
      setIsSaving(false);
    }
  };

  const handleReset = () => {
    setRateLimitPokeAmount(project.rateLimitPokeAmount);
    setRateLimitValue(project.rateLimitValue);
    setRateLimitUnit(project.rateLimitUnit);
  };

  const removeFromExcludedPokes = async (evolution) => {
    const {optionsFlags} = evolution;

    setIsDeleting(evolution.uid);
    try {
      await evolutionService.updateEvolutionSettings(evolution.uid, {
        optionsFlags: removeFlag(F_OPTION_IGNORE_RATE_LIMITING, optionsFlags),
      });
      refetch();
      setIsDeleting(null);
      return toastSuccess(
        ['Done!', `Experience removed from excluded list successfully!`],
        {
          autoClose: 3000,
          toastId: 'changes-saved',
        }
      );
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(
        'Unexpected error when updating project rate limiting : ',
        code
      );
      setIsDeleting(null);
      return toastDanger([title, message], {actions});
    }
  };

  const currentSavedConfig = {
    rateLimitPokeAmount: project.rateLimitPokeAmount,
    rateLimitValue: project.rateLimitValue,
    rateLimitUnit: project.rateLimitUnit,
  };

  const currentUpdateConfig = {
    rateLimitPokeAmount: parseInt(rateLimitPokeAmount, 10),
    rateLimitValue: parseInt(rateLimitValue, 10),
    rateLimitUnit,
  };

  return (
    <SettingsBody className="s-settings-control">
      <div className="title-3">Rate Display Limit</div>
      <div className="settings-card card-limit">
        <div className="left-wrapper">
          <div className="subtitle-3">Rate limit</div>
          <div className="body-3 n-700">
            Limit display frequency to prevent users overwhelmed
          </div>
        </div>
        <div className="right-wrapper">
          <span>Display</span>
          <Input
            type="number"
            trailing="Experience(s)"
            value={rateLimitPokeAmount}
            onChange={({target}) => setRateLimitPokeAmount(target.value)}
          />
          <span>every</span>
          <Input
            type="number"
            value={rateLimitValue}
            onChange={({target}) => setRateLimitValue(target.value)}
            option
            optionItems={rateLimitUnitOptions}
            optionValue={rateLimitUnit}
            onOptionSelected={(value) => setRateLimitUnit(value)}
          />
        </div>
      </div>
      <div className="settings-card card-excluded-list">
        <div className="card-header">
          <div className="left-wrapper">
            <div className="subtitle-3">Excluded experiences</div>
            <div className="body-3 n-700">
              List of excluded experiences from rate-limiting setting
            </div>
          </div>
          <div className="right-wrapper">
            <Button
              iconLeft="icon-plus"
              thin
              onClick={() => setShowModalIgnore(true)}>
              Exclude experience
            </Button>
          </div>
        </div>
        {evolutions.length > 0 && (
          <div className="card-content">
            {evolutions?.map((evolution) => {
              const {
                uid: pushId,
                onTheFlySegment,
                segments,
                isDraft,
                state,
                expiresAt,
              } = evolution;

              const audience =
                onTheFlySegment != null
                  ? 'On the fly segmentation'
                  : segments?.length > 0
                  ? segments.map((s) => s.name)
                  : 'All';

              const isLive =
                isDraft !== true &&
                (state === EVOLUTION_STATE_LIVE || state == null);
              const isPaused =
                isDraft !== true && state === EVOLUTION_STATE_PAUSED;
              const isExpired =
                isDraft !== true &&
                expiresAt != null &&
                dayjs(expiresAt).isBefore(dayjs());

              return (
                <div className="excluded-poke" key={pushId}>
                  <div className="left-wrapper">
                    {isExpired ? (
                      <div className="status-tag tag-expired body-3">
                        Expired
                      </div>
                    ) : isLive ? (
                      <div className="status-tag tag-live body-3">Live</div>
                    ) : isPaused ? (
                      <div className="status-tag tag-paused body-3">Paused</div>
                    ) : (
                      <div className="status-tag tag-draft body-3">Draft</div>
                    )}
                  </div>
                  <div className="center-wrapper">
                    <div className="subtitle-2">{evolution.title}</div>
                    <div className="labels-wrapper">
                      <div className="excluded-label">
                        {evolution.type === EVOLUTION_TYPE_TOUR ? (
                          <>
                            <i className="isax isax-routing-2" />
                            Tour
                          </>
                        ) : evolution.type === EVOLUTION_TYPE_SURVEY ? (
                          <>
                            <i className="isax isax-note-2" />
                            Survey
                          </>
                        ) : evolution.type === EVOLUTION_TYPE_BANNER ? (
                          <>
                            <i className="icon-slot-top-bar" />
                            Banner
                          </>
                        ) : evolution.type === EVOLUTION_TYPE_HINT ? (
                          <>
                            <i className="icon-slot-dot" />
                            Hint
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      {audience === 'All' && (
                        <div className="excluded-label body-3">
                          <i className="isax isax-profile-2user"></i>All user
                        </div>
                      )}
                      {audience === 'On the fly segmentation' && (
                        <div className="excluded-label body-3">
                          <i className="isax isax-profile-2user"></i>On the fly
                          segmentation segment
                        </div>
                      )}
                      {Array.isArray(audience) &&
                        audience.map((s) => (
                          <div className="excluded-label body-3">
                            <i className="isax isax-profile-2user"></i>
                            {s}
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="right-wrapper">
                    <Dropdown
                      position="bottom right"
                      offsetY={4}
                      trigger={
                        <Button iconOnly loading={isDeleting === evolution.uid}>
                          <i className="icon-menu-vertical"></i>
                        </Button>
                      }>
                      <div className="dp-menu">
                        <div
                          className="dp-menu-item body-3"
                          onClick={() => {
                            history.push(
                              ROUTE_POKE_WITH_ID_AND_TYPE(
                                evolution.type,
                                evolution.uid
                              )
                            );
                          }}>
                          <i className="isax isax-info-circle"></i>Detail
                        </div>
                        <div
                          className="dp-menu-item r-400 body-3 danger"
                          onClick={() => removeFromExcludedPokes(evolution)}>
                          <i className="isax isax-trash"></i>Remove from
                          excluded
                        </div>
                      </div>
                    </Dropdown>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <Saver
        onSave={handleSubmit}
        onCancel={handleReset}
        isOpen={
          JSON.stringify(currentSavedConfig) !==
          JSON.stringify(currentUpdateConfig)
        }
        isSaving={isSaving}
      />
      <ModalIgnoreRateLimiting
        isOpen={showModalIgnore}
        onRequestClose={() => {
          setShowModalIgnore(false);
          refetch();
        }}
      />
    </SettingsBody>
  );
};

export default Control;
