import Axios from 'axios';
import {generalSelector} from 'selectors';

export const F_POSTHOG_DATA_JIMO_2_POSTHOG = 1;
export const F_POSTHOG_DATA_POSTHOG_2_JIMO = 2;

// Endpoint
const EP_POSTHOG_SETUP = '/posthog/setup';
const EP_POSTHOG_SETUP_UPDATE = '/posthog/setup';
const EP_POSTHOG_REMOVE = '/posthog/setup';
const EP_POSTHOG_EVENTS = '/posthog/events';

// Method
export const setup = (projectId) => {
  return Axios.post(
    EP_POSTHOG_SETUP,
    {},
    {
      params: {projectId},
    }
  ).then((response) => response.data);
};

export const remove = (projectId) => {
  return Axios.delete(EP_POSTHOG_REMOVE, {
    params: {projectId},
  }).then((response) => response.data);
};

export const getPosthogEvents = (
  projectId = generalSelector.getProject().uid
) => {
  return Axios.get(EP_POSTHOG_EVENTS, {params: {projectId}}).then(
    (response) => response.data
  );
};

export const update = (data, projectId = generalSelector.getProject().uid) => {
  const {flags} = data;

  return Axios.put(
    EP_POSTHOG_SETUP_UPDATE,
    {flags},
    {params: {projectId}}
  ).then((response) => response.data);
};
