import classNames from 'classnames';
import React, {useContext} from 'react';
import AnimateHeight from 'react-animate-height';
import ClickableBlockOverlay from '../../../Poke/components/ClickableBlockOverlay';
import {
  BLOCK_CHECKLIST_DISMISS,
  BLOCK_CHECKLIST_TASK_CHECKBOX,
  BLOCK_CHECKLIST_TASK_ITEM,
  BLOCK_CHECKLIST_TASK_ITEM_DESCRIPTION,
  BLOCK_CHECKLIST_TASK_ITEM_MEDIA,
  BLOCK_CHECKLIST_TASK_ITEM_PRIMARY_CTA,
  BLOCK_CHECKLIST_TASK_ITEM_SECONDARY_CTA,
  BLOCK_CHECKLIST_TASK_ITEM_TITLE,
} from '../../../Poke/constants/blocks';
import {PokeStateContext} from '../../../Poke/context';
import ChecklistCta from '../ChecklistCta';
import './_Styles.scss';
import Checkbox from './components/Checkbox';
import TaskMedia from './components/TaskMedia';
import TaskParagraph from './components/TaskParagraph';
import TaskTitle from './components/TaskTitle';

const ChecklistTaskItem = ({task, completed, isLastTask}) => {
  const {
    onBlockSelected,
    onTaskSelected,
    selectedTaskId,
    selectedBlock,
    inBuilder,
    blocks,
    setHoveredTaskId,
    JimoLabel,
    onTaskClicked,
    expandTaskOnClick,
    onTriggerActions,
  } = useContext(PokeStateContext);

  const taskItemBlocks = blocks.filter(
    (b) => b.parentBlockId === task.uid && b.removed !== true
  );

  const checkboxBlock = taskItemBlocks.find(
    (b) => b.type === BLOCK_CHECKLIST_TASK_CHECKBOX
  );
  const titleBlock = taskItemBlocks.find(
    (b) => b.type === BLOCK_CHECKLIST_TASK_ITEM_TITLE
  );
  const paragraphBlock = taskItemBlocks.find(
    (b) => b.type === BLOCK_CHECKLIST_TASK_ITEM_DESCRIPTION
  );
  const mediaBlock = taskItemBlocks.find(
    (b) => b.type === BLOCK_CHECKLIST_TASK_ITEM_MEDIA
  );
  const primaryCtaBlock = taskItemBlocks.find(
    (b) => b.type === BLOCK_CHECKLIST_TASK_ITEM_PRIMARY_CTA
  );
  const secondaryCtaBlock = taskItemBlocks.find(
    (b) => b.type === BLOCK_CHECKLIST_TASK_ITEM_SECONDARY_CTA
  );

  const expanded = task.uid === selectedTaskId;

  const {style} = task;
  const {borderColor} = style;

  const dismissibleBlock = blocks.find(
    (b) => b.type === BLOCK_CHECKLIST_DISMISS
  );
  const isDismissible = dismissibleBlock != null;

  const hasFooter = isDismissible === true || JimoLabel != null;

  return (
    <div
      className={classNames('checklist-task-item', {
        'poke-block-clickable': inBuilder === true,
        completed: completed,
        selected:
          selectedBlock === BLOCK_CHECKLIST_TASK_ITEM &&
          selectedTaskId === task?.uid,
        clickable:
          (primaryCtaBlock == null && secondaryCtaBlock == null) ||
          (expandTaskOnClick === true && expanded !== true),
        expanded: expanded === true,
      })}
      onClick={(e) => {
        e.stopPropagation();

        if (inBuilder === true) {
          onBlockSelected(BLOCK_CHECKLIST_TASK_ITEM);
          onTaskSelected(task.uid);
          return;
        }

        if (expandTaskOnClick === true) {
          if (expanded !== true) {
            setHoveredTaskId(task.uid);
            return;
          }
        }
        if (primaryCtaBlock == null) {
          onTriggerActions(task.actions || []);
          onTaskClicked(task.uid);
        }
      }}
      onMouseEnter={() => {
        if (inBuilder === true || expandTaskOnClick !== true) {
          setHoveredTaskId(task.uid);
        }
      }}
      style={{
        ...(isLastTask !== true || hasFooter === true ? {borderColor} : {}),
      }}>
      <div className="task-header">
        <Checkbox block={checkboxBlock} completed={completed} />
        <TaskTitle block={titleBlock} completed={completed} />
      </div>
      <div
        className={classNames('task-content', {
          'has-paragraph': paragraphBlock != null,
          'has-additional-content':
            mediaBlock != null ||
            primaryCtaBlock != null ||
            secondaryCtaBlock != null,
        })}>
        <AnimateHeight
          height={expanded === true ? 'auto' : 0}
          duration={300}
          easing="ease-in-out">
          <div className="task-collapsable-content">
            {paragraphBlock != null && <TaskParagraph block={paragraphBlock} />}
            {mediaBlock != null && <TaskMedia block={mediaBlock} />}
            {(primaryCtaBlock != null || secondaryCtaBlock != null) && (
              <div className="task-ctas">
                {primaryCtaBlock != null && (
                  <ChecklistCta block={primaryCtaBlock} parentBlock={task} />
                )}
                {secondaryCtaBlock != null && (
                  <ChecklistCta block={secondaryCtaBlock} />
                )}
              </div>
            )}
          </div>
        </AnimateHeight>
      </div>

      <ClickableBlockOverlay />
    </div>
  );
};

export default ChecklistTaskItem;
