import classNames from 'classnames';
import Button from 'components/Button';
import {toastDanger} from 'components/Toaster';
import {crispHelpers, errorHelpers} from 'helpers';
import {useUpdateSubscription} from 'hooks/useUpdateSubscription';
import {useState} from 'react';
import {useQuery} from 'react-query';
import {useSelector} from 'react-redux';
import {SettingsBody} from 'scenes/Settings/components/Body';
import {generalSelector} from 'selectors';
import {environmentService} from 'services';
import {
  PLAN_BASIC_ID,
  PLAN_GROWTH_ID,
  PLAN_STARTUP_ID,
} from 'services/subscription';
import {Swaler} from 'swaler';
import './_Styles.scss';
import ModalEditEnvironment from './components/ModalEditEnvironment';
import ModalEnvironment from './components/ModalEnvironment';

const logger = new Swaler('Environments');

const getClassFromColor = (color) => {
  let colorClass = '';
  if (color === '#E6FDE8') {
    colorClass = 'green';
  } else if (color === '#DDEEFF') {
    colorClass = 'blue';
  } else if (color === '#FDF2E6') {
    colorClass = 'orange';
  } else if (color === '#ECE6FD') {
    colorClass = 'purple';
  } else if (color === '#FFD7D7') {
    colorClass = 'red';
  } else if (color === '#FDF8C9') {
    colorClass = 'yellow';
  } else if (color === '#ECECEC') {
    colorClass = 'gray';
  }

  return colorClass;
};

const Environment = ({environment, onClick}) => {
  const {name, color, icon, domains} = environment;

  const domainsArr = domains?.split(';')?.filter((d) => d);

  const colorClass = getClassFromColor(color);

  return (
    <div
      className={classNames('environment-wrapper', colorClass)}
      onClick={onClick}>
      <div className={classNames('environment-icon')}>{icon}</div>
      <div className="environment-info">
        <div className="environment-name subtitle-3">{name}</div>
        <div className="environment-domains body-3 n-700">
          {domainsArr?.length > 1
            ? `${domainsArr?.length} domains`
            : domainsArr?.length === 1
            ? domainsArr[0]
            : 'All domains'}
        </div>
      </div>
    </div>
  );
};

const Environments = () => {
  const {update} = useUpdateSubscription();

  const project = useSelector((state) => generalSelector.getProject(state));
  const projectSubscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );

  const [environmentToSee, setEnvironmentToSee] = useState(null);
  const [environmentToEdit, setEnvironmentToEdit] = useState(null);

  const {data: environments = [], refetch} = useQuery({
    queryKey: 'environments',
    queryFn: () => environmentService.getEnvironments({}),
    onError: (err) => {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Failed to fetch environments with error', code);
      return toastDanger([title, message], {actions});
    },
  });

  return (
    <SettingsBody className="s-settings-environments">
      <div className="title-3">Environments</div>

      <div className="settings-card card-environments">
        <div className="card-header">
          <div className="left-wrapper">
            <div className="subtitle-3">Project environments</div>
            <div className="body-3 n-700">
              Publish and manage experiences across multiple environments. (e.g.
              Production, Staging, etc.)
            </div>
          </div>
          <div className="right-wrapper">
            <Button
              iconLeft="icon-plus"
              thin
              onClick={() => {
                if (
                  environments.length === 2 &&
                  [PLAN_BASIC_ID, PLAN_STARTUP_ID].includes(
                    projectSubscription.plan
                  )
                ) {
                  return update({
                    planId: PLAN_GROWTH_ID,
                    title: 'Need more environments?',
                    description: 'Upgrade and create up to 5 environments!',
                  });
                }
                if (environments.length >= project.thresholdEnvironments) {
                  return crispHelpers.startCrispThread(
                    "I'd like to increase my environments limit!"
                  );
                }
                setEnvironmentToEdit(true);
              }}>
              New environment
            </Button>
          </div>
        </div>
        {environments.length > 0 && (
          <div className="card-content">
            {environments?.map((environment) => (
              <Environment
                key={environment.uid}
                environment={environment}
                onClick={() => {
                  setEnvironmentToSee(environment);
                }}
              />
            ))}
          </div>
        )}
      </div>

      {environmentToEdit != null && (
        <ModalEditEnvironment
          isOpen={environmentToEdit != null}
          onRequestClose={() => {
            setEnvironmentToEdit(null);
            refetch();
          }}
          environment={environmentToEdit}
        />
      )}

      {environmentToSee != null && (
        <ModalEnvironment
          isOpen={environmentToSee != null}
          onRequestClose={() => {
            setEnvironmentToSee(null);
            refetch();
          }}
          environment={environmentToSee}
          onEdit={() => {
            setEnvironmentToEdit(environmentToSee);
            setEnvironmentToSee(null);
          }}
          onDelete={async () => {
            setEnvironmentToSee(null);
            refetch();
          }}
        />
      )}
    </SettingsBody>
  );
};

export default Environments;
