import {number, string} from 'prop-types';
import React from 'react';
import './_Styles.scss';

const CircularProgress = ({
  size = 44,
  strokeWidth = 3,
  progress = 30,
  strokeColor = '#FFFFFF',
  trackColor = '#FFFFFF33',
  textColor = '#FFFFFF',
}) => {
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (progress / 100) * circumference;

  return (
    <svg
      className="circular-progress"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}>
      <g transform={`rotate(-90 ${size / 2} ${size / 2})`}>
        <circle
          stroke={trackColor}
          fill="transparent"
          strokeWidth={strokeWidth}
          cx={size / 2}
          cy={size / 2}
          r={radius}
        />

        <circle
          stroke={strokeColor}
          fill="transparent"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          strokeLinecap="round"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          style={{transition: 'stroke-dashoffset 0.5s ease'}}
        />
      </g>

      <text x="50%" y="50%" textAnchor="middle" dy=".3em">
        {`${progress}%`}
      </text>
    </svg>
  );
};

CircularProgress.propTypes = {
  size: number,
  strokeWidth: number,
  progress: number,
  strokeColor: string,
  trackColor: string,
  textColor: string,
};

export default CircularProgress;
