import {generalActions} from 'actions';
import Alert from 'components/Alert';
import Button from 'components/Button';
import {toastInfo} from 'components/Toaster';
import React, {useEffect} from 'react';
import {useMutation} from 'react-query';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {ROUTE_SETTINGS_INTEGRATIONS} from 'router/routes.const';
import {SettingsBody} from 'scenes/Settings/components/Body';
import {generalSelector} from 'selectors';
import {sseService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';
import HelpSection from './components/HelpSection';
import SettingsSetup from './components/SettingsSetup';

const logger = new Swaler('SseSettings');

const SseSettings = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const project = useSelector(() => generalSelector.getProject());

  const {sse} = project;

  const {mutate: deleteSse, isLoading: isLoadingSseDelete} = useMutation(
    () => sseService.deleteSse(sse?.uid),
    {
      onError: (err) => {
        logger.error(err);
      },
      onSuccess: () => {
        toastInfo([
          'Integration disconnected',
          'Your integration with Server-Side Events has been disconnected!',
        ]);
        history.push(ROUTE_SETTINGS_INTEGRATIONS);
        dispatch(generalActions.uptProject({sse: null}));
      },
    }
  );

  useEffect(() => {
    if (sse == null) {
      history.push(ROUTE_SETTINGS_INTEGRATIONS);
      toastInfo([
        'Integration not connected',
        "Server-Side Events isn't activated yet. Please activate the integration first.",
      ]);
    }
  }, [sse]);

  return (
    <SettingsBody className="sse-settings">
      <div className="sse-settings-header">
        <div className="left-side">
          <Button
            className="back-btn"
            iconOnly
            iconLeft="icon-chevron-left"
            onClick={async () => {
              history.push(ROUTE_SETTINGS_INTEGRATIONS);
            }}
          />
          <div className="title-3">Server-Side Events</div>
        </div>
      </div>
      <div className="sse-settings-content">
        <div className="left-side">
          <SettingsSetup sse={sse} />
        </div>
        <div className="right-side">
          <Alert
            info
            title="Server-Side Events in BETA"
            icon="isax isax-info-circle">
            Jimo Server-Side Events is currently in BETA. If you have any
            questions, please contact us at{' '}
            <a href="mailto:support@usejimo.com">support@usejimo.com</a>.
          </Alert>
          <HelpSection
            isDisconnecting={isLoadingSseDelete}
            onDisconnect={() => {
              deleteSse();
            }}
          />
        </div>
      </div>
    </SettingsBody>
  );
};

export default SseSettings;
