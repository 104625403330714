import {SegmentAttributesEditorContext} from 'components/SegmentAttributesEditor';
import SelectGroup from 'components/Select';
import React, {useContext} from 'react';
import {JimoIconSmall} from 'scenes/Settings/scenes/Integrations/Customerio/scenes/Settings';
import {
  CATEGORY_ACTIVITY,
  CATEGORY_ATTRIBUTES,
  CATEGORY_CIO_EVENTS,
  CATEGORY_CUSTOM_ATTRIBUTES,
  CATEGORY_JIMO_API_EVENTS,
  CATEGORY_MIXPANEL_EVENTS,
  CATEGORY_POSTHOG_EVENTS,
  CATEGORY_SEGMENTIO_EVENTS,
  CATEGORY_SEGMENTIO_FIELDS,
  CATEGORY_TRACKED_EVENTS,
} from 'services/segment';
import {AttributeContext} from '../..';
import MixpanelIcon from '../../../../icons/mixpanel.svg';
import PosthogIcon from '../../../../icons/posthog.svg';
import SegmentioIcon from '../../../../icons/segmentio-icon-dark.svg';
import {Control, Option} from '../AttributeType';
import './_Styles.scss';

export const typeOptions = [
  {
    label: 'Properties',
    value: CATEGORY_ATTRIBUTES,
    icon: <i className="icon-users" />,
    iconBackgroundColor: '#FFE7A4',
  },
  {
    label: 'Custom Attributes',
    value: CATEGORY_CUSTOM_ATTRIBUTES,
    icon: <i className="icon-pin" />,
    iconBackgroundColor: '#FFD7D7',
  },
  {
    label: 'Experiences',
    value: CATEGORY_ACTIVITY,
    icon: <i className="icon-layers" />,
    iconBackgroundColor: '#BCDDFF',
  },
  {
    label: 'Events',
    value: CATEGORY_TRACKED_EVENTS,
    icon: <i className="icon-pointer" />,
    iconBackgroundColor: '#C4E9BF',
  },
  {
    label: 'Segment.io Events',
    value: CATEGORY_SEGMENTIO_EVENTS,
    icon: (
      <img
        className="icon-segmentio"
        src={SegmentioIcon}
        alt="segmentio-icon"
      />
    ),
    iconBackgroundColor: '#CEB1E5',
  },
  {
    label: 'Segment.io Traits',
    value: CATEGORY_SEGMENTIO_FIELDS,
    icon: (
      <img
        className="icon-segmentio"
        src={SegmentioIcon}
        alt="segmentio-icon"
      />
    ),
    iconBackgroundColor: '#ECE6FD',
  },
  {
    label: 'Customer.io Events',
    value: CATEGORY_CIO_EVENTS,
    icon: (
      <img
        className="icon-customerio"
        src={SegmentioIcon}
        alt="customerio-icon"
      />
    ),
    iconBackgroundColor: '#ECE6FD',
  },
  {
    label: 'Mixpanel Events',
    value: CATEGORY_MIXPANEL_EVENTS,
    iconBackgroundColor: '#d39bfc',
    icon: (
      <img
        style={{
          filter: 'brightness(0)',
          width: '60%',
          height: '60%',
        }}
        width="60%"
        height="60%"
        className="icon-mixpanel"
        src={MixpanelIcon}
        alt="mixpanel-icon"
      />
    ),
  },
  {
    label: 'PostHog Events',
    value: CATEGORY_POSTHOG_EVENTS,
    iconBackgroundColor: '#F03707',
    icon: (
      <img
        style={{filter: 'brightness(0)'}}
        width="100%"
        height="100%"
        className="icon-posthog"
        src={PosthogIcon}
        alt="posthog-icon"
      />
    ),
  },
  {
    label: 'Jimo API Events',
    value: CATEGORY_JIMO_API_EVENTS,
    icon: (
      <img
        className="jimo-icon"
        width="60%"
        height="60%"
        src={JimoIconSmall}
        alt="jimo-icon"
      />
    ),
    iconBackgroundColor: '#C4E9BF',
  },
];

const AttributeCategorySelect = () => {
  const {jimoApiEvents, mixpanelEvents, posthogEvents} = useContext(
    SegmentAttributesEditorContext
  );
  const {attribute, updateAttribute} = useContext(AttributeContext);

  const {category} = attribute;

  const selectedCategory = typeOptions.find((e) => e.value === category);

  if (selectedCategory != null) {
    return (
      <SelectGroup
        menuPortalTarget={document.body}
        className="select-type-attribute"
        options={typeOptions.filter((e) => {
          if (e.value === CATEGORY_JIMO_API_EVENTS) {
            return jimoApiEvents?.length > 0;
          }

          if (e.value === CATEGORY_MIXPANEL_EVENTS) {
            return mixpanelEvents?.length > 0;
          }

          if (e.value === CATEGORY_POSTHOG_EVENTS) {
            return posthogEvents?.length > 0;
          }

          return true;
        })}
        closeMenuOnSelect={true}
        value={selectedCategory}
        onChange={(e) => {
          updateAttribute({
            category: e.value,
            type: null,
            value: null,
            evolutionId: null,
          });
        }}
        components={{
          Option: Option,
          Control: (controlProps) => (
            <Control {...controlProps} selectedBlock={selectedCategory} />
          ),
        }}
      />
    );
  }

  return <></>;
};

export default AttributeCategorySelect;
