// Safely get a nested value from an object using a dot-notation string path
export const getValueFromPath = (obj, path) => {
  if (!obj || typeof path !== 'string') {
    return undefined;
  }

  return path.split('.').reduce((acc, key) => {
    return acc && acc[key] !== undefined ? acc[key] : undefined;
  }, obj);
};
