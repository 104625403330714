import {dataActions, generalActions} from 'actions';
import Alert from 'components/Alert';
import Button from 'components/Button';
import Input from 'components/Input';
import {addFlag} from 'helpers/bitwise';
import {delay} from 'helpers/utils';
import {useStartTrial} from 'hooks/useStartTrial';
import {useState} from 'react';
import AnimateHeight from 'react-animate-height';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {ROUTE_GET_STARTED} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {
  meService,
  projectService,
  rolesService,
  subscriptionService,
} from 'services';
import {F_USAGE_CHANGELOG, F_USAGE_DISCOVERY} from 'services/project';
import {PLAN_GROWTH_ID} from 'services/subscription';
import {Swaler} from 'swaler';
import imgLogo from './../../assets/logo.svg';
import './_Styles.scss';

const logger = new Swaler('Onboarding/Project');

export const OnboardingProject = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {start} = useStartTrial({showWelcomeModal: false});

  const user = useSelector((state) => generalSelector.getUser(state));

  const setProject = (project) => dispatch(generalActions.setProject(project));
  const setProjects = (projects) =>
    dispatch(generalActions.setProjects(projects));
  const uptProjectSubscription = (subscription) =>
    dispatch(generalActions.uptProjectSubscription(subscription));
  const uptProject = (data) => dispatch(generalActions.uptProject(data));
  const setSubscriptionPlans = (plans) =>
    dispatch(dataActions.setSubscriptionPlans(plans));
  const setSegments = (segments) => dispatch(dataActions.setSegments(segments));
  const setCustomAttributes = (attributes) =>
    dispatch(dataActions.setCustomAttributes(attributes));
  const setBuiltInRoles = (roles) =>
    dispatch(dataActions.setBuiltInRoles(roles));

  const [inputProduct, setInputProduct] = useState('');
  const [isCreating, setIsCreating] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let subscription = null;
    let plans = null;
    let projects = [];
    let createdProject = null;

    setIsCreating(true);
    try {
      createdProject = await projectService.createProject({
        name: inputProduct,
        usage:
          user.usageFlags != null
            ? user.usageFlags
            : addFlag(
                [F_USAGE_DISCOVERY],
                F_USAGE_CHANGELOG,
                F_USAGE_CHANGELOG
              ),
      });
      subscription = await subscriptionService.initSubscription(
        createdProject.uid
      );
      projects = await meService.getMyProjects();

      await subscriptionService
        .setSubscriptionCurrency(subscription.uid)
        .catch((err) =>
          logger.error("Couldn't set currency for project", inputProduct)
        );
      plans = await subscriptionService.getSubscriptionPlans();
      const builtInRoles = await rolesService.getBuiltInRoles();
      const setCustomRoles = (roles) =>
        dispatch(dataActions.setCustomRoles(roles));

      setSubscriptionPlans(plans);
      setProjects(projects);
      setProject(createdProject.uid);
      uptProjectSubscription(subscription);
      setSegments([]);
      setCustomAttributes([]);
      setBuiltInRoles(builtInRoles);
      setCustomRoles([]);
      // Make sure store has been updated before starting trial
      await delay(500);
      await start({
        planId: PLAN_GROWTH_ID,
        mau: 10000,
      });
    } catch (err) {
      setIsCreating(false);
      return logger.error('Fail to create project!', err);
    }
    if (projects.length === 1) {
      await meService.welcomeMe(createdProject.uid);
    }
    history.push(ROUTE_GET_STARTED);
  };

  return (
    <div className="s-onboarding-project">
      <div className="cover-peoples people-left"></div>
      <div className="cover-peoples people-right"></div>
      <div className="s-onboarding-project fade-in-right">
        <div className="main-wrapper">
          <img width="64px" src={imgLogo} alt="Logo of Jimo" />
          <div className="title">Let's get started!</div>
          <div className="subtitle">
            Make better products by engaging your users in your product life
            cycle.
          </div>
          <form onSubmit={handleSubmit}>
            <AnimateHeight
              height={
                projectService.isValidName(inputProduct) === false ? 'auto' : 0
              }>
              <Alert danger className="fade-in">
                Your product name can't go over 32 characters!
              </Alert>
            </AnimateHeight>
            <div className="input-item last-product">
              <div className="input-legend">Name of your product</div>
              <Input
                className="input-onboarding"
                inverted
                placeholder="Notion"
                required
                disable={isCreating}
                value={inputProduct}
                onChange={({target}) => setInputProduct(target.value)}></Input>
            </div>
            <Button
              className="btn-submit"
              primary
              fullwidth
              thin
              rounded={false}
              iconRight="icon-chevron-right"
              text="Create"
              loading={isCreating}
              disabled={
                projectService.isValidName(inputProduct) === false
              }></Button>
          </form>
        </div>
      </div>
    </div>
  );
};
