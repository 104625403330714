import classNames from 'classnames';
import Label from 'components/Label';
import DefaultLoader from 'components/Loader';
import dayjs from 'dayjs';
import {hasFlag} from 'helpers/bitwise';
import {object} from 'prop-types';
import React, {useMemo, useState} from 'react';
import {useQuery} from 'react-query';
import {HiddenStepTooltip} from 'scenes/Poke/component/Overview';
import {
  HINT_TYPE_HIDDEN,
  HINT_TYPE_ICON,
  HINT_TYPE_LABEL,
  HINT_TYPE_STICKER,
} from 'scenes/PokeBuilder/components/BlockEditor/blocks/Hint';
import {evolutionAnalyticsService} from 'services';
import {F_OPTION_DOT_SHOW_ON_HOVER} from 'services/evolution';
import {BLOCK_TYPE_HINT} from 'services/steps';
import {Swaler} from 'swaler';
import UsersReachedDrawer from '../UsersReachedDrawer';
import './_Styles.scss';

const HintActivityItem = ({index, evolution, hint, totalViewsCount}) => {
  const [showUsersModal, setShowUsersModal] = useState(false);

  const {name, viewsCount, uniqueViewsCount, blocks = [], hintEvolution} = hint;
  const hintBlock = blocks.find((b) => b.type === BLOCK_TYPE_HINT);

  let typeName =
    hintBlock?.style?.type === HINT_TYPE_ICON
      ? 'Icon'
      : hintBlock?.style?.type === HINT_TYPE_LABEL
      ? 'Label'
      : hintBlock?.style?.type === HINT_TYPE_HIDDEN
      ? 'Targeted element'
      : hintBlock?.style?.type === HINT_TYPE_STICKER
      ? 'Sticker'
      : 'Unknown';
  let triggerName = hasFlag(
    F_OPTION_DOT_SHOW_ON_HOVER,
    hintEvolution.optionsFlags
  )
    ? 'On Hover'
    : 'On Click';

  const percentageOfTotalViews =
    totalViewsCount > 0 ? Math.round((viewsCount * 100) / totalViewsCount) : 0;

  return (
    <div className="hint-activity-item">
      <div className="hint-activity-item-content">
        <div className="hint-info">
          <div className="hint-basic-info">
            Hint {index + 1} &bull; {typeName}
          </div>
          <div className="hint-name">{name}</div>
          {percentageOfTotalViews > 0 && (
            <div
              className="chart-wrapper"
              style={{
                width: `${percentageOfTotalViews}%`,
              }}
            />
          )}
          {hint.removed === true && (
            <HiddenStepTooltip
              step={hint}
              isHint
              trigger={
                <div className="label-hidden-wrapper">
                  <Label
                    size="small"
                    type="neutral"
                    primary
                    iconLeft="isax isax-eye-slash"
                    className="label-hidden">
                    Hidden
                  </Label>
                </div>
              }
            />
          )}
        </div>
        <div className="hint-action">{triggerName}</div>
        <div className="hint-views">{viewsCount || '-'}</div>
        <div
          className={classNames('hint-users', {
            clickable: uniqueViewsCount > 0,
          })}
          onClick={() => {
            if (uniqueViewsCount > 0) {
              setShowUsersModal(true);
            }
          }}>
          {uniqueViewsCount || '-'}
        </div>
      </div>

      {showUsersModal && (
        <UsersReachedDrawer
          isOpen={showUsersModal}
          onRequestClose={() => setShowUsersModal(false)}
          step={hint}
          evolution={evolution}
          title={`Users who viewed "${evolution.title}"`}
        />
      )}
    </div>
  );
};

const propTypes = {
  evolution: object,
  startDate: object.isRequired,
  endDate: object.isRequired,
};

const defaultProps = {
  evolution: null,
};

const logger = new Swaler('HintActivity');

const HintActivity = ({evolution, startDate, endDate}) => {
  const steps = useMemo(() => {
    return evolution?.tourSteps
      .map((t) =>
        t.steps.map((s) => {
          const tourStepIndex = (t.tourStepInfo || '0;0;0').split(';')[0];
          return {...s, _tourStepIndex: tourStepIndex, hintEvolution: t};
        })
      )
      .flat()
      .sort((a, b) => {
        if (a._tourStepIndex === b._tourStepIndex) {
          return a.indexOrder - b.indexOrder;
        }
        return a._tourStepIndex - b._tourStepIndex;
      });
  }, [evolution]);

  const {
    data: analyticsHourlyByStep = [],
    isLoading: isLoadingAnalyticHourlyByStep,
  } = useQuery({
    queryKey: ['analyticsHourlyPerStep', evolution.uid, startDate, endDate],
    queryFn: () => {
      return evolutionAnalyticsService.getAnalyticsHourlyByStep({
        startDate,
        endDate: dayjs(endDate).add(1, 'day').toDate(),
        evolutionIds: [evolution.uid],
      });
    },
    onError: (err) => {
      logger.error(
        'Could not fetch steps analytics, failed with err',
        err.message
      );
    },
  });

  const stepsWithAnalytics = analyticsHourlyByStep?.reduce((acc, cur) => {
    const index = acc.map((step) => step.uid).indexOf(cur.step_uid);
    if (index >= 0) {
      if (acc[index].uniqueViewsCount == null) {
        acc[index].uniqueViewsCount = parseInt(cur.sum_unique_views_count, 10);
        acc[index].viewsCount = parseInt(cur.sum_views_count, 10);
      } else {
        acc[index].uniqueViewsCount =
          acc[index].uniqueViewsCount +
          parseInt(cur.sum_unique_views_count, 10);
        acc[index].viewsCount =
          acc[index].viewsCount + parseInt(cur.sum_views_count, 10);
      }
    }
    return acc;
  }, JSON.parse(JSON.stringify(steps || [])));

  const totalViewsCount = stepsWithAnalytics?.reduce(
    (acc, cur) => (acc += cur.viewsCount || 0),
    0
  );

  return (
    <div className="hint-activity">
      {isLoadingAnalyticHourlyByStep ? (
        <div className="loader-wrapper">
          <DefaultLoader />
        </div>
      ) : (
        <>
          <div className="hint-activity-header">
            <div>Hint</div>
            <div style={{justifySelf: 'right'}}>Event</div>
            <div style={{justifySelf: 'right'}}>Interactions</div>
            <div style={{justifySelf: 'right'}}>Users</div>
          </div>
          <div className="hint-activity-content">
            {stepsWithAnalytics?.map((hint, index) => {
              return (
                <HintActivityItem
                  index={index}
                  evolution={evolution}
                  hint={hint}
                  totalViewsCount={totalViewsCount}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

HintActivity.propTypes = propTypes;
HintActivity.defaultProps = defaultProps;

export default HintActivity;
