import {GlobalContext} from 'contextes/Global';
import {crispHelpers} from 'helpers';
import {hasFlag} from 'helpers/bitwise';
import {useContext, useState} from 'react';
import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {
  canLaunchTrialExtension,
  isEligibleToTrial,
  isPaying,
} from 'services/project';
import {
  F_PRICING_V0,
  hasAnnualBilling,
  isUpgrade,
  PLAN_SCALE_ID,
} from 'services/subscription';
import {useDowngradePlan} from './useDowngradePlan';
import {usePlanPreview} from './usePlanPreview';
import {useUpgradePlan} from './useUpgradePlan';

// Open either a checkout, upgrade or downgrade modal should be shown based on the plan provided
export const useUpdateSubscription = ({onSuccess = () => {}} = {}) => {
  const subscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );

  const {setModalSubscriptionCheckout} = useContext(GlobalContext);

  const {downgrade} = useDowngradePlan();
  const {upgrade} = useUpgradePlan();
  const {preview} = usePlanPreview();

  const [isUpdating, setIsUpdating] = useState(false);

  async function update({
    planId,
    mau = subscription.extraJimers > 0 ? subscription.extraJimers : 2500,
    modeAnnualBilling = hasAnnualBilling(),
    skipPreviewMode = false,
    title = null,
    description = null,
    onPreviewClose = () => {},
  }) {
    if (hasFlag(F_PRICING_V0, subscription.extraFlags)) {
      crispHelpers.startCrispThread(
        `I'd like to update my subscription but I'm on the old pricing model. Can you help me?`
      );
      return;
    }
    if (planId === PLAN_SCALE_ID) {
      crispHelpers.startCrispThread(
        `Hi! I'd like to upgrade to your Enterprise plan.`
      );
      return;
    }

    if (skipPreviewMode === false) {
      preview({planId, mau, title, description, onClose: onPreviewClose});
    } else if (isEligibleToTrial(planId) || canLaunchTrialExtension(planId)) {
      preview({planId, mau, title, description, onClose: onPreviewClose});
    } else if (isPaying() === false) {
      setModalSubscriptionCheckout({
        planId,
        mau,
        modeAnnualBilling,
        onCancel: () => {
          setIsUpdating(false);
        },
        onSuccess,
      });
    } else if (isUpgrade(planId, mau) === true) {
      upgrade({planId, mau});
    } else {
      downgrade({planId, mau});
    }
  }

  return {
    update,
    isUpdating,
  };
};
