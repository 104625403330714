import Axios from 'axios';

// Endpoint
const EP_SSE_GET_GENERATE_API_KEY = '/sse/generate-api-key';
const EP_SSE_DELETE = (sseId) => `/sse/${sseId}`;

// Method
export const generateApiKey = (projectId) => {
  return Axios.get(EP_SSE_GET_GENERATE_API_KEY, {
    params: {projectId},
  }).then((response) => response.data);
};

export const deleteSse = (sseId) =>
  Axios.delete(EP_SSE_DELETE(sseId)).then((response) => response.data);
