export const getVisualCueStyle = (style, dimensions, zoom = 1) => {
  const {position, offsetX = 0, offsetY = 0, scale = 1, rotate = 0} = style;
  const {width = 0, height = 0} = dimensions;

  const adjustedWidth = width / zoom;
  const adjustedHeight = height / zoom;
  const adjustedOffsetX = offsetX / zoom;
  const adjustedOffsetY = offsetY / zoom;

  switch (position) {
    case 'top-left':
      return {
        transform: `scale(${scale}) rotate(${rotate}deg)`,
        top: `calc(-${adjustedHeight / 2}px + ${adjustedOffsetY}px)`,
        left: `calc(-${adjustedWidth / 2}px + ${adjustedOffsetX}px)`,
      };
    case 'top-center':
      return {
        transform: `scale(${scale}) rotate(${rotate}deg) translateX(-50%)`,
        top: `calc(-${adjustedHeight / 2}px + ${adjustedOffsetY}px)`,
        left: `calc(50% + ${adjustedOffsetX}px)`,
      };
    case 'top-right':
      return {
        transform: `scale(${scale}) rotate(${rotate}deg)`,
        top: `calc(-${adjustedHeight / 2}px + ${adjustedOffsetY}px)`,
        right: `calc(-${adjustedWidth / 2}px - ${adjustedOffsetX}px)`,
      };
    case 'center-left':
      return {
        transform: `scale(${scale}) rotate(${rotate}deg) translateY(-50%)`,
        top: `calc(50% + ${adjustedOffsetY}px)`,
        left: `calc(-${adjustedWidth / 2}px + ${adjustedOffsetX}px)`,
      };
    case 'center':
      return {
        transform: `scale(${scale}) rotate(${rotate}deg) translate(-50%, -50%)`,
        top: `calc(50% + ${adjustedOffsetY}px)`,
        left: `calc(50% + ${adjustedOffsetX}px)`,
      };
    case 'center-right':
      return {
        transform: `scale(${scale}) rotate(${rotate}deg) translateY(-50%)`,
        top: `calc(50% + ${adjustedOffsetY}px)`,
        right: `calc(-${adjustedWidth / 2}px - ${adjustedOffsetX}px)`,
      };
    case 'bottom-left':
      return {
        transform: `scale(${scale}) rotate(${rotate}deg)`,
        bottom: `calc(-${adjustedHeight / 2}px + ${adjustedOffsetY}px)`,
        left: `calc(-${adjustedWidth / 2}px + ${adjustedOffsetX}px)`,
      };
    case 'bottom-center':
      return {
        transform: `scale(${scale}) rotate(${rotate}deg) translateX(-50%)`,
        bottom: `calc(-${adjustedHeight / 2}px + ${adjustedOffsetY}px)`,
        left: `calc(50% + ${adjustedOffsetX}px)`,
      };
    case 'bottom-right':
      return {
        transform: `scale(${scale}) rotate(${rotate}deg)`,
        bottom: `calc(-${adjustedHeight / 2}px + ${adjustedOffsetY}px)`,
        right: `calc(-${adjustedWidth / 2}px - ${adjustedOffsetX}px)`,
      };
    default:
      return {
        transform: `scale(${scale}) rotate(${rotate}deg) translate(-50%, -50%)`,
        top: `calc(50% + ${adjustedOffsetY}px)`,
        left: `calc(50% + ${adjustedOffsetX}px)`,
      };
  }
};

export const getVisualCueAnimation = (style, animation) => {
  const {speed = 100, loop = false} = style;

  const speedInSeconds = 1 * (100 / speed);

  return `${animation} ${speedInSeconds}s ${
    loop ? 'infinite' : ''
  } ease-in-out`;
};
