import classNames from 'classnames';
import Divider from 'components/Divider';
import InputGroup from 'components/Input';
import SelectGroup from 'components/Select';
import {BuilderContext} from 'contextes/builder';
import React, {useContext} from 'react';
import Section from 'scenes/PokeBuilder/components/Section';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {BLOCK_TYPE_CHECKLIST_TRIGGER} from 'services/steps';
import {
  CHECKLIST_TRIGGER_PROGRESS_TYPE_CIRCULAR,
  CHECKLIST_TRIGGER_PROGRESS_TYPE_COUNT,
  CHECKLIST_TRIGGER_TYPE_FLOAT,
  CHECKLIST_TRIGGER_TYPE_MINIMIZED,
} from 'shared/front/components/Checklist/constants';
import RadioGroup from '../../../../../../components/RadioGroup';
import ClickableInput from '../../components/items/ClickableInput';
import ColorPickerInput from '../../components/items/ColorPickerInput';
import {positionsArr} from '../../components/modals/Position';
import Width from '../../components/modals/Width';
import PopupSetting from '../../components/PopupSetting';
import ColorItem from '../../components/sectionItems/ColorItem';
import FontFamilyItem from '../../components/sectionItems/FontFamilyItem';
import OffsetItem from '../../components/sectionItems/OffsetItem';
import RadiusItem from '../../components/sectionItems/RadiusItem';
import './_Styles.scss';

const progressOptions = [
  {label: 'Circular', value: CHECKLIST_TRIGGER_PROGRESS_TYPE_CIRCULAR},
  {label: 'Count', value: CHECKLIST_TRIGGER_PROGRESS_TYPE_COUNT},
];

const shapeOptions = [
  {
    label: (
      <>
        <i className="isax isax-sidebar-bottom" />
        Minimized
      </>
    ),
    value: CHECKLIST_TRIGGER_TYPE_MINIMIZED,
  },
  {
    label: (
      <>
        <i className="isax isax-screenmirroring" />
        Float
      </>
    ),
    value: CHECKLIST_TRIGGER_TYPE_FLOAT,
  },
];

const ChecklistTrigger = () => {
  const {
    controlledEvolution: evolution,
    setControlledEvolution: setEvolution,
    updateBlock: uptBlock,
    selectedStep: step,
  } = useContext(BuilderContext);

  const updateBlock = (updateObj) => {
    uptBlock(BLOCK_TYPE_CHECKLIST_TRIGGER, updateObj);
  };

  const block = step?.blocks.find(
    (b) => b.type === BLOCK_TYPE_CHECKLIST_TRIGGER
  );

  if (block == null) {
    return <></>;
  }

  const {value = '', style = {}} = block;

  const selectedAlignment = positionsArr.find(
    (o) => o.value === evolution?.boostedPositionFlags
  );

  return (
    <div className="block-settings checklist-trigger">
      <Section title="General">
        <div className="section-content">
          <SectionItem title="Type" sectionItemClassName="change-icon-type">
            <SelectGroup
              isSearchable={false}
              classNamePrefix="type-icon-select"
              options={shapeOptions}
              value={shapeOptions.find((o) => o.value === style.type)}
              onChange={(option) => {
                updateBlock({
                  style: {
                    ...style,
                    type: option.value,
                  },
                });
              }}
              menuPortalTarget={document.body}
            />
          </SectionItem>
          {style.type === CHECKLIST_TRIGGER_TYPE_FLOAT && (
            <SectionItem title="CTA label">
              <InputGroup
                className="button-content-input"
                value={value}
                onChange={({target}) =>
                  updateBlock({
                    value: target.value,
                  })
                }
              />
            </SectionItem>
          )}
        </div>
      </Section>
      <Divider />
      {style.type === CHECKLIST_TRIGGER_TYPE_FLOAT && (
        <>
          <Section title="Progress">
            <SectionItem title="Type">
              <RadioGroup
                value={style.progressType}
                options={progressOptions}
                onSelect={(value) => {
                  updateBlock({
                    style: {
                      ...style,
                      progressType: value,
                    },
                  });
                }}
              />
            </SectionItem>
          </Section>
          <Divider />
        </>
      )}

      <Section title="Position">
        <SectionItem
          title="Placement"
          contentClassName="snippet-position-picker">
          <div className="alignment-figure-wrapper">
            <div className="alignment-figure">
              <div className="alignment-label">
                {selectedAlignment != null ? (
                  <>Always show from {selectedAlignment.position}</>
                ) : (
                  <>Select a position</>
                )}
              </div>
              {positionsArr.map((o) => {
                if (o.value >= 128 || o.value === 8) {
                  return <></>;
                }
                return (
                  <div
                    className={classNames('position-dot', o.position, {
                      selected: evolution?.boostedPositionFlags === o.value,
                    })}
                    onClick={() =>
                      setEvolution({
                        ...evolution,
                        boostedPositionFlags: o.value,
                      })
                    }
                  />
                );
              })}
            </div>
          </div>
        </SectionItem>
        <OffsetItem
          value={evolution?.style}
          onChange={(value) =>
            setEvolution({
              ...evolution,
              style: {
                ...evolution.style,
                ...value,
              },
            })
          }
        />
        {style.type === CHECKLIST_TRIGGER_TYPE_MINIMIZED && (
          <SectionItem title="Width">
            <PopupSetting
              trigger={
                <ClickableInput
                  value={style.width != null ? `${style.width}px` : 'Default'}
                />
              }
              title="Width"
              content={
                <Width
                  value={style.width}
                  onChange={(value) =>
                    updateBlock({
                      style: {
                        ...style,
                        width: value,
                      },
                    })
                  }
                />
              }
            />
          </SectionItem>
        )}
      </Section>
      <Divider />
      {style.type === CHECKLIST_TRIGGER_TYPE_FLOAT && (
        <>
          <Section title="Style">
            <ColorItem
              title="Background"
              value={style.backgroundColor || null}
              onChange={(value) =>
                updateBlock({
                  style: {
                    ...style,
                    backgroundColor: value,
                  },
                })
              }
            />
            <RadiusItem
              value={style.borderRadius || 0}
              onChange={(value) =>
                updateBlock({
                  style: {
                    ...style,
                    borderRadius: value,
                  },
                })
              }
              max={50}
            />
          </Section>
          <Divider />
          <Section title="Text">
            <SectionItem title="Text color">
              <ColorPickerInput
                inputProps={{small: true}}
                title="Color"
                value={style.fontColor}
                onChange={(value) =>
                  updateBlock({
                    style: {...style, fontColor: value},
                  })
                }
              />
            </SectionItem>
            <FontFamilyItem
              value={style.fontFamily}
              onChange={(value) =>
                updateBlock({
                  style: {...style, fontFamily: value},
                })
              }
            />
          </Section>
        </>
      )}
    </div>
  );
};

export default ChecklistTrigger;
