import classnames from 'classnames';
import Button from 'components/Button';
import {EmptyStateBlock, EmptyStateImgs} from 'components/EmptyStateImgs';
import {default as DefaultLoader, default as Loader} from 'components/Loader';
import {ModalConfirmV2} from 'components/Modal';
import {toastDanger} from 'components/Toaster';
import {PermissionsEvent} from 'constants/permissions';
import dayjs from 'dayjs';
import {errorHelpers} from 'helpers';
import {hasPermissions} from 'helpers/permission';
import {useState} from 'react';
import {useQuery} from 'react-query';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import {ROUTE_EVENTS_WITH_ID} from 'router/routes.const';
import {eventService} from 'services';
import {
  EVENT_SOURCE_MIXPANEL,
  EVENT_SOURCE_POSTHOG,
  EVENT_SOURCE_SEGMENT,
} from 'services/event';
import {Swaler} from 'swaler';
import './_Styles.scss';
import logoMixpanel from './imgs/mixpanel.svg';
import logoPosthog from './imgs/posthog.svg';
import logoSegment from './imgs/segment.svg';

const logger = new Swaler('Events');

const Events = () => {
  const history = useHistory();

  const [isDeleting, setIsDeleting] = useState(null);
  const [deletingEventId, setDeletingEventId] = useState(null);

  const {
    data: events,
    isLoading,
    refetch: refetchEvents,
  } = useQuery({
    queryKey: ['events'],
    queryFn: () =>
      eventService.getEvents({
        withLastEvent: true,
        relations: [],
      }),
  });

  const handleDelete = async (eventId) => {
    setIsDeleting(eventId);
    try {
      await eventService.deleteEvent(eventId);
      refetchEvents();
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Deleting event failed with error ', code);
      toastDanger([title, message], {actions});
    } finally {
      setIsDeleting(null);
    }
  };

  const classNames = classnames('success-tracker-events', {
    'is-loading': isLoading,
    'is-empty': isLoading !== true && events.length === 0,
  });

  if (isLoading) {
    return (
      <div className={classNames}>
        <DefaultLoader />
      </div>
    );
  }

  if (events.length === 0) {
    return (
      <div className={classNames}>
        <div className="empty-state-wrapper">
          <EmptyStateBlock
            img={EmptyStateImgs.EmptyResults}
            title="No events found"
            description="Create an event to track user actions"
          />
          <Button
            primary
            iconLeft={'icon-plus'}
            onClick={() => history.push(ROUTE_EVENTS_WITH_ID('new'))}>
            Create your first event
          </Button>
        </div>
      </div>
    );
  }

  const canDeleteEvent = hasPermissions(PermissionsEvent.DELETE);

  return (
    <>
      <div className={classNames}>
        <div className="events-table">
          <div className="top">
            <div></div>
            <div>Name</div>

            <div>Created at</div>
          </div>
          {isLoading === true && (
            <div className="loader-wrapper">
              <Loader width={24} />
            </div>
          )}
          {isLoading === false && (
            <div className="list">
              {events
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                .map((event) => {
                  return (
                    <div
                      key={event.uid}
                      className="card-event"
                      onClick={() =>
                        history.push(ROUTE_EVENTS_WITH_ID(event.uid))
                      }>
                      <div className="event-icon-wrapper">
                        {event.icon ? (
                          <div className="event-icon">{event.icon}</div>
                        ) : null}
                      </div>
                      <div className="event-name">
                        <span className="name">
                          {event.name || 'Unnamed event'}
                          {[
                            EVENT_SOURCE_MIXPANEL,
                            EVENT_SOURCE_POSTHOG,
                            EVENT_SOURCE_SEGMENT,
                          ].includes(event.source) && (
                            <img
                              alt="source icon"
                              className={classnames('source-icon', {
                                'is-mixpanel':
                                  event.source === EVENT_SOURCE_MIXPANEL,
                                'is-posthog':
                                  event.source === EVENT_SOURCE_POSTHOG,
                                'is-segment':
                                  event.source === EVENT_SOURCE_SEGMENT,
                              })}
                              src={
                                event.source === EVENT_SOURCE_MIXPANEL
                                  ? logoMixpanel
                                  : event.source === EVENT_SOURCE_POSTHOG
                                  ? logoPosthog
                                  : event.source === EVENT_SOURCE_SEGMENT
                                  ? logoSegment
                                  : null
                              }
                            />
                          )}
                        </span>
                        <span className="description">{event.description}</span>
                      </div>
                      <div>{dayjs(event.createdAt).fromNow()}</div>
                      {canDeleteEvent && (
                        <div className="event-actions">
                          <Button
                            iconOnly
                            iconLeft="isax isax-trash"
                            danger
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();

                              setDeletingEventId(event.uid);
                            }}
                            loading={isDeleting === event.uid}
                            disabled={
                              isDeleting != null && isDeleting !== event.uid
                            }
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>
      {
        <ModalConfirmV2
          className="delete-event-modal"
          title="Delete event?"
          isOpen={deletingEventId != null}
          onRequestClose={() => setDeletingEventId(null)}
          onConfirm={() => {
            handleDelete(deletingEventId);
            setDeletingEventId(null);
          }}
          onCancel={() => setDeletingEventId(null)}
          cancelText="Cancel"
          confirmText="Delete"
          cancelBtnProps={{
            cta: false,
          }}
          confirmBtnProps={{
            danger: true,
            primary: false,
            cta: false,
          }}>
          <div className="content">
            Are you sure you want to delete this event?
          </div>
        </ModalConfirmV2>
      }
    </>
  );
};

export default Events;
