import classNames from 'classnames';
import {BuilderContext} from 'contextes/builder';
import {hasFlag} from 'helpers/bitwise';
import {bool} from 'prop-types';
import React, {useContext, useEffect} from 'react';
import {isLogicViewCompatible} from 'scenes/PokeBuilder/utils';
import {EVOLUTION_TYPE_SURVEY, EVOLUTION_TYPE_TOUR} from 'services/evolution';
import {F_STEP_IS_SELECTING_PRESET, STEP_TYPE_CHECKLIST} from 'services/steps';
import BlockManager from '../BlockManager';
import ChecklistBlockManager from '../ChecklistBlockManager';
import ChecklistNavigator from '../ChecklistNavigator';
import ContentManager from '../ContentManager';
import Navigator from '../Navigator';
import PresetSelector from '../PresetSelector';
import ThemeManager from '../ThemeManager';
import TranslationManager from '../TranslationManager';
import TriggerManager from '../TriggerManager';
import './_Styles.scss';

export const MODE_ADD_STEP = 'MODE_ADD_STEP';
export const MODE_ADD_BLOCK = 'MODE_ADD_BLOCK';
export const MODE_NAVIGATOR = 'MODE_NAVIGATOR';
export const MODE_TRANSLATIONS = 'MODE_TRANSLATIONS';
export const MODE_LOGIC = 'MODE_LOGIC';
export const MODE_THEME = 'MODE_THEME';
export const MODE_TRIGGERS = 'MODE_TRIGGERS';
export const MODE_ADD_CONTENT = 'MODE_ADD_CONTENT';

const propTypes = {
  hideContent: bool,
};

const defaultProps = {
  hideContent: false,
};

const PokeBuilderSidebar = ({hideContent}) => {
  const {
    evolution,
    mode,
    setMode,
    selectedStep,
    setSelectedBlockType,
    setSelectedChecklistItemId,
    setSelectedStepId,
    isInApp,
  } = useContext(BuilderContext);

  const isTour = evolution.type === EVOLUTION_TYPE_TOUR;
  const isSurvey = evolution.type === EVOLUTION_TYPE_SURVEY;
  const isChecklistStep = selectedStep?.type === STEP_TYPE_CHECKLIST;

  const isSelectingPreset = hasFlag(
    F_STEP_IS_SELECTING_PRESET,
    selectedStep?.stepFlags
  );

  const triggersCount = selectedStep?.triggers?.length || 0;

  const navigator = isChecklistStep ? <ChecklistNavigator /> : <Navigator />;
  const blockManager = isChecklistStep ? (
    <ChecklistBlockManager onClose={() => setMode(MODE_NAVIGATOR)} />
  ) : (
    <BlockManager onClose={() => setMode(MODE_NAVIGATOR)} />
  );

  const isCompatibleWithLogicView = isLogicViewCompatible(evolution);

  const isInLogicView =
    (isTour || isSurvey) &&
    isCompatibleWithLogicView === true &&
    isInApp !== true;

  useEffect(() => {
    if (isSelectingPreset === true) {
      setMode(MODE_NAVIGATOR);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectingPreset]);

  useEffect(() => {
    if (isInLogicView !== true) {
      setMode(MODE_NAVIGATOR);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInLogicView]);

  const steps =
    isTour === true
      ? evolution?.tourSteps?.map((ts) => ts.steps).flat()
      : isSurvey === true
      ? evolution?.steps
      : [];
  const isEmpty = steps.length === 0;

  return (
    <div
      className={classNames('poke-builder-sidebar-wrapper', {
        'fade-in-left': isTour || isSurvey,
      })}>
      {(isInLogicView !== true || isEmpty !== true) &&
        isSelectingPreset !== true && (
          <div className="poke-builder-sidebar">
            {isInLogicView === true && (
              <div
                className={classNames('sidebar-item', {
                  selected: mode === MODE_ADD_STEP,
                })}
                onClick={() => {
                  if (isChecklistStep) {
                    setSelectedBlockType(null);
                    setSelectedChecklistItemId(null);
                  }
                  setMode(MODE_ADD_STEP);
                  if (isInLogicView === true) {
                    setMode(mode === MODE_ADD_STEP ? null : MODE_ADD_STEP);
                    setSelectedStepId(null);
                    setSelectedBlockType(null);
                  } else {
                    setMode(MODE_ADD_STEP);
                  }
                }}>
                <i
                  className={
                    mode === MODE_ADD_STEP
                      ? 'isax isax-add-square5'
                      : 'isax isax-add-square'
                  }
                />
              </div>
            )}
            <div
              className={classNames('sidebar-item', {
                selected:
                  [MODE_NAVIGATOR, MODE_ADD_BLOCK].includes(mode) === true,
              })}
              onClick={() => {
                setMode(
                  isInLogicView === true && mode === MODE_NAVIGATOR
                    ? null
                    : MODE_NAVIGATOR
                );
                setSelectedBlockType(null);
              }}>
              <i
                className={
                  [MODE_NAVIGATOR, MODE_ADD_BLOCK].includes(mode) === true
                    ? 'isax isax-layer5'
                    : 'isax isax-layer'
                }
              />
            </div>
            {(isTour || isSurvey) && (
              <div
                className={classNames('sidebar-item', {
                  selected: mode === MODE_TRIGGERS,
                })}
                onClick={() => {
                  setMode(
                    isInLogicView === true && mode === MODE_TRIGGERS
                      ? null
                      : MODE_TRIGGERS
                  );
                  setSelectedBlockType(null);
                }}>
                <i
                  className={
                    mode === MODE_TRIGGERS
                      ? 'isax isax-hierarchy-35'
                      : 'isax isax-hierarchy-3'
                  }
                />
                {triggersCount > 0 && (
                  <div className="triggers-count body-4">{triggersCount}</div>
                )}
              </div>
            )}
            <div
              className={classNames('sidebar-item', {
                selected: mode === MODE_THEME,
              })}
              onClick={() => {
                setMode(
                  isInLogicView === true && mode === MODE_THEME
                    ? null
                    : MODE_THEME
                );
                setSelectedBlockType(null);
              }}>
              <i
                className={
                  mode === MODE_THEME
                    ? 'isax isax-brush-25'
                    : 'isax isax-brush-4'
                }
              />
            </div>
            <div
              className={classNames('sidebar-item', {
                selected: mode === MODE_TRANSLATIONS,
              })}
              onClick={() => {
                setMode(
                  isInLogicView === true && mode === MODE_TRANSLATIONS
                    ? null
                    : MODE_TRANSLATIONS
                );
                setSelectedBlockType(null);
              }}>
              <i
                className={
                  mode === MODE_TRANSLATIONS
                    ? 'isax isax-language-square5'
                    : 'isax isax-language-square'
                }
              />
            </div>
          </div>
        )}

      {hideContent !== true && mode != null && (
        <div className="poke-builder-sidebar-content-wrapper">
          {isSelectingPreset === true ? (
            <PresetSelector />
          ) : (
            <>
              {mode === MODE_NAVIGATOR && navigator}
              {mode === MODE_ADD_BLOCK && blockManager}
              {mode === MODE_ADD_STEP && (
                <ContentManager
                  onClose={() => {
                    setMode(MODE_NAVIGATOR);
                    setSelectedBlockType(null);
                  }}
                />
              )}
              {mode === MODE_TRANSLATIONS && (
                <TranslationManager
                  onClose={() => {
                    setMode(MODE_NAVIGATOR);
                    setSelectedBlockType(null);
                  }}
                />
              )}
              {mode === MODE_THEME && (
                <ThemeManager
                  onClose={() => {
                    setMode(MODE_NAVIGATOR);
                    setSelectedBlockType(null);
                  }}
                />
              )}
              {mode === MODE_TRIGGERS && (
                <TriggerManager
                  onClose={() => {
                    setMode(MODE_NAVIGATOR);
                    setSelectedBlockType(null);
                  }}
                />
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

PokeBuilderSidebar.propTypes = propTypes;
PokeBuilderSidebar.defaultProps = defaultProps;

export default PokeBuilderSidebar;
