import React, {useState} from 'react';

import Divider from 'components/Divider';
import DefaultLoader from 'components/Loader';
import {useQuery} from 'react-query';
import {useRouteMatch} from 'react-router-dom';
import {AudienceContext} from 'scenes/PokeAudience';
import AudienceHeader from 'scenes/PokeAudience/components/AudienceHeader';
import Boost from 'scenes/PokeAudience/sections/Boost';
import PostSummary from 'scenes/PokeAudience/sections/PostSummary';
import When from 'scenes/PokeAudience/sections/When';
import Who from 'scenes/PokeAudience/sections/Who';
import {evolutionService} from 'services';
import {Swaler} from 'swaler';
import './_styles.scss';

export const SECTION_WHO = 'WHO';
export const SECTION_WHEN = 'WHEN';
export const SECTION_SUMMARY = 'SUMMARY';
export const SECTION_BOOST = 'BOOST';

const logger = new Swaler('PostAudience');

const PostAudience = () => {
  const [evolution, setEvolution] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [jimersCount, setJimersCount] = useState(null);

  const match = useRouteMatch();
  const evolutionId = match?.params?.evolutionId;

  const {isLoading, refetch} = useQuery({
    queryKey: ['evolution', 'audience', evolutionId],
    queryFn: async () => {
      const evolution = await evolutionService.getEvolutionById(evolutionId, {
        relations: [
          'texts',
          'tags',
          'segments',
          'steps',
          'boostedPaths',
          'event',
          'tourSteps',
          'theme',
          'section',
        ],
      });

      return setEvolution(evolution);
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });

  const save = async () => {
    try {
      await evolutionService.updateEvolution(evolution.uid, {
        ...evolution,
      });
    } catch (err) {
      logger.error('Publishing failed with error ', err);
    }
  };

  return (
    <AudienceContext.Provider
      value={{
        evolution,
        setEvolution,
        refetchEvolution: refetch,
        selectedSection,
        setSelectedSection,
        jimersCount,
        setJimersCount,
        save,
        sections: [
          {
            section: SECTION_WHO,
            title: 'Who',
          },
          {
            section: SECTION_WHEN,
            title: 'When',
          },
        ],
      }}>
      <div className="post-audience-wrapper">
        {isLoading === true ? (
          <div className="loader-wrapper">
            <DefaultLoader />
          </div>
        ) : evolution == null ? (
          <div>This evolution does not exist</div>
        ) : (
          <>
            <AudienceHeader isPost />
            <Divider />
            <div className="post-audience">
              <PostSummary />
              <Divider />
              <div className="post-audience-customize subtitle-2 n-800">
                Customize
              </div>
              <Who />
              <When isPost />
              <Boost />
            </div>
          </>
        )}
      </div>
    </AudienceContext.Provider>
  );
};

export default PostAudience;
