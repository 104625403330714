import {miscActions} from 'actions';
import classNames from 'classnames';
import Button from 'components/Button';
import {hasFlag} from 'helpers/bitwise';
import {sendSetChildTab} from 'helpers/utils';
import useUndercityBuilderMessenger from 'managers/useDalaranMessenger';
import {useEffect, useState} from 'react';
import Draggable from 'react-draggable';
import {Helmet} from 'react-helmet';
import {useDispatch} from 'react-redux';
import {F_BOOST_SLOT_HINT} from 'services/evolution';
import './_Styles.scss';
import DefaultElementEditor from './components/DefaultElementEditor';
import HintElementEditor from './components/HintElementEditor';
import PinnedElementEditor from './components/PinnedElementEditor';

const EmbeddedElementSelectorBuilder = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    querySelector: '',
    image: null,
    querySelectorManual: null,
    evolution: null,
  });
  const [count, setCount] = useState(0);

  const {querySelectorManual} = state;

  const setHideMobileOverlay = (isHidden) =>
    dispatch(miscActions.setHideMobileOverlay(isHidden));

  const handleSelectedTargetElement = async (data) => {
    setState({
      ...state,
      querySelector: data.targetElement.querySelector,
      image: data.targetElement.image,
      querySelectorManual: null,
      evolution: data.targetElement.evolution,
    });
  };

  const handleFoundElementsCount = (data) => {
    setCount(data.count);
  };

  const messenger = useUndercityBuilderMessenger({
    onSelectedTargetElement: handleSelectedTargetElement,
    onFoundElementsCount: handleFoundElementsCount,
  });

  const handleSelectElementClick = () => {
    messenger.sendSelectTargetElement();
  };

  const handleCancel = () => {
    messenger.sendSelectTargetElement();
  };

  const handleConfirm = () => {
    messenger.sendConfirmElementSelection(state);
  };

  useEffect(() => {
    setHideMobileOverlay(true);
  }, []);

  useEffect(() => {
    handleSelectElementClick();
  }, []);

  useEffect(() => {
    sendSetChildTab();
  }, []);

  const {evolution} = state;

  useEffect(() => {
    messenger.sendEvolutionUpdate(evolution);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evolution]);

  useEffect(() => {
    messenger.sendQuerySelectorManualUpdate(querySelectorManual);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [querySelectorManual]);

  // Hide Crisp
  useEffect(() => {
    if (window.$crisp != null) {
      window.$crisp.push(['do', 'chat:hide']);
    }
  });

  const isHint = hasFlag(F_BOOST_SLOT_HINT, evolution?.boostFlags);

  return (
    <Draggable handle=".handle">
      <div className={classNames('lightweight-element-selector-builder', {})}>
        <Helmet>
          <body className={classNames('transparent-mode')} />
        </Helmet>
        <div className="element-selector-builder">
          <div className="handle">
            <div className="icon-drag">
              <i className="icon-move" />
            </div>
          </div>
          {evolution != null ? (
            <>
              {isHint ? (
                <HintElementEditor
                  evolution={evolution}
                  setEvolution={(evolution) => setState({...state, evolution})}
                  messenger={messenger}
                  image={state.image}
                />
              ) : (
                <PinnedElementEditor
                  evolution={evolution}
                  setEvolution={(evolution) => setState({...state, evolution})}
                  messenger={messenger}
                  image={state.image}
                />
              )}
            </>
          ) : (
            <DefaultElementEditor
              count={count}
              state={state}
              setState={setState}
            />
          )}
          <div className="footer">
            <Button thin className="cancel-btn" onClick={handleCancel}>
              Cancel
            </Button>
            <Button thin className="save-btn" primary onClick={handleConfirm}>
              Confirm selection
            </Button>
          </div>
        </div>
      </div>
    </Draggable>
  );
};

export default EmbeddedElementSelectorBuilder;
