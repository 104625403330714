import {generalActions} from 'actions';
import classnames from 'classnames';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import {ModalConfirm} from 'components/Modal';
import {toastDanger, toastSuccess} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {useUpdateSubscription} from 'hooks/useUpdateSubscription';
import {useRef, useState} from 'react';
import {useQuery} from 'react-query';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {ROUTE_SETTINGS_THEMES_ID} from 'router/routes.const';
import ModalCreateTheme from 'scenes/PokeBuilder/components/ThemeManager/components/ModalCreateTheme';
import {
  generateThemeFromPalette,
  getColorsFromThemeStyle,
  getFontsFromThemeStyle,
} from 'scenes/PokeBuilder/components/ThemeManager/utils';
import {SettingsBody} from 'scenes/Settings/components/Body';
import {generalSelector} from 'selectors';
import {themeService} from 'services';
import {
  PLAN_BASIC_ID,
  PLAN_GROWTH_ID,
  PLAN_STARTUP_ID,
} from 'services/subscription';
import {Swaler} from 'swaler';
import './_Styles.scss';

const logger = new Swaler('Themes');

const Themes = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {update} = useUpdateSubscription();

  const project = useSelector((state) => generalSelector.getProject(state));
  const subscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );

  const updateProject = (project) => {
    dispatch(generalActions.uptProject(project));
  };

  const refDropdown = useRef(null);

  const [selectedTheme, setSelectedTheme] = useState(null);
  const [themeIdToDelete, setThemeIdToDelete] = useState(null);
  const [isSmartTheme, setIsSmartTheme] = useState(false);
  const [showModalCreateTheme, setShowModalCreateTheme] = useState(false);

  const {data: themes = [], refetch} = useQuery({
    queryKey: 'themes',
    queryFn: themeService.getThemes,
  });

  const handleSetDefaultTheme = async (theme) => {
    try {
      await themeService.setDefaultTheme(theme.uid);
      updateProject({
        ...project,
        defaultTheme: theme,
      });
      toastSuccess(
        ['Theme updated', `Theme ${theme.name} is now your theme by default!`],
        {
          toastId: 'theme-default-changed',
        }
      );
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(`Error setting default theme: ${code}`);
      toastDanger([title, message], {actions});
    }
  };

  const handleDuplicate = async (theme) => {
    try {
      await themeService.duplicateTheme(theme.uid);
      await refetch();
      toastSuccess(['Theme duplicated.'], {toastId: 'theme-duplicated'});
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(`Error duplicating theme: ${code}`);
      toastDanger([title, message], {actions});
    }
  };

  const handleDeleteTheme = async (themeId) => {
    try {
      await themeService.deleteTheme(themeId);
      const themes = await refetch().then((response) => response.data);

      if (themeId === project.defaultTheme?.uid && themes.length > 0) {
        await themeService.setDefaultTheme(themes[0].uid);
        updateProject({
          ...project,
          defaultTheme: themes[0],
        });
      }
      setThemeIdToDelete(null);
      toastSuccess(['Theme deleted.'], {toastId: 'theme-deleted'});
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(`Error deleting theme: ${code}`);
      toastDanger([title, message], {actions});
    }
  };

  return (
    <SettingsBody className="s-settings-themes">
      <div className="settings-header">
        <div className="title-3">Themes</div>
        <div className="header-actions">
          <Button
            className="btn-smart-theme"
            iconLeft="icon-ai"
            onClick={() => {
              if (
                themes.length >= 1 &&
                [PLAN_BASIC_ID, PLAN_STARTUP_ID].includes(subscription.plan)
              ) {
                return update({
                  planId: PLAN_GROWTH_ID,
                  title: 'Unlimited Themes',
                  description: 'Create and use as many themes as you want.',
                });
              }
              setIsSmartTheme(true);
              setShowModalCreateTheme(true);
            }}>
            Smart Theme
          </Button>
          <Button
            primary
            iconLeft="icon-plus"
            onClick={() => {
              if (
                themes.length >= 1 &&
                [PLAN_BASIC_ID, PLAN_STARTUP_ID].includes(subscription.plan)
              ) {
                return update({
                  planId: PLAN_GROWTH_ID,
                  title: 'Unlimited Themes',
                  description: 'Create and use as many themes as you want.',
                });
              }
              setIsSmartTheme(false);
              setShowModalCreateTheme(true);
            }}>
            New Theme
          </Button>
        </div>
      </div>
      <div className="settings-themes-list">
        {themes
          .sort(
            (a, b) =>
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          )
          .filter(
            (theme) =>
              theme.style?.stepStyle != null && theme.style?.blocksStyle != null
          )
          .map((theme) => {
            const {name, style} = theme;
            const colors = getColorsFromThemeStyle(style);
            const isDefault = theme.uid === project.defaultTheme?.uid;

            return (
              <div
                className={classnames('item-theme', {
                  selected: theme.uid === selectedTheme?.uid,
                  default: isDefault,
                })}
                onClick={() => setSelectedTheme(theme)}>
                <div className="theme-colors">
                  {colors.map((color) => (
                    <div className="theme-color" style={{background: color}} />
                  ))}
                </div>
                <div className="theme-content">
                  <div className="theme-infos-wrapper">
                    <div className="theme-name subtitle-3">
                      {name}
                      {isDefault && (
                        <span className="label-default body-4">Default</span>
                      )}
                    </div>
                    <div className="theme-font">
                      {getFontsFromThemeStyle(style)}
                    </div>
                  </div>
                  <div className="theme-actions">
                    <Dropdown
                      className="dropdown-theme-actions"
                      innerRef={refDropdown}
                      position="bottom right"
                      offsetY={4}
                      trigger={
                        <Button className="btn-theme-options" iconOnly>
                          <i className="icon-menu-vertical"></i>
                        </Button>
                      }>
                      <>
                        <div className="dp-menu">
                          <div
                            className="dp-menu-item body-3"
                            thin
                            primary
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();

                              history.push(ROUTE_SETTINGS_THEMES_ID(theme.uid));
                            }}>
                            <i className="isax isax-edit-2"></i> Edit
                          </div>
                          <div
                            className="dp-menu-item body-3"
                            thin
                            primary
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();

                              handleDuplicate(theme);
                            }}>
                            <i className="icon-duplicate"></i> Duplicate
                          </div>
                          <div
                            className="dp-menu-item body-3"
                            thin
                            primary
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();

                              refDropdown.current.close();
                              handleSetDefaultTheme(theme);
                            }}>
                            <i className="isax isax-heart"></i>Make default
                          </div>
                          <div
                            className="dp-menu-item body-3 r-400 danger"
                            thin
                            primary
                            iconLeft="icon-trash"
                            iconOnly
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();

                              setThemeIdToDelete(theme.uid);
                            }}>
                            <i className="isax isax-trash"></i>Delete
                          </div>
                        </div>
                      </>
                    </Dropdown>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <ModalConfirm
        className="delete-theme-modal"
        title="Delete theme?"
        isOpen={themeIdToDelete != null}
        onConfirm={() => handleDeleteTheme(themeIdToDelete)}
        onCancel={() => setThemeIdToDelete(null)}
        cancelText="Cancel"
        confirmText="Delete"
        cancelBtnProps={{
          cta: false,
        }}
        confirmBtnProps={{
          danger: true,
          primary: false,
          cta: false,
        }}>
        <div className="content">
          Are you sure you want to delete this theme?
        </div>
      </ModalConfirm>
      <ModalCreateTheme
        isOpen={showModalCreateTheme}
        style={
          isSmartTheme === true
            ? null
            : generateThemeFromPalette({
                palette: ['#000000', '#ffffff', '#1260eb'],
                borderRadius: 8,
                fontFamilies: ['Arial'],
              })
        }
        onRequestClose={() => {
          setIsSmartTheme(false);
          setShowModalCreateTheme(false);
        }}
        onThemeCreated={async (theme) => {
          history.push(ROUTE_SETTINGS_THEMES_ID(theme.uid));
        }}
      />
    </SettingsBody>
  );
};

export default Themes;
