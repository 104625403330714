import classNames from 'classnames';
import Divider from 'components/Divider';
import RadioGroup from 'components/RadioGroup';
import {BuilderContext} from 'contextes/builder';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import {useFileUpload} from 'hooks/useFileUpload';
import React, {useContext, useEffect, useRef, useState} from 'react';
import Section from 'scenes/PokeBuilder/components/Section';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {InAppBuilderContext} from 'scenes/Pushes/context';
import {ElementSelector} from 'scenes/SuccessTracker/components/Event/components/EventConditions/components/EditDropdown/components/ElementSelector';
import {
  F_BOOST_SLOT_CURSOR,
  F_BOOST_SLOT_DOT,
  F_BOOST_SLOT_TOOLTIP,
  F_OPTION_POKE_CARD_WITH_POINTER,
  F_OPTION_PROGRESS_ON_TARGET_CLICK,
} from 'services/evolution';
import {Swaler} from 'swaler';
import ClickableInput from '../../components/items/ClickableInput';
import PixelPicker from '../../components/items/PixelPicker';
import ZIndex from '../../components/modals/ZIndex';
import PopupSetting from '../../components/PopupSetting';
import ColorItem from '../../components/sectionItems/ColorItem';
import EffectsSection from '../../components/sectionItems/EffectsSection';
import './_Styles.scss';

const propTypes = {};

const positionOptions = [
  {label: 'Auto', value: 'auto'},
  {label: 'Fixed', value: 'fixed'},
  {label: 'Custom', value: 'custom'},
];

const progressOnClickOptions = [
  {label: 'On', value: true},
  {label: 'Off', value: false},
];

export const positionsArr = [
  {position: 'top-left', value: 1},
  {position: 'top', value: 2},
  {position: 'top-right', value: 4},
  {position: 'right', value: 8},
  {position: 'bottom-right', value: 16},
  {position: 'bottom', value: 32},
  {position: 'bottom-left', value: 64},
  {position: 'left', value: 128},
  {position: 'right-top', value: 512},
  {position: 'right-bottom', value: 1024},
  {position: 'left-bottom', value: 2048},
  {position: 'left-top', value: 4096},
];

const pointerOptions = [
  {label: 'Yes', value: true},
  {label: 'No', value: false},
];

const logger = new Swaler('PinnedElement');

const PinnedElement = () => {
  const {upload} = useFileUpload({logger});

  const {
    evolution: parentEvolution,
    setEvolution: setParentEvolution,
    controlledEvolution: evolution,
    setControlledEvolution: setEvolution,
    isInApp,
    messenger,
    selectedStepId,
  } = useContext(BuilderContext);

  const {goToEditInApp, stopInAppEditing} = useContext(InAppBuilderContext);

  const [isUploading, setIsUploading] = useState(false);

  const parentEvolutionRef = useRef();
  const evolutionRef = useRef();

  useEffect(() => {
    parentEvolutionRef.current = parentEvolution;
  }, [parentEvolution]);

  useEffect(() => {
    evolutionRef.current = evolution;
  }, [evolution]);

  const style = evolution?.style ?? {};

  const isHotspot = hasFlag(F_BOOST_SLOT_DOT, evolution.boostFlags);
  const isTooltip = hasFlag(F_BOOST_SLOT_TOOLTIP, evolution.boostFlags);
  const isCursor = hasFlag(F_BOOST_SLOT_CURSOR, evolution.boostFlags);

  const isAuto = evolution?.boostedPositionFlags === 0;
  const isCustom = evolution?.boostedPositionFlags === 256;
  const isFixed = isAuto !== true && isCustom !== true;

  const [offsetX, offsetY, offsetCardX = 0, offsetCardY = 0] = (
    evolution?.boostedPositionOffsets || '0;0;0;0'
  ).split(';');

  const selectedAlignment = positionsArr.find(
    (o) => o.value === evolution?.boostedPositionFlags
  );

  const hasPointer = hasFlag(
    F_OPTION_POKE_CARD_WITH_POINTER,
    evolution.optionsFlags
  );

  const handleStyleChange = (changes) => {
    if (evolution.uid === parentEvolution.uid) {
      setParentEvolution({
        ...parentEvolution,
        style: {...style, ...changes},
      });
    } else {
      setParentEvolution({
        ...parentEvolution,
        tourSteps: parentEvolution.tourSteps.map((tourStep) => {
          if (tourStep.uid === evolution.uid) {
            return {
              ...tourStep,
              style: {...tourStep.style, ...changes},
            };
          }
          return tourStep;
        }),
      });
    }
  };

  const uploadFile = async (file) => {
    if (file == null) {
      return;
    }
    const response = await fetch(file);
    const blob = await response.blob();
    const uploadableFile = new File([blob], 'upload.png', {type: 'image/png'});
    const uploadedFile = await upload({file: uploadableFile});

    return uploadedFile;
  };

  const handleElementSelected = async (data) => {
    // set loading state
    setParentEvolution({
      ...parentEvolutionRef.current,
      tourSteps: parentEvolutionRef.current.tourSteps.map((tourStep) => {
        if (tourStep.uid === evolutionRef.current?.uid) {
          return {
            ...tourStep,
            ...data.evolution,
            isUploading: true,
          };
        }
        return tourStep;
      }),
    });
    setIsUploading(true);
    const file = await uploadFile(data.image);
    setIsUploading(false);

    setParentEvolution({
      ...parentEvolutionRef.current,
      tourSteps: parentEvolutionRef.current.tourSteps.map((tourStep) => {
        if (tourStep.uid === evolutionRef.current?.uid) {
          return {
            ...tourStep,
            ...data.evolution,
            isUploading: false,
            ...(file != null
              ? {
                  file,
                }
              : {
                  file: null,
                }),
          };
        }
        return tourStep;
      }),
    });
  };

  const hasProgressOnClick = hasFlag(
    F_OPTION_PROGRESS_ON_TARGET_CLICK,
    evolution.optionsFlags
  );

  return (
    <div className="block-settings pinned-element">
      {(isTooltip || isCursor) && (
        <>
          <Section title="Behavior">
            <SectionItem title="Progress on click">
              <RadioGroup
                value={hasProgressOnClick}
                options={progressOnClickOptions}
                onSelect={(v) => {
                  setEvolution({
                    ...evolution,
                    optionsFlags: v
                      ? addFlag(
                          F_OPTION_PROGRESS_ON_TARGET_CLICK,
                          evolution.optionsFlags
                        )
                      : removeFlag(
                          F_OPTION_PROGRESS_ON_TARGET_CLICK,
                          evolution.optionsFlags
                        ),
                  });
                }}
              />
            </SectionItem>
          </Section>
          <Divider />
        </>
      )}
      <Section title="Target element">
        <div className="section-item target-picker">
          <ElementSelector
            data={{
              querySelector: evolution?.boostedQueryselector,
              file: evolution?.file,
              querySelectorManual: evolution?.querySelectorManual,
            }}
            onChange={(data) => {
              setEvolution({
                ...evolution,
                boostedQueryselector: data.querySelector,
                file: data.file,
                querySelectorManual: data.querySelectorManual,
              });
            }}
            withElementsCount={isInApp}
            onSelectElement={
              isInApp
                ? messenger.sendSelectTargetElement
                : () =>
                    goToEditInApp({
                      data: {
                        title: 'Select target element',
                      },
                      handshakeData: {
                        type: 'SET_MODE',
                        data: {
                          mode: 'ELEMENT_SELECTOR_BUILDER',
                          onlyInput: false,
                          evolution: parentEvolution,
                          stepId: selectedStepId,
                        },
                      },
                      onChildCommunication: (message) => {
                        if (message?.type === 'TARGET_ELEMENT_SELECTED') {
                          handleElementSelected(message.data);
                          stopInAppEditing();
                        }
                        if (message?.type === 'EMBEDDED_BUILDER_CLOSE') {
                          stopInAppEditing();
                        }
                      },
                    })
            }
            isEditingStep
          />
        </div>
        <Divider />
        {(isTooltip || isHotspot) && (
          <>
            <SectionItem>
              <RadioGroup
                value={isAuto ? 'auto' : isFixed ? 'fixed' : 'custom'}
                options={positionOptions.filter((o) => {
                  if (isHotspot === true) {
                    return o.value !== 'auto';
                  }
                  return true;
                })}
                onSelect={(v) => {
                  if (v === 'auto') {
                    setEvolution({...evolution, boostedPositionFlags: 0});
                  } else if (v === 'fixed') {
                    setEvolution({...evolution, boostedPositionFlags: 1});
                  } else {
                    setEvolution({
                      ...evolution,
                      boostedPositionFlags: 256,
                      ...(evolution.boostedPositionFlags !== 256
                        ? {boostedPositionOffsets: '0;0;0;110'}
                        : {}),
                    });
                  }
                }}
              />
            </SectionItem>
            {isCustom && (
              <>
                {isHotspot ? (
                  <>
                    <SectionItem title="Hotspot offset X">
                      <PixelPicker
                        value={offsetX}
                        min={-400}
                        max={400}
                        onChange={(value) => {
                          if (isInApp) {
                            messenger.sendForceHotspotView(evolution.uid, true);
                          }
                          setEvolution({
                            ...evolution,
                            boostedPositionOffsets: `${value};${offsetY};${offsetCardX};${offsetCardY}`,
                          });
                        }}
                      />
                    </SectionItem>
                    <SectionItem title="Hotspot offset Y">
                      <PixelPicker
                        value={offsetY}
                        min={-400}
                        max={400}
                        onChange={(value) => {
                          if (isInApp) {
                            messenger.sendForceHotspotView(evolution.uid, true);
                          }
                          setEvolution({
                            ...evolution,
                            boostedPositionOffsets: `${offsetX};${value};${offsetCardX};${offsetCardY}`,
                          });
                        }}
                      />
                    </SectionItem>
                    <SectionItem title="Card offset X">
                      <PixelPicker
                        value={offsetCardX}
                        min={-400}
                        max={400}
                        onChange={(value) => {
                          if (isInApp) {
                            messenger.sendForceHotspotView(
                              evolution.uid,
                              false
                            );
                          }
                          setEvolution({
                            ...evolution,
                            boostedPositionOffsets: `${offsetX};${offsetY};${value};${offsetCardY}`,
                          });
                        }}
                      />
                    </SectionItem>
                    <SectionItem title="Card offset Y">
                      <PixelPicker
                        value={offsetCardY}
                        min={-400}
                        max={400}
                        onChange={(value) => {
                          if (isInApp) {
                            messenger.sendForceHotspotView(
                              evolution.uid,
                              false
                            );
                          }
                          setEvolution({
                            ...evolution,
                            boostedPositionOffsets: `${offsetX};${offsetY};${offsetCardX};${value}`,
                          });
                        }}
                      />
                    </SectionItem>
                  </>
                ) : (
                  <>
                    <SectionItem title="Offset X">
                      <PixelPicker
                        value={offsetX}
                        min={-400}
                        max={400}
                        onChange={(value) =>
                          setEvolution({
                            ...evolution,
                            boostedPositionOffsets: `${value};${offsetY}`,
                          })
                        }
                      />
                    </SectionItem>
                    <SectionItem title="Offset Y">
                      <PixelPicker
                        value={offsetY}
                        min={-400}
                        max={400}
                        onChange={(value) =>
                          setEvolution({
                            ...evolution,
                            boostedPositionOffsets: `${offsetX};${value}`,
                          })
                        }
                      />
                    </SectionItem>
                  </>
                )}
              </>
            )}
            {isFixed && (
              <div className="alignment-figure-wrapper">
                <div className="alignment-figure">
                  <div className="alignment-label">
                    {selectedAlignment != null ? (
                      <>Always show from {selectedAlignment.position}</>
                    ) : (
                      <>Select a position</>
                    )}
                  </div>
                  {positionsArr.map((o) => {
                    if (isHotspot && o.value > 128) {
                      return <></>;
                    }
                    return (
                      <div
                        className={classNames('position-dot', o.position, {
                          selected: evolution?.boostedPositionFlags === o.value,
                        })}
                        onClick={() =>
                          setEvolution({
                            ...evolution,
                            boostedPositionFlags: o.value,
                          })
                        }
                      />
                    );
                  })}
                </div>
              </div>
            )}
            {isTooltip && (
              <>
                <ColorItem
                  title="Target border"
                  value={style.targetBorderColor}
                  onChange={(value) => {
                    handleStyleChange({targetBorderColor: value});
                  }}
                  colorPickerProps={{erasable: true}}
                />
                <SectionItem title="Pointer">
                  <RadioGroup
                    value={hasPointer}
                    options={pointerOptions}
                    onSelect={(v) => {
                      if (v === true) {
                        setEvolution({
                          ...evolution,
                          optionsFlags: addFlag(
                            F_OPTION_POKE_CARD_WITH_POINTER,
                            evolution.optionsFlags
                          ),
                        });
                      } else {
                        setEvolution({
                          ...evolution,
                          optionsFlags: removeFlag(
                            F_OPTION_POKE_CARD_WITH_POINTER,
                            evolution.optionsFlags
                          ),
                        });
                      }
                    }}
                  />
                </SectionItem>
                {hasPointer === true && (
                  <ColorItem
                    title="Pointer color"
                    value={style.pointerColor || style.background?.primaryColor}
                    onChange={(value) =>
                      handleStyleChange({
                        pointerColor: value,
                      })
                    }
                    colorPickerProps={{erasable: style.pointerColor != null}}
                  />
                )}
              </>
            )}
          </>
        )}
        <SectionItem title="Z-index" sectionItemClassName="z-index">
          <PopupSetting
            trigger={
              <ClickableInput
                className="position-z-index"
                value={
                  evolution.boostedZIndex != null
                    ? `${evolution.boostedZIndex}`
                    : 'Auto'
                }
                leftLabel={
                  <div className="icon-wrapper">
                    <i className="icon-duplicate" />
                  </div>
                }
              />
            }
            title="Z-index"
            content={
              <ZIndex
                value={evolution.boostedZIndex}
                onChange={(value) =>
                  setEvolution({
                    ...evolution,
                    boostedZIndex: value,
                  })
                }
              />
            }
          />
        </SectionItem>
      </Section>
      <Divider />
      <EffectsSection
        style={style}
        onChange={(changes) => handleStyleChange(changes)}
      />
    </div>
  );
};

PinnedElement.propTypes = propTypes;

export default PinnedElement;
