import React, {useEffect, useState} from 'react';
import './_Styles.scss';

const DynamicSvg = ({
  url,
  primaryColor = '#FFC319',
  secondaryColor = '#1260EB',
  borderColor = 'white',
}) => {
  const [svgContent, setSvgContent] = useState(null);

  useEffect(() => {
    // Fetch the SVG content
    fetch(url)
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        setSvgContent(data);
      })
      .catch((error) => {
        console.error('Error loading SVG:', error);
      });
  }, [url]);

  if (!svgContent) {
    return null;
  }

  // Customize colors using a wrapper function
  const customizeSvgColors = (svg) =>
    svg
      .replace(/fill={primaryColor}/g, `fill="${primaryColor}"`)
      .replace(/fill={secondaryColor}/g, `fill="${secondaryColor}"`)
      .replace(/fill={borderColor}/g, `fill="${borderColor}"`)
      .replace(/stroke={primaryColor}/g, `stroke="${primaryColor}"`)
      .replace(/stroke={secondaryColor}/g, `stroke="${secondaryColor}"`)
      .replace(/stroke={borderColor}/g, `stroke="${borderColor}"`);

  return (
    <div
      className="dynamic-svg"
      dangerouslySetInnerHTML={{__html: customizeSvgColors(svgContent)}}
    />
  );
};

export default DynamicSvg;
