import InputGroup from 'components/Input';
import React from 'react';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import './_Styles.scss';

const MIN_MARGIN = 0;
const MAX_MARGIN = 1000;

const MarginItem = ({value, onChange}) => {
  const {marginTop, marginBottom, marginLeft, marginRight} = value;

  return (
    <SectionItem title="Margin" sectionItemClassName="margin-item">
      <InputGroup
        small
        value={marginLeft}
        type="number"
        labelTextLeft={
          <div className="icon-wrapper">
            <i className="isax isax-slider-horizontal" />
          </div>
        }
        min={MIN_MARGIN}
        max={MAX_MARGIN}
        onChange={(e) =>
          onChange({
            marginTop,
            marginBottom,
            marginLeft: parseInt(e.target.value, 10),
            marginRight: parseInt(e.target.value, 10),
          })
        }
      />
      <InputGroup
        small
        value={marginTop}
        type="number"
        labelTextLeft={
          <div className="icon-wrapper">
            <i className="isax isax-slider-vertical" />
          </div>
        }
        min={MIN_MARGIN}
        max={MAX_MARGIN}
        onChange={(e) =>
          onChange({
            marginTop: parseInt(e.target.value, 10),
            marginBottom: parseInt(e.target.value, 10),
            marginLeft,
            marginRight,
          })
        }
      />
    </SectionItem>
  );
};

export default MarginItem;
