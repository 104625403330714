import React, {useEffect, useRef, useState} from 'react';
import {useQuery} from 'react-query';
import {hubspotService, segmentService} from 'services';
import {
  HUBSPOT_OBJECT_TYPE_COMPANY,
  HUBSPOT_OBJECT_TYPE_CONTACT,
  HUBSPOT_OBJECT_TYPE_DEAL,
} from 'services/hubspot';
import {CUSTOM_ATTRIBUTE_SOURCE_HUBSPOT} from 'services/segment';
import {v4 as uuidv4} from 'uuid';

import classNames from 'classnames';
import Button from 'components/Button';
import SelectGroup from 'components/Select';
import {components} from 'react-select';
import {Swaler} from 'swaler';
import Hubspot from '../../imgs/hubspot.svg';
import JimoIcon from '../../imgs/jimo.svg';
import './_Styles.scss';

const MATCHING_OPTION_EMAIL = 'EMAIL';
const MATCHING_OPTION_USER_ID = 'USER_ID';

const SYNCED_FIELD_SIDE_HUBSPOT_TO_JIMO = 'HUBSPOT_TO_JIMO';
const SYNCED_FIELD_SIDE_JIMO_TO_HUBSPOT = 'JIMO_TO_HUBSPOT';

export const HubspotIcon = () => {
  return (
    <img
      className="hubspot-icon"
      src={Hubspot}
      width={26}
      height={26}
      alt="hubspot-icon"
    />
  );
};

const matchingOptions = [
  {
    value: MATCHING_OPTION_EMAIL,
    label: 'Email',
  },
  {
    value: MATCHING_OPTION_USER_ID,
    label: 'User ID (set through the Jimo SDK)',
  },
];

const Option = (props) => {
  const {data} = props;
  return (
    <components.Option {...props}>
      {data?.value?.object === HUBSPOT_OBJECT_TYPE_COMPANY && (
        <div className="object-type-tag">Company</div>
      )}
      {data?.value?.object === HUBSPOT_OBJECT_TYPE_DEAL && (
        <div className="object-type-tag">Deal</div>
      )}
      {props.data.label}
    </components.Option>
  );
};
const Control = ({children, ...rest}) => {
  const {isHubspot, isJimo} = rest;
  return (
    <components.Control {...rest}>
      <div className="icon-wrapper">
        {isJimo === true && (
          <img src={JimoIcon} width={26} height={26} alt="jimo-icon" />
        )}
        {isHubspot === true && <HubspotIcon />}
      </div>
      {children}
    </components.Control>
  );
};
const SingleValue = ({children, ...props}) => {
  const {data} = props;
  return (
    <components.SingleValue {...props}>
      {data?.value?.object === HUBSPOT_OBJECT_TYPE_COMPANY && (
        <div className="object-type-tag">Company</div>
      )}
      {data?.value?.object === HUBSPOT_OBJECT_TYPE_DEAL && (
        <div className="object-type-tag">Deal</div>
      )}
      {children}
    </components.SingleValue>
  );
};

const CreatableCustomAttribute = ({
  className,
  options,
  value,
  onChange,
  onCreate,
  ...rest
}) => {
  const [inputSearch, setInputSearch] = useState(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const handleChange = (value) => {
    onChange(value.value);
    setMenuIsOpen(false);
  };
  const handlePressEnter = async () => {
    const type = Object.values(options).find((o) =>
      o.label.includes(inputSearch)
    );

    if (inputSearch != null && type == null) {
      const created = await onCreate(inputSearch);
      handleChange(created);
    }
  };

  return (
    <SelectGroup
      small
      menuIsOpen={menuIsOpen}
      options={options}
      placeholder="Select a property"
      value={value}
      className={classNames('select-custom-types', className)}
      noOptionsMessage={() => 'Press enter to create'}
      onChange={handleChange}
      onInputChange={(str) => setInputSearch(str)}
      onPressEnter={handlePressEnter}
      onMenuOpen={(value) => setMenuIsOpen(value)}
      {...rest}
    />
  );
};

const logger = new Swaler('HubspotSettingsSetup');

const SettingsSetup = ({hubspot, setHubspot}) => {
  const [customAttributes, setCustomAttributes] = useState([]);

  const customAttributesRef = useRef(customAttributes);

  const {
    data: properties = [],
    refetch: refetchHubspotProperties,
    isLoading: isLoadingHubspotProperties,
  } = useQuery({
    queryKey: 'hubspotProperties',
    queryFn: async () => {
      const data = await hubspotService.getProperties(hubspot?.uid);
      return [
        ...data.contact.map((f) => ({
          ...f,
          objectType: HUBSPOT_OBJECT_TYPE_CONTACT,
        })),
        ...data.company.map((f) => ({
          ...f,
          objectType: HUBSPOT_OBJECT_TYPE_COMPANY,
        })),
        ...data.deal.map((f) => ({
          ...f,
          objectType: HUBSPOT_OBJECT_TYPE_DEAL,
        })),
      ];
    },
    enabled: !!hubspot?.uid,
    onError: (err) => {
      logger.error(err);
    },
  });

  const {isLoading: isLoadingAttributes, refetch: refetchAttributes} = useQuery(
    {
      queryKey: 'customAttributes',
      queryFn: async () => {
        const customAttributes =
          await segmentService.getSegmentCustomAttributes();
        setCustomAttributes(customAttributes);
      },
      onError: (err) => {
        logger.error(err);
      },
      cacheTime: 0,
    }
  );

  useEffect(() => {
    customAttributesRef.current = customAttributes;
  }, [customAttributes]);

  const updateSyncedField = (syncedFieldId, update) => {
    const syncedFields = hubspot?.syncedFields.map((syncedField) => {
      if (syncedField.uid === syncedFieldId) {
        return {
          ...syncedField,
          ...update,
        };
      }
      return syncedField;
    });
    setHubspot({
      ...hubspot,
      syncedFields,
    });
  };

  const addSyncedField = (side) => {
    const syncedFields = [
      ...hubspot?.syncedFields,
      {
        uid: uuidv4(),
        side,
        hubspotObject: 'CONTACT',
        hubspotField: null,
        jimoField: null,
      },
    ];

    setHubspot({
      ...hubspot,
      syncedFields,
    });
  };

  properties.sort((a, b) => {
    if (a.objectType === b.objectType) {
      return a.label.localeCompare(b.label);
    }

    return 0;
  });

  const hubspotOptionsFrom = properties
    ?.filter((o) =>
      [
        'string',
        'number',
        'datetime',
        'date',
        'bool',
        'phone_number',
        'enumeration',
      ].includes(o.type)
    )
    .map((property) => ({
      value: {
        name: property.name,
        object: property.objectType,
      },
      label: property.label,
    }));
  const jimoOptionsTo = customAttributes
    ?.filter((o) => o.source === CUSTOM_ATTRIBUTE_SOURCE_HUBSPOT)
    .map((attribute) => ({
      value: attribute.uid,
      label: attribute.name,
    }));

  const syncedFieldsFromHubspot = hubspot?.syncedFields.filter(
    (s) => s.side === SYNCED_FIELD_SIDE_HUBSPOT_TO_JIMO
  );

  const alreadyUsedHubspotFields = hubspotOptionsFrom?.filter((o) =>
    syncedFieldsFromHubspot?.some(
      (s) =>
        s.hubspotField === o.value.name && s.hubspotObject === o.value.object
    )
  );

  const alreadyUsedJimoFields = jimoOptionsTo?.filter((o) =>
    syncedFieldsFromHubspot?.some((s) => s.jimoField?.uid === o.value)
  );

  return (
    <div className="hubspot-settings-setup">
      <div className="settings-card hubspot-match-field">
        <div className="card-header">
          <div className="subtitle-3">Matched by</div>
          <div className="body-3 n-700">
            The selected field will be used to match users between HubSpot and
            Jimo. The selected field must be unique for each user.
          </div>
        </div>
        <div className="card-body">
          <SelectGroup
            isLoading={isLoadingHubspotProperties}
            options={hubspotOptionsFrom.filter(
              (o) => o.value.object === HUBSPOT_OBJECT_TYPE_CONTACT
            )}
            placeholder="Select a property"
            onChange={(option) =>
              setHubspot({
                ...hubspot,
                matchingFieldHubspot: option?.value.name,
              })
            }
            value={hubspotOptionsFrom?.find(
              (o) => o.value.name === hubspot?.matchingFieldHubspot
            )}
            components={{
              Option: Option,
              Control: (props) => <Control {...props} isHubspot />,
              SingleValue: SingleValue,
            }}
          />
          <i className="icon-pause-rounded n-700" />
          <SelectGroup
            options={matchingOptions}
            placeholder="Select a property"
            onChange={(option) =>
              setHubspot({
                ...hubspot,
                matchingFieldJimo: option?.value,
              })
            }
            value={matchingOptions.find(
              (o) => o.value === hubspot?.matchingFieldJimo
            )}
            components={{
              Option: Option,
              Control: (props) => <Control {...props} isJimo />,
              SingleValue: SingleValue,
            }}
          />
        </div>
      </div>

      <div className="settings-card hubspot-synced-fields">
        <div className="card-header">
          <div className="left-side">
            <div className="subtitle-3">Synced fields</div>
            <div className="body-3 n-700">
              Fields that will be synced in near real-time from HubSpot to Jimo.
              Only users observed by Jimo in your app will be synced.
            </div>
          </div>
          <div className="right-side">
            <Button
              iconLeft="icon-plus"
              thin
              onClick={() => addSyncedField(SYNCED_FIELD_SIDE_HUBSPOT_TO_JIMO)}>
              Add
            </Button>
          </div>
        </div>
        {syncedFieldsFromHubspot.length > 0 && (
          <div className="card-body">
            {syncedFieldsFromHubspot
              ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
              .map((syncedField) => {
                const {hubspotField, hubspotObject, jimoField} = syncedField;
                const selectedHubspotField = hubspotOptionsFrom?.find(
                  (o) =>
                    o.value.name === hubspotField &&
                    o.value.object === hubspotObject
                );
                const selectedJimoField = jimoOptionsTo?.find(
                  (o) => o.value === jimoField?.uid
                );

                return (
                  <div className="synced-field">
                    <SelectGroup
                      small
                      isLoading={isLoadingHubspotProperties}
                      options={hubspotOptionsFrom.filter(
                        (o) =>
                          !alreadyUsedHubspotFields?.some(
                            (f) =>
                              f.value.name === o.value.name &&
                              f.value.object === o.value.object
                          ) ||
                          (selectedHubspotField?.value?.name === o.value.name &&
                            selectedHubspotField?.value?.object ===
                              o.value.object)
                      )}
                      placeholder="Select a property"
                      onChange={(value) => {
                        updateSyncedField(syncedField.uid, {
                          hubspotObject: value.value?.object,
                          hubspotField: value.value?.name,
                        });
                      }}
                      value={selectedHubspotField}
                      components={{
                        Option: Option,
                        Control: (props) => <Control {...props} isHubspot />,
                        SingleValue: SingleValue,
                      }}
                    />
                    <i className="icon-forward n-700" />
                    <CreatableCustomAttribute
                      isLoading={isLoadingAttributes}
                      options={jimoOptionsTo.filter(
                        (o) =>
                          !alreadyUsedJimoFields?.some(
                            (f) => f.value === o.value
                          ) || selectedJimoField?.value === o.value
                      )}
                      onChange={(value) => {
                        const jimoField = customAttributesRef.current?.find(
                          (o) => o.uid === value
                        );
                        updateSyncedField(syncedField.uid, {
                          jimoField,
                        });
                      }}
                      onCreate={async (value) => {
                        const customAttribute =
                          await segmentService.createCustomAttribute({
                            attributeName: value,
                            source: CUSTOM_ATTRIBUTE_SOURCE_HUBSPOT,
                          });
                        await refetchAttributes();
                        return {
                          label: customAttribute.name,
                          value: customAttribute.uid,
                        };
                      }}
                      value={selectedJimoField}
                      components={{
                        Option: Option,
                        Control: (props) => <Control {...props} isJimo />,
                        SingleValue: SingleValue,
                      }}
                    />
                    <Button
                      iconOnly
                      danger
                      onClick={() => {
                        const syncedFields = hubspot?.syncedFields.filter(
                          (field) => field.uid !== syncedField.uid
                        );

                        setHubspot({
                          ...hubspot,
                          syncedFields,
                        });
                      }}>
                      <i className="icon-trash"></i>
                    </Button>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default SettingsSetup;
