import {SegmentAttributesEditorContext} from 'components/SegmentAttributesEditor';
import {
  getAttributeType,
  getDefaultProperty,
} from 'components/SegmentAttributesEditor/utils';
import SelectGroup from 'components/Select';
import React, {useContext} from 'react';
import {components} from 'react-select';
import {AttributeContext} from '../..';
import './_Styles.scss';

export const iconStyle = (color) => ({
  background: color,
  height: 24,
  width: 24,
  margin: 8,
  marginRight: 0,
  borderRadius: 8,
  padding: 4,
  color: 'black',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 16,
  overflow: 'hidden',
});

export const Option = (props) => {
  const {data} = props;

  return (
    <components.Option {...props}>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <div
          className="icon-wrapper"
          style={{
            ...iconStyle(data.iconBackgroundColor),
            marginRight: 8,
            fontSize: 18,
            height: 22,
            width: 22,
            overflow: 'hidden',
          }}>
          {data.icon}
        </div>
        <div
          className="content"
          style={{
            fontSize: 16,
          }}>
          <div className="title">{data.label}</div>
        </div>
      </div>
    </components.Option>
  );
};

export const Control = (props) => {
  const {children, selectedBlock, ...rest} = props;

  return (
    <components.Control {...rest}>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <div
          className="icon-wrapper"
          style={iconStyle(selectedBlock.iconBackgroundColor)}>
          {selectedBlock.icon}
        </div>
        {children}
      </div>
    </components.Control>
  );
};

const AttributeType = () => {
  const {BLOCKS} = useContext(SegmentAttributesEditorContext);
  const {attribute, updateAttribute} = useContext(AttributeContext);

  const {category, type} = attribute;

  const handleTypeChange = (option) => {
    const type = getAttributeType(option.value);

    if (
      type !== attribute.type ||
      option.eventId !== attribute.eventId ||
      option.name !== attribute.name ||
      option.customAttributeId !== attribute.customAttributeId
    ) {
      attribute.type = type;
      attribute.property = getDefaultProperty(category);
      attribute.op = null;
      attribute.values = [];
      attribute.evolutions = [];
      attribute.eventId = option.eventId;
      attribute.name = option.name ?? null;
      attribute.customAttributeId = option.customAttributeId ?? null;

      updateAttribute(attribute);
    }
  };

  const typeOptions = BLOCKS.filter((b) => b.category === category)?.map(
    (e) => ({
      label: e.title,
      value: e.value,
      icon: e.icon,
      iconBackgroundColor: e.iconBackgroundColor,
      eventId: e.eventId,
      name: e.name,
      customAttributeId: e.customAttributeId,
    })
  );

  const selectedBlock = BLOCKS.filter((b) => b.category === category).find(
    (b) => {
      if (type === 'CUSTOM') {
        return b.customAttributeId === attribute.customAttributeId;
      }
      // TO-DO: handle SEGMENTIO_FIELDS, SEGMENTIO_EVENTS, TRACKED_EVENTS
      if (type === 'SEGMENTIO_FIELDS') {
        return b.value === `${attribute.category}-${attribute.name}`;
      }
      if (type === 'SEGMENTIO_EVENTS') {
        return b.value === `${attribute.category}-${attribute.name}`;
      }
      if (type === 'CIO_EVENTS') {
        return b.value === `${attribute.category}-${attribute.name}`;
      }
      if (type === 'POSTHOG_EVENTS') {
        return b.value === `${attribute.category}-${attribute.name}`;
      }
      if (type === 'MIXPANEL_EVENTS') {
        return b.value === `${attribute.category}-${attribute.name}`;
      }
      if (type === 'JIMO_API_EVENTS') {
        return b.value === `${attribute.category}-${attribute.name}`;
      }
      if (type === 'TRACKED_EVENTS') {
        return b.eventId === attribute.eventId;
      }
      return b.value === type;
    }
  );

  const selectValue = typeOptions.find((e) => e.value === selectedBlock?.value);

  return (
    <SelectGroup
      menuPortalTarget={document.body}
      options={typeOptions}
      closeMenuOnSelect={true}
      value={selectValue || null}
      onChange={handleTypeChange}
      components={{
        Option,
        ...(selectedBlock != null && {
          Control: (controlProps) => (
            <Control {...controlProps} selectedBlock={selectedBlock} />
          ),
        }),
      }}
    />
  );
};

export default AttributeType;
