import {BadgeAI} from 'components/BadgeAI';
import Button from 'components/Button';
import Divider from 'components/Divider';
import DefaultLoader from 'components/Loader';
import {BuilderContext} from 'contextes/builder';
import {func} from 'prop-types';
import React, {useContext, useState} from 'react';
import {
  BLOCK_TYPE_BODY,
  BLOCK_TYPE_CHECKLIST_DISMISS,
  BLOCK_TYPE_CHECKLIST_HEADER_DESCRIPTION,
  BLOCK_TYPE_CHECKLIST_HEADER_TITLE,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_DESCRIPTION,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_MEDIA,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_PRIMARY_CTA,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_SECONDARY_CTA,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_TITLE,
  BLOCK_TYPE_CHOICE,
  BLOCK_TYPE_HINT,
  BLOCK_TYPE_LABEL,
  BLOCK_TYPE_MEDIA,
  BLOCK_TYPE_NPS,
  BLOCK_TYPE_OPEN_QUESTION,
  BLOCK_TYPE_OPINION,
  BLOCK_TYPE_PRIMARY_CTA,
  BLOCK_TYPE_SECONDARY_CTA,
  BLOCK_TYPE_SLIDER,
  BLOCK_TYPE_TITLE,
  STEP_TYPE_CHECKLIST,
} from 'services/steps';
import './_Styles.scss';
import ButtonBlockInput from './components/ButtonBlockInput';
import ChoiceBlockInputs from './components/ChoiceBlockInputs';
import LabelBlockInput from './components/LabelBlockInput';
import LanguageSelector from './components/LanguageSelector';
import MediaBlockInput from './components/MediaBlockInput';
import NpsBlockInput from './components/NpsBlockInput';
import OpenQuestionBlockInput from './components/OpenQuestionBlockInput';
import OpinionBlockInput from './components/OpinionBlockInput';
import ParagraphBlockInput from './components/ParagraphBlockInput';
import SliderBlockInput from './components/SliderBlockInput';
import TitleBlockInput from './components/TitleBlockInput';

const propTypes = {
  onClose: func,
};
const defaultProps = {
  onClose: () => {},
};

const TranslationManager = () => {
  const {
    evolution,
    setEvolution,
    smartTranslate,
    isTranslating,
    selectedStep: step,
    updateStep,
    setSelectedLanguage,
    selectedLanguage,
  } = useContext(BuilderContext);

  const [languages, setLanguages] = useState([]);

  const handleDeleteLanguage = (code) => {
    // TO-DO: delete language from prototype steps
    setEvolution({
      ...evolution,
      tourSteps: evolution.tourSteps.map((tourStep) => {
        tourStep.steps = tourStep.steps.map((step) => {
          step.blocks = step.blocks.map((block) => {
            block.translations = block.translations?.filter(
              (t) => t.language !== code
            );
            block.options = block.options?.map((option) => {
              option.labels = option.labels.filter((l) => l.language !== code);
              return option;
            });
            return block;
          });
          return step;
        });
        return tourStep;
      }),
      steps: evolution.steps.map((step) => {
        step.blocks = step.blocks.map((block) => {
          block.translations = block.translations?.filter(
            (t) => t.language !== code
          );
          block.options = block.options?.map((option) => {
            option.labels = option.labels.filter((l) => l.language !== code);
            return option;
          });
          return block;
        });
        return step;
      }),
    });
  };

  const mediaBlock = step?.blocks.find((b) => b.type === BLOCK_TYPE_MEDIA);
  const titleBlock = step?.blocks.find((b) => b.type === BLOCK_TYPE_TITLE);
  const bodyBlock = step?.blocks.find((b) => b.type === BLOCK_TYPE_BODY);
  const tagBlock = step?.blocks.find((b) => b.type === BLOCK_TYPE_LABEL);
  const primaryButtonBlock = step?.blocks.find(
    (b) => b.type === BLOCK_TYPE_PRIMARY_CTA
  );
  const secondaryButtonBlock = step?.blocks.find(
    (b) => b.type === BLOCK_TYPE_SECONDARY_CTA
  );
  const choiceBlock = step?.blocks.find((b) => b.type === BLOCK_TYPE_CHOICE);
  const sliderBlock = step?.blocks.find((b) => b.type === BLOCK_TYPE_SLIDER);
  const npsBlock = step?.blocks.find((b) => b.type === BLOCK_TYPE_NPS);
  const opinionBlock = step?.blocks.find((b) => b.type === BLOCK_TYPE_OPINION);
  const openQuestionBlock = step?.blocks.find(
    (b) => b.type === BLOCK_TYPE_OPEN_QUESTION
  );
  const hintBlock = step?.blocks.find((b) => b.type === BLOCK_TYPE_HINT);

  const isChecklistStep = step?.type === STEP_TYPE_CHECKLIST;

  const checklistHeaderTitleBlock = step?.blocks.find(
    (b) => b.type === BLOCK_TYPE_CHECKLIST_HEADER_TITLE
  );
  const checklistHeaderParagraphBlock = step?.blocks.find(
    (b) => b.type === BLOCK_TYPE_CHECKLIST_HEADER_DESCRIPTION
  );
  const checklistDismissBlock = step?.blocks.find(
    (b) => b.type === BLOCK_TYPE_CHECKLIST_DISMISS
  );

  const checklistTaskBlocks = step?.blocks
    .filter((b) => b.type === BLOCK_TYPE_CHECKLIST_TASK_ITEM)
    .sort((a, b) => {
      const valueA = a.value;
      const valueB = b.value;
      const [_, indexA] = valueA.split('|-|');
      const [__, indexB] = valueB.split('|-|');

      return indexA - indexB;
    });

  const updateBlock = (updateObj, blockType, {parentBlockId = null} = {}) => {
    updateStep(step.uid, {
      blocks: step.blocks.map((block) => {
        if (
          block.type === blockType &&
          (parentBlockId == null || block.parentBlockId === parentBlockId)
        ) {
          return {
            ...block,
            ...updateObj,
          };
        } else {
          return block;
        }
      }),
    });
  };

  return (
    <div className="translation-manager-wrapper">
      <div className="translation-manager">
        <div className="translation-manager-header">
          <div className="translation-manager-title">Add translations</div>
          {languages?.length > 0 && selectedLanguage != null && (
            <Button
              className="smart-translate-btn"
              thin
              onClick={() => smartTranslate(languages)}
              disabled={languages.length <= 0 || isTranslating}>
              Smart translate
              <BadgeAI />
            </Button>
          )}
        </div>
        {isTranslating ? (
          <div className="loader-wrapper">
            <DefaultLoader />
            Translating...
          </div>
        ) : (
          <>
            <LanguageSelector
              evolution={evolution}
              selectedLanguage={selectedLanguage}
              onAddLanguage={setSelectedLanguage}
              onSelectLanguage={setSelectedLanguage}
              onRemoveLanguage={handleDeleteLanguage}
              languages={languages}
              setLanguages={setLanguages}
            />
            <div className="translation-manager-blocks">
              {hintBlock != null &&
                ['label', 'button'].includes(hintBlock.style?.type) && (
                  <>
                    <div className="translation-manager-block">
                      <div className="translation-manager-block-title">
                        Hint label
                      </div>
                      <div className="translation-manager-block-content">
                        <LabelBlockInput
                          selectedLanguage={selectedLanguage}
                          block={hintBlock}
                          onChange={(value) => {
                            updateBlock(value, BLOCK_TYPE_HINT);
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
              {isChecklistStep ? (
                <>
                  <div className="translation-manager-subtitle subtitle-3">
                    Checklist header
                  </div>
                  {checklistHeaderTitleBlock != null && (
                    <div className="translation-manager-block">
                      <div className="translation-manager-block-title">
                        Title
                      </div>
                      <div className="translation-manager-block-content">
                        <TitleBlockInput
                          selectedLanguage={selectedLanguage}
                          block={checklistHeaderTitleBlock}
                          onChange={(value) => {
                            updateBlock(
                              value,
                              BLOCK_TYPE_CHECKLIST_HEADER_TITLE
                            );
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {checklistHeaderParagraphBlock != null && (
                    <div className="translation-manager-block">
                      <div className="translation-manager-block-title">
                        Paragraph
                      </div>
                      <div className="translation-manager-block-content">
                        <ParagraphBlockInput
                          selectedLanguage={selectedLanguage}
                          block={checklistHeaderParagraphBlock}
                          onChange={(value) => {
                            updateBlock(
                              value,
                              BLOCK_TYPE_CHECKLIST_HEADER_DESCRIPTION
                            );
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <Divider />
                  {checklistTaskBlocks.map((task, index) => {
                    const [title] = task.value?.split('|-|');

                    const mediaBlock = step?.blocks.find(
                      (b) =>
                        b.type === BLOCK_TYPE_CHECKLIST_TASK_ITEM_MEDIA &&
                        b.parentBlockId === task.uid
                    );
                    const titleBlock = step?.blocks.find(
                      (b) =>
                        b.type === BLOCK_TYPE_CHECKLIST_TASK_ITEM_TITLE &&
                        b.parentBlockId === task.uid
                    );
                    const bodyBlock = step?.blocks.find(
                      (b) =>
                        b.type === BLOCK_TYPE_CHECKLIST_TASK_ITEM_DESCRIPTION &&
                        b.parentBlockId === task.uid
                    );
                    const primaryButtonBlock = step?.blocks.find(
                      (b) =>
                        b.type === BLOCK_TYPE_CHECKLIST_TASK_ITEM_PRIMARY_CTA &&
                        b.parentBlockId === task.uid
                    );
                    const secondaryButtonBlock = step?.blocks.find(
                      (b) =>
                        b.type ===
                          BLOCK_TYPE_CHECKLIST_TASK_ITEM_SECONDARY_CTA &&
                        b.parentBlockId === task.uid
                    );

                    return (
                      <>
                        <div className="translation-manager-subtitle subtitle-3">
                          {title}
                        </div>
                        {mediaBlock != null && (
                          <div className="translation-manager-block">
                            <div className="translation-manager-block-title">
                              Media
                            </div>
                            <div className="translation-manager-block-content">
                              <MediaBlockInput
                                selectedLanguage={selectedLanguage}
                                block={mediaBlock}
                                onChange={(value) => {
                                  updateBlock(
                                    value,
                                    BLOCK_TYPE_CHECKLIST_TASK_ITEM_MEDIA,
                                    {
                                      parentBlockId: task.uid,
                                    }
                                  );
                                }}
                              />
                            </div>
                          </div>
                        )}
                        {titleBlock != null && (
                          <div className="translation-manager-block">
                            <div className="translation-manager-block-title">
                              Title
                            </div>
                            <div className="translation-manager-block-content">
                              <TitleBlockInput
                                selectedLanguage={selectedLanguage}
                                block={titleBlock}
                                onChange={(value) => {
                                  updateBlock(
                                    value,
                                    BLOCK_TYPE_CHECKLIST_TASK_ITEM_TITLE,
                                    {
                                      parentBlockId: task.uid,
                                    }
                                  );
                                }}
                              />
                            </div>
                          </div>
                        )}
                        {bodyBlock != null && (
                          <div className="translation-manager-block">
                            <div className="translation-manager-block-title">
                              Paragraph
                            </div>
                            <div className="translation-manager-block-content">
                              <ParagraphBlockInput
                                selectedLanguage={selectedLanguage}
                                block={bodyBlock}
                                onChange={(value) => {
                                  updateBlock(
                                    value,
                                    BLOCK_TYPE_CHECKLIST_TASK_ITEM_DESCRIPTION,
                                    {
                                      parentBlockId: task.uid,
                                    }
                                  );
                                }}
                              />
                            </div>
                          </div>
                        )}
                        {primaryButtonBlock != null && (
                          <div className="translation-manager-block">
                            <div className="translation-manager-block-title">
                              Primary CTA
                            </div>
                            <div className="translation-manager-block-content">
                              <ButtonBlockInput
                                selectedLanguage={selectedLanguage}
                                block={primaryButtonBlock}
                                onChange={(value) => {
                                  updateBlock(
                                    value,
                                    BLOCK_TYPE_CHECKLIST_TASK_ITEM_PRIMARY_CTA,
                                    {
                                      parentBlockId: task.uid,
                                    }
                                  );
                                }}
                              />
                            </div>
                          </div>
                        )}
                        {secondaryButtonBlock != null && (
                          <div className="translation-manager-block">
                            <div className="translation-manager-block-title">
                              Secondary CTA
                            </div>
                            <div className="translation-manager-block-content">
                              <ButtonBlockInput
                                selectedLanguage={selectedLanguage}
                                block={secondaryButtonBlock}
                                onChange={(value) => {
                                  updateBlock(
                                    value,
                                    BLOCK_TYPE_CHECKLIST_TASK_ITEM_SECONDARY_CTA,
                                    {
                                      parentBlockId: task.uid,
                                    }
                                  );
                                }}
                              />
                            </div>
                          </div>
                        )}
                        {index < checklistTaskBlocks.length - 1 && <Divider />}
                      </>
                    );
                  })}
                  {checklistDismissBlock != null && (
                    <>
                      <Divider />
                      <div className="translation-manager-subtitle subtitle-3">
                        Checklist dismiss
                      </div>
                      <div className="translation-manager-block">
                        <div className="translation-manager-block-title">
                          Content
                        </div>
                        <div className="translation-manager-block-content">
                          <LabelBlockInput
                            selectedLanguage={selectedLanguage}
                            block={checklistDismissBlock}
                            onChange={(value) => {
                              updateBlock(value, BLOCK_TYPE_CHECKLIST_DISMISS);
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  {mediaBlock != null && (
                    <div className="translation-manager-block">
                      <div className="translation-manager-block-title">
                        Media
                      </div>
                      <div className="translation-manager-block-content">
                        <MediaBlockInput
                          selectedLanguage={selectedLanguage}
                          block={mediaBlock}
                          onChange={(value) => {
                            updateBlock(value, BLOCK_TYPE_MEDIA);
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {titleBlock != null && (
                    <div className="translation-manager-block">
                      <div className="translation-manager-block-title">
                        Title
                      </div>
                      <div className="translation-manager-block-content">
                        <TitleBlockInput
                          selectedLanguage={selectedLanguage}
                          block={titleBlock}
                          onChange={(value) => {
                            updateBlock(value, BLOCK_TYPE_TITLE);
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {bodyBlock != null && (
                    <div className="translation-manager-block">
                      <div className="translation-manager-block-title">
                        Paragraph
                      </div>
                      <div className="translation-manager-block-content">
                        <ParagraphBlockInput
                          selectedLanguage={selectedLanguage}
                          block={bodyBlock}
                          onChange={(value) => {
                            updateBlock(value, BLOCK_TYPE_BODY);
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {tagBlock != null && (
                    <div className="translation-manager-block">
                      <div className="translation-manager-block-title">
                        Label
                      </div>
                      <div className="translation-manager-block-content">
                        <LabelBlockInput
                          selectedLanguage={selectedLanguage}
                          block={tagBlock}
                          onChange={(value) => {
                            updateBlock(value, BLOCK_TYPE_LABEL);
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {primaryButtonBlock != null && (
                    <div className="translation-manager-block">
                      <div className="translation-manager-block-title">
                        Primary CTA
                      </div>
                      <div className="translation-manager-block-content">
                        <ButtonBlockInput
                          selectedLanguage={selectedLanguage}
                          block={primaryButtonBlock}
                          onChange={(value) => {
                            updateBlock(value, BLOCK_TYPE_PRIMARY_CTA);
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {secondaryButtonBlock != null && (
                    <div className="translation-manager-block">
                      <div className="translation-manager-block-title">
                        Secondary CTA
                      </div>
                      <div className="translation-manager-block-content">
                        <ButtonBlockInput
                          selectedLanguage={selectedLanguage}
                          block={secondaryButtonBlock}
                          onChange={(value) => {
                            updateBlock(value, BLOCK_TYPE_SECONDARY_CTA);
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {choiceBlock != null && (
                    <div className="translation-manager-block">
                      <div className="translation-manager-block-title">
                        Multiple choice
                      </div>
                      <div className="translation-manager-block-content">
                        <ChoiceBlockInputs
                          selectedLanguage={selectedLanguage}
                          block={choiceBlock}
                          onChange={(value) => {
                            updateBlock(value, BLOCK_TYPE_CHOICE);
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {sliderBlock != null && (
                    <div className="translation-manager-block">
                      <div className="translation-manager-block-title">
                        Slider
                      </div>
                      <div className="translation-manager-block-content">
                        <SliderBlockInput
                          selectedLanguage={selectedLanguage}
                          block={sliderBlock}
                          onChange={(value) => {
                            updateBlock(value, BLOCK_TYPE_SLIDER);
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {npsBlock != null && (
                    <div className="translation-manager-block">
                      <div className="translation-manager-block-title">NPS</div>
                      <div className="translation-manager-block-content">
                        <NpsBlockInput
                          selectedLanguage={selectedLanguage}
                          block={npsBlock}
                          onChange={(value) => {
                            updateBlock(value, BLOCK_TYPE_NPS);
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {opinionBlock != null && (
                    <div className="translation-manager-block">
                      <div className="translation-manager-block-title">
                        Opinion scale
                      </div>
                      <div className="translation-manager-block-content">
                        <OpinionBlockInput
                          selectedLanguage={selectedLanguage}
                          block={opinionBlock}
                          onChange={(value) => {
                            updateBlock(value, BLOCK_TYPE_OPINION);
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {openQuestionBlock != null && (
                    <div className="translation-manager-block">
                      <div className="translation-manager-block-title">
                        Open question
                      </div>
                      <div className="translation-manager-block-content">
                        <OpenQuestionBlockInput
                          selectedLanguage={selectedLanguage}
                          block={openQuestionBlock}
                          onChange={(value) => {
                            updateBlock(value, BLOCK_TYPE_OPEN_QUESTION);
                          }}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

TranslationManager.propTypes = propTypes;
TranslationManager.defaultProps = defaultProps;

export default TranslationManager;
