import React from 'react';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import PixelPicker from '../../items/PixelPicker';
import './_Styles.scss';

const MIN_GAP = 0;
const MAX_GAP = 50;

const GapItem = ({
  value,
  onChange,
  min = MIN_GAP,
  max = MAX_GAP,
  isHorizontal = false,
}) => {
  return (
    <SectionItem title="Gap" sectionItemClassName="gap-item">
      <PixelPicker
        small
        value={value}
        min={min}
        max={max}
        onChange={(value) => onChange(value)}
        inputProps={{
          labelTextLeft: (
            <div className="icon-wrapper">
              <i
                className="isax isax-pharagraphspacing"
                style={{
                  transform: isHorizontal ? 'rotate(90deg)' : 'rotate(0deg)',
                }}
              />
            </div>
          ),
          max: null,
        }}
        label={null}
      />
    </SectionItem>
  );
};

export default GapItem;
