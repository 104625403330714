function listPaths(obj) {
  function rKeys(o, path) {
    if (typeof o !== 'object' || o === null) return path;
    if (Array.isArray(o)) return path;
    return Object.keys(o).map((key) =>
      rKeys(o[key], path ? `${path}.${key}` : key)
    );
  }
  return rKeys(obj).toString().split(',').filter(Boolean);
}

function getValueFromPath(obj, path) {
  return path
    .split('.')
    .reduce(
      (acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined),
      obj
    );
}

function flattenAttribute(attribute) {
  const {name, value} = attribute;
  if (!value || typeof value !== 'object' || Array.isArray(value)) {
    return [attribute];
  }
  const subPaths = listPaths(value);
  return subPaths.map((subPath) => ({
    ...attribute,
    name: `${name}.${subPath}`,
    value: getValueFromPath(value, subPath),
  }));
}

export const flattenAttributes = (attributes = []) => {
  return attributes.flatMap((attr) => flattenAttribute(attr));
};
