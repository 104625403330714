export const previewPost = {
  uid: '84e4788f-71eb-4186-aa8c-c7e9c03e45ee',
  style: null,
  title: 'Test',
  description: null,
  content: null,
  rawContent: null,
  step: null,
  optionsFlags: 262217,
  lastStepChangeAt: '2023-08-28T16:27:55.000Z',
  createdAt: '2023-08-31T10:18:19.000Z',
  typeformFormId: null,
  mazeUrl: null,
  ctaLabel: null,
  ctaColor: null,
  ctaBackgroundColor: null,
  ctaBorderRadius: null,
  ctaUrl: null,
  analyticViewsCount: 0,
  analyticClicksCount: 0,
  boostFlags: 0,
  boostedAt: null,
  expiresAt: null,
  isDraft: false,
  boostedUrl: null,
  boostedDelay: 0,
  boostedQueryselector: null,
  boostedPositionFlags: 64,
  boostedPositionOffsets: '0;0',
  boostedTextsColors: '#071331;#071331',
  boostedPrimaryColor: '#ffffff',
  boostedSecondaryColor: '#1260EB',
  boostedRoundness: '8',
  boostedContentFontSize: '14',
  boostedTitleFontSize: '18',
  boostedActiveUrl: '',
  boostedActiveOperator: 'EVERYWHERE',
  boostedPathOperator: 'OR',
  boostedDomainFilter: null,
  boostedDotStyle: '#1260EB;22;default',
  boostedSize: null,
  boostedAnimations: 'fade-in;fade-out',
  boostedLightbox: 'SOFT',
  boostedZIndex: null,
  icon: 'DEFAULT',
  triggerType: 'DEFAULT',
  state: 'LIVE',
  sectionIndexOrder: 0,
  recurrencyType: 'SINGLE_TIME',
  recurrencyValue: 1,
  tourStepInfo: null,
  context: 'PORTAL',
  deviceFlags: 1,
  priority: 0,
  draft: null,
  isDraftOf: null,
  section: null,
  texts: [],
  tags: [
    {
      uid: '259fe636-2318-4471-833d-b8c065a89dd4',
      name: 'Shaping',
      color: '#AA5D16',
      context: 'EVOLUTION',
      indexOrder: 3,
      createdAt: '2023-01-07T23:34:23.000Z',
      showInRoadmap: true,
      texts: [],
    },
  ],
  segments: [],
  onTheFlySegment: {
    attributesFormatted: true,
    uid: '6b80fae5-d0c7-4b57-8660-2a20dd4662ba',
    name: null,
    description: null,
    createdAt: '2023-09-08T10:20:19.000Z',
    lastEditedAt: '2023-09-08T10:20:19.000Z',
    loading: false,
    size: 1,
    temporary: true,
    icon: null,
    disabled: false,
    deleted: false,
    logic: {
      uid: '20e85dd8-e3fa-424d-abee-0da614e009eb',
      type: 'logicGroup',
      children: [
        {
          type: 'condition',
          attributeId: '652e82ef-b3c5-458a-8d4c-9fd75107526d',
        },
      ],
      operator: 'and',
    },
    attributes: [
      {
        uid: '652e82ef-b3c5-458a-8d4c-9fd75107526d',
        category: 'ATTRIBUTES',
        type: 'EMAIL',
        name: null,
        property: null,
        op: 'NIS_EMPTY',
        value: null,
        valueGroup: 0,
        values: [],
        indexOrder: null,
        createdAt: '2023-09-08T10:20:19.000Z',
        eventProperty: null,
        eventOp: null,
        eventValues: null,
        eventOccurrenceValues: [],
        customAttribute: null,
        evolutions: [],
        event: null,
        step: null,
        evolution: null,
        evolutionId: null,
        stepId: null,
        customAttributeId: null,
      },
    ],
    analytics: {
      uniqueViewsCount: 0,
      viewsCount: 1,
      surveyCompletionSum: 0,
      surveyCount: 0,
      surveyCompletedCount: 0,
      tourStartedCount: 1,
      uniqueTourStartedCount: 0,
      tourCompletedCount: 0,
      uniqueTourCompletedCount: 0,
      tourExitedCount: 0,
      dismissCount: 2,
      uniqueClicksCount: 1,
      clicksCount: 1,
      engagementsCount: 1,
      ctaClicksCount: 1,
      commentsCount: 0,
      uniqueCommentAuthors: 0,
      votesCount: 0,
      votesYeahCount: 0,
      votesIdkCount: 0,
      votesNahCount: 0,
      votesCongratsCount: 0,
      uid: '2975559b-ca35-4b88-90cf-14212601e08f',
      segmentId: '6b80fae5-d0c7-4b57-8660-2a20dd4662ba',
    },
  },
  steps: [
    {
      uid: '45b5334e-8b01-4570-a3e5-951eb5be7fbf',
      style: {
        width: null,
        height: null,
        margin: 0,
        shadow: {
          x: 0,
          y: 0,
          blur: 0,
          color: null,
          opacity: 0,
        },
        overlay: null,
        background: {
          type: 'color',
          animated: false,
          primaryColor: '#FFFFFF',
          secondaryColor: null,
        },
        animationIn: 'fade',
        animationOut: 'fade',
        borderRadius: 0,
        withElementsAnimations: true,
      },
      name: 'New step',
      question: null,
      additionalInformation: null,
      type: null,
      mdContent: null,
      mdRawContent: null,
      ctaLabel: null,
      ctaColor: null,
      ctaBackgroundColor: null,
      ctaBorderRadius: null,
      ctaUrl: null,
      ctaAction: null,
      ctaFontSize: null,
      interactiveLink: null,
      conceptThumbnailUrl: null,
      createdAt: '2023-08-31T10:18:19.000Z',
      mazeUrl: null,
      stepFlags: 0,
      endSurvey: false,
      blocks: [
        {
          uid: 'bd8a8e51-f858-48a3-bf88-8b53344e7956',
          type: 'LABEL',
          value: 'New',
          rawValue: null,
          flags: 0,
          style: {
            align: 'left',
            padding: 8,
            fontSize: 12,
            fontColor: '#000000',
            fontFamily: 'Inter',
            borderColor: '#FFFFFF',
            borderRadius: 8,
            primaryColor: '#FFFFFF',
          },
          createdAt: '2023-08-31T16:10:43.000Z',
          options: [],
          translations: [],
          file: null,
        },
        {
          uid: 'd44e4273-86aa-4945-b339-2947e11b957e',
          type: 'BODY',
          value:
            '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus vel metus mi. In malesuada nec odio quis laoreet. Phasellus elementum tortor et ante ornare, sit amet interdum ipsum fermentum. Suspendisse potenti. Quisque in eleifend ipsum. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Praesent molestie nunc eu velit elementum malesuada a vitae risus. Quisque gravida dolor id nisl tempus, vel malesuada velit euismod. Pellentesque sagittis ac arcu in maximus. Nunc vestibulum vulputate orci, eget finibus lacus vulputate vel.</p>',
          rawValue: [
            {
              type: 'p',
              children: [
                {
                  text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus vel metus mi. In malesuada nec odio quis laoreet. Phasellus elementum tortor et ante ornare, sit amet interdum ipsum fermentum. Suspendisse potenti. Quisque in eleifend ipsum. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Praesent molestie nunc eu velit elementum malesuada a vitae risus. Quisque gravida dolor id nisl tempus, vel malesuada velit euismod. Pellentesque sagittis ac arcu in maximus. Nunc vestibulum vulputate orci, eget finibus lacus vulputate vel.',
                },
              ],
            },
          ],
          flags: 0,
          style: {
            align: 'left',
            fontSize: 12,
            fontColor: '#000000',
            fontFamily: 'Inter',
          },
          createdAt: '2023-08-31T10:18:19.000Z',
          options: [],
          translations: [
            {
              uid: '67227b56-3ee9-4718-9bdf-1f79a389f458',
              value: '<p>a</p>',
              rawValue: [
                {
                  data: {},
                  type: 'paragraph',
                  object: 'block',
                  children: [
                    {
                      text: 'a',
                      object: 'text',
                    },
                  ],
                },
              ],
              language: 'EN',
            },
          ],
          file: null,
        },
        {
          uid: 'dbc1a58c-5c6a-44e9-801f-36d01ff312ac',
          type: 'TITLE',
          value: 'Post example',
          rawValue: null,
          flags: 0,
          style: {
            align: 'left',
            fontSize: 14,
            fontColor: '#000000',
            fontFamily: 'Inter',
          },
          createdAt: '2023-08-31T10:18:19.000Z',
          options: [],
          translations: [
            {
              uid: 'bdd3cd9f-102d-4a64-95ed-65b891a1fd68',
              value: 'english',
              rawValue: null,
              language: 'EN',
            },
          ],
          file: null,
        },
        {
          uid: 'f8cf92f6-07fe-4296-9619-bec309f86187',
          type: 'PRIMARY_CTA',
          value:
            'Button;step-next;post-open;70ffe6da-854c-4439-a766-3339e0aeb033;http://localhost:3001/users',
          rawValue: null,
          flags: 0,
          style: {
            align: 'left',
            padding: 8,
            fontSize: 14,
            fontColor: '#000000',
            fontFamily: 'Inter',
            borderColor: '#FFFFFF',
            borderRadius: 8,
            primaryColor: '#FFFFFF',
          },
          createdAt: '2023-08-31T14:06:53.000Z',
          options: [],
          translations: [],
          file: null,
        },
      ],
      texts: [],
      options: [],
      goTo: null,
      jumps: [],
      prototypes: [],
    },
  ],
  boostedPaths: [],
  event: null,
  onTheFlyEvent: null,
  theme: null,
  isBoostOf: null,
  boostedBy: null,
  boostedById: null,
  isBoostOfId: null,
  themeId: null,
  parentTourId: null,
  tourSteps: [],
};
