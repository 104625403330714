import {generalActions} from 'actions';
import {toastDanger, toastWarning} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {addFlag, hasFlag} from 'helpers/bitwise';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  TEMPLATE_1,
  TEMPLATE_2,
  TEMPLATE_3,
  TEMPLATE_4,
  TEMPLATE_5,
} from 'scenes/Pushes/components/NewPushModal';
import {generalSelector} from 'selectors';
import {evolutionService, projectService, stepsService} from 'services';
import {F_GET_STARTED_CREATE_ANNOUNCEMENT} from 'services/project';
import {
  STEP_TYPE_CONCEPT_TEST,
  STEP_TYPE_INTERVIEW,
  STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
  STEP_TYPE_NPS,
  STEP_TYPE_OPINION_SCALE,
  STEP_TYPE_TEXT_BLOCK,
} from 'services/steps';
import {Swaler} from 'swaler';
import {v4 as uuidv4} from 'uuid';

const logger = new Swaler('use-fetch-evolution');

export const useFetchOrCreateEvolution = ({
  evolutionId = null,
  defaultEvolution,
  templateId,
  tagId,
  onFetched = () => {},
  onCreated = () => {},
  disabled = false,
}) => {
  const [isFetching, setIsFetching] = useState(true);

  const dispatch = useDispatch();

  const builder = useSelector((state) => state.builder);

  const projectMember = useSelector((state) =>
    generalSelector.getProjectMember(state)
  );

  const fetchEvolution = async () => {
    setIsFetching(true);
    try {
      let evolution = await evolutionService.getEvolutionById(evolutionId, {
        relations: [
          'texts',
          'tags',
          'segments',
          'steps',
          'boostedPaths',
          'event',
          'tourSteps',
          'theme',
          'section',
          'tracker',
        ],
      });

      if (evolution == null) {
        return toastWarning([
          'Evolution not found!',
          'We could not find the evolution you want to update!',
        ]);
      }
      if (evolution.draft != null) {
        evolution = await evolutionService.getEvolutionById(
          evolution.draft.uid,
          {
            relations: [
              'texts',
              'tags',
              'segments',
              'steps',
              'boostedPaths',
              'event',
              'tourSteps',
              'theme',
              'section',
              'tracker',
            ],
          }
        );
      }
      evolution.tourSteps?.forEach((s) => {
        const [tourIndexOrder] = (s.tourStepInfo || '0;0;0').split(';');
        s.tourIndexOrder = parseInt(tourIndexOrder, 10);
      });
      evolution.tourSteps?.sort((a, b) => a.tourIndexOrder - b.tourIndexOrder);
      onFetched(evolution);
      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(`Fetch from evolution failed with error `, code);
      toastDanger([title, message], {actions});
    }
  };
  const createDefaultEvolution = async () => {
    setIsFetching(true);
    try {
      const createdEvolution = await evolutionService.createEvolution({
        ...defaultEvolution,
        ...(templateId != null ? handleTemplate(templateId) : {}),
        ...(tagId != null ? {tags: [{uid: tagId}]} : {}),
      });
      const evolution = await evolutionService.getEvolutionById(
        createdEvolution.uid,
        {
          relations: [
            'texts',
            'tags',
            'segments',
            'steps',
            'boostedPaths',
            'section',
          ],
        }
      );

      if (
        evolution.isDraft === false &&
        hasFlag(
          F_GET_STARTED_CREATE_ANNOUNCEMENT,
          projectMember.getStartedFlags
        ) === false
      ) {
        const newGetStartedFlag = addFlag(
          F_GET_STARTED_CREATE_ANNOUNCEMENT,
          projectMember.getStartedFlags
        );

        await projectService.updateGetStartedFlag({flag: newGetStartedFlag});
        dispatch(
          generalActions.uptProjectMember({
            getStartedFlags: newGetStartedFlag,
          })
        );
      }

      onCreated(evolution);
      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(`Fetch from evolution failed with error `, code);
      toastDanger([title, message], {
        actions,
      });
    }
  };
  useEffect(() => {
    if (disabled === true) {
      return;
    }
    if (builder.poke != null && builder.poke?.uid === evolutionId) {
      onFetched(builder.poke);
      setIsFetching(false);
    } else if (evolutionId != null && evolutionId !== 'new') {
      fetchEvolution();
    } else {
      createDefaultEvolution();
    }
  }, [disabled]);

  return {
    isFetching,
    refetch: fetchEvolution,
  };
};

const handleTemplate = (templateId) => {
  switch (parseInt(templateId, 10)) {
    case TEMPLATE_1: {
      return {
        steps: [
          {
            uid: uuidv4(),
            question: 'What do you think about this?',
            additionalInformation: '',
            type: STEP_TYPE_OPINION_SCALE,
          },
        ],
      };
    }
    case TEMPLATE_2: {
      return {
        steps: [
          stepsService.createStepObj(STEP_TYPE_INTERVIEW, {
            question: 'Would you be available to chat with us?',
          }),
        ],
      };
    }
    case TEMPLATE_3: {
      return {
        steps: [
          stepsService.createStepObj(STEP_TYPE_CONCEPT_TEST, {
            question: 'Try our new design!',
            prototypesSteps: stepsService.createStepObj(
              STEP_TYPE_OPINION_SCALE,
              {
                question: 'Liked it? Please let us know!',
              }
            ),
          }),
        ],
      };
    }
    case TEMPLATE_4: {
      return {
        steps: [
          stepsService.createStepObj(STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT, {
            question: 'How to improve this feature?',
          }),
          stepsService.createStepObj(STEP_TYPE_NPS, {
            question: 'What do you think of this feature?',
          }),
        ],
      };
    }
    case TEMPLATE_5: {
      return {
        steps: [
          stepsService.createStepObj(STEP_TYPE_TEXT_BLOCK, {
            question: 'Our v2 is here!',
          }),
        ],
      };
    }
    default: {
      return {};
    }
  }
};
