import classNames from 'classnames';
import Button from 'components/Button';
import Divider from 'components/Divider';
import {
  PermissionsPoke,
  PermissionsPost,
  PermissionsSettings,
} from 'constants/permissions';
import {crispHelpers} from 'helpers';
import {hasFlag, hasFlags} from 'helpers/bitwise';
import {hasPermissions} from 'helpers/permission';
import {useEffect, useRef, useState} from 'react';
import {PopupModal} from 'react-calendly';
import ReactPlayer from 'react-player';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {
  ROUTE_GET_STARTED_INSTALL,
  ROUTE_GET_STARTED_TRY,
  ROUTE_TOURS,
} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {
  F_EXTRA_FIRST_POKE_ADOPTION,
  F_EXTRA_FIRST_POKE_DISCOVERY,
  F_EXTRA_WENT_LIVE,
} from 'services/project';
import './_styles.scss';
import BookDemo from './images/bookdemo.jpg';
import ChatWithUs from './images/chatwithus.jpg';
import HelpCenter from './images/helpcenter.jpg';
import VideoBackground from './images/welcome_background.svg';

export const GetStarted = () => {
  const history = useHistory();

  const project = useSelector((state) => generalSelector.getProject(state));

  const resourcesContentRef = useRef(null);

  const isTryDone = hasFlags(
    [F_EXTRA_FIRST_POKE_ADOPTION, F_EXTRA_FIRST_POKE_DISCOVERY],
    project.extraFlags,
    true
  );
  const isInstallDone = project.snippetInstalledAt != null;
  const wentLive = hasFlag(F_EXTRA_WENT_LIVE, project.extraFlags);

  const scroll = (scrollOffset) => {
    resourcesContentRef.current.scrollLeft += scrollOffset;
  };

  const [canScrollToRight, setCanScrollToRight] = useState(true);
  const [canScrollToLeft, setCanScrollToLeft] = useState(false);
  const [showCalendlyModal, setShowCalendlyModal] = useState(false);

  const calendlyUrl = 'https://calendly.com/thomas-747/30min';

  useEffect(() => {
    const handleScroll = () => {
      const element = resourcesContentRef.current;

      if (element) {
        const scrollableWidth = element.scrollWidth;
        const clientWidth = element.clientWidth;
        const scrollLeft = element.scrollLeft;

        // Check if you can scroll to the right
        if (scrollableWidth - clientWidth - 2 > scrollLeft) {
          setCanScrollToRight(true);
        } else {
          setCanScrollToRight(false);
        }

        // Check if you can scroll to the left
        if (scrollLeft > 0) {
          setCanScrollToLeft(true);
        } else {
          setCanScrollToLeft(false);
        }
      }
    };

    handleScroll();

    const scrollableDiv = resourcesContentRef.current;
    if (scrollableDiv) {
      scrollableDiv.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollableDiv) {
        scrollableDiv.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <div className="s-get-started">
      <div className="gs-top">Get started</div>
      <div className="gs-body">
        <div className="gs-container">
          Welcome to Jimo 👋
          <div className="video-group-wrapper">
            <img src={VideoBackground} alt="jimer banner" />
            <div className="video-wrapper">
              <div
                style={{
                  position: 'relative',
                  paddingBottom: '54.86%',
                  height: 0,
                }}>
                <iframe
                  title="install-snippet"
                  src="https://www.loom.com/embed/ffeb31b7a110472aa34a9da8f2448e61"
                  frameBorder="0"
                  allowFullScreen
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}></iframe>
              </div>
            </div>
          </div>
          <div className="gs-cards-wrapper">
            <div className="gs-card gs-card-item completed">
              <div className="section-index checked">
                <i className="icon-tick" />
              </div>
              <div className="item-timing">~2m</div>
              <div className="item-content">Complete your sign up</div>
            </div>
            {hasPermissions([
              PermissionsPoke.ACCESS,
              PermissionsPost.ACCESS,
            ]) && (
              <div
                className={classNames('gs-card gs-card-item', {
                  completed: isTryDone === true,
                })}
                onClick={() =>
                  history.push({
                    pathname: ROUTE_GET_STARTED_TRY,
                    state: {expandGetStarted: true},
                  })
                }>
                {isTryDone !== true ? (
                  <div className="section-index">2</div>
                ) : (
                  <div className="section-index checked">
                    <i className="icon-tick" />
                  </div>
                )}
                <div className="item-timing">~5m</div>
                <div className="item-content">
                  Create your first in-app experience
                </div>
                <div className="item-actions">
                  <Button cta iconOnly iconRight="icon-chevron-right"></Button>
                </div>
              </div>
            )}
            {hasPermissions(PermissionsSettings.INSTALLATION_ACCESS) && (
              <div
                className={classNames('gs-card gs-card-item', {
                  completed: isInstallDone === true,
                })}
                onClick={() =>
                  history.push({
                    pathname: ROUTE_GET_STARTED_INSTALL,
                    state: {expandGetStarted: true},
                  })
                }>
                {isInstallDone !== true ? (
                  <div className="section-index">3</div>
                ) : (
                  <div className="section-index checked">
                    <i className="icon-tick" />
                  </div>
                )}
                <div className="item-timing">~10m</div>
                <div className="item-content">Install Jimo snippet</div>
                <div className="item-actions">
                  <Button cta iconOnly iconRight="icon-chevron-right"></Button>
                </div>
              </div>
            )}
            {hasPermissions(PermissionsSettings.BILLING_ACCESS) && (
              <div
                className={classNames('gs-card-wrapper', {
                  disabled: isInstallDone !== true,
                })}>
                <div
                  className={classNames('gs-card gs-card-item', {
                    completed: wentLive === true,
                  })}
                  onClick={() => {
                    if (isInstallDone === true) {
                      history.push({
                        pathname: ROUTE_TOURS,
                        state: {expandGetStarted: true},
                      });
                    }
                  }}>
                  {wentLive !== true ? (
                    <div className="section-index">4</div>
                  ) : (
                    <div className="section-index checked">
                      <i className="icon-tick" />
                    </div>
                  )}
                  <div className="item-timing">~0m</div>
                  <div className="item-content">
                    Publish your first experience 🚀
                  </div>
                  <div className="item-actions">
                    <Button
                      cta
                      iconOnly
                      iconRight="icon-chevron-right"></Button>
                  </div>
                </div>
                {isInstallDone !== true && (
                  <div className="install-first">
                    Install Jimo first to start your trial
                  </div>
                )}
              </div>
            )}
          </div>
          <Divider />
          <div className="resources-wrapper">
            <div className="resources-header">
              <div className="resources-title">Resources</div>
              <div className="resources-navigator">
                <Button
                  disabled={canScrollToLeft === false}
                  cta
                  onClick={() => scroll(-400)}
                  iconOnly
                  iconRight="icon-chevron-left"
                />
                <Button
                  disabled={canScrollToRight === false}
                  cta
                  onClick={() => scroll(400)}
                  iconOnly
                  iconRight="icon-chevron-right"
                />
              </div>
            </div>
            <div className="resources-content" ref={resourcesContentRef}>
              <ResourceCard
                content={
                  <ReactPlayer
                    controls
                    className="resource-video"
                    url="https://youtu.be/dXdn01Ls0Zs"
                  />
                }
                title="Learn how to edit your first experience"
                subtitle="A quick video overview to get you started."
                onClick={() => {
                  setShowCalendlyModal(true);
                }}
                large
              />
              <ResourceCard
                content={
                  <img
                    src={HelpCenter}
                    alt="help-center"
                    style={{width: '100%'}}
                  />
                }
                title="Help center"
                subtitle="Quick answers, expert guidance."
                onClick={() => {
                  window.open(
                    'https://help.usejimo.com/',
                    '_blank',
                    'noopener noreferrer'
                  );
                }}
              />
              <ResourceCard
                content={
                  <img src={BookDemo} alt="book demo" style={{width: '100%'}} />
                }
                title="Book a demo with us"
                subtitle="Explain your use case and see Jimo in action."
                onClick={() => {
                  setShowCalendlyModal(true);
                }}
              />
              <ResourceCard
                content={
                  <img
                    src={ChatWithUs}
                    alt="chat with us"
                    style={{width: '100%'}}
                  />
                }
                title="Chat with us"
                subtitle="Real-time assistance."
                onClick={() => {
                  crispHelpers.startCrispThread(`I need help using Jimo`);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <PopupModal
        url={calendlyUrl}
        onModalClose={() => setShowCalendlyModal(false)}
        open={showCalendlyModal}
        rootElement={document.getElementById('root')}
      />
    </div>
  );
};

const ResourceCard = ({content, title, subtitle, large, onClick}) => {
  return (
    <div className={classNames('resource-card', {large})} onClick={onClick}>
      <div className="resource-content">{content}</div>
      <div className="resource-title">{title}</div>
      <div className="resource-subtitle">{subtitle}</div>
    </div>
  );
};
