import {default as classnames, default as classNames} from 'classnames';
import Button from 'components/Button';
import LivePreviewNew from 'components/LivePreviewNew';
import {getStepData, getTypeFromBoostFlags} from 'helpers/poke';
import {object} from 'prop-types';
import {useEffect, useState} from 'react';
import {getBoostFlags} from 'scenes/Pushes/components/ModalCreatePoke';
import {evolutionService} from 'services';
import {PAGE_ACTIVE_OPERATOR_SINGLE_URL} from 'services/evolution';
import {STEP_TYPE_TEXT_BLOCK} from 'services/steps';
import {getIcon} from '../../../TemplatesModal';
import {
  TYPE_BANNER,
  TYPE_HOTSPOT,
  TYPE_MODAL,
  TYPE_SNIPPET,
  TYPE_TOOLTIP,
  TYPE_TOUR,
} from '../../../TemplatesModal/templates';
import IframePreview from '../IframePreview';
import './_Styles.scss';

const previewBaseUrl = 'https://templates.usejimo.com';

const TemplateStep = ({icon, className, title, name, preview, onClick}) => {
  return (
    <div className={classnames('template-step', className)} onClick={onClick}>
      <div className="preview">{preview}</div>
      <div className="subtitle subtitle-4">{name}</div>
      <div className="type body-4 n-700">
        {icon}
        <span>{title}</span>
      </div>
    </div>
  );
};

const propTypes = {
  template: object.isRequired,
};

const defaultProps = {};

const TemplatePreview = ({template}) => {
  const [selectedStepId, setSelectedStepId] = useState(null);
  const [selectedConceptStepId, setSelectedConceptStepId] = useState(null);
  const [showModalPreview, setShowModalPreview] = useState(false);

  let actualSteps;

  const evolution = evolutionService.getDefaultEvolution({
    boostFlags: getBoostFlags(template.type),
    title: template.name,
    steps: template.steps || [],
    icon: getIcon(template.templateType),
    style: template.style,
    tourSteps:
      template.tourSteps?.map((s) =>
        evolutionService.getDefaultEvolution({
          uid: s.uid,
          boostFlags: getBoostFlags(s.type),
          steps: s.steps,
          style: s.style,
          tourStepInfo: s.tourStepInfo,
        })
      ) || [],
    ...(template.type === TYPE_TOUR
      ? {boostedActiveOperator: PAGE_ACTIVE_OPERATOR_SINGLE_URL}
      : {}),
  });

  const {steps, tourSteps} = evolution;

  const isTour = tourSteps?.length > 0;

  if (isTour) {
    tourSteps.forEach((ts) => {
      const [tourIndexOrder] = (ts.tourStepInfo || '0;0;0').split(';');
      ts.tourIndexOrder = parseInt(tourIndexOrder, 10);
      ts.steps.forEach((s) => {
        s.boostFlags = ts.boostFlags;
      });
    });
    tourSteps.sort((a, b) => a.tourIndexOrder - b.tourIndexOrder);
    actualSteps = tourSteps
      ?.map((t) => t.steps?.sort((a, b) => a.indexOrder - b.indexOrder))
      .flat();
  } else {
    actualSteps = steps?.sort((a, b) => a.indexOrder - b.indexOrder) || [];
  }

  useEffect(() => {
    if (template != null) {
      if (isTour) {
        setSelectedStepId(tourSteps[0].steps[0].uid);
      } else {
        setSelectedStepId(steps[0]?.uid);
      }
    } else {
      setSelectedStepId(null);
    }
  }, []);

  return (
    <div
      className={classNames('template-preview-wrapper', {
        'template-steps-hidden': actualSteps.length <= 1,
      })}>
      <div className="preview-group">
        <div className="preview-group-header">
          <div className="left-group">
            <div className="btn-1" />
            <div className="btn-2" />
            <div className="btn-3" />
          </div>
          <div className="center-group body-4 n-500">
            <span>yoursite.com</span>
          </div>
          <div className="right-group">
            <Button
              tertiary
              thin
              iconRight="icon-goto"
              onClick={() => setShowModalPreview(true)}>
              Preview
            </Button>
          </div>
        </div>
        <div className="main-wrapper">
          <LivePreviewNew
            poke={evolution}
            selectedStepId={selectedStepId}
            selectedConceptStepId={selectedConceptStepId}
            isScaledDownPreview
            isTemplatePreview
          />
        </div>
      </div>
      <div className="template-steps">
        {actualSteps.map((step, index) => {
          let icon, className, title, type;

          if (isTour) {
            type = getTypeFromBoostFlags(step.boostFlags);
            icon = getIcon(type);

            if (type === TYPE_SNIPPET) {
              title = 'Snippet';
            } else if (type === TYPE_MODAL) {
              title = 'Pop-in';
            } else if (type === TYPE_BANNER) {
              title = 'Banner';
            } else if (type === TYPE_HOTSPOT) {
              title = 'Hotspot';
            } else if (type === TYPE_TOOLTIP) {
              title = 'Tooltip';
            }
          } else {
            ({icon, className, title} = getStepData(
              step.type || STEP_TYPE_TEXT_BLOCK
            ));
          }
          const {name} = step;

          return (
            <>
              <TemplateStep
                key={index}
                icon={icon}
                className={classnames(className, {
                  selected: step.uid === selectedStepId,
                })}
                title={title}
                name={name}
                preview={
                  <LivePreviewNew
                    poke={evolution}
                    selectedStepId={step.uid}
                    isScaledDownPreview
                  />
                }
                onClick={() => setSelectedStepId(step.uid)}
              />
              {index < actualSteps.length - 1 && (
                <i className="icon-chevron-right n-500" />
              )}
            </>
          );
        })}
      </div>

      {showModalPreview && (
        <IframePreview
          url={`${previewBaseUrl}?jimo_force_init=true&jimo_poke=${template.id}`}
          isOpen={showModalPreview}
          onRequestClose={() => setShowModalPreview(false)}
        />
      )}
    </div>
  );
};

TemplatePreview.propTypes = propTypes;
TemplatePreview.defaultProps = defaultProps;

export default TemplatePreview;
