import Divider from 'components/Divider';
import InputGroup from 'components/Input';
import SelectGroup from 'components/Select';
import {BuilderContext} from 'contextes/builder';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import React, {useContext, useState} from 'react';
import Section from 'scenes/PokeBuilder/components/Section';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {DropdownAddAction} from 'scenes/PokeBuilder/components/TriggerManager/components/Triggers/components/TriggerActions';
import {F_OPTION_POKE_CARD_WITH_POINTER} from 'services/evolution';
import {
  BLOCK_TYPE_HINT,
  STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW,
  STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
  STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO,
  STEP_CONDITION_ACTION_TYPE_OPEN_POST,
  STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE,
} from 'services/steps';
import {visualCues} from 'shared/front/components/Poke/components/VisualCues';
import {v4 as uuidv4} from 'uuid';
import RadioGroup from '../../../../../../components/RadioGroup';
import PopupSetting from '../../components/PopupSetting';
import ClickableInput from '../../components/items/ClickableInput';
import PixelPicker from '../../components/items/PixelPicker';
import HintPosition from '../../components/modals/HintPosition';
import IconPicker, {
  HINT_ICON_BUILT_IN,
  hintIconsList,
} from '../../components/modals/IconPicker';
import ColorItem from '../../components/sectionItems/ColorItem';
import EffectsSection from '../../components/sectionItems/EffectsSection';
import FontFamilyItem from '../../components/sectionItems/FontFamilyItem';
import FontSizeItem from '../../components/sectionItems/FontSizeItem';
import FontWeightItem from '../../components/sectionItems/FontWeightItem';
import PaddingItem from '../../components/sectionItems/PaddingItem';
import RadiusItem from '../../components/sectionItems/RadiusItem';
import ButtonAction from '../Button/components/ButtonAction';
import './_Styles.scss';
import StickerPicker from './components/StickerPicker';

export const HOTSPOT_SHAPE_DEFAULT = 'default';
export const HOTSPOT_SHAPE_QUESTION_MARK = 'question-mark';
export const HOTSPOT_SHAPE_EXCLAMATION_MARK = 'exclamation-mark';
export const HOTSPOT_ANIMATION_PULSE = 'pulse';
export const HOTSPOT_ANIMATION_NONE = 'none';

export const HINT_TYPE_ICON = 'icon';
export const HINT_TYPE_LABEL = 'label';
export const HINT_TYPE_HIDDEN = 'hidden';
export const HINT_TYPE_BUTTON = 'button';
export const HINT_TYPE_STICKER = 'sticker';

const pulsatingOptions = [
  {label: 'Yes', value: true},
  {label: 'No', value: false},
];

const pointerOptions = [
  {label: 'Show', value: true},
  {label: 'Hide', value: false},
];

export const triggerOptions = [
  {
    label: (
      <>
        <i className="icon-question-circle" /> Icon
      </>
    ),
    value: HINT_TYPE_ICON,
  },
  {
    label: (
      <>
        <i className="isax isax-tag5" /> Label
      </>
    ),
    value: HINT_TYPE_LABEL,
  },
  {
    label: (
      <>
        <i className="icon-target" /> Target Element
      </>
    ),
    value: HINT_TYPE_HIDDEN,
  },
  {
    label: (
      <>
        <i className="icon-label-icon" /> Button
      </>
    ),
    value: HINT_TYPE_BUTTON,
  },
  {
    label: (
      <>
        <i className="isax isax-sticker5" /> Sticker
      </>
    ),
    value: HINT_TYPE_STICKER,
  },
];

const Hint = () => {
  const {
    controlledEvolution: evolution,
    setControlledEvolution: setEvolution,
    selectedStep: step,
    updateBlock: uptBlock,
    isInApp,
    setIsEditingTargetElement,
  } = useContext(BuilderContext);

  const [newlyAddedAction, setNewlyAddedAction] = useState(null);

  const updateBlock = (updateObj) => {
    uptBlock(BLOCK_TYPE_HINT, updateObj);
  };

  const handleAddAction = (type) => {
    const newAction = {
      uid: uuidv4(),
      type,
    };

    updateBlock({
      actions: [...actions, newAction],
    });
    setNewlyAddedAction(newAction);
  };

  const block = step?.blocks.find((b) => b.type === BLOCK_TYPE_HINT);

  if (block == null) {
    return <></>;
  }

  const hasPointer = hasFlag(
    F_OPTION_POKE_CARD_WITH_POINTER,
    evolution.optionsFlags
  );

  const {value = '', style = {}, file, actions = []} = block;
  const isHintButton = style?.type === HINT_TYPE_BUTTON;
  const isHintSticker = style?.type === HINT_TYPE_STICKER;

  const selectedIcon = hintIconsList.find((i) => i.value === style.iconName);
  const selectedSticker = visualCues.find((i) => i.value === value);

  const hasPrimaryColor =
    isHintSticker && selectedSticker?.primaryColor != null;
  const hasSecondaryColor =
    isHintSticker && selectedSticker?.secondaryColor != null;
  const hasBorderColor = isHintSticker && selectedSticker?.borderColor != null;

  let authorizedActions = [
    STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
    STEP_CONDITION_ACTION_TYPE_OPEN_POST,
    STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO,
    STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE,
    STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW,
  ].filter((a) => actions.map((action) => action.type).includes(a) !== true);

  if (
    actions
      .map((action) => action.type)
      .some((t) =>
        [
          STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
          STEP_CONDITION_ACTION_TYPE_OPEN_POST,
          STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW,
        ].includes(t)
      ) === true
  ) {
    authorizedActions = authorizedActions.filter(
      (a) =>
        [
          STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
          STEP_CONDITION_ACTION_TYPE_OPEN_POST,
          STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW,
        ].includes(a) !== true
    );
  }
  if (
    actions
      .map((action) => action.type)
      .some((t) => [STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW].includes(t)) ===
    true
  ) {
    authorizedActions = authorizedActions.filter(
      (a) => a !== STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO
    );
  }

  return (
    <div className="block-settings hint">
      <Section title="General">
        <SectionItem title="Position" contentClassName="position-picker">
          <>
            {isInApp === true ? (
              <ClickableInput
                value={
                  evolution.querySelectorManual?.elementText != null ||
                  evolution.querySelectorManual?.cssSelector != null
                    ? 'Manual selector'
                    : evolution.boostedQueryselector || 'Select an element'
                }
                leftLabel={
                  <div className="position-icon-wrapper">
                    <i className="icon-target" />
                  </div>
                }
                onClick={() => {
                  setIsEditingTargetElement(true);
                }}
              />
            ) : (
              <PopupSetting
                large
                className="hint-position-popup"
                trigger={
                  <ClickableInput
                    value={
                      evolution.querySelectorManual?.elementText != null ||
                      evolution.querySelectorManual?.cssSelector != null
                        ? 'Manual selector'
                        : evolution.boostedQueryselector || 'Select an element'
                    }
                    leftLabel={
                      <div className="position-icon-wrapper">
                        <i className="icon-target" />
                      </div>
                    }
                  />
                }
                title="Position"
                content={
                  <HintPosition
                    evolution={evolution}
                    setEvolution={setEvolution}
                  />
                }
                invalid={!evolution.boostedQueryselector}
              />
            )}
          </>
        </SectionItem>
        <SectionItem title="Type" sectionItemClassName="change-icon-type">
          <SelectGroup
            isSearchable={false}
            classNamePrefix="type-icon-select"
            options={triggerOptions}
            value={triggerOptions.find((o) => o.value === style.type)}
            onChange={(option) => {
              updateBlock({
                style: {
                  ...style,
                  type: option.value,
                  ...(option.value === HINT_TYPE_STICKER
                    ? {
                        primaryColor: visualCues.find((v) => v.value === 'new')
                          ?.primaryColor,
                        secondaryColor: visualCues.find(
                          (v) => v.value === 'new'
                        )?.secondaryColor,
                        borderColor: visualCues.find((v) => v.value === 'new')
                          ?.borderColor,
                      }
                    : {}),
                },
                ...(option.value === HINT_TYPE_STICKER
                  ? {
                      value: 'new',
                    }
                  : {}),
              });
            }}
            menuPortalTarget={document.body}
          />
        </SectionItem>

        {isHintButton !== true && (
          <SectionItem title="Pointer">
            <RadioGroup
              value={hasPointer}
              options={pointerOptions}
              onSelect={(v) => {
                if (v === true) {
                  setEvolution({
                    ...evolution,
                    optionsFlags: addFlag(
                      F_OPTION_POKE_CARD_WITH_POINTER,
                      evolution.optionsFlags
                    ),
                  });
                } else {
                  setEvolution({
                    ...evolution,
                    optionsFlags: removeFlag(
                      F_OPTION_POKE_CARD_WITH_POINTER,
                      evolution.optionsFlags
                    ),
                  });
                }
              }}
            />
          </SectionItem>
        )}
      </Section>
      <Divider />
      {isHintButton === true && (
        <>
          <Section title="Behavior">
            <SectionItem title="Actions" contentClassName="action-picker">
              <div className="actions-wrapper">
                {actions.map((action) => {
                  return (
                    <ButtonAction
                      key={action.uid}
                      action={action}
                      setAction={(updatedAction) => {
                        updateBlock({
                          actions: actions.map((_action) =>
                            _action.uid === action.uid ? updatedAction : _action
                          ),
                        });
                      }}
                      onDelete={() => {
                        updateBlock({
                          actions: actions.filter(
                            (_action) => _action.uid !== action.uid
                          ),
                        });
                      }}
                      defaultOpen={newlyAddedAction?.uid === action.uid}
                    />
                  );
                })}
                <DropdownAddAction
                  className="add-action-element-button"
                  authorizedActions={authorizedActions}
                  usedActionTypes={actions.map((action) => action.type)}
                  onAddAction={handleAddAction}
                  position="left top"
                  offsetY={-4}
                  offsetX={8}
                  trigger={
                    <ClickableInput
                      className="add-action-element-placeholder-btn"
                      inputProps={{placeholder: 'Add action...'}}
                      leftLabel={
                        <div className="action-icon-wrapper">
                          <i className="isax isax-flash-15" />
                        </div>
                      }
                    />
                  }
                />
              </div>
            </SectionItem>
          </Section>
          <Divider />
        </>
      )}
      {isHintSticker === true && (
        <>
          <Section title="Size">
            <SectionItem title="Scale">
              <PixelPicker
                small
                value={style.scale ?? 1}
                min={0.1}
                max={4}
                onChange={(value) => {
                  updateBlock({
                    style: {...style, scale: value},
                  });
                }}
                step={0.1}
                label="x"
              />
            </SectionItem>
            <SectionItem title="Rotate">
              <PixelPicker
                small
                value={style.rotate ?? 0}
                min={0}
                max={360}
                onChange={(value) => {
                  updateBlock({
                    style: {...style, rotate: value},
                  });
                }}
                label="°"
              />
            </SectionItem>
          </Section>
          <Divider />
        </>
      )}
      {(hasPointer === true ||
        [
          HINT_TYPE_ICON,
          HINT_TYPE_LABEL,
          HINT_TYPE_BUTTON,
          HINT_TYPE_STICKER,
        ].includes(style.type)) && (
        <Section title="Style">
          {hasPointer === true && style.type !== HINT_TYPE_BUTTON && (
            <ColorItem
              title="Pointer color"
              value={
                evolution?.style?.pointerColor ||
                evolution?.style?.background?.primaryColor
              }
              onChange={(value) => {
                setEvolution({
                  ...evolution,
                  style: {
                    ...(evolution?.style || {}),
                    pointerColor: value,
                  },
                });
              }}
              colorPickerProps={{
                erasable: evolution?.style.pointerColor != null,
              }}
            />
          )}
          {style.type === HINT_TYPE_ICON && (
            <>
              <SectionItem title="Icon" sectionItemClassName="item-icon-picker">
                <PopupSetting
                  className="picker-icon-popup"
                  trigger={
                    <ClickableInput
                      value={
                        style.iconSource === HINT_ICON_BUILT_IN
                          ? selectedIcon?.label
                          : style.iconUrl != null
                          ? 'Custom Icon'
                          : null
                      }
                      leftLabel={
                        <span className="preview-icon" style={{}}>
                          {style.iconSource === HINT_ICON_BUILT_IN ? (
                            selectedIcon?.icon
                          ) : style.iconUrl ? (
                            <img src={style.iconUrl} alt="" />
                          ) : (
                            <></>
                          )}
                        </span>
                      }
                      inputProps={{
                        placeholder: 'Select an icon',
                      }}
                    />
                  }
                  title="Hint icons"
                  content={
                    <IconPicker
                      style={style}
                      file={file}
                      type="hint"
                      onChange={({style, file}) => {
                        updateBlock({
                          style: {
                            ...style,
                            ...value,
                          },
                          file,
                        });
                      }}
                    />
                  }
                />
              </SectionItem>
              <IconStyleSelection
                style={style}
                updateStyle={updateBlock}
                hasPointer={hasPointer}
              />
            </>
          )}
          {style.type === HINT_TYPE_STICKER && (
            <>
              <SectionItem
                title="Sticker"
                sectionItemClassName="item-icon-picker">
                <div className="section-item sticker-picker">
                  <StickerPicker
                    value={value}
                    onChange={({value, style: updatedStyle}) =>
                      updateBlock({
                        value,
                        style: {...style, ...updatedStyle},
                      })
                    }
                  />
                </div>
              </SectionItem>
              {hasPrimaryColor && (
                <ColorItem
                  title="Primary color"
                  value={style.primaryColor ?? null}
                  onChange={(value) =>
                    updateBlock({
                      style: {...style, primaryColor: value},
                    })
                  }
                />
              )}
              {hasSecondaryColor && (
                <ColorItem
                  title="Secondary color"
                  value={style.secondaryColor ?? null}
                  onChange={(value) =>
                    updateBlock({
                      style: {...style, secondaryColor: value},
                    })
                  }
                />
              )}
              {hasBorderColor && (
                <ColorItem
                  title="Border color"
                  value={style.borderColor ?? null}
                  onChange={(value) =>
                    updateBlock({
                      style: {...style, borderColor: value},
                    })
                  }
                />
              )}
            </>
          )}
          {[HINT_TYPE_LABEL, HINT_TYPE_BUTTON].includes(style.type) && (
            <>
              <SectionItem title="Text">
                <InputGroup
                  className="button-content-input"
                  value={value}
                  onChange={({target}) =>
                    updateBlock({
                      value: target.value,
                    })
                  }
                />
              </SectionItem>
              <LabelStyleSelection
                style={style}
                updateStyle={updateBlock}
                hasPointer={hasPointer}
              />
            </>
          )}
        </Section>
      )}
      {[HINT_TYPE_LABEL, HINT_TYPE_BUTTON, HINT_TYPE_HIDDEN].includes(
        style.type
      ) && (
        <>
          <Divider />
          <EffectsSection
            style={style}
            onChange={(newStyle) => {
              updateBlock({
                style: {
                  ...style,
                  ...newStyle,
                },
              });
            }}
            additionalEffects={
              [HINT_TYPE_LABEL, HINT_TYPE_BUTTON].includes(style.type)
                ? ['moving-border']
                : []
            }
          />
        </>
      )}
      {style.type === HINT_TYPE_STICKER && (
        <>
          <Divider />
          <Section title="Animation">
            <SectionItem title="Speed">
              <PixelPicker
                small
                value={style.speed ?? 50}
                min={0}
                max={100}
                label="%"
                onChange={(value) => {
                  updateBlock({
                    style: {...style, speed: value},
                  });
                }}
              />
            </SectionItem>
            <SectionItem title="Loop">
              <RadioGroup
                value={style.loop ?? false}
                options={[
                  {label: 'Yes', value: true},
                  {label: 'No', value: false},
                ]}
                onSelect={(value) => {
                  updateBlock({style: {...style, loop: value}});
                }}
              />
            </SectionItem>
          </Section>
        </>
      )}
    </div>
  );
};

export const IconStyleSelection = ({style, updateStyle}) => {
  return (
    <>
      {style.iconSource === HINT_ICON_BUILT_IN && (
        <ColorItem
          title="Icon color"
          value={style.iconColor}
          onChange={(value) =>
            updateStyle({
              style: {...style, iconColor: value},
            })
          }
        />
      )}
      {style.type === HINT_TYPE_ICON && (
        <SectionItem title="Pulsating" sectionItemClassName="pulsating">
          <RadioGroup
            value={style.pulsating}
            options={pulsatingOptions}
            onSelect={(value) => {
              updateStyle({
                style: {
                  ...style,
                  pulsating: value,
                },
              });
            }}
          />
        </SectionItem>
      )}
      <SectionItem title="Size">
        <PixelPicker
          value={style.fontSize}
          min={4}
          max={40}
          onChange={(value) =>
            updateStyle({
              style: {
                ...style,
                fontSize: value,
              },
            })
          }
        />
      </SectionItem>
    </>
  );
};

export const LabelStyleSelection = ({style, updateStyle}) => {
  return (
    <>
      <FontFamilyItem
        value={style.fontFamily}
        onChange={(value) =>
          updateStyle({
            style: {...style, fontFamily: value},
          })
        }
      />
      <FontSizeItem
        value={style.fontSize}
        onChange={(value) =>
          updateStyle({
            style: {...style, fontSize: value},
          })
        }
      />
      <FontWeightItem
        value={style.fontWeight}
        onChange={(value) =>
          updateStyle({
            style: {...style, fontWeight: value},
          })
        }
      />
      <PaddingItem
        value={{
          paddingTop: style.paddingTop ?? style.padding,
          paddingBottom: style.paddingBottom ?? style.padding,
          paddingLeft: style.paddingLeft ?? style.padding,
          paddingRight: style.paddingRight ?? style.padding,
        }}
        onChange={(value) =>
          updateStyle({
            style: {...style, ...value},
          })
        }
      />
      <RadiusItem
        value={style.borderRadius}
        onChange={(value) =>
          updateStyle({
            style: {
              ...style,
              borderRadius: value,
            },
          })
        }
      />
      <ColorItem
        title="Text color"
        value={style.color}
        onChange={(value) =>
          updateStyle({
            style: {...style, color: value},
          })
        }
      />
      <ColorItem
        title="Background"
        value={style.backgroundColor}
        onChange={(value) =>
          updateStyle({
            style: {...style, backgroundColor: value},
          })
        }
      />
      <ColorItem
        title="Border"
        value={style.borderColor}
        onChange={(value) =>
          updateStyle({
            style: {...style, borderColor: value},
          })
        }
        colorPickerProps={{
          erasable: true,
        }}
      />
    </>
  );
};

export default Hint;
