import {CardElement} from '@stripe/react-stripe-js';
import classnames from 'classnames';
import Button from 'components/Button';
import {DropdownMau} from 'components/DropdownMau';
import Input from 'components/Input';
import DefaultLoader from 'components/Loader';
import {Modal} from 'components/Modal';
import Toggle from 'components/Toggle';
import {GlobalContext} from 'contextes/Global';
import countries from 'country-codes-list';
import {spacedNumber} from 'helpers/number';
import {useFetchCoupon} from 'hooks/useFetchCoupon';
import {useFetchTaxes} from 'hooks/useFetchTaxes';
import {useSubscriptionCheckout} from 'hooks/useSubscriptionCheckout';
import {useContext, useState} from 'react';
import {useSelector} from 'react-redux';
import {dataSelector} from 'selectors';
import {
  ADDON_WHITE_LABEL_ID,
  displayPrice,
  getAddonMonthlyPrice,
  getAddonYearlyPrice,
  getPlanMonthlyPrice,
  getPlanYearlyPrice,
  getSubscriptionPrices,
  PLAN_GROWTH_ID,
  PLAN_SCALE_ID,
  PLAN_STARTUP_ID,
} from 'services/subscription';
import {Swaler} from 'swaler';
import './_styles.scss';
import imgLogo from './imgs/logo.svg';

const logger = new Swaler('ModalSubscriptionCheckout');
const cardOptions = {
  hidePostalCode: true,
  showIcon: true,
  style: {
    base: {
      fontSize: '16px',
      color: '#424770',
      letterSpacing: '0.025em',
      fontFamily: 'Source Code Pro, monospace',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

export const ModalSubscriptionCheckout = () => {
  const {modalSubscriptionCheckout, setModalSubscriptionCheckout} =
    useContext(GlobalContext);
  const {
    planId,
    mau: defaultMau,
    modeAnnualBilling = false,
    onCancel = () => {},
    onSuccess = () => {},
  } = modalSubscriptionCheckout;

  const plans = useSelector((state) =>
    dataSelector.getSubscriptionsPlans(state)
  );
  const addons = useSelector((state) =>
    dataSelector.getSubscriptionsAddons(state)
  );
  const addonWhiteLabel = addons.find(
    (addon) => addon.uid === ADDON_WHITE_LABEL_ID
  );

  const [mau, setMau] = useState(defaultMau);
  const [cardEmpty, setCardEmpty] = useState(false);
  const [inputCardholderName, setInputCardholderName] = useState('');
  const [inputVAT, setInputVAT] = useState('');
  const [inputZip, setInputZip] = useState('');
  const [inputCountry, setInputCountry] = useState('');
  const [inputCoupon, setInputCoupon] = useState(null);
  const [addonsEnabled, setAddonsEnabled] = useState(
    [PLAN_GROWTH_ID, PLAN_SCALE_ID].includes(planId) ? [addonWhiteLabel] : []
  );
  const [showExitCheckoutModal, setShowExitCheckoutModal] = useState(false);

  const handleCheckoutRequestClose = () => {
    if (
      inputCardholderName.length > 0 ||
      inputVAT.length > 0 ||
      inputZip.length > 0 ||
      inputCountry.length > 0 ||
      coupon != null
    ) {
      setShowExitCheckoutModal(true);
    } else {
      handleRequestClose();
    }
  };
  const handleRequestClose = () => {
    onCancel();
    setModalSubscriptionCheckout(null);
  };
  const handleSuccess = () => {
    onSuccess();
    setModalSubscriptionCheckout(null);
  };

  const {
    coupon,
    fetch: fetchCoupon,
    reset: resetCoupon,
    isFetching: isFetchingCoupon,
  } = useFetchCoupon();
  const {
    taxes,
    fetch: fetchTaxes,
    isFetching: isFetchingTaxes,
  } = useFetchTaxes();
  const {checkout, isWorking} = useSubscriptionCheckout({
    onSuccess: handleSuccess,
  });

  const plan = plans.find((p) => p.uid === planId);
  const {planPrice, mauPrice, couponReduction, subTotal, total, amountSaved} =
    getSubscriptionPrices({
      plan,
      mau,
      addons: addonsEnabled,
      modeAnnualBilling,
      coupon,
      taxes,
    });
  const addonWhiteLabelEnabled =
    addonsEnabled.find((addon) => addon.uid === ADDON_WHITE_LABEL_ID) != null;

  return (
    <>
      <Modal
        animationOnOpen=""
        isOpen={true}
        className="modal-subscription-checkout"
        overlayClassName="modal-subscription-checkout-overlay"
        onRequestClose={handleCheckoutRequestClose}>
        <Button
          className="btn-modal-close"
          iconOnly
          thin
          onClick={handleCheckoutRequestClose}>
          <i className="icon-close"></i>
        </Button>
        <div className="left-side-wrapper">
          <div className="left-side">
            <img src={imgLogo} alt="Logo Jimo" width={68} />
            {/* Billing Interval */}
            <section>
              <div className="body-3">Billing Interval</div>
              <div className="interval-wrapper">
                <div
                  className={classnames('item-interval', {
                    active: modeAnnualBilling === false,
                  })}
                  onClick={() => {
                    setModalSubscriptionCheckout({
                      ...modalSubscriptionCheckout,
                      modeAnnualBilling: false,
                    });
                  }}>
                  <div className="interval-header">
                    <div className="subtitle-3">Monthly</div>
                    {modeAnnualBilling === false && (
                      <i className="isax isax-tick-circle5 b-400"></i>
                    )}
                  </div>
                  <div className="body-3 n-700">
                    {displayPrice(getPlanMonthlyPrice(plan))}/month
                  </div>
                </div>
                <div
                  className={classnames('item-interval', {
                    active: modeAnnualBilling === true,
                  })}
                  onClick={() => {
                    setModalSubscriptionCheckout({
                      ...modalSubscriptionCheckout,
                      modeAnnualBilling: true,
                    });
                  }}>
                  <div className="interval-header">
                    <div className="subtitle-3">Yearly</div>
                    {modeAnnualBilling === true && (
                      <i className="isax isax-tick-circle5 b-400"></i>
                    )}
                  </div>
                  <div className="interval-body">
                    <div className="body-3 n-700">
                      {displayPrice(Math.round(getPlanYearlyPrice(plan) / 12))}
                      /month
                    </div>
                    <div className="yearly-discount body-4">Save 16%</div>
                  </div>
                </div>
              </div>
            </section>
            {/* Billing information */}
            <section className="section-billing-info">
              <div className="body-3">Billing Information</div>

              {/* Cardholder name */}
              <Input
                className="input-cardholder-name"
                placeholder="Cardholder name"
                value={inputCardholderName}
                onChange={({target}) => setInputCardholderName(target.value)}
              />

              {/* Country & ZIP */}
              <div className="inputs-address-lines-wrapper">
                <div className="element-select-wrapper">
                  <select
                    className={classnames('element-country', {
                      'is-empty': inputCountry.length === 0,
                    })}
                    onChange={({target}) => {
                      setInputCountry(target.value);
                      fetchTaxes({
                        countryCode: target.value,
                        zip: inputZip,
                        plan: plan.uid,
                        modeAnnualBilling,
                      });
                    }}>
                    <option value="" selected disabled>
                      Country
                    </option>
                    {countries
                      .all()
                      .sort((a, b) =>
                        a.countryNameEn.localeCompare(b.countryNameEn)
                      )
                      .map((country) => (
                        <option value={country.countryCode}>
                          {country.countryNameEn}
                        </option>
                      ))}
                  </select>
                </div>
                <Input
                  className="element-zip"
                  placeholder="Postal Code"
                  value={inputZip}
                  onChange={({target}) => {
                    setInputZip(target.value);
                  }}
                  onBlur={({target}) => {
                    fetchTaxes({
                      countryCode: inputCountry,
                      zip: target.value,
                      plan: plan.uid,
                      modeAnnualBilling,
                    });
                  }}
                />
              </div>

              {/* VAT */}
              <Input
                className="element-vat"
                value={inputVAT}
                placeholder="VAT / GST Number (optional)"
                onChange={({target}) => setInputVAT(target.value)}></Input>
            </section>
            {/* Payment information */}
            <section>
              <div className="body-3">Payment Information</div>
              <CardElement
                className="input-card-element"
                options={cardOptions}
                onChange={({empty}) => setCardEmpty(empty)}></CardElement>
            </section>

            {/* Upgrade button */}
            <Button
              className="btn-upgrade"
              primary
              disabled={
                cardEmpty ||
                inputZip.length === 0 ||
                inputCountry.length === 0 ||
                isFetchingCoupon ||
                isFetchingTaxes
              }
              loading={isWorking}
              onClick={() =>
                checkout({
                  cardEmpty,
                  zip: inputZip,
                  countryCode: inputCountry,
                  cardholderName: inputCardholderName,
                  vat: inputVAT,
                  plan,
                  mau,
                  addons: addonsEnabled,
                  modeAnnualBilling,
                  coupon,
                  CardElement,
                })
              }>
              Upgrade to {plan.title}
            </Button>

            {/* Saved */}
            {amountSaved > 0 && (
              <div className="amount-saved body-3 n-700">
                You've just saved {displayPrice(amountSaved)}! 🎉
              </div>
            )}
          </div>
        </div>

        <div className="right-side-wrapper">
          <div className="right-side">
            {/* Order summary */}
            <div className="title-4">Order Summary</div>
            <div className="card-order-summary">
              {/* Plan */}
              <div className="summary-plan">
                <div className="plan-interval-wrapper">
                  <div
                    className={classnames('subtitle-3', {
                      'g-500': planId === PLAN_STARTUP_ID,
                      'o-500': planId === PLAN_GROWTH_ID,
                      'b-500': planId === PLAN_SCALE_ID,
                    })}>
                    {plan.title} plan
                  </div>
                  <div className="plan-period-mau body-3 n-600">
                    {modeAnnualBilling === true
                      ? 'Billed yearly'
                      : 'Billed monthly'}{' '}
                    &middot;{' '}
                    <DropdownMau
                      trigger={
                        <span>
                          {spacedNumber(mau)} MAU
                          <i className="icon-chevron-bottom"></i>
                        </span>
                      }
                      onSelect={(mau) => setMau(mau)}
                    />
                  </div>
                </div>
                <div className="subtitle-3">
                  {modeAnnualBilling === true
                    ? `${displayPrice(planPrice + mauPrice)} / year`
                    : `${displayPrice(planPrice + mauPrice)} / month`}
                </div>
              </div>

              {/* Addons */}
              {addonWhiteLabelEnabled === true && (
                <div className="summary-addons">
                  <div className="item-addon">
                    <div className="addon-interval-wrapper">
                      <div className="subtitle-3">+ Hide Jimo Label</div>
                      <div className="body-3 n-700">
                        {[PLAN_GROWTH_ID, PLAN_SCALE_ID].includes(plan.uid) ? (
                          'Included in your plan'
                        ) : (
                          <>
                            Billed{' '}
                            {modeAnnualBilling === true ? 'yearly' : 'monthly'}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="subtitle-3">
                      {[PLAN_GROWTH_ID, PLAN_SCALE_ID].includes(plan.uid) ? (
                        'Free'
                      ) : (
                        <>
                          {modeAnnualBilling === true
                            ? `${displayPrice(
                                getAddonYearlyPrice(addonWhiteLabel)
                              )} / year`
                            : `${displayPrice(
                                getAddonMonthlyPrice(addonWhiteLabel)
                              )} / month`}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {/* Coupon  */}
              <div className="summary-coupon">
                {inputCoupon == null ? (
                  <Button
                    tertiary
                    iconLeft="isax isax-ticket-discount"
                    thin
                    onClick={() => setInputCoupon('')}>
                    I have a coupon
                  </Button>
                ) : coupon == null ? (
                  <div className="input-button-wrapper">
                    <Input
                      danger={coupon === undefined}
                      helper={
                        coupon === undefined
                          ? 'Sorry, the coupon code is either invalid or expired.'
                          : undefined
                      }
                      iconLeft="isax isax-ticket-discount"
                      placeholder="Enter coupon code"
                      value={inputCoupon}
                      onChange={({target}) => {
                        resetCoupon();
                        setInputCoupon(target.value);
                      }}
                    />
                    <Button
                      onClick={() => fetchCoupon(inputCoupon)}
                      loading={isFetchingCoupon}>
                      Apply
                    </Button>
                  </div>
                ) : (
                  <>
                    <div className="coupon-interval-wrapper">
                      <div className="subtitle-3">Coupon code</div>
                      <div className="body-3 n-700">
                        {coupon.code}, {coupon.stripeData.percent_off}%
                        {coupon.stripeData.duration !== 'forever' ? (
                          <>
                            {' '}
                            will be applied for the next{' '}
                            {coupon.stripeData.duration_in_months} month(s)
                          </>
                        ) : (
                          ' forever'
                        )}
                      </div>
                    </div>
                    <div className="subtitle-3 g-500">
                      -{displayPrice(couponReduction)}
                    </div>
                  </>
                )}
              </div>
              <div className="summary-divider"></div>

              {/* Taxes */}
              <div className="summary-tax">
                <div className="tax-interval-wrapper">
                  <div className="subtitle-3">Tax</div>
                  {taxes.length > 0 && (
                    <div className="body-3 n-700">
                      {taxes[0].displayName} {taxes[0].percentage}%
                    </div>
                  )}
                </div>
                <div className="subtitle-3">
                  {taxes.length > 0 ? (
                    <>
                      {displayPrice(
                        Math.round(subTotal * (taxes[0].percentage / 100))
                      )}
                    </>
                  ) : isFetchingTaxes === true ? (
                    <DefaultLoader width="10px" />
                  ) : (
                    '-'
                  )}
                </div>
              </div>
              <div className="summary-divider"></div>

              {/* Total */}
              <div className="summary-total">
                <div className="subtitle-3">Total for today</div>
                <div className="subtitle-2">{displayPrice(total)}</div>
              </div>
            </div>

            {/* Recommended Add-ons */}
            {planId !== PLAN_SCALE_ID && (
              <>
                <div className="subtitle-4">Recommended Add-ons</div>
                <div
                  className={classnames('card-addon', {
                    included: [PLAN_GROWTH_ID, PLAN_SCALE_ID].includes(planId),
                  })}
                  onClick={() => {
                    if (
                      [PLAN_GROWTH_ID, PLAN_SCALE_ID].includes(planId) === false
                    )
                      setAddonsEnabled(
                        addonWhiteLabelEnabled === true
                          ? addonsEnabled.filter(
                              (addon) => addon.uid !== ADDON_WHITE_LABEL_ID
                            )
                          : addonsEnabled.concat(addonWhiteLabel)
                      );
                  }}>
                  <div className="addon-gradient"></div>
                  <div className="text-wrapper">
                    {[PLAN_GROWTH_ID, PLAN_SCALE_ID].includes(planId) && (
                      <div className="addon-included">
                        Included in your plan
                      </div>
                    )}
                    <div className="subtitle-3">
                      Hide Jimo Label for +
                      {displayPrice(getAddonMonthlyPrice(addonWhiteLabel))} /
                      month
                    </div>
                    <div className="body-3 n-700">
                      Hide Jimo Label to make sure your brand won't be
                      associated with Jimo
                    </div>
                  </div>
                  <div className="action-wrapper">
                    <Toggle
                      checked={addonWhiteLabelEnabled}
                      disabled={[PLAN_GROWTH_ID, PLAN_SCALE_ID].includes(
                        planId
                      )}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={showExitCheckoutModal}
        className="modal-exit-checkout"
        animationOnOpen=""
        onRequestClose={() => setShowExitCheckoutModal(false)}>
        <div className="title-3">Cancel Checkout?</div>
        <div className="modal-description body-2 n-500">
          Returning now will cancel your current plan checkout. Are you sure you
          want to go back?
        </div>
        <div className="actions-wrapper">
          <Button onClick={() => handleRequestClose(false)}>
            Cancel checkout
          </Button>
          <Button primary onClick={() => setShowExitCheckoutModal()}>
            Stay on page
          </Button>
        </div>
      </Modal>
    </>
  );
};
