import classnames from 'classnames';
import commaNumber from 'comma-number';
import Button from 'components/Button';
import DefaultLoader from 'components/Loader';
import Toggle from 'components/Toggle';
import {hasFlag} from 'helpers/bitwise';
import {useFetchSubscriptionPlansAndAddons} from 'hooks/useFetchSubscriptionPlansAndAddons';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {SettingsBody} from 'scenes/Settings/components/Body';
import {dataSelector, generalSelector} from 'selectors';
import {isEligibleToTrial, isPaying, isTrying} from 'services/project';
import {
  F_MODE_ANNUAL_BILLING,
  PLAN_BASIC_ID,
  PLAN_GROWTH_ID,
  PLAN_STARTUP_ID,
} from 'services/subscription';
import {Swaler} from 'swaler';
import './_styles.scss';
import {BannerTrial} from './components/BannerTrial';
import {ItemPlan} from './components/ItemPlan';

const logger = new Swaler('SettingsPlan');

export default function SettingsPlan() {
  useFetchSubscriptionPlansAndAddons();

  const plans = useSelector((state) => dataSelector.getSubscriptionsPlans());
  const subscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );

  const [modeAnnualBilling, setModeAnnualBilling] = useState(
    isPaying() === false
      ? true
      : hasFlag(F_MODE_ANNUAL_BILLING, subscription.extraFlags)
  );
  const [mau, setMau] = useState(
    subscription.extraJimers > 0 ? subscription.extraJimers : 2500
  );

  const preventPeriodChanges =
    isPaying() &&
    hasFlag(F_MODE_ANNUAL_BILLING, subscription.extraFlags) !==
      modeAnnualBilling;
  const canStartTrial =
    isEligibleToTrial(PLAN_STARTUP_ID) === true &&
    isEligibleToTrial(PLAN_GROWTH_ID) === true;

  return (
    <SettingsBody
      className={classnames('s-settings-plan', {'extra-p-b': canStartTrial})}>
      <div className="settings-header">
        <div className="title-3">Plan</div>
        <a className="subtitle-3" href="https://usejimo.com/pricing">
          <span>Compare Plans</span> <i className="isax isax-export-3"></i>
        </a>
      </div>
      {canStartTrial && <BannerTrial />}
      <div className="plan-options">
        <div
          className="plan-period"
          onClick={() => setModeAnnualBilling(!modeAnnualBilling)}>
          <div
            className={classnames('body-3', {
              'n-600': modeAnnualBilling === true,
            })}>
            Monthly
          </div>
          <Toggle checked={modeAnnualBilling} />
          <div
            className={classnames('body-3', {
              'n-600': modeAnnualBilling === false,
            })}>
            Yearly (Save 16%)
          </div>
        </div>
        {(subscription.plan === PLAN_BASIC_ID || isTrying() === true) && (
          <>
            <div className="vertical-divider"></div>
            <div className="plan-mau-wrapper">
              <div className="plan-mau">
                <Button
                  disabled={mau === 2500}
                  className="btn-del"
                  onClick={() =>
                    setMau(
                      mau === 5000
                        ? 2500
                        : mau === 10000
                        ? 5000
                        : mau === 20000
                        ? 10000
                        : mau === 50000
                        ? 20000
                        : 50000
                    )
                  }>
                  <i className="isax isax-minus-cirlce5"></i>
                </Button>
                <div className="mau-value n-400">
                  <span className="n-800">
                    Up to {mau > 50000 ? `>50,000` : commaNumber(mau)}
                  </span>{' '}
                  MAU
                  <i className="isax isax-info-circle5"></i>
                </div>
                <Button
                  className="btn-add"
                  disabled={mau > 50000}
                  onClick={() =>
                    setMau(
                      mau === 2500
                        ? 5000
                        : mau === 5000
                        ? 10000
                        : mau === 10000
                        ? 20000
                        : mau === 20000
                        ? 50000
                        : 100000
                    )
                  }>
                  <i className="isax isax-add-circle5"></i>
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
      <div className={classnames('plan-list', {loading: plans.length === 0})}>
        {plans.length === 0 ? (
          <DefaultLoader width="24px" />
        ) : (
          plans
            .filter((p) => p.uid !== PLAN_BASIC_ID)
            .map((plan) => (
              <ItemPlan
                plan={plan}
                mau={mau}
                modeAnnualBilling={modeAnnualBilling}
                preventPeriodChanges={preventPeriodChanges}
              />
            ))
        )}
      </div>
    </SettingsBody>
  );
}
