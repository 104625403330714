import classnames from 'classnames';
import {BuilderContext} from 'contextes/builder';
import {hasFlag, hasFlags} from 'helpers/bitwise';
import {useFileUpload} from 'hooks/useFileUpload';
import {useContext, useEffect, useRef, useState} from 'react';
import TargetNotSelectedWarning from 'scenes/EmbeddedPokeBuilder/components/TargetNotSelectedWarning';
import {InAppBuilderContext} from 'scenes/Pushes/context';
import {evolutionService} from 'services';
import {
  EVOLUTION_TYPE_CHECKLIST,
  EVOLUTION_TYPE_HINT,
  EVOLUTION_TYPE_SURVEY,
  EVOLUTION_TYPE_TOUR,
  F_BOOST_SLOT_CURSOR,
} from 'services/evolution';
import {F_STEP_IS_SELECTING_PRESET} from 'services/steps';
import {
  F_SLOT_CURSOR,
  F_SLOT_DOT,
  F_SLOT_HINT,
  F_SLOT_TOOLTIP,
  F_SLOT_TOP_BAR,
} from 'shared/front/components/Poke/constants/poke';
import {Swaler} from 'swaler';
import './_Styles.scss';
import BlockEditor from './components/BlockEditor';
import ChecklistRender from './components/ChecklistRender';
import LightweightPokeBuilder from './components/LightweightPokeBuilder';
import LiveEditWarningModal from './components/LiveEditWarningModal';
import LogicView from './components/LogicView';
import PokeBuilderSidebar, {
  MODE_NAVIGATOR,
  MODE_TRIGGERS,
} from './components/PokeBuilderSidebar';
import {PokeRender} from './components/PokeRender';
import PreviewSelector from './components/PreviewSelector';
import {isLogicViewCompatible} from './utils';

const logger = new Swaler('PokeBuilder');

const PokeBuilder = () => {
  const {upload} = useFileUpload({logger});

  const {goToEditInApp, stopInAppEditing} = useContext(InAppBuilderContext);
  const {
    selectedStepId,
    controlledEvolution: poke,
    evolution: parentEvolution,
    setEvolution: setParentEvolution,
    setOriginalEvolution,
    isInApp,
    messenger,
    setLastAutoSaveAt,
    selectedStep,
    mode,
    isSelectingPreview,
    setIsInInteractivePreview,
    savedDraft,
    setSavedDraft,
  } = useContext(BuilderContext);

  const isTour = parentEvolution?.type === EVOLUTION_TYPE_TOUR;
  const isHint = parentEvolution?.type === EVOLUTION_TYPE_HINT;
  const isSurvey = parentEvolution?.type === EVOLUTION_TYPE_SURVEY;
  const isChecklist = parentEvolution?.type === EVOLUTION_TYPE_CHECKLIST;

  const isCompatibleWithLogicView = isLogicViewCompatible(parentEvolution);

  const isLogicView =
    (isTour || isSurvey) && isCompatibleWithLogicView === true;

  const [showLiveEditWarningModal, setShowLiveEditWarningModal] =
    useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const evolutionRef = useRef(parentEvolution);
  const savedDraftRef = useRef();
  const hasSubmittedRef = useRef();
  const autoSaving = useRef(false);

  const hasSubmittedRefCurrent = hasSubmittedRef.current;
  const evolutionRefCurrent = evolutionRef.current;

  useEffect(() => {
    evolutionRef.current = parentEvolution;
  }, [parentEvolution]);

  useEffect(() => {
    savedDraftRef.current = savedDraft;
  }, [savedDraft]);

  useEffect(() => {
    const hasSeenLiveEditWarning = localStorage.getItem(
      'hasSeenLiveEditWarning'
    );

    if (
      parentEvolution?.isDraft !== true &&
      isHint !== true &&
      hasSeenLiveEditWarning !== 'true'
    ) {
      setShowLiveEditWarningModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Poke autosave
  useEffect(() => {
    const interval = setInterval(() => {
      if (
        evolutionRefCurrent?.isDraft === true &&
        autoSaving.current === false
      ) {
        autoSaving.current = true;
        saveDraft().then(() => {
          autoSaving.current = false;
        });
      }
    }, 1000);

    return () => {
      clearInterval(interval);
      if (
        hasSubmittedRefCurrent === false &&
        evolutionRefCurrent?.isDraft === true &&
        autoSaving.current === false
      ) {
        saveDraft();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveDraft = async () => {
    // check that draft needs to be saved
    if (
      JSON.stringify(evolutionRef.current) ===
      JSON.stringify(savedDraftRef.current)
    ) {
      return;
    }

    // Hold the current evolution at the time of saving to make sure a new save is trigger if the evolution is updated during the save process
    const evolutionAtUpdate = evolutionRef.current;

    try {
      await evolutionService.updateEvolutionContent(evolutionRef.current.uid, {
        ...evolutionRef.current,
      });

      setLastAutoSaveAt(new Date().getTime());
      setSavedDraft(evolutionAtUpdate);
      setOriginalEvolution({...evolutionAtUpdate});
    } catch (err) {
      logger.error('Update evolution draft failed with error ', err);
    }
  };

  const uploadFile = async (file) => {
    if (file == null) {
      return;
    }
    const response = await fetch(file);
    const blob = await response.blob();
    const uploadableFile = new File([blob], 'upload.png', {type: 'image/png'});
    const uploadedFile = await upload({file: uploadableFile});

    return uploadedFile;
  };

  const handleElementSelected = async (data) => {
    setParentEvolution({
      ...evolutionRef.current,
      tourSteps: evolutionRef.current.tourSteps.map((tourStep) => {
        if (tourStep.uid === evolutionRef.current?.uid) {
          return {
            ...tourStep,
            ...data.evolution,
            isUploading: true,
          };
        }
        return tourStep;
      }),
    });

    setIsUploading(true);
    const file = await uploadFile(data.image);
    setIsUploading(false);

    setParentEvolution({
      ...evolutionRef.current,
      ...(parentEvolution.uid === poke.uid
        ? {
            ...data.evolution,
            isUploading: false,
            ...(file != null
              ? {
                  file,
                }
              : {
                  file: null,
                }),
          }
        : {
            tourSteps: evolutionRef.current.tourSteps.map((tourStep) => {
              if (tourStep.uid === poke.uid) {
                return {
                  ...tourStep,
                  ...data.evolution,
                  isUploading: false,
                  ...(file != null
                    ? {
                        file,
                      }
                    : {
                        file: null,
                      }),
                };
              }
              return tourStep;
            }),
          }),
    });
  };

  const handleGoToEditInApp = () => {
    goToEditInApp({
      data: {
        title: 'Select in-app element',
      },
      handshakeData: {
        type: 'SET_MODE',
        data: {
          mode: 'ELEMENT_SELECTOR_BUILDER',
          evolution: parentEvolution,
          stepId: selectedStepId,
        },
      },
      onChildCommunication: (message) => {
        if (message?.type === 'TARGET_ELEMENT_SELECTED') {
          handleElementSelected(message.data);
          stopInAppEditing();
        }
        if (message?.type === 'EMBEDDED_BUILDER_CLOSE') {
          stopInAppEditing();
        }
      },
    });
  };

  const isTargetNotSelected =
    hasFlags(
      [F_SLOT_DOT, F_SLOT_TOOLTIP, F_SLOT_HINT, F_BOOST_SLOT_CURSOR],
      poke.boostFlags,
      true
    ) &&
    !poke.boostedQueryselector &&
    !poke.querySelectorManual?.elementText &&
    !poke.querySelectorManual?.cssSelector;
  const isSelectingPreset = hasFlag(
    F_STEP_IS_SELECTING_PRESET,
    selectedStep?.stepFlags
  );

  if (isInApp === true) {
    if (isSelectingPreview === true) {
      return (
        <div className="preview-selector-wrapper">
          <div className="preview-selector-overlay" />
          <PreviewSelector
            evolution={parentEvolution}
            onStartPreview={() => {
              messenger.sendStartPreview();
              setIsInInteractivePreview(true);
            }}
            onStartFromOrigin={() => {
              messenger.sendNavigateTo({
                url: parentEvolution.boostedActiveUrl,
                previewAfterNavigation: true,
              });
              setIsInInteractivePreview(true);
            }}
            onNavigateToPage={() => {
              messenger.sendSwitchToNavigatorMode(true);
              setIsInInteractivePreview(true);
            }}
          />
        </div>
      );
    }

    return <LightweightPokeBuilder />;
  }

  return (
    <div
      className={classnames('poke-builder', {
        'grid-a-b-a': isLogicView !== true,
        'is-logic-view': isLogicView,
        'mode-triggers': mode === MODE_TRIGGERS,
      })}>
      {/* LEFT SIDE */}

      <div
        className={classnames('poke-builder-left-wrapper', {
          'no-mode': mode == null,
        })}>
        <PokeBuilderSidebar />
      </div>

      {/* CENTER */}
      {isLogicView || selectedStepId != null ? (
        <div
          className={classnames('poke-render-wrapper', {
            'poke-is-banner': hasFlag(F_SLOT_TOP_BAR, poke.boostFlags),
          })}>
          {isLogicView ? (
            <LogicView />
          ) : isChecklist ? (
            <ChecklistRender />
          ) : (
            <PokeRender />
          )}
        </div>
      ) : (
        <div />
      )}

      {/* RIGHT SIDE */}
      <div
        className={classnames('poke-builder-right-wrapper', {
          'show-in-right': (isTour || isSurvey) && mode === MODE_NAVIGATOR,
          'hide-in-right': (isTour || isSurvey) && mode !== MODE_NAVIGATOR,
        })}>
        {isSelectingPreset ? null : <BlockEditor />}
      </div>

      {/* OTHERS */}
      {isTargetNotSelected && isSelectingPreset !== true && (
        <TargetNotSelectedWarning
          type={
            poke.boostFlags === F_SLOT_DOT
              ? 'hotspot'
              : poke.boostFlags === F_SLOT_TOOLTIP
              ? 'tooltip'
              : poke.boostFlags === F_SLOT_HINT
              ? 'hint'
              : poke.boostFlags === F_SLOT_CURSOR
              ? 'cursor'
              : 'element'
          }
          onSelectElementClick={() => {
            if (isInApp) {
              messenger.sendSelectTargetElement();
            } else {
              handleGoToEditInApp();
            }
          }}
        />
      )}

      {showLiveEditWarningModal && (
        <LiveEditWarningModal
          isOpen={showLiveEditWarningModal}
          onRequestClose={() => {
            setShowLiveEditWarningModal(false);
            localStorage.setItem('hasSeenLiveEditWarning', true);
          }}
        />
      )}
    </div>
  );
};

export default PokeBuilder;
