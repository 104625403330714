import classNames from 'classnames';
import {stringToColour} from 'components/Charts/utils';
import {object} from 'prop-types';
import React from 'react';
import {HubspotIcon} from 'scenes/Settings/scenes/Integrations/Hubspot/components/HubspotSettings/components/SettingsSetup';
import SegmentioIcon from '../../../../../../components/SegmentAttributesEditor/icons/segmentio-icon-dark.svg';
import {shadeColor} from '../../utils';
import './_Styles.scss';
import {flattenAttributes} from './utils';

const getAttributeTypeIcon = (type) => {
  if (type === 'jimo') {
    return <i className="icon-jimo" />;
  } else if (type === 'segmentio') {
    return (
      <img
        className="icon-segmentio"
        src={SegmentioIcon}
        alt="segmentio-icon"
      />
    );
  } else if (type === 'hubspot') {
    return <HubspotIcon />;
  }
  return null;
};

const propTypes = {
  jimer: object,
};

const Attributes = ({jimer = {}}) => {
  const {attributes = [], segments = []} = jimer;

  const flattenedAttributes = React.useMemo(
    () => flattenAttributes(attributes),
    [attributes]
  );

  const email =
    jimer?.personalEmail != null ? jimer.personalEmail : jimer.externalEmail;

  return (
    <div className="jimer-attributes">
      <a
        href="https://help.usejimo.com/help-center/v/for-developers/for-developers/sdk-guides"
        target="_blank"
        rel="noopener noreferrer">
        <div className="learn-section">
          Learn how to send more data to Jimo
          <i className="icon-arrow-right" />
        </div>
      </a>

      <div className="section">
        <div className="section-title">Segments</div>
        <div className="jimer-segments-list">
          {segments
            .filter((s) => s.temporary !== true)
            .map((segment) => (
              <span
                key={segment.uid}
                className="segment"
                style={{
                  backgroundColor: `${shadeColor(
                    stringToColour(segment.uid),
                    50
                  )}33`,
                  color: shadeColor(stringToColour(segment.uid), -50),
                }}>
                {segment.name}
              </span>
            ))}
        </div>
      </div>
      <div className="section">
        <div className="section-title">Attributes</div>
        <div className="jimer-attributes-list">
          {(jimer.identifyToken || jimer.externalUid) && (
            <div className="jimer-attribute">
              <div className="jimer-attribute-name">Jimer ID :</div>
              <div className="jimer-attribute-value">
                {jimer.identifyToken || jimer.externalUid}
              </div>
            </div>
          )}
          {email && (
            <div className="jimer-attribute">
              <div className="jimer-attribute-name">Email :</div>
              <div className="jimer-attribute-value">{email}</div>
            </div>
          )}

          {flattenedAttributes.map((attribute, index) => {
            const type = attribute._attributeType;
            const icon = getAttributeTypeIcon(type);

            let displayValue;
            if (
              typeof attribute.value === 'object' &&
              attribute.value !== null &&
              !Array.isArray(attribute.value)
            ) {
              displayValue = JSON.stringify(attribute.value);
            } else if (Array.isArray(attribute.value)) {
              displayValue = JSON.stringify(attribute.value);
            } else {
              displayValue = attribute.value;
            }

            return (
              <div
                className="jimer-attribute"
                key={`${attribute.name}-${index}`}>
                <div className="jimer-attribute-name">{attribute.name} :</div>
                <div className="jimer-attribute-value">{displayValue}</div>
                <div className={classNames('jimer-attribute-icon', type)}>
                  {icon}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

Attributes.propTypes = propTypes;

export default Attributes;
