import classNames from 'classnames';
import {BuilderContext} from 'contextes/builder';
import {validateAction} from 'helpers/step';
import {bool, func, object} from 'prop-types';
import {useContext, useState} from 'react';
import PopupSetting from 'scenes/PokeBuilder/components/BlockEditor/components/PopupSetting';
import ClickableInput from 'scenes/PokeBuilder/components/BlockEditor/components/items/ClickableInput';
import {MODE_TRIGGERS} from 'scenes/PokeBuilder/components/PokeBuilderSidebar';
import SettingsBookInterview from 'scenes/PokeBuilder/components/TriggerManager/components/Triggers/components/EditActionDropdown/components/SettingsBookInterview';
import SettingsGoToStep from 'scenes/PokeBuilder/components/TriggerManager/components/Triggers/components/EditActionDropdown/components/SettingsGoToStep';
import SettingsLaunchExperience from 'scenes/PokeBuilder/components/TriggerManager/components/Triggers/components/EditActionDropdown/components/SettingsLaunchExperience';
import SettingsNavigateTo from 'scenes/PokeBuilder/components/TriggerManager/components/Triggers/components/EditActionDropdown/components/SettingsNavigateTo';
import SettingsOpenPost from 'scenes/PokeBuilder/components/TriggerManager/components/Triggers/components/EditActionDropdown/components/SettingsOpenPost';
import SettingsRunJSCode from 'scenes/PokeBuilder/components/TriggerManager/components/Triggers/components/EditActionDropdown/components/SettingsRunJSCode';
import SettingsSnooze from 'scenes/PokeBuilder/components/TriggerManager/components/Triggers/components/EditActionDropdown/components/SettingsSnooze';
import {
  EVOLUTION_TYPE_BANNER,
  EVOLUTION_TYPE_CHECKLIST,
  EVOLUTION_TYPE_HINT,
  EVOLUTION_TYPE_SURVEY,
  EVOLUTION_TYPE_TOUR,
} from 'services/evolution';
import {
  BLOCK_TYPE_PRIMARY_CTA,
  BLOCK_TYPE_SECONDARY_CTA,
  STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW,
  STEP_CONDITION_ACTION_TYPE_DISMISS,
  STEP_CONDITION_ACTION_TYPE_GO_TO_STEP,
  STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
  STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO,
  STEP_CONDITION_ACTION_TYPE_OPEN_POST,
  STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE,
  STEP_CONDITION_ACTION_TYPE_SKIP_TASK,
  STEP_CONDITION_ACTION_TYPE_SNOOZE,
  STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
  STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
  STEP_TYPE_NPS,
  STEP_TYPE_OPINION_SCALE,
  STEP_TYPE_SLIDER,
  STEP_TYPE_TEXT_LONG,
} from 'services/steps';
import './_Styles.scss';

const propTypes = {
  action: object.isRequired,
  setAction: func,
  onDelete: func,
  defaultOpen: bool,
};

const ButtonAction = ({
  key,
  action,
  setAction = () => {},
  onDelete = null,
  defaultOpen = false,
  ...rest
}) => {
  const {
    evolution: evolutionContext,
    selectedStep: step,
    selectedBlockType,
    setMode,
  } = useContext(BuilderContext);
  const isSurvey = evolutionContext.type === EVOLUTION_TYPE_SURVEY;
  const isBanner = evolutionContext.type === EVOLUTION_TYPE_BANNER;
  const isTour = evolutionContext.type === EVOLUTION_TYPE_TOUR;
  const isHint = evolutionContext.type === EVOLUTION_TYPE_HINT;
  const isChecklist = evolutionContext.type === EVOLUTION_TYPE_CHECKLIST;

  const [preventClose, setPreventClose] = useState(false);

  const {type, value} = action;

  const isGoToStep = action.type === STEP_CONDITION_ACTION_TYPE_GO_TO_STEP;
  const isRunJSCode = type === STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE;
  const isDimiss = type === STEP_CONDITION_ACTION_TYPE_DISMISS;
  const isLaunchExperience =
    type === STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE;
  const isNavigateTo = type === STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO;
  const isOpenPost = type === STEP_CONDITION_ACTION_TYPE_OPEN_POST;
  const isBookInterview = type === STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW;
  const isSkipTask = type === STEP_CONDITION_ACTION_TYPE_SKIP_TASK;
  const isSnooze = type === STEP_CONDITION_ACTION_TYPE_SNOOZE;

  const isPrimaryCta = selectedBlockType === BLOCK_TYPE_PRIMARY_CTA;
  const isSecondaryCta = selectedBlockType === BLOCK_TYPE_SECONDARY_CTA;

  const steps = isSurvey
    ? evolutionContext.steps.filter((s) => s.removed !== true)
    : evolutionContext.tourSteps
        .map((ts) =>
          ts.steps.map((s) => ({
            ...s,
            tourStepIndex: parseInt(ts.tourStepInfo.split(';')[0], 10),
          }))
        )
        .flat()
        .filter((s) => s.removed !== true);
  steps.sort((a, b) => {
    // first sort on tourStepIndex and then on indexOrder
    const tourStepIndexA = a.tourStepIndex;
    const tourStepIndexB = b.tourStepIndex;
    const indexOrderA = a.indexOrder;
    const indexOrderB = b.indexOrder;

    if (tourStepIndexA < tourStepIndexB) {
      return -1;
    }
    if (tourStepIndexA > tourStepIndexB) {
      return 1;
    }
    if (indexOrderA < indexOrderB) {
      return -1;
    }
    if (indexOrderA > indexOrderB) {
      return 1;
    }
    return 0;
  });
  const currentStepIndex = steps.findIndex((s) => s.uid === step.uid);
  const nextStep = steps[currentStepIndex + 1];

  const selectedStep = steps.find((s) => s.uid === value);

  const isValid =
    validateAction(action, {
      hasNoPrevStep: currentStepIndex === 0,
    }).length === 0;

  let content = null,
    icon,
    iconClassName,
    title,
    popupTitle,
    popupIcon,
    onClick = () => {};

  if (isGoToStep) {
    icon = 'isax isax-arrow-right';
    popupIcon = 'isax isax-arrow-right';
    iconClassName = 'go-to-step';
    title = 'Go to step';
    popupTitle = 'Go to step';
    content = <SettingsGoToStep action={action} setAction={setAction} />;

    const canAddConditions = [
      STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
      STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
      STEP_TYPE_NPS,
      STEP_TYPE_SLIDER,
      STEP_TYPE_OPINION_SCALE,
      STEP_TYPE_TEXT_LONG,
    ].includes(step.type);

    if (isBanner === true) {
      icon = 'isax isax-tick-circle';
      title = 'Close banner';
      content = null;
    } else if (isHint === true) {
      icon = 'isax isax-tick-circle';
      title = 'Close hint';
      content = null;
    } else if (isChecklist === true) {
      icon = 'isax isax-tick-circle';
      title = 'Close checklist';
      content = null;
    } else if (isSurvey === true && isPrimaryCta === true) {
      if (canAddConditions === true) {
        onClick = () => {
          setMode(MODE_TRIGGERS);
        };

        if (isPrimaryCta) {
          content = null;
        }
      }

      const isEndSurvey = step.endSurvey === true;
      const goToStep = steps.find((s) => s.uid === step.goTo);

      if (isEndSurvey || value === 'survey-completed') {
        icon = 'isax isax-tick-circle';
        title = 'Finish survey';
      } else if (goToStep != null) {
        icon = 'isax isax-tick-circle';
        title = `To ${goToStep?.name}`;
      } else if (nextStep != null) {
        icon = 'isax isax-arrow-right-2';
        title = 'To next step';
      } else {
        icon = 'isax isax-tick-circle';
        title = 'Finish survey';
      }
    } else if (value === 'next-step') {
      if (nextStep != null) {
        icon = 'isax isax-arrow-right-2';
        title = 'To next step';
      } else if (isTour) {
        icon = 'isax isax-tick-circle';
        title = 'Complete tour';
      }
    } else if (value === 'previous-step') {
      icon = 'isax isax-arrow-left-3';
      title = 'To previous step';
    } else if (selectedStep != null) {
      title = `To ${selectedStep?.name}`;
    }
  } else if (isLaunchExperience) {
    icon = 'isax isax-routing';
    iconClassName = 'launch-experience';
    title = 'Launch experience';
    content = (
      <SettingsLaunchExperience action={action} setAction={setAction} />
    );
  } else if (isNavigateTo) {
    icon = 'isax isax-send-2';
    iconClassName = 'navigate-to';
    title = 'Navigate to';
    content = (
      <SettingsNavigateTo
        action={action}
        setAction={setAction}
        setPreventClose={setPreventClose}
      />
    );
  } else if (isRunJSCode) {
    icon = 'isax isax-document-code';
    iconClassName = 'run-js-code';
    title = 'Run JavaScript code';
    content = <SettingsRunJSCode action={action} setAction={setAction} />;
  } else if (isDimiss) {
    icon = 'isax isax-close-square';
    iconClassName = 'dismiss';
    title = isSurvey === true ? 'Finish survey' : 'Dismiss experience';
  } else if (isOpenPost) {
    icon = 'isax isax-slider-vertical-1';
    iconClassName = 'open-post';
    title = 'Open post';
    content = <SettingsOpenPost action={action} setAction={setAction} />;
  } else if (isBookInterview) {
    icon = 'isax isax-calendar-search';
    iconClassName = 'book-interview';
    title = 'Book interview';
    content = <SettingsBookInterview action={action} setAction={setAction} />;
  } else if (isDimiss) {
    icon = 'isax isax-close-square';
    iconClassName = 'dismiss';
    title = isSurvey ? 'Finish survey' : 'Dismiss experience';
  } else if (isSkipTask) {
    icon = 'isax isax-arrow-square-left';
    iconClassName = 'skip-task';
    title = 'Skip task';
  } else if (isSnooze) {
    icon = 'isax isax-pause-circle';
    iconClassName = 'snooze';
    title = isSurvey ? 'Snooze survey' : 'Snooze experience';
    content = <SettingsSnooze action={action} setAction={setAction} />;
  }

  const trigger = (
    <ClickableInput
      className={classNames('button-action', {
        'no-content': content == null,
      })}
      value={title}
      leftLabel={
        <div className={classNames('icon-wrapper', iconClassName)}>
          <i className={icon} />
        </div>
      }
      erasable={
        ([
          STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW,
          STEP_CONDITION_ACTION_TYPE_GO_TO_STEP,
        ].includes(type) !== true ||
          isSecondaryCta) &&
        onDelete != null
      }
      disabled={isBanner || isHint}
      onErase={onDelete}
      invalid={isValid !== true}
      onClick={onClick}
    />
  );

  if (content == null) {
    return trigger;
  }

  return (
    <PopupSetting
      key={key}
      className="button-action-dropdown"
      large={isSnooze === false}
      medium={isSnooze === true}
      title={
        <>
          <div className={classNames('icon-wrapper', iconClassName)}>
            <i className={popupIcon || icon} />
          </div>
          {popupTitle || title}
        </>
      }
      trigger={trigger}
      dropdownProps={{
        position: ['left top', 'left bottom'],
        offsetX: 8,
        isOpen: defaultOpen,
        ...(preventClose === true ? {closeOnDocumentClick: false} : {}),
      }}
      content={content}
      {...rest}
    />
  );
};

ButtonAction.propTypes = propTypes;

export default ButtonAction;
