import {uptProject} from 'actions/general';
import Button from 'components/Button';
import DefaultLoader from 'components/Loader';
import {toastDanger} from 'components/Toaster';
import Toggle from 'components/Toggle';
import {errorHelpers} from 'helpers';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {ROUTE_SETTINGS_INTEGRATIONS} from 'router/routes.const';
import {SettingsBody} from 'scenes/Settings/components/Body';
import {generalSelector} from 'selectors';
import {posthogService} from 'services';
import {
  F_POSTHOG_DATA_JIMO_2_POSTHOG,
  F_POSTHOG_DATA_POSTHOG_2_JIMO,
} from 'services/posthog';
import {Swaler} from 'swaler';
import './_Styles.scss';
import HelpSection from './components/HelpSection';

const logger = new Swaler('PosthogSettings');

const PosthogSettings = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const project = useSelector(() => generalSelector.getProject());

  const updateProject = (project) => {
    dispatch(uptProject(project));
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleUpdateSetup = async (data) => {
    setIsUpdating(true);
    try {
      const updatedPosthog = await posthogService.update(data);

      updateProject({
        ...project,
        posthog: updatedPosthog,
      });
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(`Failed to update setup posthog integration, ${code}`);
      toastDanger([title, message], {actions});
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveSetup = async () => {
    setIsLoading(true);
    try {
      await posthogService.remove(project.uid);

      updateProject({
        ...project,
        posthog: null,
      });
      history.push(ROUTE_SETTINGS_INTEGRATIONS);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(`Failed to setup posthog integration, ${code}`);
      toastDanger([title, message], {actions});
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading === true)
    return (
      <SettingsBody className="posthog-settings is-loading">
        <DefaultLoader />
      </SettingsBody>
    );

  const hasDataJimoToPosthog = hasFlag(
    F_POSTHOG_DATA_JIMO_2_POSTHOG,
    project.posthog.flags
  );
  const hasDataPosthogToJimo = hasFlag(
    F_POSTHOG_DATA_POSTHOG_2_JIMO,
    project.posthog.flags
  );

  return (
    <SettingsBody className="posthog-settings">
      <div className="posthog-settings-header">
        <div className="left-side">
          <Button
            className="back-btn"
            iconOnly
            iconLeft="icon-chevron-left"
            onClick={() => {
              history.push(ROUTE_SETTINGS_INTEGRATIONS);
            }}
          />
          <div className="title-3">Posthog Integration</div>
        </div>
      </div>
      <div className="posthog-settings-content">
        <div className="left-side">
          <div className="settings-card settings-jimo-to-posthog">
            <div className="left-side">
              <div className="title-wrapper subtitle-3">
                Jimo <i className="isax isax-arrow-right-1"></i> Posthog
              </div>
              <div className="body-3 n-700">
                Send Jimo related events to Posthog
              </div>
            </div>
            <div className="right-side">
              <Toggle
                checked={hasDataJimoToPosthog}
                onClick={() => {
                  handleUpdateSetup({
                    flags:
                      hasDataJimoToPosthog === true
                        ? removeFlag(
                            F_POSTHOG_DATA_JIMO_2_POSTHOG,
                            project.posthog.flags
                          )
                        : addFlag(
                            F_POSTHOG_DATA_JIMO_2_POSTHOG,
                            project.posthog.flags
                          ),
                  });
                }}
              />
            </div>
          </div>
          <div className="settings-card settings-posthog-to-jimo">
            <div className="left-side">
              <div className="title-wrapper subtitle-3">
                Posthog <i className="isax isax-arrow-right-1"></i> Jimo
              </div>
              <div className="body-3 n-700">Send Posthog events to Jimo</div>
            </div>
            <div className="right-side">
              <Toggle
                checked={hasDataPosthogToJimo}
                onClick={() => {
                  handleUpdateSetup({
                    flags:
                      hasDataPosthogToJimo === true
                        ? removeFlag(
                            F_POSTHOG_DATA_POSTHOG_2_JIMO,
                            project.posthog.flags
                          )
                        : addFlag(
                            F_POSTHOG_DATA_POSTHOG_2_JIMO,
                            project.posthog.flags
                          ),
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="right-side">
          <HelpSection
            isDisconnecting={isLoading}
            onDisconnect={handleRemoveSetup}
          />
        </div>
      </div>
    </SettingsBody>
  );
};

export default PosthogSettings;
