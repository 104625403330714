import * as builderActions from 'actions/builder';
import classnames from 'classnames';
import {BuilderContext} from 'contextes/builder';
import {hasFlag, hasFlags} from 'helpers/bitwise';
import {removeTrailingSlash} from 'helpers/evolution';
import {sendSetChildTab} from 'helpers/utils';
import {useFileUpload} from 'hooks/useFileUpload';
import useUndercityBuilderMessenger from 'managers/useDalaranMessenger';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import PokeBuilder from 'scenes/PokeBuilder';
import {
  EVOLUTION_TYPE_BANNER,
  EVOLUTION_TYPE_HINT,
  EVOLUTION_TYPE_TOUR,
  F_BOOST_SLOT_CURSOR,
  F_BOOST_SLOT_DOT,
  F_BOOST_SLOT_HINT,
  F_BOOST_SLOT_NAVIGATION,
  F_BOOST_SLOT_TOOLTIP,
  F_BOOST_SLOT_TOUR,
} from 'services/evolution';
import {F_STEP_HEIGHT_CUSTOM, F_STEP_IS_SELECTING_PRESET} from 'services/steps';
import {
  F_SLOT_DOT,
  F_SLOT_HINT,
  F_SLOT_TOOLTIP,
} from 'shared/front/components/Poke/constants/poke';
import {Swaler} from 'swaler';
import './_Styles.scss';
import TargetNotFoundWarning from './components/TargetNotFoundWarning';

const logger = new Swaler('EmbeddedPokeBuilder');

const EmbeddedPokeBuilder = () => {
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const stepId = query.get('step-id');

  const {
    evolution: contextEvolution,
    setEvolution: setContextEvolution,
    controlledEvolution,
    selectedStepId,
    setSelectedStepId,
    selectedTourStepId,
    setSelectedTourStepId,
    setMessenger,
    isEditingTargetElement,
    setIsEditingTargetElement,
    setSelectedBlockType,
    selectedStep,
    inAppForegroundForce,
    selectedBlockType,
    currentUrl,
    setCurrentUrl,
    isInInteractivePreview,
    setIsInInteractivePreview,
    selectingElementTriggerConditionId,
    setSelectingElementTriggerConditionId,
    selectedLanguage,
    setIsSelectingPreview,
  } = useContext(BuilderContext);

  const {upload} = useFileUpload({logger});

  const evolution = controlledEvolution;
  const setEvolution = (updatedEvolution) => {
    if (
      controlledEvolutionRef.current?.uid !== contextEvolutionRef.current?.uid
    ) {
      setContextEvolution({
        ...contextEvolutionRef.current,
        tourSteps: contextEvolutionRef.current?.tourSteps.map((s) => {
          if (s.uid === controlledEvolutionRef.current?.uid) {
            return updatedEvolution;
          } else {
            return s;
          }
        }),
      });
    } else {
      setContextEvolution(updatedEvolution);
    }
  };

  const dispatch = useDispatch();

  const builder = useSelector((state) => state.builder);

  const [targetNotFound, setTargetNotFound] = useState(null);

  const controlledEvolutionRef = useRef();
  const contextEvolutionRef = useRef();
  const selectedStepRef = useRef();
  const isInInteractivePreviewRef = useRef();
  const currentUrlRef = useRef();
  const selectingElementTriggerConditionIdRef = useRef();

  const uploadFile = async (file) => {
    if (file == null) {
      return;
    }
    const response = await fetch(file);
    const blob = await response.blob();
    const uploadableFile = new File([blob], 'upload.png', {type: 'image/png'});
    const uploadedFile = await upload({file: uploadableFile});

    return uploadedFile;
  };

  useEffect(() => {
    sendSetChildTab();
  }, []);

  useEffect(() => {
    currentUrlRef.current = currentUrl;
  }, [currentUrl]);

  useEffect(() => {
    isInInteractivePreviewRef.current = isInInteractivePreview;
  }, [isInInteractivePreview]);

  useEffect(() => {
    selectedStepRef.current = selectedStep;
  }, [selectedStep]);

  useEffect(() => {
    selectingElementTriggerConditionIdRef.current =
      selectingElementTriggerConditionId;
  }, [selectingElementTriggerConditionId]);

  useEffect(() => {
    if (stepId != null) {
      setSelectedStepId(stepId);
    }
    if (builder.tourStepId != null) {
      setSelectedTourStepId(builder.tourStepId);
    }
    if (builder.stepId != null) {
      setSelectedStepId(builder.stepId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    contextEvolutionRef.current = contextEvolution;
    if (isEditingTargetElement) {
      messenger.sendPreviewEvolution({evolution: controlledEvolution});
    } else {
      messenger.sendPreviewEvolution({evolution: contextEvolution});
    }
    dispatch(builderActions.setPoke(contextEvolution));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextEvolution, isEditingTargetElement]);

  useEffect(() => {
    messenger.sendSelectedStepId({selectedStepId});
    dispatch(builderActions.setSelectedStepId(selectedStepId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStepId]);

  useEffect(() => {
    dispatch(builderActions.setSelectedTourStepId(selectedTourStepId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTourStepId]);

  useEffect(() => {
    controlledEvolutionRef.current = controlledEvolution;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlledEvolution]);

  useEffect(() => {
    if (
      hasFlags(
        [
          F_BOOST_SLOT_DOT,
          F_BOOST_SLOT_TOOLTIP,
          F_BOOST_SLOT_HINT,
          F_BOOST_SLOT_CURSOR,
        ],
        evolution?.boostFlags,
        true
      ) &&
      hasFlag(F_STEP_IS_SELECTING_PRESET, selectedStep?.stepFlags) !== true &&
      !evolution?.boostedQueryselector &&
      !evolution?.querySelectorManual?.elementText &&
      !evolution?.querySelectorManual?.cssSelector
    ) {
      messenger.sendSelectTargetElement();
    }
  }, [evolution]);

  const handleSelectedTargetElement = async (data) => {
    const file = await uploadFile(data.image);

    setEvolution({
      ...controlledEvolutionRef.current,
      ...(selectingElementTriggerConditionIdRef.current != null
        ? {
            steps: controlledEvolutionRef.current?.steps.map((s) => {
              if (s.uid === selectedStepRef.current.uid) {
                return {
                  ...s,
                  triggers: s.triggers.map((t) => {
                    return {
                      ...t,
                      conditions: t.conditions.map((c) => {
                        if (
                          c.uid ===
                          selectingElementTriggerConditionIdRef.current
                        ) {
                          return {
                            ...c,
                            querySelector: data.querySelector,
                            querySelectorManual: data.querySelectorManual,
                            ...(file != null
                              ? {
                                  file,
                                }
                              : {
                                  file: null,
                                }),
                          };
                        } else {
                          return c;
                        }
                      }),
                    };
                  }),
                };
              }

              return s;
            }),
          }
        : {
            boostedQueryselector: data.querySelector,
            querySelectorManual: data.querySelectorManual,
            ...(file != null
              ? {
                  file,
                }
              : {
                  file: null,
                }),
          }),
    });

    if (selectingElementTriggerConditionIdRef.current == null) {
      setIsEditingTargetElement(true);
    } else {
      setSelectingElementTriggerConditionId(null);
    }
  };

  const handleCurrentUrlChange = (data) => {
    setCurrentUrl(data.url);

    if (isInInteractivePreviewRef.current === true) {
      return;
    }

    const isTour = hasFlag(
      F_BOOST_SLOT_TOUR,
      contextEvolutionRef.current?.boostFlags
    );
    const hasUrlSet = !!controlledEvolutionRef.current?.boostedActiveUrl;
    const isNavigationStep = hasFlag(
      F_BOOST_SLOT_NAVIGATION,
      controlledEvolutionRef.current?.boostFlags
    );

    if (
      hasFlag(
        F_BOOST_SLOT_NAVIGATION,
        controlledEvolutionRef.current?.boostFlags
      ) !== true &&
      contextEvolutionRef.current?.boostedActiveUrl == null
    ) {
      setContextEvolution({
        ...contextEvolutionRef.current,
        boostedActiveUrl: data.url,
      });
    } else if (
      isTour === false ||
      hasUrlSet === false ||
      isNavigationStep === true
    ) {
      setEvolution({
        ...controlledEvolutionRef.current,
        boostedActiveUrl: data.url,
      });
    }
  };

  const handleTargetNotFound = (data) => {
    setTargetNotFound(data.stepId);
  };

  const handleTargetFound = () => {
    setTargetNotFound(false);
  };

  const handleInteractivePreviewStopped = () => {
    setIsInInteractivePreview(false);
    if (
      removeTrailingSlash(currentUrlRef.current || '') !==
      removeTrailingSlash(contextEvolutionRef.current?.boostedActiveUrl || '')
    ) {
      // messenger.sendNavigateTo({
      //   url: contextEvolutionRef.current?.boostedActiveUrl,
      // });
    }
  };

  const handleSelectedTourStepId = (data) => {
    setSelectedTourStepId(data.tourStepId);
  };

  const handleBlockSelected = (data) => {
    setSelectedBlockType(data.type);
  };

  const handleStepSelected = (data) => {
    const {stepId} = data;
    const tourStep = contextEvolutionRef.current?.tourSteps?.find((ts) =>
      ts.steps?.some((s) => s.uid === stepId)
    );
    if (tourStep != null) {
      setSelectedTourStepId(tourStep.uid);
      setSelectedStepId(data.stepId);
    }
  };

  const handlePokeHeightChange = (data) => {
    const {height, selectedStepId} = data;

    if (
      selectedStepRef.current != null &&
      selectedStepRef.current.uid === selectedStepId &&
      hasFlag(F_STEP_HEIGHT_CUSTOM, selectedStepRef.current.stepFlags) !==
        true &&
      height !== selectedStepRef.current.style?.height &&
      height !== 0
    ) {
      setContextEvolution({
        ...contextEvolutionRef.current,
        ...([
          EVOLUTION_TYPE_BANNER,
          EVOLUTION_TYPE_TOUR,
          EVOLUTION_TYPE_HINT,
        ].includes(contextEvolutionRef.current?.type)
          ? {
              tourSteps: contextEvolutionRef.current?.tourSteps.map((ts) => {
                if (
                  ts.steps?.some((s) => s.uid === selectedStepRef.current.uid)
                ) {
                  return {
                    ...ts,
                    steps: ts.steps.map((s) => {
                      if (s.uid === selectedStepRef.current.uid) {
                        return {
                          ...s,
                          style: {
                            ...s.style,
                            height: Math.min(height, 900),
                          },
                        };
                      } else {
                        return s;
                      }
                    }),
                  };
                } else {
                  return ts;
                }
              }),
            }
          : {
              steps: contextEvolutionRef.current?.steps.map((s) => {
                if (s.uid === selectedStepRef.current.uid) {
                  return {
                    ...s,
                    style: {
                      ...s.style,
                      height: Math.min(height, 900),
                    },
                  };
                } else {
                  return s;
                }
              }),
            }),
      });
    }
  };

  const handleBuilderStateRequest = () => {
    messenger.sendBuilderState({
      poke: controlledEvolution,
      selectedStepId,
      selectedBlockType,
      selectedStep,
      inConcept: null,
    });
  };

  const handleFoundElementsCount = (data) => {
    setEvolution({
      ...controlledEvolutionRef.current,
      querySelectorManual: {
        ...controlledEvolutionRef.current.querySelectorManual,
        count: data.count,
      },
    });
  };

  const handleOpenPreviewSelector = () => {
    setIsSelectingPreview(true);
  };

  const messenger = useUndercityBuilderMessenger({
    onSelectedTargetElement: handleSelectedTargetElement,
    onCurrentUrlChange: handleCurrentUrlChange,
    onTargetNotFound: handleTargetNotFound,
    onTargetFound: handleTargetFound,
    onInteractivePreviewStopped: handleInteractivePreviewStopped,
    onSelectedTourStepId: handleSelectedTourStepId,
    onBlockSelected: handleBlockSelected,
    onPokeHeightChange: handlePokeHeightChange,
    onBuilderStateRequest: handleBuilderStateRequest,
    onStepSelected: handleStepSelected,
    onFoundElementsCount: handleFoundElementsCount,
    onOpenPreviewSelector: handleOpenPreviewSelector,
  });

  useEffect(() => {
    messenger.sendRequestCurrentUrl();
    setMessenger(messenger);
    messenger.sendLoginSuccess();
  }, []);

  useEffect(() => {
    messenger.sendInAppForegroundForce(inAppForegroundForce);
  }, [inAppForegroundForce]);

  useEffect(() => {
    messenger.sendLanguageSelected(selectedLanguage);
  }, [selectedLanguage]);

  useEffect(() => {
    const isTooltip = hasFlag(
      F_BOOST_SLOT_TOOLTIP,
      controlledEvolution?.boostFlags
    );
    messenger.sendEvolutionOverlayUpdate(
      isTooltip ? null : controlledEvolution?.style?.overlay
    );
  }, [controlledEvolution?.style?.overlay]);

  return (
    <div className="embedded-poke-builder">
      <Helmet>
        <body
          className={classnames('transparent-mode', {
            'target-not-found-mode':
              targetNotFound === controlledEvolution?.uid &&
              isEditingTargetElement !== true,
          })}
        />
      </Helmet>

      <PokeBuilder />

      {targetNotFound === controlledEvolution?.uid &&
        isEditingTargetElement !== true && (
          <TargetNotFoundWarning
            type={
              controlledEvolution.boostFlags === F_SLOT_DOT
                ? 'hotspot'
                : controlledEvolution.boostFlags === F_SLOT_TOOLTIP
                ? 'tooltip'
                : controlledEvolution.boostFlags === F_SLOT_HINT
                ? 'hint'
                : controlledEvolution.boostFlags === F_BOOST_SLOT_CURSOR
                ? 'cursor'
                : 'element'
            }
            onSelectElementClick={() => {
              messenger.sendSelectTargetElement();
            }}
            onNavigateClick={() => {
              messenger.sendSwitchToNavigatorMode();
            }}
          />
        )}
    </div>
  );
};

export default EmbeddedPokeBuilder;
