import classNames from 'classnames';
import Divider from 'components/Divider';
import Dropdown from 'components/Dropdown';
import RadioGroup from 'components/RadioGroup';
import React, {useState} from 'react';
import {
  VISUAL_CUE_TYPE_GESTURES,
  VISUAL_CUE_TYPE_POINTERS,
  VISUAL_CUE_TYPE_STICKERS,
  visualCues,
} from 'shared/front/components/Poke/components/VisualCues';
import DynamicSvg from '../DynamicSvg';
import './_Styles.scss';

const types = [
  {label: 'Stickers', value: VISUAL_CUE_TYPE_STICKERS},
  {label: 'Gestures', value: VISUAL_CUE_TYPE_GESTURES},
  {label: 'Pointers', value: VISUAL_CUE_TYPE_POINTERS},
];

export const VisualCueItem = ({name, url, onClick, selected, ...props}) => {
  return (
    <div
      className={classNames('visual-cue', {
        selected: selected,
      })}
      onClick={onClick}>
      <div className="icon-wrapper">
        <DynamicSvg url={url} {...props} />
      </div>
      <div className="label body-4 n-700">{name}</div>
    </div>
  );
};

const VisualCuePicker = ({value, onChange}) => {
  const initialType =
    visualCues.find((item) => item.value === value)?.type ??
    VISUAL_CUE_TYPE_STICKERS;

  const [showDropdown, setShowDropdown] = useState(false);
  const [type, setType] = useState(initialType);

  const selectedVisualCue = visualCues.find((item) => item.value === value);

  const items = visualCues.filter((item) => item.type === type);

  return (
    <Dropdown
      open={showDropdown}
      className={classNames('dropdown-visual-cue-picker')}
      position="bottom left"
      repositionOnResize={true}
      trigger={
        <div
          className="visual-cue-picker-trigger"
          onClick={() => setShowDropdown(true)}>
          <div className="icon-wrapper">
            <DynamicSvg url={selectedVisualCue?.url} {...selectedVisualCue} />
          </div>
          <div className="visual-cue-picker-trigger-content">
            <div className="type body-4 n-600">
              {selectedVisualCue?.type === VISUAL_CUE_TYPE_STICKERS
                ? 'Stickers'
                : selectedVisualCue?.type === VISUAL_CUE_TYPE_GESTURES
                ? 'Gestures'
                : 'Arrows'}
            </div>
            <div className="title subtitle-4 n-800">
              {selectedVisualCue?.name}
            </div>
          </div>
          <i className="icon-chevron-bottom" />
        </div>
      }>
      <div className="dropdown-menu">
        <div className="visual-cue-picker-header">
          <RadioGroup value={type} options={types} onSelect={setType} />
        </div>
        <Divider />
        <div className="visual-cue-picker-content">
          {type === VISUAL_CUE_TYPE_GESTURES ? (
            <>
              <div className="section-title">Hand</div>
              <div className="section-grid">
                {items
                  .filter((item) => item.subtype === 'hand')
                  .map((item) => (
                    <VisualCueItem
                      name={item.name}
                      url={item.url}
                      onClick={() =>
                        onChange({
                          value: item.value,
                          style: {
                            primaryColor: item.primaryColor || null,
                            secondaryColor: item.secondaryColor || null,
                            borderColor: item.borderColor || null,
                          },
                        })
                      }
                      selected={value === item.value}
                      {...item}
                    />
                  ))}
              </div>
              <div className="section-title">Finger</div>
              <div className="section-grid">
                {items
                  .filter((item) => item.subtype === 'finger')
                  .map((item) => (
                    <VisualCueItem
                      name={item.name}
                      url={item.url}
                      onClick={() =>
                        onChange({
                          value: item.value,
                          style: {
                            primaryColor: item.primaryColor || null,
                            secondaryColor: item.secondaryColor || null,
                            borderColor: item.borderColor || null,
                          },
                        })
                      }
                      selected={value === item.value}
                      {...item}
                    />
                  ))}
              </div>
            </>
          ) : (
            <div className="section-grid">
              {items.map((item) => (
                <VisualCueItem
                  name={item.name}
                  url={item.url}
                  onClick={() =>
                    onChange({
                      value: item.value,
                      style: {
                        primaryColor: item.primaryColor || null,
                        secondaryColor: item.secondaryColor || null,
                        borderColor: item.borderColor || null,
                      },
                    })
                  }
                  selected={value === item.value}
                  {...item}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </Dropdown>
  );
};

export default VisualCuePicker;
