import SelectGroup from 'components/Select';
import {BuilderContext} from 'contextes/builder';
import React, {useContext} from 'react';
import Section from 'scenes/PokeBuilder/components/Section';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {EVOLUTION_TYPE_TOUR} from 'services/evolution';
import {BLOCK_TYPE_ANIMATION} from 'services/steps';
import {playConfettiAnimation} from 'shared/front/components/Poke/components/BlockAnimation/confetti';
import PixelPicker from '../../components/items/PixelPicker';
import './_Styles.scss';

export const successAnimations = [
  {
    label: 'Confetti',
    value: 'confetti',
  },
  {
    label: 'Hearts',
    value: 'heart',
  },
  {
    label: 'Smileys',
    value: 'smiley',
  },
  {
    label: 'Check',
    value: 'check',
  },
];

const Animation = () => {
  const {
    selectedStep: step,
    updateBlock: uptBlock,
    evolution,
  } = useContext(BuilderContext);

  const updateBlock = (updateObj) => {
    uptBlock(BLOCK_TYPE_ANIMATION, updateObj);
  };

  const block = step?.blocks.find((b) => b.type === BLOCK_TYPE_ANIMATION);

  if (block == null) {
    return <></>;
  }

  const isTour = evolution?.type === EVOLUTION_TYPE_TOUR;

  const {value, style = {}} = block;
  const {speed = 100} = style || {};

  const animation =
    value != null ? successAnimations.find((a) => a.value === value) : null;

  return (
    <div className="block-settings animation">
      <Section title="Animation">
        <SectionItem title="Type">
          <SelectGroup
            small
            options={successAnimations.filter((a) =>
              isTour === true ? a.value === 'confetti' : true
            )}
            value={animation}
            onChange={(option) => updateBlock({value: option.value})}
            isSearchable={false}
          />
        </SectionItem>
        {isTour === true && (
          <>
            <SectionItem title="Speed">
              <PixelPicker
                small
                value={speed}
                min={0}
                max={100}
                label="%"
                onChange={(value) =>
                  updateBlock({
                    style: {
                      ...style,
                      speed: value,
                    },
                  })
                }
              />
            </SectionItem>
            <SectionItem title="">
              <div
                className="play-animation-button b-400 subtitle-4"
                onClick={() => playConfettiAnimation(speed)}>
                <i className="isax isax-arrow-right-4" />
                Play
              </div>
            </SectionItem>
          </>
        )}
      </Section>
    </div>
  );
};

export default Animation;
