import classNames from 'classnames';
import Divider from 'components/Divider';
import DefaultLoader from 'components/Loader';
import {BuilderContext} from 'contextes/builder';
import {hasFlag} from 'helpers/bitwise';
import {useFileUpload} from 'hooks/useFileUpload';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {Handle, Position} from 'reactflow';
import {MODE_NAVIGATOR} from 'scenes/PokeBuilder/components/PokeBuilderSidebar';
import {InAppBuilderContext} from 'scenes/Pushes/context';
import {
  F_BOOST_SLOT_CURSOR,
  F_BOOST_SLOT_TOOLTIP,
  F_OPTION_PROGRESS_ON_TARGET_CLICK,
} from 'services/evolution';
import {BLOCK_TYPE_TARGET, BLOCK_TYPE_VISUAL_CUE} from 'services/steps';
import ClickableBlockOverlay from 'shared/front/components/Poke/components/ClickableBlockOverlay';
import Effects from 'shared/front/components/Poke/components/Effects';
import {visualCues} from 'shared/front/components/Poke/components/VisualCues';
import BlockVisualCue from 'shared/front/components/Poke/components/VisualCues/BlockVisualCue';
import {getVisualCueStyle} from 'shared/front/components/Poke/components/VisualCues/BlockVisualCue/utils';
import {Swaler} from 'swaler';
import {LogicViewContext} from '../../..';
import './_Styles.scss';

const logger = new Swaler('TargetElement');

const propTypes = {};

const TargetElement = ({evolution, step, steps, isSelected}) => {
  const {upload} = useFileUpload({logger});

  const {
    selectedBlockType,
    setSelectedBlockType,
    setMode,
    setSelectedStepId,
    evolution: parentEvolution,
    setEvolution,
  } = useContext(BuilderContext);
  const {goToEditInApp = () => {}, stopInAppEditing = () => {}} =
    useContext(InAppBuilderContext) || {};
  const {reactFlowInstance} = useContext(LogicViewContext) || {};

  const [visualCueDimensions, setVisualCueDimensions] = useState({
    width: 0,
    height: 0,
  });

  const parentEvolutionRef = useRef(parentEvolution);
  const evolutionRef = useRef(evolution);
  const visualCueRef = useRef(null);
  const resizeObserverRef = useRef(null);

  useEffect(() => {
    parentEvolutionRef.current = parentEvolution;
  }, [parentEvolution]);

  useEffect(() => {
    evolutionRef.current = evolution;
  }, [evolution]);

  const isTooltip = hasFlag(F_BOOST_SLOT_TOOLTIP, evolution?.boostFlags);
  const isCursor = hasFlag(F_BOOST_SLOT_CURSOR, evolution?.boostFlags);

  const visualCueBlock = step.blocks.find(
    (b) => b.type === BLOCK_TYPE_VISUAL_CUE
  );
  const selectedVisualCue = visualCues.find(
    (s) => s.value === visualCueBlock?.value
  );

  const hasProgressOnTargetClick = hasFlag(
    F_OPTION_PROGRESS_ON_TARGET_CLICK,
    evolution?.optionsFlags
  );
  const isLastStep = steps.indexOf(step) === steps.length - 1;

  const zoom = reactFlowInstance?.getZoom?.() ?? 1;

  useEffect(() => {
    if (visualCueRef.current) {
      const observer = new ResizeObserver(() => {
        if (!visualCueRef.current) return;

        const rect = visualCueRef.current.getBoundingClientRect();

        setVisualCueDimensions({
          width: rect.width,
          height: rect.height,
        });
      });

      observer.observe(visualCueRef.current);
      resizeObserverRef.current = observer;
    }

    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
  }, [visualCueRef, visualCueBlock, zoom]);

  const uploadFile = async (file) => {
    if (file == null) {
      return;
    }
    const response = await fetch(file);
    const blob = await response.blob();
    const uploadableFile = new File([blob], 'upload.png', {type: 'image/png'});
    const uploadedFile = await upload({file: uploadableFile});

    return uploadedFile;
  };

  const handleElementSelected = async (data) => {
    setEvolution({
      ...parentEvolutionRef.current,
      tourSteps: parentEvolutionRef.current.tourSteps.map((tourStep) => {
        if (tourStep.uid === evolutionRef.current?.uid) {
          return {
            ...tourStep,
            ...data.evolution,
            isUploading: true,
          };
        }
        return tourStep;
      }),
    });

    const file = await uploadFile(data.image);

    setEvolution({
      ...parentEvolutionRef.current,
      tourSteps: parentEvolutionRef.current.tourSteps.map((tourStep) => {
        if (tourStep.uid === evolutionRef.current?.uid) {
          return {
            ...tourStep,
            ...data.evolution,
            isUploading: false,
            ...(file != null
              ? {
                  file,
                }
              : {
                  file: null,
                }),
          };
        }
        return tourStep;
      }),
    });
  };

  const handleGoToEditInApp = () => {
    goToEditInApp({
      data: {
        title: 'Select element',
      },
      handshakeData: {
        type: 'SET_MODE',
        data: {
          mode: 'ELEMENT_SELECTOR_BUILDER',
          evolution: parentEvolutionRef.current,
          stepId: step.uid,
        },
      },
      onChildCommunication: (message) => {
        if (message?.type === 'TARGET_ELEMENT_SELECTED') {
          handleElementSelected(message.data);
          stopInAppEditing();
        }
        if (message?.type === 'EMBEDDED_BUILDER_CLOSE') {
          stopInAppEditing();
        }
      },
    });
  };

  const hasEffect = isTooltip || isCursor;

  return (
    <div
      className={classNames('step-node-selector-preview-wrapper', {
        selected: isSelected && selectedBlockType === BLOCK_TYPE_TARGET,
      })}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();

        setSelectedStepId(step.uid);
        setMode(MODE_NAVIGATOR);
        setSelectedBlockType(BLOCK_TYPE_TARGET);

        if (
          !evolution.boostedQueryselector &&
          evolution.querySelectorManual == null
        ) {
          handleGoToEditInApp();
        }
      }}
      onMouseDownCapture={(e) => {
        e.stopPropagation();
      }}>
      {evolution.boostedQueryselector ||
      evolution.querySelectorManual != null ? (
        <>
          {evolution.file?.publicUrl != null ? (
            <img src={evolution.file?.publicUrl} alt="element" />
          ) : (
            <div className="image-not-found">
              <i className="icon-image" />
              No preview found
            </div>
          )}
        </>
      ) : (
        <div className="pinned-element-empty-state">
          <i className="isax isax-gps" />
          <Divider vertical dark />
          <div className="content">Target an element in-app</div>
        </div>
      )}

      {evolution.isUploading === true && (
        <div className="uploading-overlay">
          <DefaultLoader width={24} />
        </div>
      )}

      {(isTooltip === true || isCursor === true) &&
        hasProgressOnTargetClick === true &&
        isLastStep !== true && (
          <Handle
            type="source"
            position={Position.Right}
            id={`${step.uid}-target`}
            isConnectable={false}
            isConnectableStart={false}
          />
        )}

      {hasEffect && (
        <Effects
          style={evolution?.style}
          disableAnimation={
            (isSelected && selectedBlockType === BLOCK_TYPE_TARGET) !== true
          }
        />
      )}

      {selectedVisualCue != null && (
        <div
          ref={visualCueRef}
          className={classNames('poke-block-clickable visual-cue-wrapper', {
            selected: isSelected && selectedBlockType === BLOCK_TYPE_VISUAL_CUE,
          })}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            setSelectedStepId(step.uid);
            setMode(MODE_NAVIGATOR);
            setSelectedBlockType(BLOCK_TYPE_VISUAL_CUE);
          }}
          onMouseDownCapture={(e) => {
            e.stopPropagation();
          }}
          style={{
            ...getVisualCueStyle(
              visualCueBlock?.style,
              visualCueDimensions,
              zoom
            ),
          }}>
          <BlockVisualCue
            block={visualCueBlock}
            disableAnimation={
              !(isSelected && selectedBlockType === BLOCK_TYPE_VISUAL_CUE)
            }
          />

          <ClickableBlockOverlay isNotCardElem />
        </div>
      )}
      <ClickableBlockOverlay isNotCardElem />
    </div>
  );
};

TargetElement.propTypes = propTypes;

export default TargetElement;
