import {crispHelpers} from 'helpers';
import {Swaler} from 'swaler';

const logger = new Swaler('Helper/Error');

export const errorCodes = {
  /** Projects */
  PROJECT_NOT_FOUND: 'PROJECT_NOT_FOUND',
  PROJECT_DOMAIN_ALREADY_TAKEN: 'PROJECT_DOMAIN_ALREADY_TAKEN',
  PROJECT_UPDATE_FAILED: 'PROJECT_UPDATE_FAILED',

  /** Users */
  USER_UPDATE_INFO_FAILED: 'USER_UPDATE_INFO_FAILED',
  USER_ALREADY_MEMBER: 'USER_ALREADY_MEMBER',

  /** Evolutions */
  EVOLUTION_NOT_OWNED_BY_PROJECT: 'EVOLUTION_NOT_OWNED_BY_PROJECT',
  DELETE_BOOSTED_PATH_FAILED: 'DELETE_BOOSTED_PATH_FAILED',
  UPDATE_BOOSTED_PATH_FAILED: 'UPDATE_BOOSTED_PATH_FAILED',
  CREATE_BOOSTED_PATH_FAILED: 'CREATE_BOOSTED_PATH_FAILED',
  CREATE_EVOLUTION_LIST_VIEW_FAILED: 'CREATE_EVOLUTION_LIST_VIEW_FAILED',
  UPDATE_EVOLUTION_LIST_VIEW_FAILED: 'UPDATE_EVOLUTION_LIST_VIEW_FAILED',
  DELETE_EVOLUTION_LIST_VIEW_FAILED: 'DELETE_EVOLUTION_LIST_VIEW_FAILED',

  /** Events */
  EVENT_CREATE_FAILED: 'EVENT_CREATE_FAILED',
  EVENT_UPDATE_FAILED: 'EVENT_UPDATE_FAILED',

  /** Comments */
  COMMENT_LIKE_NOT_FOUND: 'COMMENT_LIKE_NOT_FOUND',
  COMMENT_NOT_OWNED: 'COMMENT_NOT_OWNED',
  COMMENTS_DISABLED: 'COMMENTS_DISABLED',
  ANONYMOUS_COMMENTS_DISABLED: 'ANONYMOUS_COMMENTS_DISABLED',
  COMMENT_TO_REPLY_NOT_FOUND: 'COMMENT_TO_REPLY_NOT_FOUND',

  /** Requests */
  REQUEST_NOT_FOUND: 'REQUEST_NOT_FOUND',
  REQUEST_NOT_OWNED_BY_PROJECT: 'REQUEST_NOT_OWNED_BY_PROJECT',

  /** Prototypes  */
  PROTOTYPE_NOT_FOUND: 'PROTOTYPE_NOT_FOUND',
  PROTOTYPE_EVOLUTION_ID_MISS_MATCH: 'PROTOTYPE_EVOLUTION_ID_MISS_MATCH',

  /** Tags */
  TAG_NOT_OWNED_BY_PROJECT: 'TAG_NOT_OWNED_BY_PROJECT',
  TAG_CREATE_FAILED: 'TAG_CREATE_FAILED',
  TAG_TEXT_DUPLICATES: 'TAG_TEXT_DUPLICATES',
  DELETE_TAG_TEXT_FAILED: 'DELETE_TAG_TEXT_FAILED',
  UPDATE_TAG_TEXT_FAILED: 'UPDATE_TAG_TEXT_FAILED',
  TAG_NOT_FOUND: 'TAG_NOT_FOUND',
  TAG_DELETE_FAILED: 'TAG_DELETE_FAILED',
  TAG_ASSIGN_REQUEST_FAILED: 'TAG_ASSIGN_REQUEST_FAILED',
  TAG_UNASSIGN_REQUEST_FAILED: 'TAG_UNASSIGN_REQUEST_FAILED',

  /** Themes */
  THEME_CREATE_FAILED: 'THEME_CREATE_FAILED',
  THEME_UPDATE_FAILED: 'THEME_UPDATE_FAILED',
  THEME_GENERATE_PALETTE_FAILED: 'THEME_GENERATE_PALETTE_FAILED',

  /** Segments */
  SEGMENT_NOT_OWNED_BY_PROJECT: 'SEGMENT_NOT_OWNED_BY_PROJECT',
  CREATE_SEGMENT_ATTRIBUTES_FAILED: 'CREATE_SEGMENT_ATTRIBUTES_FAILED',
  CUSTOM_ATTRIBUTE_NOT_FOUND: 'CUSTOM_ATTRIBUTE_NOT_FOUND',
  MATCH_JIMERS_FAILED: 'MATCH_JIMERS_FAILED',
  SEGMENT_ATTRIBUTES_MUST_BE_JOIN: 'SEGMENT_ATTRIBUTES_MUST_BE_JOIN',
  SEGMENT_PROJECT_MUST_BE_JOIN: 'SEGMENT_PROJECT_MUST_BE_JOIN',

  /** Subscriptions */
  NOT_ELIGIBLE_TO_TRIAL: 'NOT_ELIGIBLE_TO_TRIAL',
  GET_TAX_FAILED: 'GET_TAX_FAILED',

  /** Stripe */
  STRIPE_SUBSCRIPTIONS_DEL_FAILED: 'STRIPE_SUBSCRIPTIONS_DEL_FAILED',
  STRIPE_SUBSCRIPTION_CREATE_FAILED: 'STRIPE_SUBSCRIPTION_CREATE_FAILED',
  STRIPE_PRORATION_CALC_FAILED: 'STRIPE_PRORATION_CALC_FAILED',
  STRIPE_PROMOTION_CODE_INVALID: 'STRIPE_PROMOTION_CODE_INVALID',

  /** Files */
  FILE_TYPE_NOT_DETECTED: 'FILE_TYPE_NOT_DETECTED',
  FILE_TYPE_NOT_AUTHORIZED: 'FILE_TYPE_NOT_AUTHORIZED',
  FILE_CSV_EXPECTED: 'FILE_CSV_EXPECTED',
  FILE_SIZE_LIMIT_EXCEEDED: 'FILE_SIZE_LIMIT_EXCEEDED',

  /** Data Import */
  DATA_IMPORT_NOT_FOUND: 'DATA_IMPORT_NOT_FOUND',

  /** Integration : Customerio */
  UPDATE_PROJECT_CIO_FAILED: 'UPDATE_PROJECT_CIO_FAILED',
  CIO_CREATE_FAILED: 'CIO_CREATE_FAILED',
  CIO_ALREADY_SETUP: 'CIO_ALREADY_SETUP',
  CIO_NOT_FOUND: 'CIO_NOT_FOUND',
  DELETE_CIO_FAILED: 'DELETE_CIO_FAILED',
  CIO_REPORTING_WEBHOOK_ALREADY_EXISTS: 'CIO_REPORTING_WEBHOOK_ALREADY_EXISTS',
  CIO_REPORTING_WEBHOOK_NOT_FOUND: 'CIO_REPORTING_WEBHOOK_NOT_FOUND',

  /** Others */
  SIGN_UP_GOOGLE_FAILED: 'SIGN_UP_GOOGLE_FAILED',
  EMAIL_ALREADY_TAKEN: 'EMAIL_ALREADY_TAKEN',
  EMAIL_IS_NOT_CORPORATE: 'EMAIL_IS_NOT_CORPORATE',
  EMAIL_NOT_VERIFIED: 'EMAIL_NOT_VERIFIED',
  EMAIL_ALREADY_INVITED: 'EMAIL_ALREADY_INVITED',
  SECURITY_VERIFICATION_FAILED: 'SECURITY_VERIFICATION_FAILED',
  CODE_NOT_FOUND: 'CODE_NOT_FOUND',
  GTM_CREATE_TAG_FAILED: 'CREATE_TAG_FAILED',
  SMART_TRANSLATE_FAILED: 'SMART_TRANSLATE_FAILED',
  DOMAIN_NOT_VALID: 'DOMAIN_NOT_VALID',
  ACCESS_LIMITED_BY_PLAN: 'ACCESS_LIMITED_BY_PLAN',
  EXISTING_CUSTOM_VALIDATION: 'EXISTING_CUSTOM_VALIDATION',
  CUSTOM_DOMAIN_ALREADY_DEPLOYED: 'CUSTOM_DOMAIN_ALREADY_DEPLOYED',
  LIVE_TOURS_LIMIT_REACHED: 'LIVE_TOURS_LIMIT_REACHED',
  LIVE_BANNERS_LIMIT_REACHED: 'LIVE_BANNERS_LIMIT_REACHED',
  LIVE_HINTS_LIMIT_REACHED: 'LIVE_HINTS_LIMIT_REACHED',
  LIVE_SURVEYS_LIMIT_REACHED: 'LIVE_SURVEYS_LIMIT_REACHED',
  LIVE_CHECKLISTS_LIMIT_REACHED: 'LIVE_CHECKLISTS_LIMIT_REACHED',
};

export const parseError = (err) => {
  const result = {
    code: err.message,
    title: null,
    message: null,
    actions: [],
  };

  if (err.response == null) {
    result.code = err.message;
    result.title = 'Unexpected error';
    result.message = `An unexpected error has been detected. Please try again or contact the support!`;
    result.actions = [
      {
        text: 'Contact support',
        props: {
          onClick: () => {
            crispHelpers.startCrispThread(
              `I just ran into an unexpected error (${err.message}). Can you help me ? :-)`
            );
          },
          iconLeft: 'icon-chat',
        },
      },
    ];
    return result;
  }
  if (err.response.status === 401) {
    result.code = 'unauthorized request';
    result.title = 'Unauthorized action';
    result.message =
      'We are unable to perform this action because you seems logged off. Please try to refresh this page.';
    return result;
  }
  if (err.response.status === 413) {
    result.code = 'file too large';
    result.title = 'File too large';
    result.message = 'The size of the file you tried to upload is above 1MB.';
    return result;
  }
  if (err.response.data == null || err.response.data.message == null) {
    result.code = err.message;
    result.title = 'Unexpected error';
    result.message = `An unexpected error has been detected. Please try again or contact the support!`;
    result.actions = [
      {
        text: 'Contact support',
        props: {
          onClick: () => {
            crispHelpers.startCrispThread(
              `I just ran into an unexpected error (${err.message}). Can you help me ? :-)`
            );
          },
          iconLeft: 'icon-chat',
        },
      },
    ];
    return result;
  }

  const responseCode = err.response.data.message;

  result.code = responseCode;
  switch (responseCode) {
    /** Project */
    case errorCodes.PROJECT_NOT_FOUND: {
      result.title = 'Project not found';
      result.message =
        "We couldn't find the project therefore we are unable to perform this action";
      break;
    }
    case errorCodes.PROJECT_DOMAIN_ALREADY_TAKEN: {
      result.title = 'Project error';
      result.message =
        "We couldn't update your project domain cause it's already taken. Please try again or contact us!";
      result.actions = [
        {
          text: 'Chat with us',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I just ran into an error while updating my project domain (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }
    case errorCodes.PROJECT_UPDATE_FAILED: {
      result.title = 'Project error';
      result.message =
        "We couldn't update your project due to an unexpected error. Please try again or contact us!";
      result.actions = [
        {
          text: 'Chat with us',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I just ran into an error while updating my project (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }

    /** Users */
    case errorCodes.USER_UPDATE_INFO_FAILED: {
      result.title = 'User error';
      result.message =
        "We couldn't update your information. Please try again or contact us!";
      result.actions = [
        {
          text: 'Chat with us',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I just ran into an error while updating my user info (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }
    case errorCodes.USER_ALREADY_MEMBER: {
      result.title = 'Already member';
      result.message =
        'You or the user you are trying to add is already a member of the project. Contact us if you need more infos';
      break;
    }

    /** Evolutions */
    case errorCodes.EVOLUTION_NOT_OWNED_BY_PROJECT: {
      result.title = 'Evolution not part of this project';
      result.message =
        "We are unable to perform this action because the evolution doesn't belong to this project";
      break;
    }
    case errorCodes.CREATE_EVOLUTION_LIST_VIEW_FAILED: {
      result.title = 'Creation view failed';
      result.message =
        'We are unable to create this view. Try again or contact us!';
      result.actions = [
        {
          text: 'Chat with us',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I just ran into an error while creating a view (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }
    case errorCodes.UPDATE_EVOLUTION_LIST_VIEW_FAILED: {
      result.title = 'Update view failed';
      result.message =
        'We are unable to update this view. Try again or contact us!';
      result.actions = [
        {
          text: 'Chat with us',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I just ran into an error while updating a view (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }
    case errorCodes.DELETE_EVOLUTION_LIST_VIEW_FAILED: {
      result.title = 'Delete view failed';
      result.message =
        'We are unable to delete this view. Try again or contact us!';
      result.actions = [
        {
          text: 'Chat with us',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I just ran into an error while deleting a view (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }
    case errorCodes.DELETE_BOOSTED_PATH_FAILED:
    case errorCodes.UPDATE_BOOSTED_PATH_FAILED:
    case errorCodes.CREATE_BOOSTED_PATH_FAILED: {
      result.title = 'Evolution error';
      result.message =
        "Sorry, an unexpected error happen. No worries, let's chat and fix this!";
      result.actions = [
        {
          text: 'Chat with us',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I just ran into an evolution error (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }

    /** Events */
    case errorCodes.EVENT_UPDATE_FAILED: {
      result.title = 'Event update failed';
      result.message =
        'We are unable to update this event. Please try again or contact us';
      result.actions = [
        {
          text: 'Chat with us',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I just ran into an event error (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }
    case errorCodes.EVENT_CREATE_FAILED: {
      result.title = 'Event creation failed';
      result.message =
        'We are unable to create this event. Please try again or contact us';
      result.actions = [
        {
          text: 'Chat with us',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I just ran into an event error (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }

    /** Comments */
    case errorCodes.COMMENT_LIKE_NOT_FOUND: {
      result.title = 'Like not found';
      result.message =
        "The comment like you are trying to access doesn't exists anymore";
      break;
    }
    case errorCodes.COMMENT_NOT_OWNED: {
      result.title = 'Comment not owned';
      result.message =
        'You are not the owner of the comment therefore we are unable to perform this action';
      break;
    }
    case errorCodes.COMMENTS_DISABLED: {
      result.title = 'Comments disabled';
      result.message = "Couldn't create comment because comments are disabled";
      break;
    }
    case errorCodes.ANONYMOUS_COMMENTS_DISABLED: {
      result.title = 'Anonymous comments disabled';
      result.message =
        "Couldn't create anonymous comment because anonymous comments are disabled";
      break;
    }
    case errorCodes.COMMENT_TO_REPLY_NOT_FOUND: {
      result.title = 'Reply not found';
      result.message =
        "We couldn't find the reply comment therefore we are unable to perform this action";
      break;
    }

    /** Requests */
    case errorCodes.REQUEST_NOT_OWNED_BY_PROJECT: {
      result.title = 'Request not part of this project';
      result.message =
        "We are unable to perform this action because a request doesn't belong to this project";
      break;
    }
    case errorCodes.REQUEST_NOT_FOUND: {
      result.title = 'Request not found';
      result.message =
        "We are unable to perform this action because we couldn't find a request";
      break;
    }

    /** Prototypes */
    case errorCodes.PROTOTYPE_NOT_FOUND: {
      result.title = 'Prototype not found';
      result.message =
        "We couldn't find prototype therefore we are unable to perform this action";
      break;
    }
    case errorCodes.PROTOTYPE_EVOLUTION_ID_MISS_MATCH: {
      result.title = 'Prototype error';
      result.message =
        'Something is wrong with this prototype, please contact our support!';
      result.actions = [
        {
          text: 'Contact support',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I just ran into a prototype error (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }

    /** Tags */
    case errorCodes.TAG_NOT_FOUND: {
      result.title = 'Tag not found';
      result.message =
        "We are unable to perform this action because the tag doesn't exists";
      break;
    }
    case errorCodes.TAG_DELETE_FAILED: {
      result.title = 'Delete tag failed';
      result.message =
        'We are unable to delete this tag. Please contact our support!';
      result.actions = [
        {
          text: 'Contact support',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I just ran into an error when deleting a tag (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }
    case errorCodes.TAG_NOT_OWNED_BY_PROJECT: {
      result.title = 'Tag error';
      result.message =
        "We are unable to perform this action because a tag doesn't belong to this project";
      break;
    }
    case errorCodes.TAG_ASSIGN_REQUEST_FAILED: {
      result.title = 'Tag assign failed';
      result.message =
        "We couldn't assign this tag, please try again or contact our support!";
      result.actions = [
        {
          text: 'Contact support',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I just ran into an error when assigning a tag (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }
    case errorCodes.TAG_UNASSIGN_REQUEST_FAILED: {
      result.title = 'Tag unassign failed';
      result.message =
        "We couldn't unassign this tag, please try again or contact our support!";
      result.actions = [
        {
          text: 'Contact support',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I just ran into an error when unassigning a tag (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }
    case errorCodes.TAG_CREATE_FAILED: {
      result.title = 'Tag creation error';
      result.message =
        "We couldn't create the tag, please contact our support!";
      result.actions = [
        {
          text: 'Contact support',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I just ran into an error when creating a tag (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }
    case errorCodes.TAG_TEXT_DUPLICATES:
    case errorCodes.DELETE_TAG_TEXT_FAILED:
    case errorCodes.UPDATE_TAG_TEXT_FAILED: {
      result.title = 'Tag error';
      result.message =
        "Sorry, an unexpected error happen. No worries, let's chat and fix this!";
      result.actions = [
        {
          text: 'Chat with us',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I just ran into a tag error (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }

    /** Themes */
    case errorCodes.THEME_CREATE_FAILED: {
      result.title = 'Theme creation failed';
      result.message =
        "We couldn't create a theme. Please try again or contact our support!";
      result.actions = [
        {
          text: 'Contact support',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I ran into an error while trying to create a theme (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }
    case errorCodes.THEME_UPDATE_FAILED: {
      result.title = 'Theme update failed';
      result.message =
        "We couldn't update this theme. Please try again or contact our support!";
      result.actions = [
        {
          text: 'Contact support',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I ran into an error while trying to update a theme (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }
    case errorCodes.THEME_GENERATE_PALETTE_FAILED: {
      result.title = 'Palette generation failed';
      result.message =
        "We couldn't generate the palette. Please try again or contact our support!";
      result.actions = [
        {
          text: 'Contact support',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I ran into an error while trying to generate a palette for a theme (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }

    /** Segments */
    case errorCodes.SEGMENT_NOT_OWNED_BY_PROJECT: {
      result.title = 'Segment error';
      result.message =
        "We are unable to perform this action because a segment doesn't belong to this project";
      break;
    }
    case errorCodes.CREATE_SEGMENT_ATTRIBUTES_FAILED: {
      result.title = 'Segment attribute creation failed';
      result.message =
        "We couldn't create an attribute for the segment. Please try again or contact our support!";
      result.actions = [
        {
          text: 'Contact support',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I ran into a segment error (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }
    case errorCodes.CUSTOM_ATTRIBUTE_NOT_FOUND:
    case errorCodes.MATCH_JIMERS_FAILED:
    case errorCodes.SEGMENT_ATTRIBUTES_MUST_BE_JOIN:
    case errorCodes.SEGMENT_PROJECT_MUST_BE_JOIN: {
      result.title = 'Segment error';
      result.message =
        "An unexpected error happened. No worries, let's chat to fix this!";
      result.actions = [
        {
          text: 'Chat with us',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I ran into a segment error (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }

    /** Subscription */
    case errorCodes.NOT_ELIGIBLE_TO_TRIAL: {
      result.title = 'Not eligible to trial';
      result.message =
        "Your project doesn't seems to be eligible for a trial. Contact us if you have any questions.";
      result.actions = [
        {
          text: 'Contact support',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `My project doesn't seems to be eligible for a trial (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }
    case errorCodes.GET_TAX_FAILED: {
      result.title = 'Fetching tax failed';
      result.message =
        "We couldn't fetch tax from the given information. Reach out to us if needed";
      result.actions = [
        {
          text: 'Chat with us',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I couldn't get my tax data applied (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }

    /** Stripe */
    case errorCodes.STRIPE_SUBSCRIPTIONS_DEL_FAILED:
    case errorCodes.STRIPE_SUBSCRIPTION_CREATE_FAILED: {
      result.title = 'Subscription error';
      result.message =
        'Sorry, something is wrong with your subscription. Chat with us now!';
      result.actions = [
        {
          text: 'Chat with us',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I ran into an issue with my subscription (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }
    case errorCodes.STRIPE_PRORATION_CALC_FAILED: {
      result.title = 'Subscription error';
      result.message =
        "Sorry, your subscription isn't setup properly. Let's chat to fix this!";
      result.actions = [
        {
          text: 'Chat with us',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I ran into an issue while trying to update my subscription (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }
    case errorCodes.STRIPE_PROMOTION_CODE_INVALID: {
      result.title = 'Promotion code invalid';
      result.message =
        'Sorry, the promotion code you provided is invalid. Please try again with a valid one or contact us!';
      result.actions = [
        {
          text: 'Chat with us',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I ran into an issue while trying to use a coupon code with my subscription. Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }

    /** Files */
    case errorCodes.FILE_TYPE_NOT_DETECTED: {
      result.title = 'File error';
      result.message =
        "We couldn't the type of your file. Please try again with another file.";
      break;
    }
    case errorCodes.FILE_TYPE_NOT_AUTHORIZED: {
      result.title = 'File error';
      result.message =
        'The type of file you provided is not authorized. Please try again with one of the following : JPG, JPEG, GIF or PNG';
      break;
    }
    case errorCodes.FILE_CSV_EXPECTED: {
      result.title = 'CSV file expected';
      result.message =
        "The file you provided isn't a CSV file. Please make sure to provide a valid CSV file.";
      break;
    }

    /** Data Import */
    case errorCodes.DATA_IMPORT_NOT_FOUND: {
      result.title = 'Import not found';
      result.message =
        "The import you are trying to access doesn't exists. Contact our support if it's not expected.";
      result.actions = [
        {
          text: 'Chat with us',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I ran into an issue while trying to access my import (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }

    /** Integration : Customerio */
    case errorCodes.UPDATE_PROJECT_CIO_FAILED:
    case errorCodes.CIO_CREATE_FAILED: {
      result.title = 'Customerio setup failed';
      result.message =
        "An error occurred when trying to update the customerio integration of your project. Let's chat to fix this!";
      result.actions = [
        {
          text: 'Chat with us',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I ran into an issue while trying to update my Customerio project integration (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }
    case errorCodes.CIO_ALREADY_SETUP: {
      result.title = 'Customerio already setup';
      result.message =
        'Customerio is already setup on this project. Please delete the current integration before creating a new one.';
      break;
    }
    case errorCodes.CIO_NOT_FOUND: {
      result.title = 'Customerio integration not found';
      result.message =
        "Customerio doesn't seems to be setup. Try to setup it from your project settings or chat with us.";
      result.actions = [
        {
          text: 'Chat with us',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I ran into an issue while trying to use the Customerio integration (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }
    case errorCodes.DELETE_CIO_FAILED: {
      result.title = 'Remove integration failed';
      result.message =
        "An error occurred when trying to delete your Customer.io integration. Let's chat to fix this!";
      result.actions = [
        {
          text: 'Chat with us',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I ran into an issue while trying to delete the Customerio integration (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }
    case errorCodes.CIO_REPORTING_WEBHOOK_ALREADY_EXISTS: {
      result.title = 'Webhook already exists';
      result.message =
        "An error occurred when trying to update your the Customer.io reporting webhook. Let's chat to fix this!";
      result.actions = [
        {
          text: 'Chat with us',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I ran into an issue while trying to update the Customerio reporting webhook (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }
    case errorCodes.CIO_REPORTING_WEBHOOK_NOT_FOUND: {
      result.title = 'Webhook not found';
      result.message =
        "An error occurred when trying to update the Customer.io reporting webhook. Let's chat to fix this!";
      result.actions = [
        {
          text: 'Chat with us',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I ran into an issue while trying to update the Customerio reporting webhook (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }

    /** Others */
    case errorCodes.SIGN_UP_GOOGLE_FAILED: {
      result.title = 'Google sign up failed';
      result.message =
        'We are unable to register your account using your google account. Try to create your account using a password or contact the support!';
      result.actions = [
        {
          text: 'Contact support',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I just ran into an error when trying to register with my google account (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }
    case errorCodes.EMAIL_ALREADY_TAKEN: {
      result.title = 'Email already taken';
      result.message = 'We already have an account with this email!';
      break;
    }
    case errorCodes.EMAIL_NOT_VERIFIED: {
      result.title = 'Email not verified';
      result.message =
        'We have sent an email to verify your account. Please click the link inside this email. If you are having troubles getting the email, please contact our support.';
      result.actions = [
        {
          text: 'Contact support',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I just ran into an error while trying to sign up with my email/password (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }
    case errorCodes.EMAIL_IS_NOT_CORPORATE: {
      result.title = 'Email not corporate';
      result.message =
        'Please use your corporate email to register on Jimo. If you are in fact using your corporate email, please contact our support.';
      result.actions = [
        {
          text: 'Contact support',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I just ran into an error while trying to register with my corporate email (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }
    case errorCodes.EMAIL_ALREADY_INVITED: {
      result.title = 'Email already invited';
      result.message =
        'It seems that you already invited this member to join your project';
      break;
    }
    case errorCodes.SECURITY_VERIFICATION_FAILED: {
      result.title = 'Security verification failed';
      result.message =
        'We are unable to perform the security verification, please try again or contact the support';
      result.actions = [
        {
          text: 'Contact support',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I just ran into an error while trying to perform a sensitive action (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }
    case errorCodes.GTM_CREATE_TAG_FAILED: {
      result.title = 'GTM Tag creation failed';
      result.message =
        'We are unable to create the GTM tag, please try again or contact the support';
      result.actions = [
        {
          text: 'Contact support',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I just ran into an error while trying to integrate jimo with GTM (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }
    case errorCodes.SMART_TRANSLATE_FAILED: {
      result.title = 'Translation failed';
      result.message =
        'We are unable to translate this content at the moment. Please try again or contact us.';
      result.actions = [
        {
          text: 'Chat with us',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I just ran into an error while trying to smart translate content (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }
    case errorCodes.DOMAIN_NOT_VALID: {
      result.title = 'Domain not valid';
      result.message =
        'The domain that you have passed is not valid, please use a valid domain.';
      break;
    }
    case errorCodes.EXISTING_CUSTOM_VALIDATION: {
      result.title = 'Existing validation';
      result.message =
        'You already have a validation ongoing. Please complete the current one before trying to create a new one!';
      break;
    }
    case errorCodes.CUSTOM_DOMAIN_ALREADY_DEPLOYED: {
      result.title = 'Custom domain already deployed';
      result.message =
        'Your custom domain has already been deployed. Refresh the page to see the changes!';
      break;
    }
    case errorCodes.ACCESS_LIMITED_BY_PLAN: {
      result.title = 'Upgrade required';
      result.message =
        "Your current plan doesn't allow you to perform this action. Please contact us!";
      result.actions = [
        {
          text: 'Chat with us',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I'm limited by my plan. I'm getting the following error (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
      break;
    }

    default: {
      logger.warn('No handler for response code ', responseCode);
      result.code = responseCode;
      result.title = 'Unexpected error';
      result.message =
        'Sorry, we have encountered an unexpected error. Please try again or contact our support.';
      result.actions = [
        {
          text: 'Contact support',
          props: {
            onClick: () =>
              crispHelpers.startCrispThread(
                `I just ran into an unexpected error (${responseCode}). Can you help me ? :-)`
              ),
            iconLeft: 'icon-chat',
          },
        },
      ];
    }
  }
  return result;
};
