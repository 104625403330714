import classNames from 'classnames';
import InputGroup from 'components/Input';
import {BuilderContext} from 'contextes/builder';
import {hasFlag} from 'helpers/bitwise';
import {getTypeFromBoostFlags} from 'helpers/poke';
import {getStepIssue} from 'helpers/step';
import React, {useContext, useEffect, useState} from 'react';
import AnimateHeight from 'react-animate-height';
import {getIcon} from 'scenes/Pushes/components/ModalCreatePoke/components/TemplatesModal';
import {
  EVOLUTION_TYPE_BANNER,
  EVOLUTION_TYPE_HINT,
  EVOLUTION_TYPE_SURVEY,
  EVOLUTION_TYPE_TOUR,
  F_BOOST_SLOT_CURSOR,
  F_BOOST_SLOT_DOT,
  F_BOOST_SLOT_HINT,
  F_BOOST_SLOT_NAVIGATION,
  F_BOOST_SLOT_TOOLTIP,
} from 'services/evolution';
import {
  BLOCK_TYPE_ANIMATION,
  BLOCK_TYPE_BODY,
  BLOCK_TYPE_CHECKLIST_HEADER_DESCRIPTION,
  BLOCK_TYPE_CHECKLIST_HEADER_PROGRESS,
  BLOCK_TYPE_CHECKLIST_HEADER_TITLE,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_CHECKBOX,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_DESCRIPTION,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_MEDIA,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_PRIMARY_CTA,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_SECONDARY_CTA,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_TITLE,
  BLOCK_TYPE_CHOICE,
  BLOCK_TYPE_CONCEPT,
  BLOCK_TYPE_CURSOR,
  BLOCK_TYPE_DISMISS_CROSS,
  BLOCK_TYPE_HINT,
  BLOCK_TYPE_HOTSPOT,
  BLOCK_TYPE_INTERVIEW,
  BLOCK_TYPE_LABEL,
  BLOCK_TYPE_MEDIA,
  BLOCK_TYPE_NPS,
  BLOCK_TYPE_OPEN_QUESTION,
  BLOCK_TYPE_OPINION,
  BLOCK_TYPE_PRIMARY_CTA,
  BLOCK_TYPE_SECONDARY_CTA,
  BLOCK_TYPE_SLIDER,
  BLOCK_TYPE_STEPPER,
  BLOCK_TYPE_TARGET,
  BLOCK_TYPE_TITLE,
  BLOCK_TYPE_USER,
  BLOCK_TYPE_VISUAL_CUE,
  STEP_TYPE_INTERVIEW,
} from 'services/steps';
import {
  HINT_TYPE_BUTTON,
  HINT_TYPE_HIDDEN,
  HINT_TYPE_ICON,
  HINT_TYPE_LABEL,
  HINT_TYPE_STICKER,
} from '../BlockEditor/blocks/Hint';
import {MODE_ADD_BLOCK} from '../PokeBuilderSidebar';
import './_Styles.scss';

const propTypes = {};
const defaultProps = {};

export const blocksObj = [
  {
    type: BLOCK_TYPE_TITLE,
    name: 'Heading',
    iconClassName: 'icon-text',
  },
  {
    type: BLOCK_TYPE_BODY,
    name: 'Paragraph',
    iconClassName: 'icon-text',
  },
  {
    type: BLOCK_TYPE_LABEL,
    name: 'Label',
    iconClassName: 'icon-pin',
  },
  {
    type: BLOCK_TYPE_MEDIA,
    name: 'Image/Video',
    iconClassName: 'icon-image-o',
  },
  {
    type: BLOCK_TYPE_USER,
    name: 'Profile',
    iconClassName: 'icon-user-interview',
  },
  {
    type: BLOCK_TYPE_PRIMARY_CTA,
    name: 'Primary CTA',
    iconClassName: 'icon-pointer',
  },
  {
    type: BLOCK_TYPE_SECONDARY_CTA,
    name: 'Secondary CTA',
    iconClassName: 'icon-pointer',
  },
  {
    type: BLOCK_TYPE_STEPPER,
    name: 'Stepper',
    iconClassName: 'icon-horizontal-menu',
  },
  {
    type: BLOCK_TYPE_CHOICE,
    name: 'Multiple choice',
    iconClassName: 'icon-vote-multi-choice',
  },
  {
    type: BLOCK_TYPE_SLIDER,
    name: 'Slider',
    iconClassName: 'icon-vote-slider',
  },
  {
    type: BLOCK_TYPE_NPS,
    name: 'NPS',
    iconClassName: 'icon-vote-nps',
  },
  {
    type: BLOCK_TYPE_OPINION,
    name: 'Opinion scale',
    iconClassName: 'icon-vote-opinion',
  },
  {
    type: BLOCK_TYPE_ANIMATION,
    name: 'Animation',
    iconClassName: 'isax isax-star-1',
  },
  {
    type: BLOCK_TYPE_OPEN_QUESTION,
    name: 'Open question',
    iconClassName: 'icon-vote-open-question',
  },
  {
    type: BLOCK_TYPE_CONCEPT,
    name: 'Concept test',
    iconClassName: 'icon-filter-preview',
  },
  {
    type: BLOCK_TYPE_INTERVIEW,
    name: 'Interview',
    iconClassName: 'icon-user-interview',
  },
  {
    type: BLOCK_TYPE_CHECKLIST_TASK_ITEM_CHECKBOX,
    name: 'Checkmark',
    iconClassName: 'icon-tick',
  },
  {
    type: BLOCK_TYPE_CHECKLIST_HEADER_TITLE,
    name: 'Heading',
    iconClassName: 'icon-text',
  },
  {
    type: BLOCK_TYPE_CHECKLIST_HEADER_DESCRIPTION,
    name: 'Paragraph',
    iconClassName: 'icon-text',
  },
  {
    type: BLOCK_TYPE_CHECKLIST_HEADER_PROGRESS,
    name: 'Progress',
    iconClassName: 'isax isax-flag',
  },
  {
    type: BLOCK_TYPE_CHECKLIST_TASK_ITEM_TITLE,
    name: 'Heading',
    iconClassName: 'icon-text',
  },
  {
    type: BLOCK_TYPE_CHECKLIST_TASK_ITEM_DESCRIPTION,
    name: 'Paragraph',
    iconClassName: 'icon-text',
  },
  {
    type: BLOCK_TYPE_CHECKLIST_TASK_ITEM_MEDIA,
    name: 'Image/Video',
    iconClassName: 'icon-image-o',
  },
  {
    type: BLOCK_TYPE_CHECKLIST_TASK_ITEM_PRIMARY_CTA,
    name: 'Primary CTA',
    iconClassName: 'icon-pointer',
  },
  {
    type: BLOCK_TYPE_CHECKLIST_TASK_ITEM_SECONDARY_CTA,
    name: 'Secondary CTA',
    iconClassName: 'icon-pointer',
  },
  {
    type: BLOCK_TYPE_DISMISS_CROSS,
    name: 'Cross',
    iconClassName: 'icon-close',
  },
];

const Navigator = () => {
  const {
    evolution: parentEvolution,
    controlledEvolution: evolution,
    selectedStepId,
    selectedBlockType,
    setSelectedBlockType,
    selectedStep: step,
    updateStep,
    isInApp,
    setMode,
  } = useContext(BuilderContext);

  const [showStepNameInput, setShowStepNameInput] = useState(false);
  const [expanded, setExpanded] = useState(true);

  const hintBlock = step?.blocks?.find((b) => b.type === BLOCK_TYPE_HINT);
  const isHintButton = hintBlock?.style?.type === HINT_TYPE_BUTTON;

  useEffect(() => {
    if (!step?.name) {
      setShowStepNameInput(true);
    } else {
      setShowStepNameInput(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step?.uid]);

  useEffect(() => {
    if (isHintButton === true) {
      setSelectedBlockType(BLOCK_TYPE_HINT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHintButton]);

  if (selectedStepId == null || step == null) {
    return <></>;
  }

  const onStepNameChange = (e) => {
    const {value} = e.target;
    updateStep(step.uid, {name: value});
  };

  const isHint = hasFlag(evolution.boostFlags, F_BOOST_SLOT_HINT);
  const isHotspot = hasFlag(evolution.boostFlags, F_BOOST_SLOT_DOT);
  const isTooltip = hasFlag(evolution.boostFlags, F_BOOST_SLOT_TOOLTIP);
  const isCursor = hasFlag(evolution.boostFlags, F_BOOST_SLOT_CURSOR);
  const isNotPrototypeStep =
    evolution.steps.find((s) => s.uid === step.uid) != null;

  const {blocks = [], name} = step;

  const orderedBlockTypes = [
    BLOCK_TYPE_DISMISS_CROSS,
    BLOCK_TYPE_USER,
    BLOCK_TYPE_MEDIA,
    BLOCK_TYPE_LABEL,
    BLOCK_TYPE_TITLE,
    BLOCK_TYPE_BODY,
    BLOCK_TYPE_STEPPER,
    BLOCK_TYPE_CHOICE,
    BLOCK_TYPE_SLIDER,
    BLOCK_TYPE_NPS,
    BLOCK_TYPE_OPINION,
    BLOCK_TYPE_ANIMATION,
    BLOCK_TYPE_OPEN_QUESTION,
    BLOCK_TYPE_CONCEPT,
    BLOCK_TYPE_INTERVIEW,
    BLOCK_TYPE_PRIMARY_CTA,
    BLOCK_TYPE_SECONDARY_CTA,
  ];
  const filteredBlocks = orderedBlockTypes.filter((type) =>
    blocks.some((block) => {
      if (
        isCursor === true &&
        [
          BLOCK_TYPE_CURSOR,
          BLOCK_TYPE_USER,
          BLOCK_TYPE_BODY,
          BLOCK_TYPE_MEDIA,
          BLOCK_TYPE_DISMISS_CROSS,
          BLOCK_TYPE_ANIMATION,
        ].includes(type) !== true
      ) {
        return false;
      }
      if (block.type === type && block.removed !== true) {
        return true;
      }
      return false;
    })
  );

  const stepIcon = getIcon(getTypeFromBoostFlags(evolution.boostFlags));

  let hasNoPrevStep = false,
    prevStepIsNavigation = false;

  if (
    [EVOLUTION_TYPE_BANNER, EVOLUTION_TYPE_TOUR, EVOLUTION_TYPE_HINT].includes(
      parentEvolution.type
    )
  ) {
    const tourSteps = JSON.parse(JSON.stringify(parentEvolution.tourSteps));
    tourSteps.forEach((ts) => {
      const [tourIndexOrder] = (ts.tourStepInfo || '0;0;0').split(';');
      ts.tourIndexOrder = parseInt(tourIndexOrder, 10);
      ts.steps.forEach((s) => {
        s.boostFlags = ts.boostFlags;
      });
    });
    tourSteps.sort((a, b) => a.tourIndexOrder - b.tourIndexOrder);
    const actualSteps = tourSteps
      ?.map((t) => t.steps?.sort((a, b) => a.indexOrder - b.indexOrder))
      .flat();
    hasNoPrevStep = step.uid === actualSteps[0]?.uid;

    const tourStepIndex = tourSteps.findIndex((ts) =>
      ts.steps.some((s) => s.uid === step.uid)
    );
    const stepIndex = tourSteps[tourStepIndex].steps.findIndex(
      (s) => s.uid === step.uid
    );
    prevStepIsNavigation =
      tourStepIndex > 0 &&
      tourSteps[tourStepIndex - 1].boostFlags === F_BOOST_SLOT_NAVIGATION &&
      stepIndex === 0;
  } else {
    hasNoPrevStep = step.uid === evolution.steps[0].uid;
  }

  const issues = getStepIssue(step, {hasNoPrevStep, prevStepIsNavigation});

  const NavigatorItem = ({type, iconClassName, name, stepType}) => {
    const onDeleteClick = (e) => {
      e.stopPropagation();

      // if the block is a CTA, flag them as removed instead of hard deleting them for analytics purposes
      if ([BLOCK_TYPE_PRIMARY_CTA, BLOCK_TYPE_SECONDARY_CTA].includes(type)) {
        updateStep(step.uid, {
          blocks: blocks.map((b) => {
            if (b.type === type) {
              return {
                ...b,
                removed: true,
              };
            }
            return b;
          }),
        });
      } else {
        updateStep(step.uid, {
          blocks: blocks.filter((b) => b.type !== type),
        });
      }
      if (selectedBlockType === type) {
        setSelectedBlockType(null);
      }
    };
    const preventDelete =
      evolution.type === EVOLUTION_TYPE_SURVEY &&
      ([
        BLOCK_TYPE_CHOICE,
        BLOCK_TYPE_NPS,
        BLOCK_TYPE_CONCEPT,
        BLOCK_TYPE_OPEN_QUESTION,
        BLOCK_TYPE_OPINION,
        BLOCK_TYPE_INTERVIEW,
        BLOCK_TYPE_SLIDER,
      ].includes(type) ||
        (stepType === STEP_TYPE_INTERVIEW &&
          [BLOCK_TYPE_PRIMARY_CTA, BLOCK_TYPE_SECONDARY_CTA].includes(type)));

    return (
      <div
        className={classNames('navigator-item', {
          selected: selectedBlockType === type,
          invalid: issues?.some((i) => i.blockType === type),
        })}
        onClick={() => setSelectedBlockType(type)}>
        <div className="icon-wrapper">
          <i className={iconClassName} />
        </div>
        {name}
        {preventDelete === false && (
          <div className="delete-icon-wrapper" onClick={onDeleteClick}>
            <i className="icon-close" />
          </div>
        )}
      </div>
    );
  };

  const hasTargetedElement = isHotspot || isTooltip || isCursor;
  const hasVisualCue =
    hasTargetedElement && blocks.some((b) => b.type === BLOCK_TYPE_VISUAL_CUE);

  return (
    <div className="navigator-wrapper">
      <div className="navigator">
        {isInApp !== true && <div className="section-title">Elements</div>}
        {hasTargetedElement && (
          <div className="blocks-wrapper empty">
            <div
              className={classNames('blocks-title', {
                selected: selectedBlockType === BLOCK_TYPE_TARGET,
              })}
              onClick={() => setSelectedBlockType(BLOCK_TYPE_TARGET)}>
              <i className="isax isax-gps" />
              <div className="step-name">Target element</div>
            </div>
          </div>
        )}
        {isHotspot && isNotPrototypeStep && (
          <div className="blocks-wrapper empty">
            <div
              className={classNames('blocks-title', {
                selected: selectedBlockType === BLOCK_TYPE_HOTSPOT,
              })}
              onClick={() => setSelectedBlockType(BLOCK_TYPE_HOTSPOT)}>
              <i className="icon-slot-dot" />
              <div className="step-name">Hotspot</div>
            </div>
          </div>
        )}
        {isHint && (
          <div className="blocks-wrapper empty">
            <div
              className={classNames('blocks-title', {
                selected: selectedBlockType === BLOCK_TYPE_HINT,
              })}
              onClick={() => setSelectedBlockType(BLOCK_TYPE_HINT)}>
              {hintBlock?.style?.type === HINT_TYPE_ICON && (
                <>
                  <i className="icon-question-circle" />
                  <div className="step-name">Hint</div>
                </>
              )}
              {hintBlock?.style?.type === HINT_TYPE_LABEL && (
                <>
                  <i className="isax isax-tag5" />
                  <div className="step-name">Label</div>
                </>
              )}
              {hintBlock?.style?.type === HINT_TYPE_HIDDEN && (
                <>
                  <i className="icon-target" />
                  <div className="step-name">Target Element</div>
                </>
              )}
              {hintBlock?.style?.type === HINT_TYPE_BUTTON && (
                <>
                  <i className="icon-label-icon" />
                  <div className="step-name">Button</div>
                </>
              )}
              {hintBlock?.style?.type === HINT_TYPE_STICKER && (
                <>
                  <i className="isax isax-sticker5" />
                  <div className="step-name">Sticker</div>
                </>
              )}
            </div>
          </div>
        )}
        {isCursor && (
          <div className="blocks-wrapper empty">
            <div
              className={classNames('blocks-title', {
                selected: selectedBlockType === BLOCK_TYPE_CURSOR,
              })}
              onClick={() => setSelectedBlockType(BLOCK_TYPE_CURSOR)}>
              <i className="isax isax-mouse-1" />
              <div className="step-name">Cursor</div>
            </div>
          </div>
        )}
        {hasVisualCue && (
          <div className="blocks-wrapper empty">
            <div
              className={classNames('blocks-title', {
                selected: selectedBlockType === BLOCK_TYPE_VISUAL_CUE,
              })}
              onClick={() => setSelectedBlockType(BLOCK_TYPE_VISUAL_CUE)}>
              <i className="isax isax-sticker" />
              <div className="step-name">Visual cue</div>
              <div
                className="delete-icon-wrapper"
                onClick={() => {
                  updateStep(step.uid, {
                    blocks: blocks.filter(
                      (b) => b.type !== BLOCK_TYPE_VISUAL_CUE
                    ),
                  });
                  if (selectedBlockType === BLOCK_TYPE_VISUAL_CUE) {
                    setSelectedBlockType(null);
                  }
                }}>
                <i className="icon-close" />
              </div>
            </div>
          </div>
        )}
        {isHintButton !== true && (
          <div
            className={classNames('blocks-wrapper', {
              'in-app': isInApp === true,
              expanded,
            })}
            onClick={() => {
              if (isInApp === true && expanded !== true) {
                setExpanded(true);
              }
            }}>
            <div
              className={classNames('blocks-title', {
                selected: selectedBlockType == null,
              })}
              onClick={() => setSelectedBlockType(null)}>
              {stepIcon}
              {showStepNameInput === true ? (
                <InputGroup
                  className="step-name-input"
                  placeholder="Enter step name..."
                  value={step.name || ''}
                  onChange={onStepNameChange}
                  onBlur={() => setShowStepNameInput(false)}
                  autoFocus
                />
              ) : (
                <div
                  className={classNames('step-name', {'is-empty': !name})}
                  onDoubleClick={() => setShowStepNameInput(true)}>
                  {name || 'Enter step name...'}
                  {isInApp &&
                  expanded !== true &&
                  selectedBlockType != null &&
                  selectedBlockType !== 'hotspot'
                    ? `/${
                        blocksObj.find((b) => b.type === selectedBlockType)
                          ?.name
                      }`
                    : ''}
                </div>
              )}
              {isInApp === true && (
                <div
                  className="icon-expand-wrapper"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setExpanded(!expanded);
                  }}>
                  <i className={classNames('icon', 'icon-chevron-right')} />
                </div>
              )}
            </div>
            <AnimateHeight
              height={isInApp !== true || expanded === true ? 'auto' : 0}
              duration={300}>
              <div className="blocks">
                {filteredBlocks.map((type) => {
                  const {iconClassName, name} = blocksObj.find(
                    (b) => b.type === type
                  );
                  return (
                    <NavigatorItem
                      key={type}
                      type={type}
                      iconClassName={iconClassName}
                      name={name}
                      stepType={step?.type}
                    />
                  );
                })}
                <div
                  className="add-block-wrapper subtitle-4 sw-1"
                  onClick={() => setMode(MODE_ADD_BLOCK)}>
                  <div className="add-block-content">
                    <i className="icon-plus" />
                    Add element
                  </div>
                </div>
              </div>
            </AnimateHeight>
          </div>
        )}
      </div>
    </div>
  );
};

Navigator.propTypes = propTypes;
Navigator.defaultProps = defaultProps;

export default Navigator;
