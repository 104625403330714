import {uptProject} from 'actions/general';
import Button from 'components/Button';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {ROUTE_SETTINGS_INTEGRATIONS_MIXPANEL} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {mixpanelService} from 'services';
import {Swaler} from 'swaler';
import MixpanelLogo from '../imgs/mixpanel.svg';
import './_Styles.scss';

const logger = new Swaler('SettingsIntegrationsMixpanel');

const Mixpanel = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const project = useSelector((state) => generalSelector.getProject(state));

  const updateProject = (project) => {
    dispatch(uptProject(project));
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleSetupMixpanel = async () => {
    setIsLoading(true);
    try {
      const mixpanel = await mixpanelService.setup(project.uid);

      updateProject({
        ...project,
        mixpanel,
      });
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(`Failed to setup mixpanel integration, ${code}`);
      toastDanger([title, message], {actions});
    } finally {
      setIsLoading(false);
    }
  };

  const hasMixpanelSetup = project.mixpanel != null;

  return (
    <div className="settings-card integration-mixpanel">
      <div className="logo-action-wrapper">
        <img
          className="logo mixpanel-logo"
          src={MixpanelLogo}
          alt="mixpanel-logo"
        />
        {hasMixpanelSetup === false ? (
          <Button
            primary
            thin
            iconRight="icon-chevron-right"
            loading={isLoading}
            onClick={handleSetupMixpanel}>
            Connect
          </Button>
        ) : (
          <Button
            thin
            iconLeft="isax isax-setting-2"
            onClick={() => history.push(ROUTE_SETTINGS_INTEGRATIONS_MIXPANEL)}>
            Configure
          </Button>
        )}
      </div>
      <div className="integration-details-wrapper">
        <div className="subtitle-3 integration-title">
          Mixpanel
          {hasMixpanelSetup === true && (
            <div className="label-connected body-4">
              <i className="isax isax-tick-circle"></i> Connected
            </div>
          )}
        </div>
        <div className="body-3">
          Mixpanel is a product analytics tool that helps you understand how
          your users interact with your product.
        </div>
      </div>
    </div>
  );
};

export default Mixpanel;
