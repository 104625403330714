import Divider from 'components/Divider';
import InputGroup from 'components/Input';
import RadioGroup from 'components/RadioGroup';
import {BuilderContext} from 'contextes/builder';
import React, {useContext} from 'react';
import Section from 'scenes/PokeBuilder/components/Section';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {BLOCK_TYPE_VISUAL_CUE} from 'services/steps';
import {visualCues} from 'shared/front/components/Poke/components/VisualCues';
import PixelPicker from '../../components/items/PixelPicker';
import ColorItem from '../../components/sectionItems/ColorItem';
import './_Styles.scss';
import PositionInElementPicker from './components/PositionInElementPicker';
import VisualCuePicker from './components/VisualCuePicker';

const VisualCue = () => {
  const {selectedStep: step, updateBlock: uptBlock} =
    useContext(BuilderContext);

  const updateBlock = (updateObj) => {
    uptBlock(BLOCK_TYPE_VISUAL_CUE, updateObj);
  };

  const block = step?.blocks.find((b) => b.type === BLOCK_TYPE_VISUAL_CUE);

  if (block == null) {
    return <></>;
  }

  const {value, style} = block;

  const selectedVisualCue = visualCues.find((item) => item.value === value);

  const hasPrimaryColor = selectedVisualCue?.primaryColor != null;
  const hasSecondaryColor = selectedVisualCue?.secondaryColor != null;
  const hasBorderColor = selectedVisualCue?.borderColor != null;

  return (
    <div className="block-settings visual-cue">
      <Section title="Type">
        <div className="section-item visual-cue-picker">
          <VisualCuePicker
            value={value}
            onChange={({value, style: updatedStyle}) =>
              updateBlock({
                value,
                style: {...style, ...updatedStyle},
              })
            }
          />
        </div>
      </Section>
      <Divider />
      <Section title="Position in element">
        <SectionItem>
          <PositionInElementPicker
            value={style.position ?? 'bottom-right'}
            onChange={(value) => {
              updateBlock({
                style: {...style, position: value},
              });
            }}
          />
        </SectionItem>
        <SectionItem sectionItemClassName="visual-cue-offset">
          <InputGroup
            small
            value={style.offsetX ?? 0}
            type="number"
            labelTextLeft={<span className="body-4 n-600">Offset X</span>}
            onChange={(e) =>
              updateBlock({
                style: {...style, offsetX: parseFloat(e.target.value)},
              })
            }
          />
          <InputGroup
            small
            value={style.offsetY ?? 0}
            type="number"
            labelTextLeft={<span className="body-4 n-600">Offset Y</span>}
            onChange={(e) =>
              updateBlock({
                style: {...style, offsetY: parseFloat(e.target.value)},
              })
            }
          />
        </SectionItem>
      </Section>
      <Divider />
      <Section title="Size">
        <SectionItem title="Scale">
          <PixelPicker
            small
            value={style.scale ?? 1}
            min={0.1}
            max={4}
            onChange={(value) => {
              updateBlock({
                style: {...style, scale: value},
              });
            }}
            step={0.1}
            label="x"
          />
        </SectionItem>
        <SectionItem title="Rotate">
          <PixelPicker
            small
            value={style.rotate ?? 0}
            min={-360}
            max={360}
            onChange={(value) => {
              updateBlock({
                style: {...style, rotate: value},
              });
            }}
            label="°"
          />
        </SectionItem>
      </Section>
      <Divider />
      {(hasPrimaryColor || hasSecondaryColor || hasBorderColor) && (
        <>
          <Section title="Style">
            {hasPrimaryColor && (
              <ColorItem
                title="Primary color"
                value={style.primaryColor ?? null}
                onChange={(value) =>
                  updateBlock({
                    style: {...style, primaryColor: value},
                  })
                }
              />
            )}
            {hasSecondaryColor && (
              <ColorItem
                title="Secondary color"
                value={style.secondaryColor ?? null}
                onChange={(value) =>
                  updateBlock({
                    style: {...style, secondaryColor: value},
                  })
                }
              />
            )}
            {hasBorderColor && (
              <ColorItem
                title="Border color"
                value={style.borderColor ?? null}
                onChange={(value) =>
                  updateBlock({
                    style: {...style, borderColor: value},
                  })
                }
              />
            )}
          </Section>
          <Divider />
        </>
      )}
      <Section title="Animation">
        <SectionItem title="Speed">
          <PixelPicker
            small
            value={style.speed ?? 50}
            min={0}
            max={100}
            label="%"
            onChange={(value) => {
              updateBlock({
                style: {...style, speed: value},
              });
            }}
          />
        </SectionItem>
        <SectionItem title="Loop">
          <RadioGroup
            value={style.loop ?? false}
            options={[
              {label: 'Yes', value: true},
              {label: 'No', value: false},
            ]}
            onSelect={(value) => {
              updateBlock({style: {...style, loop: value}});
            }}
          />
        </SectionItem>
      </Section>
    </div>
  );
};

export default VisualCue;
